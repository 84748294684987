import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { queryParamsToString } from '../../../utils/queryParamsHelper';

const usePaginationWithUrl = (
  onChangeFn?: (value: number) => void,
): { updateUrlOnPaginationChange: (page: number) => void } => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  useEffect(() => {
    const { page } = Object.fromEntries(new URLSearchParams(search).entries());
    if (page && onChangeFn) {
      onChangeFn(Number(page));
    }
  }, [search, onChangeFn]);

  const updateUrlOnPaginationChange = (pageNumber: number) => {
    if (onChangeFn) {
      onChangeFn(Number(pageNumber));
    }

    navigate({
      pathname: pathname,
      search: queryParamsToString({ paramValue: String(pageNumber), paramName: 'page', search }),
    });
  };

  return {
    updateUrlOnPaginationChange,
  };
};

export default usePaginationWithUrl;
