import React from 'react';
import { Box, Button, useDisclosure } from '@chakra-ui/react';

import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../../contexts/ScannedRelevosContext';
import { ClearAllRelevosConfirmModal } from '../ClearAllRelevosConfirmModal/ClearAllRelevosConfirmModal';

export const ClearAllRelevos = () => {
  const translations = useTranslations();
  const confirmRemoveModal = useDisclosure();
  const { scannedRelevos } = useScannedRelevosContext();

  return (
    <Box>
      <Button
        variant="transparent"
        color="orange.500"
        aria-label={translations('scan_remove_relevo')}
        disabled={scannedRelevos.length < 1}
        title={translations('scan_clear_all_relevo')}
        onClick={confirmRemoveModal.onOpen}
        margin="auto"
        minWidth={144}
      >
        {translations('scan_clear_all_relevo')}
      </Button>
      <ClearAllRelevosConfirmModal isOpen={confirmRemoveModal.isOpen} onClose={confirmRemoveModal.onClose} />
    </Box>
  );
};
