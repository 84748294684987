import React from 'react';
import { Box } from '@chakra-ui/react';

import { ItemsSummaryAccordion } from '../../../../../../../common/components/ItemsSummaryModal/ItemsSummaryAccordion';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { DepositRelevoTableMode } from '../../../../../model/enums/deposit-relevo-table-mode.enum';
import { ErrorDepositItemResponse } from '../../../../../model/error-deposit-item-response';
import { DepositRelevoTable } from '../../../DepositRelevoTable/DepositRelevoTable';

type DepositFailureResultProps = {
  failureItems: ErrorDepositItemResponse[];
};

export const DepositFailureResult = ({ failureItems }: DepositFailureResultProps) => {
  const translations = useTranslations();

  return (
    <Box px={8} pt={4}>
      <ItemsSummaryAccordion isOpen={true} buttonText={translations('errors')}>
        <DepositRelevoTable items={failureItems} mode={DepositRelevoTableMode.ERROR} />
      </ItemsSummaryAccordion>
    </Box>
  );
};
