import React, { useEffect, useRef } from 'react';
import { Divider, Flex, HStack, Stack, useTheme } from '@chakra-ui/react';

import { RelevoInterface } from '../../../../api/types';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import ReturnStationItem from './ReturnStationItem';

type ReturnStationListProps = {
  scannedRelevos: RelevoInterface[];
};

const ReturnStationList = ({ scannedRelevos }: ReturnStationListProps): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const scrollToBottomRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (scrollToBottomRef.current) {
      scrollToBottomRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [scannedRelevos]);

  const scrollBarStyles = {
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.orange[500],
      borderRadius: '24px',
    },
    scrollbarColor: `${colors.orange[500]} ${colors.white}`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    scrollbarWidth: 'thin' as any,
  };

  return (
    <Flex width="100%" bg={colors.white} height="200px" pb={2}>
      <Stack width="100%" pt={2} maxHeight="400px">
        <HStack pl={5} pb={1} alignItems="center">
          <div style={{ width: '30%', textAlign: 'left', paddingLeft: '1em' }}>
            <h2 style={{ fontSize: '14px', color: colors.grey[200] }}>{translations('relevos_table_dish')}</h2>
          </div>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h2 style={{ fontSize: '14px', color: colors.grey[200] }}>{translations('relevos_table_size')}</h2>
          </div>
          <div style={{ width: '30%', textAlign: 'left' }}>
            <h2 style={{ fontSize: '14px', color: colors.grey[200] }}>{translations('relevos_table_name')}</h2>
          </div>
        </HStack>

        <Divider color={colors.grey[200]} />
        <Stack css={scrollBarStyles} overflowY="scroll">
          {scannedRelevos.map((relevo) => (
            <ReturnStationItem key={relevo.uId} relevo={relevo} />
          ))}
          <div ref={scrollToBottomRef} />
        </Stack>
      </Stack>
    </Flex>
  );
};

export default ReturnStationList;
