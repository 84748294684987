import React, { JSX } from 'react';
import {
  Box,
  Center,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { sanitize } from 'dompurify';

import { WordpressNewsResponse } from '../../api/types';
import colors from '../../styles/colors';
import { parseWordpressContent } from '../../utils/worpdressContent';

const defaultGreyStyle = {
  fontSize: '14px',
  color: colors.grey[200],
};

const NewsCard = (props: { news: WordpressNewsResponse }): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box pl={2}>
        <Box
          borderWidth="2px"
          borderRadius="10px"
          borderColor={colors.beige[50]}
          height="180px"
          _hover={{ cursor: 'pointer' }}
          onClick={onOpen}
        >
          <HStack width="100%">
            <Box width={['25%', '25%', '45%', '25%', '25%']} height="180px" overflow="hidden">
              <img
                src={props.news.yoast_head_json?.og_image?.[0].url}
                alt="news"
                style={{
                  borderRadius: '10px 0px 0px 10px',
                  width: 'auto',
                  height: '176px',
                  maxHeight: '100%',
                  maxWidth: 'none',
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Box width="70%">
              <VStack height="180px" textAlign="start" alignItems="start" p={4} w={'100%'}>
                <h2 style={defaultGreyStyle}>{props.news.date?.split('T')[0]}</h2>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(parseWordpressContent(props.news.title?.rendered || '')),
                  }}
                />
                <span
                  style={{ width: '100%' }}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      parseWordpressContent(props.news.content?.rendered?.substring(0, 120).concat('...') || ''),
                    ),
                  }}
                />
              </VStack>
            </Box>
          </HStack>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent h="70%">
          <ModalCloseButton />
          <Flex overflowY="scroll" h="100%" display="column" p={2}>
            <Center pt={2}>
              <img
                src={props.news.yoast_head_json?.og_image?.[0].url}
                alt="news"
                style={{
                  width: 'auto',
                  height: '180px',
                  maxHeight: '100%',
                  maxWidth: 'none',
                  objectFit: 'fill',
                  borderRadius: '10px',
                }}
              />
            </Center>
            <ModalHeader>
              <h2 style={defaultGreyStyle}>{props.news.date?.split('T')[0]}</h2>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitize(parseWordpressContent(props.news.title?.rendered || '')),
                }}
              />
            </ModalHeader>
            <ModalBody>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitize(parseWordpressContent(props.news.content?.rendered || '')),
                }}
              />
            </ModalBody>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewsCard;
