import React from 'react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Button, ButtonProps, HStack, Input, InputProps } from '@chakra-ui/react';

type QuantityInputProps = {
  inputProps: InputProps;
  incrementButtonProps: ButtonProps;
  decrementButtonProps: ButtonProps;
};

export const QuantityInput = ({ inputProps, incrementButtonProps, decrementButtonProps }: QuantityInputProps) => {
  return (
    <HStack m={'auto'} mr={0}>
      <Button
        variant={'incrementDecrementButton'}
        {...decrementButtonProps}
        color={'orange.500'}
        aria-label={'decrement'}
      >
        <MinusIcon />
      </Button>
      <Input p={0} m={0} variant={'quantity'} {...inputProps} textAlign="center" />
      <Button
        variant={'incrementDecrementButton'}
        {...incrementButtonProps}
        color={'orange.500'}
        aria-label={'increment'}
      >
        <AddIcon />
      </Button>
    </HStack>
  );
};
