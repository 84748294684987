import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { RelevoTableContainer } from '../../../../common/components/Table/TableHelper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useProfile } from '../../../../services/profile';
import { ProfileLocations } from './ProfileLocations';

export const ProfileInformationTable = () => {
  const translations = useTranslations();
  const { data: profileData } = useProfile();

  return (
    <RelevoTableContainer border={'1px solid'}>
      <Table variant="userProfile">
        <Thead>
          <Tr>
            <Th width="50%">{translations('profile_information_table_header')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{translations('profile_information_table_first_name')}</Td>
            <Td>{profileData?.firstName}</Td>
          </Tr>
          <Tr>
            <Td>{translations('profile_information_table_last_name')}</Td>
            <Td>{profileData?.lastName}</Td>
          </Tr>
          <Tr>
            <Td>{translations('profile_information_table_email')}</Td>
            <Td>{profileData?.email}</Td>
          </Tr>
          <Tr>
            <Td>{translations('profile_information_table_locations')}</Td>
            <Td>
              {profileData?.restaurantNames ? <ProfileLocations restaurantNames={profileData.restaurantNames} /> : null}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </RelevoTableContainer>
  );
};
