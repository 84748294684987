import React, { JSX } from 'react';
import { useQuery } from 'react-query';
import { Box, Stack } from '@chakra-ui/react';

import { getProductCategories } from '../../api/product-categories';
import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { NoLiveLocationsHeader } from '../../common/components/NoActiveLocationsHeader/NoLiveLocationsHeader';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useRestaurantsData } from '../../services/restaurants';
import FilteredResults from './Filters/FilteredResults';

export const CK_PRODUCT_CATEGORIES = 'categories';

const Reports = (): JSX.Element => {
  const translations = useTranslations();
  const { restaurants } = useRestaurantsData();

  const { data: productCategories } = useQuery([CK_PRODUCT_CATEGORIES], () => getProductCategories(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!restaurants.length,
  });

  return (
    <>
      <MainPageContent>
        {restaurants.length ? (
          <Box>
            <MainPageHeading>{translations('reports_heading')}</MainPageHeading>
            {productCategories ? (
              <Stack width="100%">
                <FilteredResults productCategories={productCategories} />
              </Stack>
            ) : null}
          </Box>
        ) : (
          <NoLiveLocationsHeader />
        )}
      </MainPageContent>
    </>
  );
};

export default Reports;
