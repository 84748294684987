import React, { JSX } from 'react';
import { Box, Center, VStack } from '@chakra-ui/react';

import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import colors from '../../../styles/colors';
import { ReportsDishResponse } from '../model/ReportsDishResponse';

const getIconPath = (iconName: string) => require(`../../../assets/icons/${iconName}`);
const getRelevoIcon = (category: string) =>
  category?.toLowerCase() === 'cups' ? getIconPath('cup.svg') : getIconPath('bowl.svg');

type Props = {
  dishSummary: ReportsDishResponse;
};

const boldStyle = {
  fontSize: '14px',
  fontWeight: 600,
};

const defaultStyle = {
  fontSize: '12px',
  marginTop: '0px',
  color: colors.grey[200],
};

const DishStatisticsCard = ({ dishSummary }: Props): JSX.Element => {
  const { language } = useLocalizationContext();
  return (
    <Center p={4} width="20%" height="100%">
      <VStack height="100%">
        <Box>
          <h1 style={boldStyle}>{dishSummary.totalBorrowings}</h1>
        </Box>
        <Center height="30px" width="auto">
          <img
            style={{
              maxWidth: '100%',
              maxHeight: 'none',
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
            src={getRelevoIcon(dishSummary.categoryGroup)}
            alt="Relevo"
          />
        </Center>
        <Box>
          <h2 style={defaultStyle}>{dishSummary.translations[language]}</h2>
        </Box>
      </VStack>
    </Center>
  );
};

export default DishStatisticsCard;
