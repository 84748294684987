import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios/index';

import { NOTIFICATION_SETTINGS_QUERY_KEY } from '../api/apiConsts';
import { getNotificationSettings, updateNotificationSettings } from '../api/notification-settings';
import { NotificationSettingsResponse } from '../modules/Profile/components/NotificationSettings/model/notification-settings-response';
import { UpdateNotificationSettingsRequest } from '../modules/Profile/components/NotificationSettings/model/update-notification-settings-request';

export const useNotificationSettings = (): QueryObserverResult<NotificationSettingsResponse, AxiosError> => {
  return useQuery([NOTIFICATION_SETTINGS_QUERY_KEY], () => getNotificationSettings(), {
    refetchOnWindowFocus: false,
  });
};

export const useUpdateNotificationSettings = (): UseMutationResult<
  NotificationSettingsResponse,
  AxiosError,
  { request: UpdateNotificationSettingsRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ request }: { request: UpdateNotificationSettingsRequest }) => updateNotificationSettings(request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(NOTIFICATION_SETTINGS_QUERY_KEY);
      },
    },
  );
};
