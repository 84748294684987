import React, { JSX } from 'react';
import { Button, Center, ModalHeader, Stack, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';

type InventoryMessageConfirmationModalProps = {
  onCloseModal: () => void;
};

export const InventoryMessageConfirmation = ({ onCloseModal }: InventoryMessageConfirmationModalProps): JSX.Element => {
  const translations = useTranslations();

  return (
    <Center height="500px">
      <Stack maxWidth="700px" mt={6} mx="auto" spacing={5} alignItems="center" textAlign="center">
        <ModalHeader>
          <Text>{translations('reorder_items_confirmation_header')}</Text>
        </ModalHeader>
        <Text>{translations('reorder_items_confirmation_thanks')}</Text>
        <Text>{translations('reorder_items_confirmation_delivery')}</Text>
        <Button
          onClick={onCloseModal}
          colorScheme="orange"
          title={translations('reorder_items_confirmation_close')}
          maxW="150px"
          _focus={{ boxShadow: 'none', backgroundColor: 'none' }}
          _hover={{ backgroundColor: 'none' }}
          _active={{ backgroundColor: 'none' }}
        >
          {translations('reorder_items_confirmation_close')}
        </Button>
      </Stack>
    </Center>
  );
};
