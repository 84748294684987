import React, { ChangeEvent, JSX } from 'react';
import { Input as ChakraInput, InputGroup, InputLeftElement, useTheme } from '@chakra-ui/react';

type Props = {
  type: string;
  autocomplete?: string;
  placeholder: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  icon: React.ElementType;
};

const Input = ({ type, autocomplete = 'off', placeholder, value, onChange, icon: Icon }: Props): JSX.Element => {
  const { colors } = useTheme();
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Icon boxSize={5} mt={2} color={colors.orange[500]} />
      </InputLeftElement>
      <ChakraInput
        type={type}
        id={type}
        autoComplete={autocomplete}
        required
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        size="lg"
        variant="flushed"
        color={colors.orange[500]}
        borderColor={colors.orange[500]}
        focusBorderColor={colors.orange[500]}
        mb={4}
      />
    </InputGroup>
  );
};

export default Input;
