import React, { JSX, useMemo } from 'react';
import { Center, Spinner, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { VisibilityTableState } from '@tanstack/table-core/src/features/ColumnVisibility';
import { useTranslations } from 'contexts/LocalizationContext';

import { CategorySummary, RelevoInventoryResponse } from '../../../api/types';
import { TableType } from '../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../common/components/Table/Table';
import { TableItemWithImage } from '../../../common/components/TableItemWithImage';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { volumePerWeek } from '../utils';

type InventoryTableProps = {
  isTwoFactorReturnEnabled: boolean;
  restaurantData: RelevoInventoryResponse | null;
  fetchMetadata: {
    isError: boolean;
    isLoading: boolean;
  };
};

const InventoryTable = ({
  isTwoFactorReturnEnabled,
  restaurantData,
  fetchMetadata,
}: InventoryTableProps): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();

  const columns = useMemo<ColumnDef<CategorySummary>[]>(
    () => [
      {
        id: 'dish',
        header: translations('inventory_table_dish'),
        cell: ({ row }) => (
          <TableItemWithImage
            text={row.original.translations ? row.original.translations[language] : ''}
            imageUrl={row.original.imageUrl}
          />
        ),
      },
      {
        id: 'itemsCount',
        header: translations('inventory_table_items_count'),
        cell: ({ row }) => <>{row.original.restaurantItemsCount.toString()}</>,
      },
      {
        id: 'pendingItemsCount',
        header: translations('inventory_table_pending_items_count'),
        cell: ({ row }) => <>{row.original.pendingReturnItemsCount.toString()}</>,
      },
      {
        id: 'volumePerWeek',
        header: translations('inventory_table_volume_per_week'),
        cell: ({ row }) => (
          <>
            {row.original.startingDate
              ? volumePerWeek(row.original.givenOutItemsCount, row.original.startingDate).toFixed(1)
              : '0.0'}
          </>
        ),
      },
    ],
    [language, translations],
  );

  const tableState: VisibilityTableState = useMemo(() => {
    return {
      columnVisibility: {
        dish: true,
        itemsCount: true,
        pendingItemsCount: isTwoFactorReturnEnabled,
        volumePerWeek: true,
      },
    };
  }, [isTwoFactorReturnEnabled]);

  if (fetchMetadata.isError) {
    return <Text color="orange.500">{translations('something_went_wrong')}</Text>;
  }

  return (
    <>
      {restaurantData ? (
        <Table
          type={TableType.SIMPLE}
          data={restaurantData.categoriesSummary}
          isLoading={fetchMetadata.isLoading}
          columns={columns}
          tableState={tableState}
          withBorder
        />
      ) : (
        <Center>
          <Spinner />
        </Center>
      )}
    </>
  );
};

export default InventoryTable;
