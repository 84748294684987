import React, { JSX, useCallback, useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { HUBSPOT_CONTACT_FORMS_IDS } from './HubspotContactForm.consts';

const HubspotContactForm = (): JSX.Element => {
  const { language } = useLocalizationContext();

  const createHubspotForm = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.hbspt) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '6959162',
        formId: HUBSPOT_CONTACT_FORMS_IDS[language],
        target: '#hubspotContactForm',
      });
    }
  }, [language]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', createHubspotForm);
    return () => {
      window.removeEventListener('load', createHubspotForm);
    };
  }, [createHubspotForm]);

  return (
    <Box px={[5, 5, 100]}>
      <Box id="hubspotContactForm"></Box>
    </Box>
  );
};

export default HubspotContactForm;
