import { useQuery } from 'react-query';

import { getLocationsStatistics } from '../api/community-statistics';
import { BaseQueryParams } from '../common/models/base-query-params';
import { CK_LOCATION_STATISTICS, SelectedRestaurantOptions, TimeFrameOptions } from '../modules/Locations/Locations';

export const useGetLocationsStatistics = (
  selectedTimeframeOption: TimeFrameOptions,
  selectedRestaurantOptions: SelectedRestaurantOptions[],
  params: BaseQueryParams,
) => {
  return useQuery(
    [CK_LOCATION_STATISTICS, { selectedTimeframeOption, selectedRestaurantOptions, ...params }],
    () =>
      getLocationsStatistics(
        selectedRestaurantOptions.map((restaurant) => restaurant.value),
        selectedTimeframeOption.value,
        params,
      ),
    {
      enabled: !!selectedRestaurantOptions.length && !!selectedTimeframeOption,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
};
