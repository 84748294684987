import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, useTheme } from '@chakra-ui/react';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';

const Confirmation = (): JSX.Element => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { colors } = useTheme();

  return (
    <>
      <Heading size="xl" color={colors.orange[500]} mb={8}>
        {translations('reset_password_confirm_success')}
      </Heading>
      <Button
        onClick={() => navigate(Routes.SIGNIN_PATH)}
        title={translations('reset_password_confirm_sign_in')}
        colorScheme="orange"
      >
        {translations('reset_password_confirm_sign_in')}
      </Button>
    </>
  );
};

export default Confirmation;
