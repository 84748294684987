import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { DEPOSIT_QUERY_KEY } from '../api/apiConsts';
import { borrowDepositItems, returnDepositItems } from '../api/deposit';
import { BorrowDepositResponse } from '../modules/Deposit/model/borrow-deposit-response';
import { DepositRequest } from '../modules/Deposit/model/deposit-request';
import { ReturnDepositResponse } from '../modules/Deposit/model/return-deposit-response';

export const useBorrowDepositItems = (): UseMutationResult<
  BorrowDepositResponse,
  AxiosError,
  { restaurantId: string; request: DepositRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ restaurantId, request }: { restaurantId: string; request: DepositRequest }) =>
      borrowDepositItems(restaurantId, request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(DEPOSIT_QUERY_KEY);
      },
    },
  );
};

export const useReturnDepositItems = (): UseMutationResult<
  ReturnDepositResponse,
  AxiosError,
  { restaurantId: string; request: DepositRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ restaurantId, request }: { restaurantId: string; request: DepositRequest }) =>
      returnDepositItems(restaurantId, request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(DEPOSIT_QUERY_KEY);
      },
    },
  );
};
