export const handleReturnTime = (returnTime: number): { returnTimeDays: number; returnTimeHours: number } => {
  let returnTimeDays = Math.floor(Number(returnTime));
  let returnTimeHours = Math.floor((Number(returnTime) - returnTimeDays) * 24) + 1;
  if (returnTimeHours === 24) {
    returnTimeDays += 1;
    returnTimeHours = 0;
  }
  return { returnTimeDays, returnTimeHours };
};

export const handleReturnRate = (returnRate?: number): string => {
  return returnRate && Number(returnRate) > 0 ? `${Math.floor(Number(returnRate) * 100)}%` : `-%`;
};
