import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import Dialog from '../../../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useSubmitInventoryCount } from '../../../../../../services/inventory-counts';
import { useInventoryCountsContext } from '../../../../context/hooks/use-inventory-counts-context.hook';
import { InventoryCountsFormValues } from '../../../../model/inventory-counts-form-values';

type SubmitResultsProps = {
  handleSubmitSuccess: () => void;
};

export const SubmitResults = ({ handleSubmitSuccess }: SubmitResultsProps) => {
  const translations = useTranslations();
  const { activeStep, setActiveStep, currentInventoryCountId } = useInventoryCountsContext();
  const confirmBackCancelRef = useRef(null);
  const backInventoryCountConfirmModal = useDisclosure();
  const { handleSubmit } = useFormContext<InventoryCountsFormValues>();
  const { mutate: submitInventoryCount, isLoading: isSubmitting } = useSubmitInventoryCount();

  const handleSubmitResults = () => {
    backInventoryCountConfirmModal.onOpen();
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleConfirm = handleSubmit((form) => {
    if (currentInventoryCountId) {
      const additionalInformation = form.additionalInformation?.trim();
      submitInventoryCount(
        {
          inventoryCountId: currentInventoryCountId,
          submitRequest: { additionalInformation: additionalInformation?.length ? additionalInformation : undefined },
        },
        {
          onSuccess: () => {
            handleSubmitSuccess();
          },
        },
      );
    }
  });

  return (
    <>
      <Flex justifyContent="flex-end" w={'100%'} px={[2, 2, 4, 4]}>
        <Box w={['50%', '50%', '200px', '200px']} p={1}>
          <Button w={'100%'} onClick={handleBack} variant={'white'} leftIcon={<ArrowLeftIcon h={3} w={3} />}>
            {translations('inventory_counts_back_button_label')}
          </Button>
        </Box>
        <Box w={['50%', '50%', '200px', '200px']} p={1}>
          <Button w={'100%'} onClick={handleSubmitResults} variant={'orangeSolid'}>
            {translations('inventory_counts_submit_results_button_label')}
          </Button>
        </Box>
      </Flex>
      <Dialog
        isLoading={isSubmitting}
        leastDestructiveRef={confirmBackCancelRef}
        isOpen={backInventoryCountConfirmModal.isOpen}
        onClose={backInventoryCountConfirmModal.onClose}
        onConfirm={handleConfirm}
        confirmText={translations('inventory_counts_submit_results_confirm_button_label')}
        cancelText={translations('cancel')}
      >
        <Text fontSize={'20px'}>{translations('inventory_counts_submit_results_confirm')}</Text>
      </Dialog>
    </>
  );
};
