const urlPattern = new RegExp(
  '^(http:\\/\\/|https:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})' + // domain name
    '(\\/[-a-z\\d%_.~+]*)*', // path
  'i',
);

export const isValidStringUrl = (url: string): boolean => {
  try {
    return urlPattern.test(url);
  } catch (e) {
    return false;
  }
};
