import React, { JSX, useState } from 'react';
import { useMutation } from 'react-query';
import { Box } from '@chakra-ui/react';
import { SupertokensApiErrorStatus, SupertokensApiSuccessStatus } from 'api/types';
import { resetPassword } from 'api/user';

import Confirmation from './Confirmation';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { mutate, isLoading } = useMutation(resetPassword, {
    onSuccess: (data) => {
      const status = data?.status;
      if (status !== SupertokensApiSuccessStatus.OK) {
        if (status === SupertokensApiErrorStatus.RESET_PASSWORD_INVALID_TOKEN_ERROR) {
          setErrorMessage('reset_password_invalid_token');
        } else {
          setErrorMessage('something_went_wrong');
        }
      } else {
        setShowConfirmation(true);
      }
    },
    onError: () => {
      setErrorMessage('something_went_wrong');
    },
  });

  return (
    <Box p={4} mx="auto" textAlign="center" maxWidth="500px">
      {showConfirmation ? (
        <Confirmation />
      ) : (
        <ResetPasswordForm
          resetPassword={mutate}
          isLoading={isLoading}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
    </Box>
  );
};

export default ResetPassword;
