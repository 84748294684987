import { apiPaths } from '../config/apiPaths';
import { ReportsStatisticsResponse } from '../modules/Reports/model/ReportsStatisticsResponse';
import axiosInstance from './axios';

export const getReportsStatistics = (
  restaurantIds: string[],
  categoryIds: string[],
  startDate?: string,
  endDate?: string,
): Promise<ReportsStatisticsResponse[]> => {
  const urlSearchParams = new URLSearchParams();
  restaurantIds.forEach((restaurantId) => urlSearchParams.append('restaurantIds[]', restaurantId));
  categoryIds.forEach((categoryId) => urlSearchParams.append('categoryIds[]', categoryId));
  if (startDate) {
    urlSearchParams.append('startDate', startDate);
  }
  if (endDate) {
    urlSearchParams.append('endDate', endDate);
  }

  return axiosInstance
    .get<ReportsStatisticsResponse[]>(apiPaths.REPORTS_STATISTICS_PATH, { params: urlSearchParams })
    .then(({ data }) => data);
};
