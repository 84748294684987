import React, { JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { AttachmentIcon } from '@chakra-ui/icons';
import { Box, Container, Flex, HStack, Text, useTheme } from '@chakra-ui/react';

import { FileInput } from '../../../../common/components/FileInput';
import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import { MainPageContent } from '../../../../common/components/MainPageContent';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import CustomerCreationFormHeading from '../../components/CustomerCreationFormHeading/CustomerCreationFormHeading';
import { CustomerCreationFormData } from '../../model/CustomerCreationFormData';
import { getRequiredValidationObject } from '../../validation/formValidation';

const EXCEL_CALCULATOR_FILE_MAX_SIZE_IN_BYTES = 50 * 1024 * 1024; // 50MB
const EXCEL_CALCULATOR_FILES_NUMBER_LIMIT = 10;

const isFileTooLarge = (fileSize: number) => {
  return fileSize > EXCEL_CALCULATOR_FILE_MAX_SIZE_IN_BYTES;
};

const isFileLimitExceeded = (filesNumber: number) => {
  return filesNumber > EXCEL_CALCULATOR_FILES_NUMBER_LIMIT;
};

const ExcelCalculatorUpload = (): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const requiredValidation = getRequiredValidationObject(translations('new_customer_form_validation_required'));
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<CustomerCreationFormData>();

  const getUploadedFiles = () => (watch('calculatorFiles') ? (Array.from(watch('calculatorFiles')) as File[]) : []);

  return (
    <MainPageContent>
      <Container maxW={['80%', '60%', '52%']} pt={2} pb={12}>
        <Box pb={12}>
          <CustomerCreationFormHeading heading={translations('new_customer_form_upload_excel_calculator_header')} />
        </Box>
        <Box pt={12}>
          <FormGroup
            reverseLayout={true}
            isVertical={true}
            isCentred={true}
            label={translations('new_customer_form_upload_excel_calculator_information')}
            inputId="calculatorFiles"
            validationError={errors.calculatorFiles?.message}
          >
            <FileInput
              uploadButtonLabel={translations('new_customer_form_upload_button')}
              accept=".xlsx,.xls,.csv"
              register={register('calculatorFiles', {
                required: requiredValidation,
                validate: (value) => {
                  const tooLargeFileUploaded = getUploadedFiles().find((file) => isFileTooLarge(file.size));
                  return (
                    (!isFileLimitExceeded(value.length) && !tooLargeFileUploaded) ||
                    translations('new_customer_form_excel_calculator_limit')
                  );
                },
              })}
              aria-label="calculatorFiles"
              backgroundColor={colors.orange[500]}
              multiple={true}
            />
          </FormGroup>

          <Flex
            alignItems="center"
            direction="column"
            justifyContent="center"
            pt={6}
            width="100%"
            pb={getUploadedFiles().length ? 0 : 8}
          >
            {getUploadedFiles().map((file) => (
              <HStack mt={2} key={file.lastModified}>
                <AttachmentIcon color={isFileTooLarge(file.size) ? colors.red[500] : colors.green[500]} w={10} h={6} />
                <Text color={colors.grey[200]} mb={2}>
                  {file.name}
                </Text>
                {isFileTooLarge(file.size) && (
                  <Text color={colors.red[500]} fontSize="12px" mb={2}>
                    {translations('new_customer_form_excel_calculator_file_too_large')}
                  </Text>
                )}
              </HStack>
            ))}
          </Flex>
        </Box>
      </Container>
    </MainPageContent>
  );
};

export default ExcelCalculatorUpload;
