import React, { ComponentProps, JSX } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

type LinkButtonProps = {
  isDownload?: boolean;
  to: string;
};

export const LinkButton = ({
  to,
  children,
  isDownload,
  ...props
}: ComponentProps<typeof Button> & LinkButtonProps): JSX.Element => {
  const LinkElement = (isDownload ? 'a' : Link) as keyof JSX.IntrinsicElements;
  const elementProps = isDownload ? { href: to, download: true } : { to };
  return (
    <>
      <LinkElement {...elementProps}>
        <Button {...props}>{children}</Button>
      </LinkElement>
    </>
  );
};
