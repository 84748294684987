export const isValidUrl = (urlString: string): boolean => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const extractUId = (qrCode: string): string | null => {
  if (isValidUrl(qrCode)) {
    return new URL(qrCode).searchParams.get('uid');
  }
  return qrCode;
};
