import React, { ComponentProps, JSX } from 'react';
import { Message, UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { Input } from '@chakra-ui/react';

import colors from '../../../styles/colors';
import { FormGroup } from '../FormGroup/FormGroup';

type FormInputProps = {
  id: string;
  useFormRegisterReturn: UseFormRegisterReturn;
  setValue: UseFormSetValue<any>;
  label: string;
  isBold?: boolean;
  isIndented?: boolean;
  isInvalid?: boolean;
  validationError?: Message;
} & ComponentProps<typeof Input>;

const inputDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: 'none' },
  _hover: { backgroundColor: 'none' },
  _active: { backgroundColor: 'none' },
  height: '28px',
  borderRadius: 0,
  border: 'none',
  backgroundColor: colors.beige[50],
};

const FormInput = ({
  id,
  useFormRegisterReturn,
  isInvalid,
  setValue,
  label,
  isBold = true,
  isIndented = false,
  validationError,
  ...inputAttributes
}: FormInputProps): JSX.Element => {
  const trimValue = (event: React.FocusEvent<HTMLInputElement>) => setValue(id, event.target.value.trim());

  return (
    <FormGroup label={label} inputId={id} isBold={isBold} isIndented={isIndented} validationError={validationError}>
      <Input
        {...inputDefaultProps}
        my="auto"
        id={id}
        {...useFormRegisterReturn}
        isInvalid={isInvalid}
        {...inputAttributes}
        onBlur={trimValue}
      />
    </FormGroup>
  );
};

export default FormInput;
