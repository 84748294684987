import { StylesConfig } from 'react-select';

import colors from '../../../styles/colors';

export const getAssignSelectStyle = <T>(): StylesConfig<T> => {
  return {
    menu: (base) => ({
      ...base,
      zIndex: 1000,
    }),
    control: (base, state) => ({
      ...base,
      borderTop: '0px !important',
      borderLeft: '0px !important',
      borderRight: '0px !important',
      borderRadius: '0 !important',
      borderBottom: `1px thin !important`,
      opacity: state.isDisabled ? 0.4 : 1,
      borderColor: state.isFocused ? colors.orange[500] : colors.grey[200],
      minHeight: '40px',
      height: '40px',
      boxShadow: '0 !important',
      fontStyle: 'Rubik',
      fontSize: '16px',
      textAlign: 'left',
      background: 'none',
      '&:hover': {
        boxShadow: '0 !important',
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (styles) => ({
      ...styles,
      paddingTop: 6,
      paddingBottom: 6,
    }),
    option: (base, state) => ({
      ...base,
      minHeight: '30px',
      paddingTop: 6,
      paddingBottom: 6,
      fontStyle: 'Rubik',
      fontSize: '16px',
      textAlign: 'left',
      whiteSpace: 'normal',
      fontWeight: state.isSelected ? '600' : '100',
      background: colors.white,
      color: colors.black,
      '&:hover': {
        cursor: 'pointer',
        background: colors.grey[50],
      },
    }),
  };
};
