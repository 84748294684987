import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    padding: 2,
    borderColor: 'orange.500',
    borderRadius: 0,
  },
});

const Checkbox = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'md',
  },
});

export default Checkbox;
