import React, { createContext, useEffect, useMemo, useState } from 'react';
import Session from 'supertokens-auth-react/recipe/session';

interface InventoryCountInProgress {
  expiresAt: string;
}

export interface RestaurantInfo {
  id: string;
  restaurantName: string;
  isTwoFactorReturnEnabled: boolean;
  isDepositEnabled: boolean;
  isQuickContactFeatureEnabled: boolean;
  inventoryCountInProgress: InventoryCountInProgress | null;
}

export enum Role {
  MANAGER = 'MANAGER',
  EMPLOYEE = 'EMPLOYEE',
  RETURN_STATION = 'RETURN_STATION',
}

export interface SessionContextInterface {
  doesSessionExist: boolean;
  message: string | null;
  role: Role | null;
  fnbLocationAccountName: string | null;
}

const defaultSessionContext = {
  doesSessionExist: false,
  message: null,
  role: null,
  fnbLocationAccountName: null,
};

export const SessionContext = createContext<SessionContextInterface>(defaultSessionContext);

export const SessionContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const sessionContext = Session.useSessionContext();
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!sessionContext.loading && sessionContext.doesSessionExist) setMessage(null);
  }, [sessionContext]);

  const value = useMemo(() => {
    if (sessionContext.loading || !sessionContext.doesSessionExist) {
      return defaultSessionContext;
    }
    const { fnbLocationAccountName, role } = sessionContext.accessTokenPayload;

    return {
      doesSessionExist: true,
      message,
      role,
      fnbLocationAccountName,
    };
  }, [sessionContext, message]);

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};
