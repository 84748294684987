import { useContext } from 'react';

import { HistoryFilterContext } from '../HistoryFilterContext';
import { HistoryFilterContextInterface } from '../model/HistoryFilterContext';

export const useHistoryFilterContext = (): HistoryFilterContextInterface => {
  const ctx = useContext(HistoryFilterContext);
  if (!ctx) {
    throw new Error('No context provided for HistoryFilter');
  }
  return ctx;
};
