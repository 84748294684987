import React, { JSX } from 'react';
import { Box, BoxProps, Heading, HStack, Image, useTheme } from '@chakra-ui/react';

type GraphBoxProps = {
  iconSrc: string;
  iconAlt: string;
  heading: string;
  children: React.ReactNode | React.ReactNode[];
  boxProps?: BoxProps;
};
const GraphBox = ({ iconSrc, iconAlt, heading, children, boxProps }: GraphBoxProps): JSX.Element => {
  const { colors } = useTheme();
  return (
    <Box border="2px solid" borderColor={colors.beige[50]} borderRadius="6px" mt={6} {...boxProps}>
      <HStack>
        <Image src={iconSrc} alt={iconAlt} width="auto" maxHeight="100%" maxWidth="none" display="inline-block" />
        <Heading as="h3" fontSize={16} color={colors.grey[200]} fontWeight="bolder">
          {heading}
        </Heading>
      </HStack>
      {children}
    </Box>
  );
};

export default GraphBox;
