import React, { Dispatch, JSX, SetStateAction } from 'react';
import { Button, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Text } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { SendResetPasswordEmailStep, SendResetPasswordEmailStepProps } from '../../../types';

interface Props extends SendResetPasswordEmailStepProps {
  setEmail: (email: string) => void;
  setCurrentStep: Dispatch<SetStateAction<SendResetPasswordEmailStep>>;
}

const Success = ({ currentStep, setCurrentStep, closeModal, setEmail }: Props): JSX.Element | null => {
  const translations = useTranslations();

  const handleResetEmailClick = () => {
    setEmail('');
    setCurrentStep(SendResetPasswordEmailStep.FormStep);
  };

  if (currentStep !== SendResetPasswordEmailStep.SuccessStep) return null;

  return (
    <>
      <ModalHeader>{translations('reset_password_email_sent')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <Text mb={4}>{translations('reset_password_email_sent_check_inbox')}</Text>
      </ModalBody>
      <ModalFooter textAlign="center">
        <Button title={translations('reset_password_email_sent_resend')} mr={4} onClick={handleResetEmailClick}>
          {translations('reset_password_email_sent_resend')}
        </Button>
        <Button onClick={closeModal} colorScheme="orange" title={translations('cancel')}>
          {translations('cancel')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default Success;
