import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button } from '@chakra-ui/react';

import { getRelevosHistoryExport } from '../../../../api/relevos';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { formatDate } from '../../../../utils/dateHelper';
import { downloadFile } from '../../../../utils/filesHelper';
import { useHistoryFilterContext } from '../../context/HistoryFilterContext/hooks/useHistoryFilterContext';
import { buildHistoryQueryParams } from '../../utils/history.helper';

const HistoryExport = (): JSX.Element => {
  const translations = useTranslations();
  const [exportDisabled, setExportDisabled] = useState(false);
  const { language } = useLocalizationContext();
  const { historyState } = useHistoryFilterContext();

  const handleEnableExportButton = () => setExportDisabled(false);
  const handleDisableExportButton = () => setExportDisabled(true);
  const handleError = () => toast.error(<b>{translations('something_went_wrong')}</b>);

  const handleHistoryExport = () => {
    handleDisableExportButton();
    getRelevosHistoryExport(buildHistoryQueryParams(historyState), language)
      .then((response) => downloadFile(new Blob([response]), `relevo-history-${formatDate(new Date())}.csv`))
      .catch(handleError)
      .finally(handleEnableExportButton);
  };

  return (
    <Box pt={2} mr={2}>
      <Button variant="orangeSolid" px={8} isLoading={exportDisabled} onClick={handleHistoryExport}>
        {translations('export')}
      </Button>
    </Box>
  );
};

export default HistoryExport;
