import React, { JSX } from 'react';
import { Heading } from '@chakra-ui/react';

type MainPageHeadingProps = {
  children: React.ReactNode;
};

export const PageSectionHeading = ({ children }: MainPageHeadingProps): JSX.Element => {
  return (
    <Heading variant="pageSubheading" textAlign="left" ml={0} pt={3}>
      {children}
    </Heading>
  );
};
