/* eslint-disable import/prefer-default-export */
import { ApplicationUser } from 'api/types';
import { apiPaths } from 'config/apiPaths';

import axios from './axios';

export enum SearchUserType {
  Application = 'Application',
  Card = 'Card',
}

export const getApplicationUsers = async (
  search: string,
  searchUserType?: SearchUserType,
): Promise<ApplicationUser[]> => {
  return axios
    .get<ApplicationUser[]>(apiPaths.APPLICATION_USERS_PATH, { params: { search, searchUserType } })
    .then((response) => response.data);
};
