import React, { useMemo } from 'react';
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { normalizeString } from '../../../utils/stringHelper';
import { DraggableTable } from './DraggableTable';

export type IdRow = {
  uniqueId: string;
};

type DndTableProps<T extends IdRow> = {
  entry: T[];
  columns: ColumnDef<T>[];
  onNewOrder: (newOrder: T[]) => void;
  isReorderMode: boolean;
  search?: string;
  variant?: string;
};

export const DndTable = <T extends IdRow>({
  entry,
  search,
  columns,
  onNewOrder,
  variant,
  isReorderMode,
}: DndTableProps<T>) => {
  const data = useMemo(() => {
    if (search && search.length) {
      return entry.filter((contact) =>
        Object.values(contact).some((v) => normalizeString(v).includes(normalizeString(search))),
      );
    }
    return entry;
  }, [entry, search]);

  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.uniqueId, // required because row indexes will change
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  const dataIds = React.useMemo<UniqueIdentifier[]>(() => data?.map(({ uniqueId }) => uniqueId), [data]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = dataIds.indexOf(active.id);
      const newIndex = dataIds.indexOf(over.id);
      const newData = arrayMove(data, oldIndex, newIndex);
      onNewOrder(newData);
    }
  };

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  return (
    <>
      {isReorderMode ? (
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <DraggableTable
            headerGroups={table.getHeaderGroups()}
            rowModel={table.getRowModel()}
            data={data}
            dataIds={dataIds}
            variant={variant}
          />
        </DndContext>
      ) : (
        <DraggableTable
          headerGroups={table.getHeaderGroups()}
          rowModel={table.getRowModel()}
          data={data}
          dataIds={dataIds}
          variant={variant}
        />
      )}
    </>
  );
};
