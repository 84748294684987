import React, { JSX } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Input, Stack, Text, Textarea } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import { convertArrayForMessage } from 'utils/stringHelper';

import { ContactMessageInputs } from './types';

type Props = {
  onSubmit: (request: ContactMessageInputs) => void;
  isLoading: boolean;
  defaultSubject?: string;
  defaultBody?: string;
  belowSubject?: string[];
  belowBody?: string[];
  nonMessageInformation?: string[];
};

const MessageForm = ({
  onSubmit,
  isLoading,
  defaultSubject = '',
  defaultBody = '',
  belowSubject = [],
  belowBody = [],
  nonMessageInformation = [],
}: Props): JSX.Element => {
  const translations = useTranslations();
  const { control, handleSubmit } = useForm<ContactMessageInputs>();

  const onFormSubmit = (data: ContactMessageInputs) => {
    const { subject, body } = data;
    const parsedBodyArray = [];
    if (belowSubject?.length > 0) {
      parsedBodyArray.push(convertArrayForMessage(belowSubject).concat('\n\n'));
    }
    parsedBodyArray.push(body.concat('\n\n'));
    if (belowBody?.length > 0) {
      parsedBodyArray.push(convertArrayForMessage(belowBody));
    }
    onSubmit({ subject, body: parsedBodyArray.join('') });
  };

  return (
    <Box textAlign="center">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Stack maxWidth="700px" mt={6} mx="auto" spacing={5} alignItems="center">
          <Controller
            name="subject"
            control={control}
            defaultValue={defaultSubject}
            render={({ field: { onChange, value } }) => (
              <Input required onChange={onChange} value={value} placeholder={translations('contact_subject')} />
            )}
          />
          <Stack width="100%" textAlign="left" spacing={4} pl={3}>
            {belowSubject.map((belowSubjectLine, index) => (
              <Text key={index}>{belowSubjectLine}</Text>
            ))}
          </Stack>
          <Controller
            name="body"
            control={control}
            defaultValue={defaultBody}
            render={({ field: { onChange, value } }) => (
              <Textarea
                required
                rows={10}
                onChange={onChange}
                value={value}
                placeholder={translations('contact_body')}
                height={['5em', '10em', '17em']}
                overscrollBehaviorY="contain"
              />
            )}
          />
          <Stack width="100%" textAlign="left" spacing={4} pl={3}>
            {belowBody.map((belowBodyLine, index) => (
              <Text key={index}>{belowBodyLine}</Text>
            ))}
          </Stack>
          <Stack width="100%" textAlign="left" spacing={4} pl={3}>
            {nonMessageInformation.map((informationLine, index) => (
              <Text key={index}>{informationLine}</Text>
            ))}
          </Stack>
          <Button
            alignSelf="flex-end"
            type="submit"
            colorScheme="orange"
            title={translations('contact_send')}
            maxW="150px"
            _focus={{ boxShadow: 'none', backgroundColor: 'none' }}
            _hover={{ backgroundColor: 'none' }}
            _active={{ backgroundColor: 'none' }}
            isLoading={isLoading}
          >
            {translations('contact_send')}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default MessageForm;
