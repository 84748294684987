import React, { JSX } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { useTranslations } from '../../contexts/LocalizationContext';
import { CommunityStatisticsReponse } from './model/CommunityStatisticsReponse';
import RelevoCommunityCard from './RelevoCommunityCard';

const getIconPath = (iconName: string) => require(`../../assets/icons/${iconName}`);

const AccountStatistics = (props: {
  restaurantIds: string[];
  statistics: CommunityStatisticsReponse | undefined;
}): JSX.Element => {
  const translations = useTranslations();

  return (
    <Flex width="100%" wrap="wrap">
      <Box width={['100%', '100%', '50%', '33%', '33%']} pr={[2, 2, 2, 2, 2]}>
        <RelevoCommunityCard
          imgSrc={getIconPath('account_locations.svg')}
          imgAlt="account locations"
          statsNumber={props.restaurantIds.length}
          statsDescription={translations('community_account_locations')}
          minWidth="300px"
        />
      </Box>
      <Box width={['100%', '100%', '50%', '33%', '33%']} pr={2} pt={[2, 2, 0, 0, 0]}>
        <RelevoCommunityCard
          imgSrc={getIconPath('account_borrowings.svg')}
          imgAlt="account saves"
          statsNumber={props.statistics?.totalAccountSaves}
          statsDescription={translations('community_account_saves')}
          minWidth="300px"
        />
      </Box>
      <Box width={['100%', '100%', '50%', '33%', '33%']} pr={[2, 2, 2, 2, 2]} pt={[2, 2, 2, 0, 0]}>
        <RelevoCommunityCard
          imgSrc={getIconPath('account_users.svg')}
          imgAlt="account users"
          statsNumber={props.statistics?.totalAccountUsers}
          statsDescription={translations('community_account_users')}
          minWidth="300px"
        />
      </Box>
    </Flex>
  );
};

export default AccountStatistics;
