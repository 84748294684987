import React, { ComponentProps, JSX, useState } from 'react';
import ReactDatepicker, { CalendarContainer } from 'react-datepicker';
import { Input, useTheme } from '@chakra-ui/react';
import { LOCALIZATION_CONFIG } from 'config/configConsts';
import de from 'date-fns/locale/de';

import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.css';

import config from '../../../config/config';

const datePickerDefaultStyle = {
  backgroundColor: 'white',
  border: 'none',
  borderRadius: 4,
  minHeight: '30px',
  height: '30px',
  paddingTop: 6,
  paddingBottom: 6,
  fontStyle: 'Rubik',
  fontSize: '14px',
  width: '100%',
};

type DatepickerProps = {
  onDateChange: (date?: string) => void;
  placeholderText?: string;
};

const Datepicker = ({
  onDateChange,
  placeholderText,
  ...props
}: DatepickerProps & Omit<ComponentProps<typeof ReactDatepicker>, 'customInput' | 'onChange'>): JSX.Element => {
  const [value, setValue] = useState<Date | null>();
  const { colors } = useTheme();

  return (
    <CalendarContainer>
      <ReactDatepicker
        locale={de}
        selected={value}
        customInput={
          <Input
            style={datePickerDefaultStyle}
            color="black"
            _placeholder={{ color: colors.grey[50] }}
            _focus={{ boxShadow: 'none' }}
          />
        }
        dateFormat={LOCALIZATION_CONFIG[config.language].dateFormat}
        placeholderText={placeholderText}
        onChange={(currentValue: Date | null) => {
          setValue(currentValue);
          onDateChange(currentValue ? currentValue?.toISOString() : undefined);
        }}
        isClearable
        {...props}
      />
    </CalendarContainer>
  );
};

export default Datepicker;
