import React, { JSX } from 'react';
import { Center, Image } from '@chakra-ui/react';

type MenuItemProps = {
  src: string;
};

export const MenuItemImage = ({ src }: MenuItemProps): JSX.Element => {
  return (
    <Center width="35px">
      <Image src={src} alt="Menu item icon" display="flex" height="22px" width="22px" />
    </Center>
  );
};
