import React, { useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { CategoryQuantity, RelevosProductCategoryWithImage } from 'api/types';

import { TableType } from '../../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../../common/components/Table/Table';
import { TableItemWithImage } from '../../../../../common/components/TableItemWithImage';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import CategoriesListItem from './CategoriesListItem';

type Props = {
  updateQuantityOfItemsInCategory: (categoryId: string, quantity: number) => void;
  categoriesToCreate: CategoryQuantity;
  categories: RelevosProductCategoryWithImage[];
};

const CategoriesList = ({ updateQuantityOfItemsInCategory, categoriesToCreate, categories }: Props) => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();

  const columns = useMemo<ColumnDef<RelevosProductCategoryWithImage>[]>(
    () => [
      {
        id: 'category',
        header: translations('category_list_category'),
        cell: ({ row }) => (
          <TableItemWithImage
            text={row.original.translations ? row.original.translations[language] : ''}
            imageUrl={row.original.imageUrl}
          />
        ),
      },
      {
        id: 'quantity',
        header: translations('category_list_quantity'),
        cell: ({ row }) => (
          <Flex w={'100%'} justifyContent="flex-end">
            <Box w={'150px'}>
              <CategoriesListItem
                key={row.original.id}
                category={row.original}
                updateQuantityOfItemsInCategory={updateQuantityOfItemsInCategory}
                categoryQuantity={categoriesToCreate[row.original.id] || 0}
              />
            </Box>
          </Flex>
        ),
      },
    ],
    [categoriesToCreate, language, translations, updateQuantityOfItemsInCategory],
  );

  return (
    <Box bg={'white'} mt={2}>
      <Table type={TableType.SIMPLE} data={categories} columns={columns} />
    </Box>
  );
};

export default CategoriesList;
