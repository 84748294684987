import { useMemo } from 'react';

import { useInventoryCount } from '../../../../../../../../services/inventory-counts';
import { useInventoryCountsContext } from '../../../../../../context/hooks/use-inventory-counts-context.hook';

export const useItemOverview = () => {
  const { currentInventoryCountId } = useInventoryCountsContext();
  const {
    data: inventoryCount,
    isLoading: isLoadingInventoryCount,
    isFetching: isFetchingInventoryCount,
  } = useInventoryCount(currentInventoryCountId || '');

  const inventoryTotalItems = useMemo(() => {
    if (!inventoryCount) {
      return 0;
    }
    return inventoryCount.currentInventoryScannedItems
      ?.map((inventory) => inventory.currentInventory)
      .reduce((acc, currentValue) => acc + currentValue, 0);
  }, [inventoryCount]);

  return { inventoryCount, isLoading: isLoadingInventoryCount || isFetchingInventoryCount, inventoryTotalItems };
};
