import React from 'react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Image, useTheme } from '@chakra-ui/react';
import { RelevoInterface } from 'api/types';

import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';

const getIconPath = (iconName: string) => require(`../../../../assets/icons/${iconName}`);
const getRelevoIcon = (category: string) =>
  category?.toLowerCase() === 'cups' ? getIconPath('cup.svg') : getIconPath('bowl.svg');

type ReturnStationItemProps = {
  relevo: RelevoInterface;
};

const ReturnStationItem = ({ relevo }: ReturnStationItemProps): JSX.Element => {
  const { colors } = useTheme();
  const { language } = useLocalizationContext();
  return (
    <Flex key={relevo.uId} alignItems="center" pb={2}>
      <div style={{ width: '30%', textAlign: 'left' }}>
        <Box textAlign="left" minWidth={14} mx={2} pl={6}>
          <HStack>
            <Image
              src={getRelevoIcon(relevo.category.categoryGroup)}
              maxHeight={7}
              width={7}
              alt="Relevo"
              display="inline-block"
            />
            <h2
              style={{
                fontSize: '14px',
                color: colors.black,
              }}
            >
              {relevo.category.categoryGroup?.toLowerCase() === 'cups' ? 'CUP' : 'BOWL'}
            </h2>
          </HStack>
        </Box>
      </div>
      <div style={{ width: '40%', textAlign: 'left', paddingLeft: '1em' }}>
        <h2 style={{ fontSize: '14px', color: colors.black }}>{relevo.category.translations[language]}</h2>
      </div>
      <div style={{ width: '20%', textAlign: 'left', paddingLeft: '8px' }}>
        <h2 style={{ fontSize: '14px', color: colors.black }}>{relevo.productName}</h2>
      </div>
      <div style={{ width: '10%', textAlign: 'left', paddingLeft: '8px' }}>
        <CheckCircleIcon color={colors.green[500]} />
      </div>
    </Flex>
  );
};

export default ReturnStationItem;
