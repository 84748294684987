import React, { JSX, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  HStack,
  Image,
  Radio,
  RadioGroup,
  Spacer,
  Spinner,
  Stack,
  VStack,
} from '@chakra-ui/react';

import DropDownSelect from '../../../common/components/DropDownSelect/DropDownSelect';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useReportsStatistics } from '../../../services/reports';
import { useRestaurantsData } from '../../../services/restaurants';
import colors from '../../../styles/colors';
import CardView from '../CardView/CardView';
import GraphView from '../GraphView/GraphView';
import ListView from '../ListView/ListView';
import { LocationProperties as RadioProperties } from '../model/LocationProperties';
import { ProductCategoryResponse } from '../model/ProductCategoryResponse';
import { ReportsStatisticsResponse } from '../model/ReportsStatisticsResponse';
import ResultsViewSelect, { ViewOption } from '../ResultsViewSelect';
import DateRange from './DateRange';
import ProductCategories from './ProductCategories';
import { useFilterSetState } from './useFilterSetState';

const getIconPath = (iconName: string) => require(`../../../assets/icons/${iconName}`);

type FiltersProps = {
  productCategories: ProductCategoryResponse[];
};

const clearButtonDefaultProps = {
  backgroundColor: colors.beige[50],
  color: colors.orange[500],
  fontSize: '16px',
  fontWeight: '100',
  _focus: { boxShadow: 'none', backgroundColor: colors.beige[50] },
  _hover: { backgroundColor: colors.beige[50] },
  _active: { backgroundColor: colors.beige[50] },
};

const acceptButtonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: colors.orange[500] },
  _hover: { backgroundColor: colors.orange[500] },
  _active: { backgroundColor: colors.orange[500] },
};

function setLocationUId(data: ReportsStatisticsResponse[]): ReportsStatisticsResponse[] {
  return data.map((res) => {
    res.locationUId = Math.random().toString();
    return res;
  });
}

const FilteredResults = ({ productCategories }: FiltersProps): JSX.Element => {
  const translations = useTranslations();
  const { restaurants } = useRestaurantsData();

  const radioConfig: { value: RadioProperties; label: string }[] = [
    { value: RadioProperties.ALL_LOCATIONS, label: translations('reports_filter_all_locations') },
    { value: RadioProperties.ONE_LOCATION, label: translations('reports_filter_one_location') },
    { value: RadioProperties.SELECTED_LOCATIONS, label: translations('reports_filter_selected_locations') },
  ];
  const [radioValue, setRadioValue] = useState<RadioProperties>(radioConfig[0].value);
  const restaurantOptions = useMemo(
    () =>
      restaurants?.map((restaurant) => ({
        value: restaurant.id,
        label: restaurant.restaurantName,
      })),
    [restaurants],
  );
  const { mutate: triggerFirstSetStatistics, isLoading: isFirstSetLoading } = useReportsStatistics();
  const { mutate: triggerSecondSetStatistics, isLoading: isSecondSetLoading } = useReportsStatistics();
  const [firstSetData, setFirstSetData] = useState([] as ReportsStatisticsResponse[]);
  const [secondSetData, setSecondSetData] = useState([] as ReportsStatisticsResponse[]);
  const [timeframeInvalid, setTimeFrameInvalid] = useState(false);
  const [selectedViewOption, setSelectedViewOption] = useState(ViewOption.LIST);

  const validateTimeframe = (startDate: string, endDate: string) => {
    if (startDate > endDate) {
      setTimeFrameInvalid(startDate > endDate);
      toast.error(<b>{translations('timeframe_invalid')}</b>);
    }
  };

  const {
    startDate: firstSetStartDate,
    setStartDate: setFirstSetStartDate,
    endDate: firstSetEndDate,
    setEndDate: setFirstSetEndDate,
    categoryIds: firstSetCategoryIds,
    setCategoryIds: setFirstSetCategoryIds,
    restaurantOption: firstSetRestaurantOption,
    handleRestaurantOptionChange: handleFirstSetRestaurantOption,
    handleCategoryIdsChange: handleFirstSetCategoryIdsChange,
    handleSelectAllCategoriesChange: handleFirstSetSelectAllChange,
  } = useFilterSetState(productCategories, restaurantOptions ?? []);
  const {
    startDate: secondSetStartDate,
    setStartDate: setSecondSetStartDate,
    endDate: secondSetEndDate,
    setEndDate: setSecondSetEndDate,
    categoryIds: secondSetCategoryIds,
    setCategoryIds: setSecondSetCategoryIds,
    restaurantOption: secondSetRestaurantOption,
    handleRestaurantOptionChange: handleSecondSetRestaurantOption,
    handleCategoryIdsChange: handleSecondSetCategoryIdsChange,
    handleSelectAllCategoriesChange: handleSecondSetSelectAllChange,
  } = useFilterSetState(productCategories, restaurantOptions ?? []);

  const clearFilters = () => {
    setFirstSetStartDate('');
    setFirstSetEndDate('');
    setSecondSetStartDate('');
    setSecondSetEndDate('');
    setRadioValue(RadioProperties.ALL_LOCATIONS);
    setFirstSetCategoryIds(productCategories.map((category) => category.id));
    setSecondSetCategoryIds(productCategories.map((category) => category.id));
  };

  useEffect(() => {
    setTimeFrameInvalid(false);
    if (firstSetStartDate && firstSetEndDate) {
      validateTimeframe(firstSetStartDate, firstSetEndDate);
    }
    if (secondSetStartDate && secondSetEndDate) {
      validateTimeframe(secondSetStartDate, secondSetEndDate);
    }
    // eslint-disable-next-line
  }, [firstSetStartDate, firstSetEndDate, secondSetStartDate, secondSetEndDate]);

  const handleCompareClick = async () => {
    if (!firstSetStartDate || !firstSetEndDate || !secondSetStartDate || !secondSetEndDate) {
      toast.error(<b>{translations('timeframe_empty')}</b>);
    } else {
      triggerFirstSetStatistics(
        {
          restaurantIds:
            radioValue === RadioProperties.ALL_LOCATIONS
              ? restaurants.map((restaurant) => restaurant.id)
              : [firstSetRestaurantOption.value],
          categoryIds: firstSetCategoryIds,
          startDate: firstSetStartDate,
          endDate: firstSetEndDate,
        },
        {
          onSuccess: (data) => {
            const set = setLocationUId(data);
            setFirstSetData(set);
          },
        },
      );
      triggerSecondSetStatistics(
        {
          restaurantIds:
            radioValue === RadioProperties.ALL_LOCATIONS
              ? restaurants.map((restaurant) => restaurant.id)
              : [
                  radioValue === RadioProperties.SELECTED_LOCATIONS
                    ? secondSetRestaurantOption.value
                    : firstSetRestaurantOption.value,
                ],
          categoryIds: secondSetCategoryIds,
          startDate: secondSetStartDate,
          endDate: secondSetEndDate,
        },
        {
          onSuccess: (data) => {
            const set = setLocationUId(data);
            setSecondSetData(set);
          },
        },
      );
    }
  };

  return (
    <>
      <Stack>
        <Flex bgColor={colors.beige[50]} marginTop="18px" width="100%" wrap="wrap" pl={6} py={4} pr={2}>
          <Box height="100%" py={4} width="100%">
            <Flex width="100%">
              <RadioGroup
                width="80%"
                pb={3}
                onChange={(value: RadioProperties) => {
                  setRadioValue(value);
                }}
                value={radioValue}
              >
                <HStack>
                  {radioConfig.map(({ label, value }) => (
                    <HStack key={value}>
                      <Radio
                        size="sm"
                        key={value}
                        value={value}
                        id={value}
                        colorScheme="orange"
                        borderColor={colors.white}
                      />
                      <FormLabel
                        m={0}
                        ml={2}
                        color={radioValue === value ? colors.black : colors.grey[200]}
                        pr={5}
                        htmlFor={value}
                      >
                        {label}
                      </FormLabel>
                    </HStack>
                  ))}
                </HStack>
              </RadioGroup>
              <Box width="20%" textAlign="right" pb={3}>
                <Button
                  sx={{ '@media print': { display: 'none' } }}
                  {...clearButtonDefaultProps}
                  onClick={clearFilters}
                >
                  {translations('reports_clear_filters')}
                </Button>
              </Box>
            </Flex>

            {radioValue === RadioProperties.ONE_LOCATION && (
              <Stack width="47%">
                <Box width={['100%', '100%', '100%', '100%', '50%']} zIndex="6" paddingBottom="8px">
                  <DropDownSelect
                    selectedOption={firstSetRestaurantOption}
                    options={restaurantOptions ?? []}
                    handleChange={handleFirstSetRestaurantOption}
                    isSearchable={true}
                  />
                </Box>
              </Stack>
            )}

            <Flex width="100%" wrap="wrap" mr={4}>
              <VStack width="47%" alignItems="flex-start">
                {radioValue === RadioProperties.SELECTED_LOCATIONS && (
                  <Box width={['100%', '100%', '100%', '100%', '50%']} zIndex="6">
                    <DropDownSelect
                      selectedOption={firstSetRestaurantOption}
                      options={restaurantOptions ?? []}
                      handleChange={handleFirstSetRestaurantOption}
                      isSearchable={true}
                    />
                  </Box>
                )}
                <DateRange
                  startDate={firstSetStartDate}
                  endDate={firstSetEndDate}
                  onStartDateChange={setFirstSetStartDate}
                  onEndDateChange={setFirstSetEndDate}
                />
                <ProductCategories
                  categories={productCategories}
                  onSelectAllChange={handleFirstSetSelectAllChange}
                  onChange={handleFirstSetCategoryIdsChange}
                  categoryIds={firstSetCategoryIds}
                />
              </VStack>

              <Spacer />
              <Box width="2px" bg={colors.white} />
              <Spacer />

              <VStack width="47%" alignItems="flex-start" mr={4}>
                {radioValue === RadioProperties.SELECTED_LOCATIONS && (
                  <Box width={['100%', '100%', '100%', '100%', '50%']} zIndex="6">
                    <DropDownSelect
                      selectedOption={secondSetRestaurantOption}
                      options={restaurantOptions ?? []}
                      handleChange={handleSecondSetRestaurantOption}
                      isSearchable={true}
                    />
                  </Box>
                )}
                <DateRange
                  startDate={secondSetStartDate}
                  endDate={secondSetEndDate}
                  onStartDateChange={setSecondSetStartDate}
                  onEndDateChange={setSecondSetEndDate}
                />
                <ProductCategories
                  categories={productCategories}
                  onChange={handleSecondSetCategoryIdsChange}
                  onSelectAllChange={handleSecondSetSelectAllChange}
                  categoryIds={secondSetCategoryIds}
                />
              </VStack>
            </Flex>

            <Box width="100%" textAlign="right">
              <Button
                {...acceptButtonDefaultProps}
                color={colors.black}
                bgColor={colors.orange[500]}
                fontWeight="normal"
                minWidth={144}
                margin="auto"
                mx={4}
                mt={5}
                sx={{ '@media print': { display: 'none' } }}
                disabled={timeframeInvalid}
                onClick={handleCompareClick}
              >
                {translations('compare')}
              </Button>
            </Box>
          </Box>
        </Flex>

        {(isFirstSetLoading || isSecondSetLoading) && (
          <Center>
            <Spinner />
          </Center>
        )}
        {firstSetData.length > 0 && secondSetData.length > 0 && !isFirstSetLoading && !isSecondSetLoading && (
          <>
            <Box pt={4} px={4} sx={{ '@media print': { display: 'none' } }}>
              <Flex>
                <Box width="33%">
                  <h4 style={{ fontSize: '20px', textAlign: 'left' }}>{translations('reports_result_heading')}</h4>
                </Box>
                <Center width="33%">
                  <ResultsViewSelect
                    selectedViewOption={selectedViewOption}
                    onSelectedViewOptionChange={(viewOption) => setSelectedViewOption(viewOption)}
                  />
                </Center>
                <Box width="33%" textAlign="right">
                  <Button backgroundColor="transparent" color={colors.orange[500]} onClick={() => window.print()}>
                    <Image width="auto" height="auto" src={getIconPath('download.svg')} mr="2.5" />
                    {translations('download_report')}
                  </Button>
                </Box>
              </Flex>
            </Box>
            {selectedViewOption === ViewOption.LIST && (
              <ListView firstSetData={firstSetData} secondSetData={secondSetData} />
            )}
            {selectedViewOption === ViewOption.CARD && (
              <CardView firstSetData={firstSetData} secondSetData={secondSetData} />
            )}
            {selectedViewOption === ViewOption.GRAPH && (
              <GraphView firstSetData={firstSetData} secondSetData={secondSetData} locationsOption={radioValue} />
            )}
          </>
        )}
        {firstSetData.length === 0 && secondSetData.length === 0 && !isFirstSetLoading && !isSecondSetLoading && (
          <Box textAlign="left" pt={2} px={4}>
            <h4 style={{ fontSize: '16px' }}>{translations('reports_explanation_info')}</h4>
          </Box>
        )}
      </Stack>
    </>
  );
};
export default FilteredResults;
