import { StylesConfig } from 'react-select';

import colors from '../../../styles/colors';
import { SelectOption } from './model/selectOption';

export const selectStyle: StylesConfig<SelectOption> = {
  menu: (base) => ({
    ...base,
    zIndex: 1000,
  }),
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base, state) => ({
    ...base,
    border: `1px solid ${colors.beige[50]}`,
    '&:hover': {
      border: `1px solid ${colors.beige[50]}`,
    },
    opacity: state.isDisabled ? 0.4 : 1,
    background: colors.white,
    color: state.isDisabled ? colors.grey[200] : colors.black,
    minHeight: '35px',
    height: '42px',
    boxShadow: 'none',
    fontStyle: 'Rubik',
    fontSize: '16px',
    textAlign: 'left',
    borderRadius: '6px',
  }),
  option: (base, state) => ({
    ...base,
    minHeight: '30px',
    paddingTop: 6,
    paddingBottom: 6,
    fontStyle: 'Rubik',
    fontSize: '16px',
    textAlign: 'left',
    whiteSpace: 'normal',
    fontWeight: state.isSelected ? '600' : '100',
    background: colors.white,
    color: colors.black,
    '&:hover': {
      cursor: 'pointer',
      background: colors.beige[50],
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isFocused ? colors.grey[200] : colors.grey[50],
    '&:hover': {
      color: state.isFocused ? colors.grey[200] : colors.grey[50],
    },
  }),
  clearIndicator: (base, state) => ({
    ...base,
    color: state.isFocused ? colors.grey[200] : colors.grey[50],
    '&:hover': {
      color: state.isFocused ? colors.grey[200] : colors.grey[50],
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    background: colors.grey[50],
  }),
};
