import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getCommunityStatistics } from '../api/community-statistics';
import { CK_COMMUNITY_STATISTICS } from '../modules/Home/Home';
import { CommunityStatisticsReponse } from '../modules/Home/model/CommunityStatisticsReponse';

export const useCommunityStatistics = (
  restaurantIds: string[],
): QueryObserverResult<CommunityStatisticsReponse, AxiosError> => {
  return useQuery([CK_COMMUNITY_STATISTICS, restaurantIds], () => getCommunityStatistics(restaurantIds), {
    refetchOnWindowFocus: false,
  });
};
