import React, { ComponentProps, JSX } from 'react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Tooltip, useTheme } from '@chakra-ui/react';

const TooltipHelp = ({ label, bgColor, ...props }: Omit<ComponentProps<typeof Tooltip>, 'children'>): JSX.Element => {
  const { colors } = useTheme();
  return (
    <Tooltip bg={bgColor} placement="top-end" label={label} color={colors.black} fontSize="12px" hasArrow {...props}>
      <QuestionOutlineIcon color={colors.black} data-testid="tooltip-trigger-icon" />
    </Tooltip>
  );
};

export default TooltipHelp;
