import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { getReportsStatistics } from '../api/reports-statistics';
import { ReportsStatisticsResponse } from '../modules/Reports/model/ReportsStatisticsResponse';

export const useReportsStatistics = (): UseMutationResult<
  ReportsStatisticsResponse[],
  AxiosError,
  { restaurantIds: string[]; categoryIds: string[]; startDate?: string; endDate?: string }
> =>
  useMutation(
    ({
      restaurantIds,
      categoryIds,
      startDate,
      endDate,
    }: {
      restaurantIds: string[];
      categoryIds: string[];
      startDate?: string;
      endDate?: string;
    }) => getReportsStatistics(restaurantIds, categoryIds, startDate, endDate),
  );
