import React, { JSX, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';
import { Badge, Box, Center, Divider, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';

import { LocationsTimeFrameEnum } from '../../api/community-statistics';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { Role, useSessionContext } from '../../contexts/SessionContext';
import colors from '../../styles/colors';
import { handleReturnRate, handleReturnTime } from '../../utils/statisticsHelper';
import { LocationStatisticsResponse } from './model/LocationStatisticsResponse';
import RestaurantStatisticsCard from './RestaurantStatisticsCard';

const getIconPath = (iconName: string) => require(`../../assets/icons/${iconName}`);

const boldStyle = {
  fontSize: '16px',
  fontWeight: 600,
};

const defaultStyle = {
  fontSize: '14px',
  color: colors.grey[200],
};

type RestaurantCardProps = {
  locationStatistics: LocationStatisticsResponse;
  timeframeOption: LocationsTimeFrameEnum;
};

const RestaurantCard = ({ locationStatistics, timeframeOption }: RestaurantCardProps): JSX.Element => {
  const { role } = useSessionContext();
  const translations = useTranslations();
  const navigate = useNavigate();

  const returnRate = useMemo(() => {
    return handleReturnRate(locationStatistics?.returnRate);
  }, [locationStatistics]);

  const returnTime = useMemo(() => {
    if (locationStatistics?.totalAccountBorrowings && locationStatistics?.totalAccountBorrowings > 0) {
      const { returnTimeDays, returnTimeHours } = handleReturnTime(locationStatistics.returnTime);
      return `${returnTimeDays}${translations('days_shortcut')} ${returnTimeHours}${translations('hours_shortcut')}`;
    }
    return `0${translations('days_shortcut')} 0${translations('hours_shortcut')}`;
  }, [locationStatistics, translations]);

  const navigateToEdit = () => {
    const path = generatePath(`${Routes.RESTAURANT_PATH}`, { id: locationStatistics?.id });
    navigate(path);
  };

  return (
    <Center p={2} minWidth="580px" width="stretch">
      <Flex minHeight="180px" width="100%" bg={colors.white} p={4} borderRadius="4px">
        <VStack height="100%" width="100%">
          <Flex height="100px">
            <Flex width="80%" justify="space-between">
              <Box width="25%">
                <img
                  style={{
                    width: 'auto',
                    borderRadius: '6px',
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                  src={locationStatistics?.imageUrl}
                  alt="logo"
                />
              </Box>
              <VStack width="70%" align="end" textAlign="left" alignItems="start" p={4}>
                <h1 style={boldStyle}>{locationStatistics?.locationName}</h1>
                <HStack align="start" alignItems="start">
                  <img
                    style={{
                      width: 'auto',
                      borderRadius: '6px',
                      maxHeight: '100%',
                      maxWidth: '100%',
                    }}
                    src={getIconPath('icon_marker.svg')}
                    alt="logo"
                  />
                  <h2 style={defaultStyle}>{locationStatistics?.locationAddress}</h2>
                </HStack>
              </VStack>
            </Flex>
            <Flex width="20%" justify="flex-end" align="flex-start" p={4} pr={0}>
              {role === Role.MANAGER && (
                <VStack>
                  <HStack onClick={navigateToEdit} cursor="pointer" color={colors.orange[500]} alignItems="center">
                    <Icon as={EditIcon} />
                    <Text>{translations('edit')}</Text>
                  </HStack>
                  {locationStatistics.hasActiveDraft && (
                    <Badge variant="outline">{translations('restaurant_draft_awaiting_approval')}</Badge>
                  )}
                </VStack>
              )}
            </Flex>
          </Flex>
          <Divider />
          <HStack width="100%" spacing="auto">
            <RestaurantStatisticsCard
              imgSrc={getIconPath('location_stats_borrowings.svg')}
              imgAlt="borrowings"
              statsNumber={locationStatistics?.totalAccountBorrowings}
              statsDescription={translations('community_location_borrowings')}
            />
            <RestaurantStatisticsCard
              imgSrc={getIconPath('location_stats_users.svg')}
              imgAlt="users"
              statsNumber={locationStatistics?.totalAccountUsers}
              statsDescription={translations('community_location_users')}
            />
            <RestaurantStatisticsCard
              imgSrc={getIconPath('location_stats_return_rate.svg')}
              imgAlt="return rate"
              statsNumber={returnRate}
              statsDescription={translations('community_location_return_rate')}
              tooltip={
                LocationsTimeFrameEnum.ALL === timeframeOption ||
                LocationsTimeFrameEnum.LAST_30_DAYS === timeframeOption
                  ? undefined
                  : translations('return_rate_explanation')
              }
            />
            <RestaurantStatisticsCard
              imgSrc={getIconPath('location_stats_return_time.svg')}
              imgAlt="return time"
              statsNumber={returnTime}
              statsDescription={translations('community_location_return_time')}
            />
          </HStack>
        </VStack>
      </Flex>
    </Center>
  );
};

export default RestaurantCard;
