import React from 'react';
import { Box } from '@chakra-ui/react';

import { useScannedRelevosContext } from '../../contexts/ScannedRelevosContext';
import { RestaurantInfo } from '../../contexts/SessionContext/SessionContext';
import { useRestaurantsData } from '../../services/restaurants';
import { QuickContactsReturnItems } from './components/QuickContactsReturnItemsList/QuickContactsReturnItems';
import { ScanReturn } from './components/ScanReturn/ScanReturn';
import { SelectAssignmentMode } from './components/SelectAssignmentMode/SelectAssignmentMode';
import { ItemAssignmentSelectMode } from './components/UserSelect/types';

type ReturnProps = {
  restaurants: RestaurantInfo[];
};

export const Return = ({ restaurants }: ReturnProps) => {
  const { selectItemMode } = useScannedRelevosContext();
  const { isQuickContactsEnabled } = useRestaurantsData();
  return (
    <>
      <Box
        display={['none', 'none', 'none', 'block', 'block']}
        width={['100%', '100%', '50%', '50%']}
        maxW={'600px'}
        p={2}
      >
        {isQuickContactsEnabled ? (
          <Box py={4}>
            <SelectAssignmentMode bgColor={'grey.20'} />
          </Box>
        ) : null}
      </Box>
      {selectItemMode === ItemAssignmentSelectMode.SCAN_ITEM || !isQuickContactsEnabled ? (
        <ScanReturn restaurants={restaurants} />
      ) : (
        <QuickContactsReturnItems restaurants={restaurants} />
      )}
    </>
  );
};
