import { useScannedRelevosContext } from './hooks';
import { ScannedRelevosContext, ScannedRelevosContextProvider } from './ScannedRelevosContext';

const ASSIGN_RETURN_RESTAURANT_ID_LS_KEY = 'assignReturnRestaurantId';
export {
  ASSIGN_RETURN_RESTAURANT_ID_LS_KEY,
  ScannedRelevosContext,
  ScannedRelevosContextProvider,
  useScannedRelevosContext,
};
