import React from 'react';
import { Box, Flex, HStack, VStack } from '@chakra-ui/react';

import { useTranslations } from '../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../contexts/ScannedRelevosContext';
import { useRestaurantsData } from '../../services/restaurants';
import { QuickContactType } from '../QuickContacts/model/enums/quick-contact-type.enum';
import RelevosSteps from './common/components/RelevosSteps/RelevosSteps';
import { SelectAssignmentMode } from './components/SelectAssignmentMode/SelectAssignmentMode';
import AssignSumUp from './components/SumUp/AssignSumUp';
import UserSelect from './components/UserSelect';
import { UserSelectMode } from './components/UserSelect/types';

export const Assign = () => {
  const translations = useTranslations();
  const { restaurantId, applicationUser, quickContact } = useScannedRelevosContext();
  const { isQuickContactsEnabled } = useRestaurantsData();

  return (
    <VStack spacing="20px">
      <Flex bgColor={'beige.50'} marginTop="18px" width="100%" wrap="wrap" px={3} pb={4}>
        <Box height="100%" py={4} width={['100%', '100%', '100%', '100%', '45%']}>
          <RelevosSteps
            firstStep={translations('relevos_step_1')}
            firstStepDescription={translations(
              isQuickContactsEnabled ? 'relevos_step_1_quick_contacts_description' : 'relevos_step_1_description',
            )}
            secondStep={
              <HStack>
                <Box mr={1}>{translations('relevos_step_2')}</Box>
              </HStack>
            }
            isUpgrade={false}
          />
          <Box width={['100%', '100%', '50%', '50%', '100%']} p={2} pt={6}>
            {restaurantId && (applicationUser || (quickContact && quickContact.type === QuickContactType.USER)) ? (
              <SelectAssignmentMode />
            ) : null}
          </Box>
        </Box>

        <Flex width={['100%', '100%', '100%', '100%', '55%']}>
          <UserSelect mode={UserSelectMode.ASSIGNMENT} />
          {restaurantId && (applicationUser || quickContact) ? (
            <Box display={['none', 'none', 'block', 'block', 'none']} ml={5}>
              <AssignSumUp />
            </Box>
          ) : null}
        </Flex>
      </Flex>
      <Box width="100%" display={['block', 'block', 'none', 'none', 'block']}>
        <AssignSumUp />
      </Box>
    </VStack>
  );
};
