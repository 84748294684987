import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { RESTAURANT_CATEGORIES_QUERY_KEY } from '../api/apiConsts';
import { getRestaurantInventory, postRestaurantInventoryReorder } from '../api/restaurant';
import { SendRestaurantInventoryReorderRequestData, SendRestaurantInventoryReorderResponse } from '../api/types';

export const useRestaurantInventory = (restaurantId: string | null) => {
  return useQuery([RESTAURANT_CATEGORIES_QUERY_KEY, restaurantId], () => getRestaurantInventory(restaurantId || ''), {
    enabled: !!restaurantId,
    refetchOnWindowFocus: false,
    refetchInterval: 60 * 60 * 1000,
  });
};

export const useSendRestaurantInventoryReorder = (
  restaurantId: string | null,
): UseMutationResult<SendRestaurantInventoryReorderResponse, AxiosError, SendRestaurantInventoryReorderRequestData> =>
  useMutation((data) => postRestaurantInventoryReorder(restaurantId, data));
