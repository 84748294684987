export enum NotificationType {
  Success = 'success',
  NoSingleUser = 'noSingleUser',
  EnterSearchUser = 'enterSearchUser',
}

export enum UserSelectMode {
  ASSIGNMENT = 'ASSIGNMENT',
  RETURN = 'RETURN',
  UPGRADE = 'UPGRADE',
}

export enum ItemAssignmentSelectMode {
  SCAN_ITEM = 'item_select_mode_scan_items',
  SELECT_CATEGORY = 'item_select_mode_select_quantities',
}

export interface Notification {
  message: string | null;
  icon: string | null;
}

export type Notifications = {
  [key in NotificationType]: Notification;
};
