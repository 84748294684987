import React, { JSX } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { ReportsStatisticsResponse } from 'modules/Reports/model/ReportsStatisticsResponse';

import colors from '../../../../styles/colors';

const CHART_COLORS_RGBA = ['rgba(250, 206, 102, 1)', 'rgba(252, 240, 211, 1)', 'rgba(251, 179, 2, 1)'];

type AllLocationsChartProps = {
  data: ReportsStatisticsResponse[];
  dataKey: keyof Pick<ReportsStatisticsResponse, 'totalAccountBorrowings' | 'totalAccountUsers'>;
};

const chartOptions: ChartOptions = {
  indexAxis: 'y',
  animation: false,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: colors.black,
      align: 'right',
      offset: 20,
      clamp: true,
      anchor: 'start',
      font: {
        weight: 'bold',
      },
      formatter: (val) => {
        return val.x;
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        font: {
          weight: 'bold',
        },
      },
    },
    x: {
      position: 'top',
      ticks: {
        display: false,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
};

const AllLocationsChart = ({ data, dataKey }: AllLocationsChartProps): JSX.Element => {
  const labels = data.map((dataset) => dataset.locationName);
  const graphData = {
    labels,
    datasets: data.map((dataset, index) => ({
      categoryPercentage: 1.0,
      barPercentage: 1.0,
      barThickness: 20,
      data: [{ x: dataset[dataKey], y: labels[index] }],
      label: labels[index],
      backgroundColor: CHART_COLORS_RGBA[index % CHART_COLORS_RGBA.length],
    })),
  };
  const config = {
    options: { ...chartOptions },
    data: graphData,
  };

  return <Chart type="bar" {...config} />;
};

export default AllLocationsChart;
