import { StarterSetSizeSelect } from './CustomerCreationRequest';

export enum SepaBilling {
  SEPA_DESIRED = 'SEPA_DESIRED',
  SEPA_NOT_DESIRED = 'SEPA_NOT_DESIRED',
}

export enum SocialMediaPress {
  SOCIAL_MEDIA_PRESS_PERMITTED = 'SOCIAL_MEDIA_PRESS_PERMITTED',
  SOCIAL_MEDIA_PRESS_NOT_PERMITTED = 'SOCIAL_MEDIA_PRESS_NOT_PERMITTED',
}

export interface CustomerCreationFormData {
  salesOrganisation: string;
  salesPersonName: string;
  salesPersonEmail: string;
  salesPersonPhone: string;
  customerName: string;
  wholesaleCustomerId: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
  billingAddressStreetNumber: string;
  billingAddressAddition: string;
  billingAddressZipCode: string;
  billingAddressCity: string;
  billingAddressCountry: string;
  billingPersonTitle: string;
  billingName: string;
  billingEmail: string;
  sepa: SepaBilling;
  desiredStartDate: Date;
  socialMediaPress: SocialMediaPress;
  // FYI: RELEVO-758 multiple starter sets has not been done yet
  // sStartersetNumber: string;
  // mStartersetNumber: string;
  // lStartersetNumber: string;
  // xlStartersetNumber: string;
  // xxlStartersetNumber: string;
  starterSet: StarterSetSizeSelect;
  specialRequests: string;
  calculatorFiles: FileList;
  contractFile: FileList;
}
