import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { RESTAURANT_USER_QUERY_KEY } from '../api/apiConsts';
import { RestaurantUserRequest, RestaurantUserResponse } from '../api/types';
import { getRestaurantUser, updateRestaurantUser } from '../api/user';

export const useRestaurantUser = (
  doesSessionExist: boolean,
): QueryObserverResult<RestaurantUserResponse, AxiosError> => {
  return useQuery([RESTAURANT_USER_QUERY_KEY], () => getRestaurantUser(), {
    refetchOnWindowFocus: false,
    enabled: doesSessionExist,
  });
};

export const useUpdateRestaurantUser = (): UseMutationResult<
  RestaurantUserResponse,
  AxiosError,
  { request: RestaurantUserRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ request }: { request: RestaurantUserRequest }) => updateRestaurantUser(request), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(RESTAURANT_USER_QUERY_KEY);
    },
  });
};
