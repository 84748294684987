import React, { JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Container } from '@chakra-ui/react';

import FormInput from '../../../../common/components/Input/FormInput';
import { MainPageContent } from '../../../../common/components/MainPageContent';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import CustomerCreationFormHeading from '../../components/CustomerCreationFormHeading/CustomerCreationFormHeading';
import { CustomerCreationFormData } from '../../model/CustomerCreationFormData';
import {
  getEmailValidationObject,
  getMaxLengthValidationObject,
  getRequiredValidationObject,
} from '../../validation/formValidation';

const CreationModeSelection = (): JSX.Element => {
  const translations = useTranslations();
  const requiredValidation = getRequiredValidationObject(translations('new_customer_form_validation_required'));
  const emailValidation = getEmailValidationObject(translations('new_customer_form_validation_email'));
  const maxLengthValidation = (maxLengthValue: number) =>
    getMaxLengthValidationObject(
      maxLengthValue,
      translations('new_customer_form_validation_too_long', {
        '{{maxLength}}': maxLengthValue.toString(),
      }),
    );

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<CustomerCreationFormData>();
  return (
    <MainPageContent>
      <Container maxW={['90%', '80%', '70%', '62%']} pt={2} pb={12}>
        <Box pb={12}>
          <CustomerCreationFormHeading heading={translations('new_customer_form_customer_header')} />
        </Box>
        <FormInput
          id="customerName"
          label={translations('new_customer_form_customer_name')}
          useFormRegisterReturn={register('customerName', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.customerName}
          autoComplete="off"
          validationError={errors?.customerName?.message}
          setValue={setValue}
        />
        <FormInput
          id="contactPersonEmail"
          label={translations('new_customer_form_customer_email_address')}
          useFormRegisterReturn={register('contactPersonEmail', {
            required: requiredValidation,
            pattern: emailValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.contactPersonEmail}
          autoComplete="off"
          validationError={errors?.contactPersonEmail?.message}
          setValue={setValue}
        />
      </Container>
    </MainPageContent>
  );
};

export default CreationModeSelection;
