import React, { JSX } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { NoLiveLocationsHeader } from '../../common/components/NoActiveLocationsHeader/NoLiveLocationsHeader';
import { useRestaurantsData } from '../../services/restaurants';
import HistoryExport from './components/HistoryExport/HistoryExport';
import HistoryFilters from './components/HistoryFilters/HistoryFilters';
import HistoryTable from './components/HistoryTable/HistoryTable';
import { HistoryFilterContextProvider } from './context/HistoryFilterContext/HistoryFilterContext';

const History = (): JSX.Element => {
  const translations = useTranslations();
  const { restaurants, isTwoFactorReturnEnabled, isDepositEnabled } = useRestaurantsData();

  return (
    <MainPageContent>
      {restaurants.length ? (
        <Box mb={2}>
          <MainPageHeading>{translations('history_heading')}</MainPageHeading>
          <HistoryFilterContextProvider userRestaurants={restaurants}>
            <Box ml={2}>
              <Flex justifyContent="space-between" wrap="wrap" mb={4}>
                <HistoryFilters
                  userRestaurants={restaurants}
                  isTwoFactorReturnEnabled={isTwoFactorReturnEnabled}
                  isDepositEnabled={isDepositEnabled}
                />
                <HistoryExport />
              </Flex>
              <HistoryTable />
            </Box>
          </HistoryFilterContextProvider>
        </Box>
      ) : (
        <NoLiveLocationsHeader />
      )}
    </MainPageContent>
  );
};

export default History;
