import React from 'react';
import { Center, Flex, Image, Text } from '@chakra-ui/react';

type TableItemWithImageProps = {
  text: string;
  imageUrl?: string | null;
};

export const TableItemWithImage = ({ text, imageUrl }: TableItemWithImageProps) => {
  return (
    <Flex my={-2} ml={-1}>
      <Center width="64px" height="64px" mr={4}>
        {imageUrl ? <Image src={imageUrl} maxHeight="64px" width="64px" /> : null}
      </Center>
      <Center>
        <Text>{text}</Text>
      </Center>
    </Flex>
  );
};
