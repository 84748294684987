import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from '../../../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { QuickContactType } from '../../../../model/enums/quick-contact-type.enum';
import { AddNewContactsModalBody } from '../AddNewContactsModalBody/AddNewContactsModalBody';
import { CreateNewContactForm } from './form/create-new-contact-form';
import { useAddQuickContactsFormValidation } from './form/use-add-quick-contacts-form-validation';

type AddNewContactsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: CreateNewContactForm, onSuccess: () => void) => void;
  isLoading: boolean;
};

export const AddNewContactsModal = ({ isOpen, onClose, onSubmit, isLoading }: AddNewContactsModalProps) => {
  const translations = useTranslations();
  const validationSchema = useAddQuickContactsFormValidation();
  const confirmCancelRef = useRef(null);

  const methods = useForm<CreateNewContactForm>({
    defaultValues: { type: QuickContactType.USER, nickname: '', selectedRestaurants: [] },
    resolver: yupResolver(validationSchema),
  });

  const handleClose = () => {
    methods.reset({ type: QuickContactType.USER, nickname: undefined, selectedRestaurants: [] });
    onClose();
  };

  const handleSubmit = (form: CreateNewContactForm) => {
    onSubmit(form, handleClose);
  };

  return (
    <Dialog
      leastDestructiveRef={confirmCancelRef}
      isOpen={isOpen}
      onClose={handleClose}
      size="lg"
      dialogHeader={translations('quick_contacts_add_new_contact_modal_header')}
      dialogFooter={<></>}
    >
      <FormProvider {...methods}>
        <AddNewContactsModalBody onSubmit={handleSubmit} isLoading={isLoading} />
      </FormProvider>
    </Dialog>
  );
};
