import React from 'react';
import { Box, Center, Text } from '@chakra-ui/react';

type DepositTileProps = {
  tileName: string;
  explanatoryText: string;
  tileColor: string;
  onClick: () => void;
};

export const DepositTile = ({ tileName, explanatoryText, tileColor, onClick }: DepositTileProps) => {
  return (
    <Box
      color="white"
      w={['100%', '100%', '47%', '45%']}
      maxW="600px"
      minW="300px"
      my={2}
      onClick={onClick}
      cursor="pointer"
    >
      <Center height="220px" bg={tileColor} p={3} mb="1px" borderTopRadius={8}>
        <Text fontSize="70px" fontWeight="bold">
          {tileName}
        </Text>
      </Center>
      <Center height="100px" bg={tileColor} borderBottomRadius={8} p={3}>
        <Text fontSize="25px" fontWeight="bold">
          {explanatoryText}
        </Text>
      </Center>
    </Box>
  );
};
