import React, { JSX } from 'react';
import { Text } from '@chakra-ui/react';
import { sanitize } from 'dompurify';

import 'react-quill/dist/quill.core.css';

type AnswerProps = {
  answer: string;
};

const Answer = ({ answer }: AnswerProps): JSX.Element => {
  return (
    <Text
      textAlign="left"
      className="ql-editor"
      dangerouslySetInnerHTML={{
        __html: sanitize(answer, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
        }),
      }}
    />
  );
};

export default Answer;
