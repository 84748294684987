import React from 'react';
import Select from 'react-select';

import { SelectOption } from '../../../../common/components/Select/model/selectOption';
import { selectStyle } from '../../../../common/components/Select/select-style';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useQuickContactsFilterContext } from '../../context/QuickContactsFilterContext/hooks/useQuickContactsFilterContext';

export const QuickContactsFnbGroupSelect = () => {
  const translations = useTranslations();
  const { quickContactsState, fnbGroups, handleSelectFnbGroup } = useQuickContactsFilterContext();

  const fnbGroupOptions = fnbGroups.map((fnbGroup) => ({
    value: fnbGroup.id,
    label: fnbGroup.name,
  }));

  return (
    <Select<SelectOption, false>
      value={fnbGroupOptions?.find((option) => option.value === quickContactsState.selectedFnbGroupId)}
      styles={selectStyle}
      options={fnbGroupOptions}
      onChange={handleSelectFnbGroup}
      placeholder={translations('quick_contacts_fnb_group_select_placeholder')}
      isDisabled={!fnbGroups.length || quickContactsState.isReorderMode}
      aria-label={'fnbGroupSelect'}
    />
  );
};
