import React from 'react';
import ReactDomServer from 'react-dom/server';
import { Center, Image, Text, VStack } from '@chakra-ui/react';

import { ItemsSummaryStatusText } from '../../../../../common/components/ItemsSummaryModal/ItemsSummaryStatusText';
import { useHtmlTranslations, useTranslations } from '../../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../../contexts/ScannedRelevosContext';
import { ScannerMode } from '../../../../RelevoAssignment/model/enums/scanner-mode.enum';
import { DepositItemResponse } from '../../../model/deposit-item-response';
import { DepositResponse } from '../../../model/deposit-response';
import { ErrorDepositItemResponse } from '../../../model/error-deposit-item-response';
import { DepositFailureResult } from './components/DepositFailureResult/DepositFailureResult';
import { DepositSuccessResult } from './components/DepositSuccessResult/DepositSuccessResult';

const getIconPath = (iconName: string) => require(`../../../../../assets/icons/${iconName}`);

type DepositResultProps = {
  successItems: DepositItemResponse[];
  failureItems: ErrorDepositItemResponse[];
  deposit: DepositResponse;
};

export const DepositResultModalBody = ({ deposit, successItems, failureItems }: DepositResultProps) => {
  const translations = useTranslations();
  const htmlTranslations = useHtmlTranslations();
  const { mode } = useScannedRelevosContext();

  const summaryText =
    mode === ScannerMode.DEPOSIT_ASSIGN
      ? translations('borrow_deposit_summary_text', {
          '{{successItems}}': successItems.length.toString(),
          '{{allItems}}': (successItems.length + failureItems.length).toString(),
        })
      : translations('return_deposit_summary_text', {
          '{{successItems}}': successItems.length.toString(),
          '{{allItems}}': (successItems.length + failureItems.length).toString(),
        });

  const depositSummaryTextArgs = {
    '{{amount}}': ReactDomServer.renderToString(
      <Text as="b" color="orange.500">
        {deposit.amount.toString()}
      </Text>,
    ),
    '{{currency}}': ReactDomServer.renderToString(
      <Text as="b" color="orange.500">
        {deposit.currency.toString()}
      </Text>,
    ),
  };

  const depositSummaryText =
    mode === ScannerMode.DEPOSIT_ASSIGN
      ? htmlTranslations('borrow_deposit_summary', depositSummaryTextArgs)
      : htmlTranslations('return_deposit_summary', depositSummaryTextArgs);

  return (
    <>
      <ItemsSummaryStatusText summaryText={summaryText} successItems={successItems} failureItems={failureItems} />
      {successItems.length ? <DepositSuccessResult successItems={successItems} deposit={deposit} /> : null}
      {failureItems.length ? <DepositFailureResult failureItems={failureItems} /> : null}
      {successItems.length ? (
        <Center pt={8}>
          <VStack>
            <Image src={getIconPath('money.svg')} maxHeight={12} width={12} alt="money" display="inline-block" />
            <Text fontSize="20px" color="grey.200">
              {depositSummaryText}
            </Text>
          </VStack>
        </Center>
      ) : null}
    </>
  );
};
