import React, { JSX, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { LocationsTimeFrameEnum } from '../../api/community-statistics';
import { useTranslations } from '../../contexts/LocalizationContext';
import { handleReturnRate, handleReturnTime } from '../../utils/statisticsHelper';
import RelevoCommunityCard from '../Home/RelevoCommunityCard';
import { LocationsStatisticsResponse } from './model/LocationsStatisticsResponse';

const getIconPath = (iconName: string) => require(`../../assets/icons/${iconName}`);

type LocationsStatisticsProps = {
  timeFrameOption: LocationsTimeFrameEnum;
  statistics?: LocationsStatisticsResponse;
};

const LocationsStatistics = ({ timeFrameOption, statistics }: LocationsStatisticsProps): JSX.Element => {
  const translations = useTranslations();
  const returnRate = useMemo(() => {
    return handleReturnRate(statistics?.returnRate);
  }, [statistics]);

  const returnTime = useMemo(() => {
    if (statistics?.totalAccountBorrowings && statistics?.totalAccountBorrowings > 0) {
      const { returnTimeDays, returnTimeHours } = handleReturnTime(statistics.returnTime);
      return `${returnTimeDays}${translations('days_shortcut')} ${returnTimeHours}${translations('hours_shortcut')}`;
    }
    return `0${translations('days_shortcut')} 0${translations('hours_shortcut')}`;
  }, [statistics, translations]);

  return (
    <Flex width="100%" wrap="wrap">
      <Box width={['100%', '100%', '50%', '50%', '25%']} pr={[0, 0, 2, 2, 2]}>
        <RelevoCommunityCard
          imgSrc={getIconPath('account_borrowings.svg')}
          imgAlt="account borrowings"
          statsNumber={statistics?.totalAccountBorrowings}
          statsDescription={translations('community_location_borrowings')}
          minWidth="250px"
        />
      </Box>
      <Box width={['100%', '100%', '50%', '50%', '25%']} pr={[0, 0, 2, 2, 2]} pt={[2, 2, 0, 0, 0]}>
        <RelevoCommunityCard
          imgSrc={getIconPath('account_users.svg')}
          imgAlt="account users"
          statsNumber={statistics?.totalAccountUsers}
          statsDescription={translations('community_location_users')}
          minWidth="250px"
        />
      </Box>
      <Box width={['100%', '100%', '50%', '50%', '25%']} pr={[0, 0, 2, 2, 2]} pt={[2, 2, 2, 2, 0]}>
        <RelevoCommunityCard
          imgSrc={getIconPath('account_return_rate.svg')}
          imgAlt="account return rate"
          statsNumber={returnRate}
          statsDescription={translations('community_location_return_rate')}
          minWidth="250px"
          tooltip={
            LocationsTimeFrameEnum.ALL === timeFrameOption || LocationsTimeFrameEnum.LAST_30_DAYS === timeFrameOption
              ? undefined
              : translations('return_rate_explanation')
          }
        />
      </Box>
      <Box width={['100%', '100%', '50%', '50%', '25%']} pr={[0, 0, 2, 2, 2]} pt={[2, 2, 2, 2, 0]}>
        <RelevoCommunityCard
          imgSrc={getIconPath('account_return_time.svg')}
          imgAlt="account return time"
          statsNumber={returnTime}
          statsDescription={translations('community_location_return_time')}
          minWidth="250px"
        />
      </Box>
    </Flex>
  );
};

export default LocationsStatistics;
