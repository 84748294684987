import React, { JSX, useMemo } from 'react';
import { Box, Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import uniqBy from 'lodash.uniqby';
import { ReportsStatisticsResponse } from 'modules/Reports/model/ReportsStatisticsResponse';
import { getFormattedTimeRange } from 'modules/Reports/utils/reportsHelper';

import AllLocationsBox from './AllLocationsBox';
import AllLocationsChart from './AllLocationsChart';

const AllLocationsView = ({ data }: { data: ReportsStatisticsResponse[] }): JSX.Element => {
  const translations = useTranslations();
  const grouppedData = useMemo(() => {
    const dataObj: { [k: string]: ReportsStatisticsResponse[] } = {};
    data.forEach((dataset) => {
      const dateLabel = getFormattedTimeRange(dataset.startDate, dataset.endDate);
      if (!dataObj[dateLabel]) {
        dataObj[dateLabel] = [];
      }
      dataObj[dateLabel] = uniqBy(
        data.filter((ds) => ds.endDate === dataset.endDate && ds.startDate === dataset.startDate),
        'locationName',
      );
    });
    return dataObj;
  }, [data]);
  return (
    <Box mt={4} px={4}>
      {Object.keys(grouppedData).map((key) => (
        <Box mt={8} key={key}>
          <Heading as="h3" fontSize={16} textAlign="left" ml={4} mb={4}>
            {key !== ' - ' ? key : null}
          </Heading>
          <Stack direction={['column', null, null, 'row', null]} justifyContent="space-around" alignItems="center">
            <AllLocationsBox
              heading={translations('reports_graph_view_borrowings')}
              countKey="totalAccountBorrowings"
              data={grouppedData[key]}
              chartContainerHeight={data.length * 11}
            >
              <AllLocationsChart data={grouppedData[key]} dataKey="totalAccountBorrowings" />
            </AllLocationsBox>
            <AllLocationsBox
              heading={translations('reports_graph_view_users')}
              countKey="totalAccountUsers"
              data={grouppedData[key]}
              chartContainerHeight={data.length * 11}
            >
              <AllLocationsChart data={grouppedData[key]} dataKey="totalAccountUsers" />
            </AllLocationsBox>
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

export default AllLocationsView;
