/* eslint-disable import/prefer-default-export */
import { LOCALIZATION_CONFIG } from '../config/configConsts';

export const formatDate = (date: Date): string => {
  const {
    de: { locale, timeZone },
  } = LOCALIZATION_CONFIG;
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone,
  };
  return date.toLocaleDateString(locale, options);
};

export const formatDateTime = (date: Date): string => {
  const {
    de: { locale, timeZone },
  } = LOCALIZATION_CONFIG;
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  };
  return date.toLocaleDateString(locale, options);
};

export const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getFirstDayOfMonth = (year: number, month: number): Date => {
  return new Date(year, month, 1);
};

export const getLastDayOfMonth = (year: number, month: number): Date => {
  return new Date(year, month + 1, 0);
};

export const getDateISOString = (date: Date): string => {
  const msTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
  const globalTimeValue = new Date(date.getTime() - msTimezoneOffset);
  return globalTimeValue.toISOString().split('T')[0];
};

export const getFormattedDate = (dateString?: string): string => {
  if (!dateString) {
    return '';
  }
  const dateValue = new Date(dateString);
  return getDateISOString(dateValue);
};
