import React, { createContext, useEffect, useState } from 'react';

import { useRestaurantUser } from '../../services/restaurant-user';
import deTranslations from '../../translations/de.json';
import { useLocalStorage } from '../../utils/LocalStorage.hook';
import { useSessionContext } from '../SessionContext';
import { Language, LocalizationContextInterface, SetLanguage, TranslationKeys } from './types';
import getTranslations from './utils';

export const LocalizationContext = createContext<LocalizationContextInterface>({
  language: Language.DE,
  translations: {},
  setLanguage: () => {},
  isFetchingRestaurantUser: false,
});

const setTranslationKeys = async (setTranslations: SetLanguage, language: string) => {
  const translations = await getTranslations(language);
  setTranslations(translations);
};

// TODO: consider changing the implementation so that translations in deps does not cause re-rendering or putting it in deps is not necessary
// check https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
export const LocalizationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [translations, setTranslations] = useState<TranslationKeys>(deTranslations);
  const { doesSessionExist } = useSessionContext();
  const { data: restaurantUser, isFetching: isFetchingRestaurantUser } = useRestaurantUser(doesSessionExist);
  const [language, setLanguage] = useLocalStorage('language', Language.DE);

  useEffect(() => {
    if (doesSessionExist && restaurantUser) {
      setLanguage(restaurantUser.language);
    }
  }, [doesSessionExist, restaurantUser, setLanguage]);

  useEffect(() => {
    setTranslationKeys(setTranslations, language);
  }, [language]);

  return (
    <LocalizationContext.Provider
      value={{
        language,
        translations,
        setLanguage,
        isFetchingRestaurantUser,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
