import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from '@chakra-ui/react';

import Dialog from '../../../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { NotificationSettingsTableData } from '../NotificationSettingsTable/model/notification-settings-table-data';

type AllLocationsConfirmationModalProps = {
  isOpen: boolean;
  onConfirm: (form: NotificationSettingsTableData) => void;
  onCancel: () => void;
};

export const AllLocationsConfirmationModal = ({ isOpen, onConfirm, onCancel }: AllLocationsConfirmationModalProps) => {
  const translations = useTranslations();
  const cancelRef = useRef(null);
  const { getValues } = useFormContext<NotificationSettingsTableData>();

  const handleConfirm = () => {
    onConfirm(getValues());
    onCancel();
  };

  return (
    <Dialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
      onConfirm={handleConfirm}
      cancelText={translations('cancel')}
      confirmText={translations('ok')}
    >
      <Text>{translations('notification_settings_all_locations_confirmation')}</Text>
    </Dialog>
  );
};
