import { createContext, ReactNode, useState } from 'react';
import { useSteps } from '@chakra-ui/react';

import { steps } from '../components/InventoryCountsStepper/utils/steps.consts';
import { InventoryCountsContextInterface } from './model/InventoryCountsContext';

type InventoryCountsContextProps = {
  children: ReactNode;
};

export const InventoryCountsContext = createContext<InventoryCountsContextInterface | null>(null);

export const InventoryCountsContextProvider = ({ children }: InventoryCountsContextProps) => {
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [currentInventoryCountId, setCurrentInventoryCountId] = useState<string | null>(null);
  const [restaurantName, setRestaurantName] = useState<string | null>(null);
  return (
    <InventoryCountsContext.Provider
      value={{
        activeStep,
        setActiveStep,
        currentInventoryCountId,
        setCurrentInventoryCountId,
        restaurantName,
        setRestaurantName,
      }}
    >
      {children}
    </InventoryCountsContext.Provider>
  );
};
