import React, { JSX, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Stack, Text, useTheme, VStack } from '@chakra-ui/react';
import { responseStatusToTranslationKey, SupertokensApiErrorStatus, SupertokensApiSuccessStatus } from 'api/types';
import { signInUser } from 'api/user';
import { useTranslations } from 'contexts/LocalizationContext';
import { KeyIcon, PersonIcon } from 'styles/icons';

import { SignInFormInputs } from '../../types';
import Input from './Input';
import SendResetPasswordEmail from './SendResetPasswordEmail';

type Props = {
  message?: string | null;
};

const SignInForm = ({ message = null }: Props): JSX.Element => {
  const { control, handleSubmit } = useForm<SignInFormInputs>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const translations = useTranslations();
  const { colors } = useTheme();

  const onSubmit = async (data: SignInFormInputs) => {
    signInUser(data)
      .then(async (response) => {
        if (response?.status !== SupertokensApiSuccessStatus.OK) {
          setErrorMessage(responseStatusToTranslationKey[response?.status as SupertokensApiErrorStatus]);
        }
      })
      .catch(() => {
        setErrorMessage('something_went_wrong');
      });
  };

  return (
    <Box pt="8em" mx="auto" textAlign="center" maxWidth="400px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <Input
              type="email"
              autocomplete="on"
              onChange={onChange}
              value={value}
              placeholder={translations('sign_in_email')}
              icon={PersonIcon}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <Input
              type="password"
              onChange={onChange}
              value={value}
              placeholder={translations('sign_in_password')}
              icon={KeyIcon}
            />
          )}
        />
        <Box py={16}>
          {errorMessage ? (
            <Stack spacing={2} fontWeight="bold" color={colors.red[500]}>
              <Text>{translations(errorMessage)}</Text>
              <Text>{translations('please_try_again')}</Text>
            </Stack>
          ) : (
            <Text fontWeight="bold" lineHeight="56px" fontSize="2xl" color={colors.orange[500]}>
              {message ? translations(message) : translations('welcome_back')}
            </Text>
          )}
        </Box>
        <Button
          size="lg"
          fontWeight="bold"
          fontSize="2xl"
          w="70%"
          type="submit"
          title={translations('sign_in')}
          colorScheme="orange"
        >
          {translations('sign_in')}
        </Button>
      </form>
      <VStack spacing={6}>
        <SendResetPasswordEmail />
      </VStack>
    </Box>
  );
};

export default SignInForm;
