import { generatePath } from 'react-router-dom';

import { apiPaths } from '../config/apiPaths';
import { RestaurantDraft } from '../modules/Restaurants/types';
import axios from './axios';

export const getRestaurantDraft = async (restaurantId: string): Promise<RestaurantDraft | null> => {
  return axios
    .get<RestaurantDraft>(generatePath(apiPaths.RESTAURANTS_DRAFTS_ACTIVE, { restaurantId }))
    .then(({ data }) => data);
};

export const createRestaurantDraft = async (restaurantId: string, draft: RestaurantDraft): Promise<RestaurantDraft> => {
  return axios
    .post<RestaurantDraft>(generatePath(apiPaths.RESTAURANTS_DRAFTS, { restaurantId }), draft)
    .then(({ data }) => data);
};

export const updateRestaurantDraft = async (restaurantId: string, draft: RestaurantDraft): Promise<RestaurantDraft> => {
  return axios
    .put<RestaurantDraft>(generatePath(apiPaths.RESTAURANTS_DRAFT_BY_ID, { draftId: draft.id, restaurantId }), draft)
    .then(({ data }) => data);
};

export const updateRestaurantDraftStatus = async ({
  restaurantId,
  draftId,
  status,
}: {
  restaurantId: string;
  draftId: string;
  status: string;
}): Promise<RestaurantDraft> => {
  return axios
    .put<RestaurantDraft>(generatePath(apiPaths.RESTAURANTS_DRAFTS_STATUS, { draftId, restaurantId }), { status })
    .then(({ data }) => data);
};
