import React, { JSX, useState } from 'react';
import { useMutation } from 'react-query';
import { Button, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { sendResetPasswordEmail } from 'api/user';
import { useTranslations } from 'contexts/LocalizationContext';

import { SendResetPasswordEmailStep } from '../../../types';
import Confirmation from './Confirmation';
import EmailForm from './EmailForm';
import Success from './Success';

const SendResetPasswordEmail = (): JSX.Element => {
  const translations = useTranslations();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<SendResetPasswordEmailStep>(SendResetPasswordEmailStep.FormStep);

  const { mutate, isLoading, isError } = useMutation(sendResetPasswordEmail, {
    onSuccess: () => {
      setCurrentStep(SendResetPasswordEmailStep.SuccessStep);
    },
  });

  const closeModal = () => {
    setCurrentStep(SendResetPasswordEmailStep.FormStep);
    onClose();
  };

  return (
    <>
      <Button
        mt={10}
        variant="link"
        size="sm"
        title={translations('reset_password_forgot')}
        colorScheme="orange"
        onClick={onOpen}
      >
        {translations('reset_password_forgot')}
      </Button>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <EmailForm
            setEmail={setEmail}
            closeModal={closeModal}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          <Confirmation
            email={email}
            sendEmail={mutate}
            isLoading={isLoading}
            isError={isError}
            closeModal={closeModal}
            currentStep={currentStep}
          />
          <Success
            setEmail={setEmail}
            closeModal={closeModal}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default SendResetPasswordEmail;
