import React from 'react';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Flex } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useInventoryCountsContext } from '../../../../context/hooks/use-inventory-counts-context.hook';

export const ReviewYourCount = () => {
  const translations = useTranslations();
  const { activeStep, setActiveStep } = useInventoryCountsContext();

  const handleReviewYourCount = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Flex justifyContent="flex-end" w={'100%'} px={[2, 2, 4, 4]}>
      <Box w={['50%', '50%', '200px', '200px']} p={1}>
        <Button w={'100%'} onClick={handleBack} variant={'white'} leftIcon={<ArrowLeftIcon h={3} w={3} />}>
          {translations('inventory_counts_back_button_label')}
        </Button>
      </Box>
      <Box w={['50%', '50%', '200px', '200px']} p={1}>
        <Button w={'100%'} onClick={handleReviewYourCount} variant={'orangeSolid'}>
          {translations('inventory_counts_review_your_count')}
        </Button>
      </Box>
    </Flex>
  );
};
