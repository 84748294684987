import React, { JSX, useEffect, useRef } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { useScannedRelevosContext } from '../../../../../contexts/ScannedRelevosContext';
import colors from '../../../../../styles/colors';
import RelevosList from '../../../../RelevoAssignment/components/SumUp/RelevosList';
import { ScannerMode } from '../../../../RelevoAssignment/model/enums/scanner-mode.enum';
import { DepositFooter } from '../DepositFooter/DepositFooter';

const DepositSumUp = (): JSX.Element | null => {
  const translations = useTranslations();
  const { scannedRelevos, setScannedRelevos, mode } = useScannedRelevosContext();
  const scrollToBottomRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (scrollToBottomRef.current) {
      scrollToBottomRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (scannedRelevos.length === 1) {
      scrollToBottom();
    }
  }, [scannedRelevos]);

  const handleRemoveRelevo = (relevo: string) => {
    setScannedRelevos((prevState) => prevState.filter((el) => el.uId !== relevo));
  };

  const depositSumUpHeading =
    mode === ScannerMode.DEPOSIT_ASSIGN
      ? translations('borrow_deposit_sum_up_heading')
      : translations('return_deposit_sum_up_heading');
  const depositInfoText =
    mode === ScannerMode.DEPOSIT_ASSIGN ? translations('borrow_deposit_info') : translations('return_deposit_info');

  if (scannedRelevos.length === 0) {
    return (
      <>
        <Box textAlign="left" ml="6px" pt={4}>
          <Text fontSize="20px">{depositInfoText}</Text>
          <Text fontSize="16px">{translations('scan_deposit_info_extended')}</Text>
        </Box>
      </>
    );
  }

  if (scannedRelevos.length >= 1) {
    return (
      <Box ref={scrollToBottomRef} backgroundColor={colors.white}>
        <Flex direction="column" pl={1} textAlign="left" pt={4}>
          <Flex justifyContent="space-between" alignItems="baseline" flexWrap="wrap">
            <Text pl={1} fontSize="20px">
              {depositSumUpHeading}
            </Text>
            <Box display={['none', 'none', 'block', 'block', 'none']}>
              <DepositFooter />
            </Box>
          </Flex>
          <RelevosList onRemoveItem={handleRemoveRelevo} />
          <Box display={['block', 'block', 'none', 'none', 'block']} mb={4}>
            <DepositFooter />
          </Box>
        </Flex>
      </Box>
    );
  }
  return <></>;
};

export default DepositSumUp;
