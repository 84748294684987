import React, { useEffect, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { useScannedRelevosContext } from 'contexts/ScannedRelevosContext';

import './RelevosList.css';

import { RelevoTableContainer } from '../../../../common/components/Table/TableHelper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import RelevosListItem from './RelevosListItem';

type RelevosListProps = {
  onRemoveItem: (uId: string) => void;
};

const RelevosList = ({ onRemoveItem }: RelevosListProps): JSX.Element => {
  const translations = useTranslations();
  const { scannedRelevos } = useScannedRelevosContext();
  const [height, setHeight] = React.useState(500);
  const refHead = useRef<HTMLTableRowElement>(null);
  const refBody = useRef<HTMLTableSectionElement>(null);

  const handleResize = () => {
    if (refBody?.current?.children?.[0] && refHead?.current) {
      setHeight(
        refBody?.current?.children?.length && refHead?.current
          ? 3 * refBody.current.children[refBody.current.children.length - 1].clientHeight +
              refHead?.current.clientHeight
          : 500,
      );
    }
  };

  useEffect(() => {
    handleResize();
  }, [scannedRelevos]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <RelevoTableContainer my={4} mr={2} maxHeight={height + 4} overflowY="auto" overflowX="auto" border={'1px solid'}>
      <Table>
        <Thead position="sticky" top={0} zIndex={10} bg={'white'}>
          <Tr ref={refHead}>
            <Th>{translations('relevos_table_dish')}</Th>
            <Th>{translations('relevos_table_name')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody ref={refBody}>
          <TransitionGroup component={null}>
            {scannedRelevos
              .slice(0)
              .reverse()
              .map((relevo) => {
                const nodeRef = React.createRef<HTMLTableRowElement>();
                return (
                  <CSSTransition key={relevo.uId} timeout={400} classNames="item" nodeRef={nodeRef}>
                    <RelevosListItem key={relevo.uId} relevo={relevo} removeRelevo={onRemoveItem} ref={nodeRef} />
                  </CSSTransition>
                );
              })}
          </TransitionGroup>
        </Tbody>
      </Table>
    </RelevoTableContainer>
  );
};

export default RelevosList;
