import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import { MainPageContent } from '../../../../common/components/MainPageContent';
import MainPageHeading from '../../../../common/components/MainPageHeading/MainPageHeading';
import { Routes } from '../../../../config/routes';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { ScannedRelevosContextProvider } from '../../../../contexts/ScannedRelevosContext';
import { useRestaurantsData } from '../../../../services/restaurants';
import { ScannerMode } from '../../../RelevoAssignment/model/enums/scanner-mode.enum';
import { DepositScanner } from '../../common/components/DepositScanner/DepositScanner';
import DepositSumUp from '../../common/components/DepositSumUp/DepositSumUp';

export const BorrowDeposit = () => {
  const translations = useTranslations();
  const { restaurants, isDepositEnabled } = useRestaurantsData();
  const depositRestaurants = useMemo(
    () => restaurants.filter((restaurant) => restaurant.isDepositEnabled),
    [restaurants],
  );

  if (restaurants.length && !isDepositEnabled) {
    return <Navigate to={Routes.HOME_PATH} />;
  }

  return (
    <MainPageContent>
      <MainPageHeading>{translations('borrow_deposit_heading')}</MainPageHeading>
      {depositRestaurants.length ? (
        <ScannedRelevosContextProvider mode={ScannerMode.DEPOSIT_ASSIGN} locationId={depositRestaurants[0].id}>
          <Box>
            <DepositScanner restaurants={depositRestaurants} />
            <DepositSumUp />
          </Box>
        </ScannedRelevosContextProvider>
      ) : null}
    </MainPageContent>
  );
};
