import React from 'react';
import { Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { DocumentsTileResponse } from './model/DocumentsTileResponse';
import { getGoogleDriveEmbeddedFolderUrl } from './utils/info-point.helper';

type GoogleDriveFolderProps = {
  tile: DocumentsTileResponse;
  isOpen: boolean;
  onClose: () => void;
};

const GoogleDriveFolderModal = ({ tile, isOpen, onClose }: GoogleDriveFolderProps) => {
  const { language } = useLocalizationContext();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent h="70%">
        <ModalHeader>{tile.translations[language]}</ModalHeader>
        <ModalCloseButton />
        <Flex h="100%" display="column" p={2}>
          <iframe
            src={getGoogleDriveEmbeddedFolderUrl(tile.driveFolder)}
            width="100%"
            height="100%"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
          />
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default GoogleDriveFolderModal;
