/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Routes } from 'config/routes';
import { useSessionContext } from 'contexts/SessionContext/hooks';
import { Role } from 'contexts/SessionContext/SessionContext';

const ProtectedRoute = ({ roles, element }: { element: React.ReactNode; roles?: Role[] }): React.ReactNode => {
  const { doesSessionExist, role } = useSessionContext();
  const location = useLocation();

  if (!doesSessionExist || !role) {
    return <Navigate to={Routes.SIGNIN_PATH} state={{ nextPathname: location.pathname }} />;
  }

  if (roles && !roles.includes(role)) {
    return <Navigate to={Role.RETURN_STATION === role ? Routes.RETURN_STATION_PATH : Routes.HOME_PATH} />;
  }

  return element;
};

export default ProtectedRoute;
