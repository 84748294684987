import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { DOCUMENTS_QUERY_KEY } from '../api/apiConsts';
import { getDocuments } from '../api/documents';
import { DocumentResponse } from '../modules/InfoPoint/model/DocumentResponse';

export const useGetDocuments = (): QueryObserverResult<DocumentResponse[], AxiosError> => {
  return useQuery([DOCUMENTS_QUERY_KEY], () => getDocuments(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
