import { getFormattedDate } from '../../../utils/dateHelper';
import { DepositResponse } from '../../Deposit/model/deposit-response';
import { HistoryFilter } from '../model/HistoryFilter';
import { HistoryQueryRequest } from '../model/HistoryQueryRequest';

export const buildHistoryQueryParams = (historyFilter: HistoryFilter): HistoryQueryRequest => {
  return {
    restaurantIds: historyFilter.restaurantIds,
    orderField: historyFilter.orderField,
    orderType: historyFilter.orderType,
    startDate: getFormattedDate(historyFilter.startDate.toString()),
    ...(historyFilter.endDate && { endDate: getFormattedDate(historyFilter.endDate.toString()) }),
    ...(historyFilter.action && { action: historyFilter.action }),
  };
};

export const getDepositHistoryActionLabel = (action: string, deposit?: DepositResponse): string => {
  return deposit ? `${action} (${deposit?.amount} ${deposit?.currency})` : action;
};
