import React, { useMemo, useRef } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react';

import { FullPageLoader } from '../../../../common/components/Loader/FullPageLoader';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import colors from '../../../../styles/colors';
import { PublicFile } from '../../types';
import { useDragAndDrop } from './useDragAndDrop';

const getIconPath = (name: string) => require(`../../../../assets/icons/${name}`);

interface Props {
  files: PublicFile[];
  onChange: (files: PublicFile[]) => void;
  onFilesAdd: (files: FileList) => void;
  isUploading: boolean;
  isInvalid: boolean;
  fixedFiles?: PublicFile[];
}

const GALLERY_GAP_PX = 8;

export const ImagesInput = ({ files, onChange, onFilesAdd, isUploading, isInvalid, fixedFiles }: Props) => {
  const translations = useTranslations();
  const addFileRef = useRef<HTMLInputElement | null>(null);
  const { isActive, onDrop, onDragEnd, onDragOver, onDragStart, predictedOrder } = useDragAndDrop({
    itemsIds: (files || []).map((file) => file.file.id),
    onDragComplete: (newOrder) => {
      if (!newOrder) {
        return;
      }
      const filesInNewOrder = files.map((nextFile) => {
        const newOrderIndex = newOrder.findIndex((id) => id === nextFile.file.id);
        return { ...nextFile, order: newOrderIndex };
      });
      onChange(filesInNewOrder);
    },
  });
  const orderedFiles = useMemo(() => {
    return files.sort((a, b) => {
      if (predictedOrder) {
        const aPredictedOrder = predictedOrder.findIndex((id) => id === a.file.id);
        const bPredictedOrder = predictedOrder.findIndex((id) => id === b.file.id);
        return aPredictedOrder - bPredictedOrder;
      } else {
        return a.order - b.order;
      }
    });
  }, [files, predictedOrder]);

  const removeImage = (fileId: string) => {
    const fileOrderNo = files.find((file) => file.file.id === fileId)?.order;
    if (fileOrderNo === undefined) {
      return;
    }
    const newFilesOrder = files
      .filter((file) => file.file.id !== fileId)
      .map((file) => {
        if (file.order > fileOrderNo) {
          return { ...file, order: file.order - 1 };
        }
        return file;
      });

    onChange(newFilesOrder);
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (!selectedFiles) {
      return;
    }
    onFilesAdd(selectedFiles);
  };

  const handleUploadClick = () => {
    if (addFileRef.current) {
      addFileRef.current.click();
    }
  };

  return (
    <Box>
      <Flex
        p={`0 ${GALLERY_GAP_PX}px`}
        border="1px solid"
        borderColor={isInvalid ? colors.red['500'] : colors.orange['500']}
        borderRadius="md"
        h="120px"
        width="100%"
      >
        <FullPageLoader show={isUploading}>
          <Flex h="100%" w="100%" alignItems="center">
            <input type="file" hidden multiple accept="image/*" ref={addFileRef} onChange={handleFileInputChange} />
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDir="column"
              h={`calc(100% - ${GALLERY_GAP_PX * 2}px)`}
              aspectRatio="1 / 1"
              background={colors.grey['50']}
              cursor="pointer"
              onClick={handleUploadClick}
            >
              <Image aspectRatio="1 / 1" h="50%" src={getIconPath('cloud_upload.svg')} />
              <Text mt={1} color={colors.grey['200']}>
                {translations('restaurant_draft_upload_image_label')}
              </Text>
            </Flex>
            {fixedFiles?.map((image) => (
              <Box
                key={image.file.id}
                marginLeft={`${GALLERY_GAP_PX}px`}
                backgroundSize="cover"
                backgroundImage={image.file.url}
                h={`calc(100% - ${GALLERY_GAP_PX * 2}px)`}
                aspectRatio="1 / 1"
                position="relative"
              >
                <Box
                  position="absolute"
                  top="-1.5"
                  right="-1.5"
                  fontWeight="600"
                  fontSize="10"
                  p={[1, 1]}
                  borderRadius="2"
                  textTransform="uppercase"
                  color={colors.white}
                  background={colors.orange[500]}
                >
                  {translations('restaurant_draft_group_image')}
                </Box>
              </Box>
            ))}
            {orderedFiles.map((image) => (
              <Box
                id={image.file.id}
                key={image.file.id}
                marginLeft={`${GALLERY_GAP_PX}px`}
                backgroundSize="cover"
                backgroundImage={image.file.url}
                h={`calc(100% - ${GALLERY_GAP_PX * 2}px)`}
                aspectRatio="1 / 1"
                position="relative"
                cursor="pointer"
                draggable={!isUploading}
                opacity={isActive ? 0.5 : 1}
                onDragOver={onDragOver.bind(this, image.file.id)}
                onDragStart={onDragStart.bind(this, image.file.id)}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
              >
                <Flex
                  onClick={removeImage.bind(this, image.file.id)}
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  padding={2}
                  backgroundColor={colors.grey['50']}
                  bottom="2"
                  right="2"
                  borderRadius="5"
                  _hover={{ background: colors.orange['500'] }}
                >
                  <Icon as={DeleteIcon} color={colors.grey['900']} />
                </Flex>
              </Box>
            ))}
          </Flex>
        </FullPageLoader>
      </Flex>
    </Box>
  );
};
