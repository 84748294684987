import React, { JSX } from 'react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { Container, Text, useTheme, VStack } from '@chakra-ui/react';

import { MainPageContent } from '../../../../common/components/MainPageContent';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import CustomerCreationFormHeading from '../../components/CustomerCreationFormHeading/CustomerCreationFormHeading';

type CustomerCreationSummaryProps = {
  isFileSaved: boolean;
};

const CustomerCreationSummary = ({ isFileSaved }: CustomerCreationSummaryProps): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();

  return (
    <MainPageContent>
      <Container maxW={['90%', '80%', '60%', '40%']} pt={16} pb={12}>
        <VStack spacing={16}>
          <CustomerCreationFormHeading
            heading={translations('new_customer_summary_success')}
            color={colors.green[500]}
          />
          {!isFileSaved && (
            <VStack>
              <WarningTwoIcon color={colors.red[500]} w={10} h={6} />
              <Text fontSize="18px">{translations('new_customer_summary_warning')}</Text>
            </VStack>
          )}
          {isFileSaved && <Text fontSize="18px">{translations('new_customer_summary_information')}</Text>}
        </VStack>
      </Container>
    </MainPageContent>
  );
};
export default CustomerCreationSummary;
