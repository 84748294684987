import React, { JSX } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { useTranslations } from 'contexts/LocalizationContext';

import { CHART_COLORS_RGBA } from '../constants';

const chartOptions: ChartOptions = {
  indexAxis: 'y',
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      stacked: true,
      beginAtZero: true,
      ticks: {
        display: false,
      },
    },
    x: {
      position: 'top',
      ticks: {
        stepSize: 1,
        font: {
          weight: 'bold',
        },
      },
      grid: {
        z: 1,
      },
    },
  },
};

const ReturnTimeChart = ({ returnTimes, labels }: { returnTimes: number[]; labels: string[] }): JSX.Element => {
  const translations = useTranslations();

  const data = {
    labels,
    datasets: returnTimes.map((value, index) => ({
      categoryPercentage: 1.0,
      barPercentage: 1.0,
      data: [{ x: value, y: labels[index] }],
      label: labels[index],
      backgroundColor: CHART_COLORS_RGBA[index],
    })),
  };
  const config = {
    options: { ...chartOptions },
    data,
  };

  config.options.scales!.x!.ticks!.callback = (value) => `${value}${translations('days_shortcut')}`;

  return <Chart type="bar" {...config} />;
};

export default ReturnTimeChart;
