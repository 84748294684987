import { FormProvider, useForm } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';

import { MainPageContent } from '../../common/components/MainPageContent';
import { InventoryCountsFooter } from './components/InventoryCountsFooter/InventoryCountsFooter';
import { InventoryCountsHeader } from './components/InventoryCountsHeader/InventoryCountsHeader';
import { InventoryCountsStep } from './components/InventoryCountsStep/InventoryCountsStep';
import { InventoryCountsStepper } from './components/InventoryCountsStepper/InventoryCountsStepper';
import { InventoryCountsContextProvider } from './context/InventoryCountsContext';
import { InventoryCountsFormValues } from './model/inventory-counts-form-values';

export const InventoryCounts = () => {
  const methods = useForm<InventoryCountsFormValues>({ defaultValues: { additionalInformation: undefined } });

  return (
    <MainPageContent>
      <InventoryCountsContextProvider>
        <FormProvider {...methods}>
          <form>
            <Flex height={['100vh', '100vh', '100vh', 'calc(100vh - 64px)']} direction={'column'}>
              <Flex overflowY={'auto'} direction={'column'} height={'100%'}>
                <InventoryCountsHeader />
                <InventoryCountsStepper />
                <InventoryCountsStep />
              </Flex>
              <Flex alignItems={'flex-end'} w={'calc(100% + 1)'} p={2} ml={[0, 0, 0, -1]} bg={'grey.900'}>
                <InventoryCountsFooter />
              </Flex>
            </Flex>
          </form>
        </FormProvider>
      </InventoryCountsContextProvider>
    </MainPageContent>
  );
};
