import React, { JSX, useCallback, useMemo } from 'react';
import { Box, Center, Flex, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslations } from 'contexts/LocalizationContext';

import { TableType } from '../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../common/components/Table/Table';
import TableHeaderWithOrder from '../../../../common/components/TableHeaderWithOrder/TableHeaderWithOrder';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useRelevosHistory } from '../../../../services/history';
import { formatDateTime } from '../../../../utils/dateHelper';
import { DepositResponse } from '../../../Deposit/model/deposit-response';
import { DEPOSIT_USER_RELEVO_ID } from '../../../Deposit/utils/deposit.consts';
import { useHistoryFilterContext } from '../../context/HistoryFilterContext/hooks/useHistoryFilterContext';
import { HistoryActionType } from '../../model/enums/historyActionType.enum';
import { HistoryOrderField } from '../../model/enums/historyOrderField.enum';
import { HistoryResponse } from '../../model/HistoryResponse';
import { HISTORY_DEFAULT_TABLE_LIMIT } from '../../utils/history.consts';
import { buildHistoryQueryParams, getDepositHistoryActionLabel } from '../../utils/history.helper';

const HistoryTable = (): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const { historyState, handleOrderBy, handlePage } = useHistoryFilterContext();

  const { data, isLoading, isFetching, isError } = useRelevosHistory(
    buildHistoryQueryParams(historyState),
    (historyState.page - 1) * HISTORY_DEFAULT_TABLE_LIMIT,
    HISTORY_DEFAULT_TABLE_LIMIT,
  );

  const handleOrderHistoryBy = useCallback((field: HistoryOrderField) => () => handleOrderBy(field), [handleOrderBy]);

  const getHistoryActionLabel = useCallback(
    (action: HistoryActionType, deposit?: DepositResponse) => {
      switch (action) {
        case HistoryActionType.BORROW:
          return translations('history_filter_action_borrow');
        case HistoryActionType.RETURN:
          return translations('history_filter_action_return');
        case HistoryActionType.PENDING:
          return translations('history_filter_action_pending_return');
        case HistoryActionType.DEPOSIT_BORROW:
          return getDepositHistoryActionLabel(translations('history_filter_action_deposit_borrow'), deposit);
        case HistoryActionType.DEPOSIT_RETURN:
          return getDepositHistoryActionLabel(translations('history_filter_action_deposit_return'), deposit);
        default: {
          const exhaustiveCheck: never = action;
          throw new Error(exhaustiveCheck);
        }
      }
    },
    [translations],
  );

  const getUserRelevoId = useCallback(
    (userRelevoId: string) => {
      if (userRelevoId === DEPOSIT_USER_RELEVO_ID) {
        return translations('deposit');
      } else {
        return userRelevoId;
      }
    },
    [translations],
  );

  const columns = useMemo<ColumnDef<HistoryResponse>[]>(
    () => [
      {
        id: 'dateTime',
        header: () => (
          <TableHeaderWithOrder
            onClick={handleOrderHistoryBy(HistoryOrderField.DATE_TIME)}
            headerText={translations('history_table_date_time')}
            showOrder={historyState.orderField === HistoryOrderField.DATE_TIME}
            orderType={historyState.orderType}
          />
        ),
        cell: ({ row }) => <>{formatDateTime(new Date(row.original.dateTime))}</>,
      },
      {
        id: 'action',
        header: () => (
          <TableHeaderWithOrder
            onClick={handleOrderHistoryBy(HistoryOrderField.ACTION)}
            headerText={translations('history_table_action')}
            showOrder={historyState.orderField === HistoryOrderField.ACTION}
            orderType={historyState.orderType}
          />
        ),
        cell: ({ row }) => <>{getHistoryActionLabel(row.original.action, row.original.deposit)}</>,
      },
      {
        id: 'restaurantName',
        header: () => (
          <TableHeaderWithOrder
            onClick={handleOrderHistoryBy(HistoryOrderField.RESTAURANT_NAME)}
            headerText={translations('history_table_restaurant_name')}
            showOrder={historyState.orderField === HistoryOrderField.RESTAURANT_NAME}
            orderType={historyState.orderType}
          />
        ),
        cell: ({ row }) => <>{row.original.restaurant?.restaurantName}</>,
      },
      {
        id: 'dish',
        header: translations('history_table_dish'),
        cell: ({ row }) => (
          <Flex>
            <Center mr={2} minW="64px">
              <Image
                src={row.original.product?.category.imageUrl || row.original.category?.imageUrl || 'test'}
                maxHeight="64px"
                width="64px"
              />
            </Center>
            <Center>
              <VStack alignItems="start">
                {row.original.product ? <Text fontWeight="bold">{row.original.product?.productName}</Text> : null}
                <Text>
                  {row.original.product?.category.translations[language] ||
                    row.original.category?.translations[language]}
                </Text>
              </VStack>
            </Center>
          </Flex>
        ),
      },
      {
        id: 'userRelevoId',
        header: translations('history_table_user_relevo_id'),
        cell: ({ row }) => getUserRelevoId(row.original.userRelevoId),
      },
    ],
    [
      getHistoryActionLabel,
      getUserRelevoId,
      handleOrderHistoryBy,
      historyState.orderField,
      historyState.orderType,
      language,
      translations,
    ],
  );

  return (
    <Box pr={2}>
      {data ? (
        <Table
          type={TableType.PAGINATED}
          page={historyState.page}
          totalPages={data.totalPages}
          onPageChanged={handlePage}
          data={data.items}
          isLoading={isLoading || isFetching}
          columns={columns}
          withBorder
        />
      ) : (
        <Spinner />
      )}
      {isError ? <Text color={'red.500'}>{translations('something_went_wrong')}</Text> : null}
    </Box>
  );
};

export default HistoryTable;
