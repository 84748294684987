import React from 'react';
import { useNumberInput } from '@chakra-ui/react';
import { RelevosProductCategoryWithImage } from 'api/types';

import { QuantityInput } from '../../../../../common/components/QuantityInput/QuantityInput';

type Props = {
  category: RelevosProductCategoryWithImage;
  updateQuantityOfItemsInCategory: (categoryId: string, quantity: number) => void;
  categoryQuantity: number;
};

const CategoriesListItem = ({ category, updateQuantityOfItemsInCategory, categoryQuantity }: Props) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: 0,
    min: 0,
    max: 50,
    onChange: (valueAsString, valueAsNumber) => updateQuantityOfItemsInCategory(category.id, valueAsNumber),
    value: categoryQuantity,
  });

  return (
    <QuantityInput
      inputProps={{ ...getInputProps() }}
      decrementButtonProps={{ ...getDecrementButtonProps() }}
      incrementButtonProps={{ ...getIncrementButtonProps() }}
    />
  );
};

export default CategoriesListItem;
