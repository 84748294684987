export interface CustomerCreationRequest {
  salesOrganisation: string;
  salesPersonName: string;
  salesPersonEmail: string;
  salesPersonPhone: string;
  customerName: string;
  wholesaleCustomerId: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
  billingAddressStreetNumber: string;
  billingAddressAddition: string;
  billingAddressZipCode: string;
  billingAddressCity: string;
  billingAddressCountry: string;
  billingPersonTitle: string;
  billingName: string;
  billingEmail: string;
  isSepaDesired: boolean;
  desiredStartDate: string;
  isSocialMediaPressPermitted: boolean;
  starterSet: StarterSet;
  specialRequests: string;
  contractFile: File;
}

export enum StarterSetSize {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}

export type StarterSet = {
  [key in StarterSetSize]: number;
};

export type StarterSetSizeSelect = {
  label: StarterSetSize;
  value: StarterSetSize;
};

export const starterSetSizeSelectOptions: StarterSetSizeSelect[] = [
  { label: StarterSetSize.S, value: StarterSetSize.S },
  { label: StarterSetSize.M, value: StarterSetSize.M },
  { label: StarterSetSize.L, value: StarterSetSize.L },
  { label: StarterSetSize.XL, value: StarterSetSize.XL },
  { label: StarterSetSize.XXL, value: StarterSetSize.XXL },
];
