import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { getRelevoByUId } from '../../api/relevos';
import QrCodeScanner from '../../common/components/QrCodeScanner/QrCodeScanner';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../contexts/ScannedRelevosContext';
import { extractUId } from '../../utils/qrCodesHelper';

const ScanRelevo = (): JSX.Element => {
  const translations = useTranslations();
  const { scannedRelevos, setScannedRelevos } = useScannedRelevosContext();
  const [code, setCode] = useState('');

  const handleResponseError = (status: StatusCodes | undefined) => {
    if (status === StatusCodes.NOT_FOUND) {
      toast.error(<b>{translations('dish_not_found')}</b>);
    } else {
      toast.error(<b>{translations('something_went_wrong')}</b>);
    }
  };

  const { mutate: getRelevo, isLoading: isLoadingGetRelevo } = useMutation(getRelevoByUId, {
    onSuccess: (response) => {
      setScannedRelevos([response]);
    },
    onError: (err: AxiosError) => {
      handleResponseError(err.response?.status);
    },
  });

  const handleScan = useCallback(
    async (qrCode: string | null) => {
      if (!qrCode || isLoadingGetRelevo) {
        return;
      }
      const uId = extractUId(qrCode);

      if (!uId) {
        toast.error(<b>{translations('scan_invalid_qr_code')}</b>);
        return;
      }
      await getRelevo(uId);
    },
    // FYI: translations in deps cause endless re-rendering
    // eslint-disable-next-line
    [getRelevo],
  );

  useEffect(() => {
    if (code) {
      handleScan(code);
    }
  }, [code, handleScan]);

  return <>{!scannedRelevos.length ? <QrCodeScanner onScan={setCode} /> : null}</>;
};

export default ScanRelevo;
