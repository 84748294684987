import React, { JSX } from 'react';
import Select, { SingleValue } from 'react-select';

import colors from '../../../styles/colors';

const selectCustomStyle = {
  // eslint-disable-next-line
  menu: (base: any) => ({
    ...base,
    zIndex: 1000,
  }),
  // eslint-disable-next-line
  control: (base: any) => ({
    ...base,
    border: 0,
    minHeight: '30px',
    height: '30px',
    boxShadow: 'none',
    fontStyle: 'Rubik',
    fontSize: '14px',
    textAlign: 'left',
  }),
  // eslint-disable-next-line
  dropdownIndicator: (styles: any) => ({
    ...styles,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  // eslint-disable-next-line
  clearIndicator: (styles: any) => ({
    ...styles,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  // eslint-disable-next-line
  option: (base: any, state: any) => ({
    ...base,
    minHeight: '30px',
    paddingTop: 6,
    paddingBottom: 6,
    fontStyle: 'Rubik',
    fontSize: '14px',
    textAlign: 'left',
    whiteSpace: 'normal',
    fontWeight: state.isSelected ? '600' : '100',
    background: colors.white,
    color: colors.black,
    '&:hover': {
      cursor: 'pointer',
      background: colors.beige[50],
    },
  }),
};

type DropDownSelectProps = {
  options: { value: string; label: string }[];
  handleChange: (value: SingleValue<{ value: string; label: string }>) => void;
  isSearchable: boolean;
  selectedOption?: SingleValue<{ value: string; label: string }>;
  placeholder?: string;
};

const DropDownSelect = ({
  selectedOption,
  options,
  handleChange,
  isSearchable,
  placeholder,
}: DropDownSelectProps): JSX.Element => {
  return (
    <Select
      placeholder={placeholder}
      value={selectedOption}
      onChange={(value) => handleChange(value)}
      options={options}
      styles={selectCustomStyle}
      hideSelectedOptions={false}
      isSearchable={isSearchable}
    />
  );
};

export default DropDownSelect;
