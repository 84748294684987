import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Routes } from '../../../../config/routes';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { DepositTile } from '../../common/components/DepositTile/DepositTile';

export const BorrowDepositTile = () => {
  const translations = useTranslations();
  const navigate = useNavigate();

  const handleNavigateToBorrow = () => navigate(Routes.DEPOSIT_BORROW_PATH);

  return (
    <DepositTile
      tileName={translations('deposit_borrow')}
      explanatoryText={translations('deposit_borrow_info')}
      tileColor="orange.500"
      onClick={handleNavigateToBorrow}
    />
  );
};
