import React, { JSX, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { Routes } from '../../../../config/routes';
import { LanguageSelect, SelectLanguageMode } from '../../LanguageSelect/LanguageSelect';
import { LinkButton } from '../../LinkButton';
import { Menu } from './Menu';

const buttonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: 'none' },
  _hover: { backgroundColor: 'none' },
  _active: { backgroundColor: 'none' },
};

export const MenuMobile = (): JSX.Element => {
  const translations = useTranslations();
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  const { colors } = useTheme();

  useEffect(() => {
    onClose();
  }, [pathname, onClose]);

  const handleShowLanguageOptions = () => {
    onClose();
  };

  return (
    <>
      <IconButton
        visibility={pathname === Routes.SURVEY_SUBMITTED ? 'hidden' : 'visible'}
        {...buttonDefaultProps}
        aria-label={translations('menu_open_menu')}
        right={0}
        zIndex={1000}
        position="fixed"
        title={translations('menu_open_menu')}
        onClick={onOpen}
        sx={{ '@media print': { display: 'none' } }}
        bg={[colors.white, colors.white, colors.white, colors.orange[500]]}
        icon={<HamburgerIcon boxSize="32px" bg={[colors.white, colors.white, colors.white, colors.orange[500]]} />}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay>
          <DrawerContent bg={colors.beige[50]}>
            <Flex position="absolute" alignSelf="flex-end" height="5%" width="65%" pt={2} pr={12}>
              <LinkButton mt="3px" variant="beigeSolid" color={colors.grey[200]} to={Routes.FREQUENTLY_ASKED_QUESTIONS}>
                {translations('menu_faq')}
              </LinkButton>
              <LanguageSelect
                textColor={colors.grey[200]}
                mode={SelectLanguageMode.MOBILE}
                onChange={handleShowLanguageOptions}
              />
            </Flex>
            <DrawerCloseButton {...buttonDefaultProps} />
            <DrawerHeader />
            <DrawerBody mt={4}>
              <Menu />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
