import React from 'react';
import { Box, Text } from '@chakra-ui/react';

type ConfirmDialogBodyProps = {
  children: React.ReactNode | React.ReactNode[];
  confirmText: string;
};

export const ConfirmDialogBody = ({ confirmText, children }: ConfirmDialogBodyProps) => {
  return (
    <Box p={4}>
      <Text pb={4} fontSize="20px">
        {confirmText}
      </Text>
      <Box px={8} py={2}>
        {children}
      </Box>
    </Box>
  );
};
