import { useContext } from 'react';

import { InventoryCountsContext } from '../InventoryCountsContext';
import { InventoryCountsContextInterface } from '../model/InventoryCountsContext';

export const useInventoryCountsContext = (): InventoryCountsContextInterface => {
  const ctx = useContext(InventoryCountsContext);
  if (!ctx) {
    throw new Error('No context provided for InventoryCounts');
  }
  return ctx;
};
