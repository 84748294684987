import { apiPaths } from '../config/apiPaths';
import { ReportDamagedItemRequest } from '../modules/DamagedItemsReporting/model/ReportDamagedItemRequest';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export const reportDamagedItem = (values: ReportDamagedItemRequest): Promise<void> => {
  const formData = createFormData(values);
  return axiosInstance.post(`${apiPaths.DAMAGED_ITEMS_PATH}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
