import {
  Box,
  StepDescription,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
} from '@chakra-ui/react';

type InventoryCountStepProps = {
  title: string;
  description: string;
};

export const InventoryCountsStepperStep = ({ title, description }: InventoryCountStepProps) => {
  return (
    <>
      <StepIndicator position={'relative'}>
        <StepStatus complete={<StepNumber />} incomplete={<StepNumber />} active={<StepNumber />} />
      </StepIndicator>
      <Box position={'absolute'} top={'50px'} width={'200px'} display={['none', 'none', 'none', 'block']}>
        <StepTitle>{title}</StepTitle>
        <StepDescription>{description}</StepDescription>
      </Box>
      <StepSeparator />
    </>
  );
};
