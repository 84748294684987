import { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { Currency } from '../../../../common/models/enums/currency.enum';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { useBorrowDepositItems, useReturnDepositItems } from '../../../../services/deposit';
import { ScannerMode } from '../../../RelevoAssignment/model/enums/scanner-mode.enum';
import { BorrowDepositResponse } from '../../model/borrow-deposit-response';
import { DepositItemResponse } from '../../model/deposit-item-response';
import { DepositRequest } from '../../model/deposit-request';
import { DepositResponse } from '../../model/deposit-response';
import { ErrorDepositItemResponse } from '../../model/error-deposit-item-response';
import { ReturnDepositResponse } from '../../model/return-deposit-response';

interface DepositState {
  deposit: DepositResponse;
  successItems: DepositItemResponse[];
  failureItems: ErrorDepositItemResponse[];
}

export const useDeposit = () => {
  const { mode, scannedRelevos, restaurantId } = useScannedRelevosContext();
  const { mutate: borrowDeposit, isLoading: isBorrowDepositLoading } = useBorrowDepositItems();
  const { mutate: returnDeposit, isLoading: isReturnDepositLoading } = useReturnDepositItems();
  const [depositState, setDepositState] = useState<DepositState>({
    deposit: { amount: 0, currency: Currency.EUR },
    successItems: [],
    failureItems: [],
  });
  const confirmDepositModal = useDisclosure();
  const depositResultModal = useDisclosure();

  const handleSuccess = () => {
    confirmDepositModal.onClose();
    depositResultModal.onOpen();
  };

  const confirm = () => {
    const request: DepositRequest = { uIds: scannedRelevos.map((relevo) => relevo.uId) };
    if (restaurantId) {
      if (mode === ScannerMode.DEPOSIT_ASSIGN) {
        borrowDeposit(
          { restaurantId, request },
          {
            onSuccess: (response: BorrowDepositResponse) => {
              setDepositState({
                deposit: response.deposit,
                successItems: response.borrowedItems,
                failureItems: response.notBorrowedItems,
              });
              handleSuccess();
            },
          },
        );
      } else if (mode === ScannerMode.DEPOSIT_RETURN) {
        returnDeposit(
          { restaurantId, request },
          {
            onSuccess: (response: ReturnDepositResponse) => {
              setDepositState({
                deposit: response.deposit,
                successItems: response.returnedItems,
                failureItems: response.notReturnedItems,
              });
              handleSuccess();
            },
          },
        );
      }
    }
  };

  return {
    confirm,
    depositState,
    confirmDepositModal,
    depositResultModal,
    isLoadingConfirm: mode === ScannerMode.DEPOSIT_ASSIGN ? isBorrowDepositLoading : isReturnDepositLoading,
  };
};
