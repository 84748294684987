export const convertArrayForMessage = (text: string[]): string => text.join('\n\n');

export const cutLongString = (str: string, range: number): string => {
  if (str.length >= range) {
    return `${str.slice(0, range)}...`;
  }
  return str;
};

export const normalizeString = (value: string): string => {
  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};
