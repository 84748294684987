import React, { useCallback, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { Box, Center, Flex, Spacer, Spinner, VStack } from '@chakra-ui/react';

import { LocationsTimeFrameEnum } from '../../api/community-statistics';
import { FullPageLoader } from '../../common/components/Loader/FullPageLoader';
import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { NoLiveLocationsHeader } from '../../common/components/NoActiveLocationsHeader/NoLiveLocationsHeader';
import { Pagination } from '../../common/components/Pagination/Pagination';
import { Searchbox } from '../../common/components/Searchbox/Searchbox';
import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useSessionContext } from '../../contexts/SessionContext';
import { useGetLocationsStatistics } from '../../services/locations';
import { useRestaurantsData } from '../../services/restaurants';
import colors from '../../styles/colors';
import { LOCATIONS_PAGINATION_SIZE } from './locations.consts';
import LocationsStatistics from './LocationStatistics';
import RestaurantsStatistics from './RestaurantsStatistics';

export const CK_LOCATION_STATISTICS = 'community';

// eslint-disable-next-line
const ValueContainer = ({ children, ...props }: any) => {
  const translations = useTranslations();
  const { restaurantIds } = useRestaurantsData();
  const { getValue, hasValue } = props;
  const nbValues = getValue().length;
  const tmpChildren = [...children];
  if (!hasValue) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }

  if (restaurantIds && restaurantIds.length === nbValues) {
    tmpChildren[0] = translations('all_locations', {
      '{{count}}': nbValues,
    });
    return <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>;
  }
  tmpChildren[0] = translations('selected_locations', {
    '{{count}}': nbValues,
  });
  return <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>;
};

const selectCustomStyle = {
  // eslint-disable-next-line
  menu: (base: any) => ({
    ...base,
    zIndex: 1000,
  }),
  // eslint-disable-next-line
  control: (base: any) => ({
    ...base,
    border: 0,
    minHeight: '30px',
    height: '30px',
    boxShadow: 'none',
    fontStyle: 'Rubik',
    fontSize: '14px',
    textAlign: 'left',
  }),
  // eslint-disable-next-line
  dropdownIndicator: (styles: any) => ({
    ...styles,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  // eslint-disable-next-line
  clearIndicator: (styles: any) => ({
    ...styles,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  // eslint-disable-next-line
  option: (base: any, state: any) => ({
    ...base,
    minHeight: '30px',
    paddingTop: 6,
    paddingBottom: 6,
    fontStyle: 'Rubik',
    fontSize: '14px',
    textAlign: 'left',
    whiteSpace: 'normal',
    fontWeight: state.isSelected ? '600' : '100',
    background: colors.white,
    color: colors.black,
    '&:hover': {
      cursor: 'pointer',
      background: colors.beige[50],
    },
  }),
};

const defaultStyle = {
  fontSize: '18px',
  fontStyle: 'Rubik',
  marginBottom: '6px',
  marginTop: '12px',
};

const timeframeHeadingStyle = {
  fontSize: '14px',
  fontStyle: 'Rubik',
  color: colors.grey[200],
  fontWeight: '600',
  marginLeft: '20px',
};

export interface TimeFrameOptions {
  value: LocationsTimeFrameEnum;
  label: string;
}

export interface SelectedRestaurantOptions {
  value: string;
  label: string;
}
const Locations = (): React.ReactNode => {
  const translations = useTranslations();
  const { fnbLocationAccountName } = useSessionContext();
  const { restaurants } = useRestaurantsData();
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);

  const timeframeOptions = [
    { value: LocationsTimeFrameEnum.TODAY, label: translations('location_timeframe_today') },
    { value: LocationsTimeFrameEnum.LAST_7_DAYS, label: translations('location_timeframe_last_7_days') },
    { value: LocationsTimeFrameEnum.LAST_30_DAYS, label: translations('location_timeframe_last_30_days') },
    { value: LocationsTimeFrameEnum.ALL, label: translations('location_timeframe_all') },
  ];

  const getMappedRestaurants = useCallback(
    () =>
      restaurants.map((restaurant) => ({
        value: restaurant.id,
        label: restaurant.restaurantName,
      })),
    [restaurants],
  );

  const [restaurantOptions, setRestaurantOptions] = useState<SelectedRestaurantOptions[]>(getMappedRestaurants());
  const [selectedTimeframeOption, setSelectedTimeframeOption] = useState<TimeFrameOptions>(timeframeOptions[1]);
  const [selectedRestaurantOptions, setSelectedRestaurantOption] =
    useState<SelectedRestaurantOptions[]>(getMappedRestaurants());

  React.useEffect(() => {
    if (restaurants.length) {
      setRestaurantOptions(getMappedRestaurants());
      setSelectedRestaurantOption(getMappedRestaurants());
    }
  }, [restaurants, getMappedRestaurants]);

  const getTimeframeHeading = useMemo(() => {
    const today = new Date().toLocaleDateString();
    if (LocationsTimeFrameEnum.TODAY === selectedTimeframeOption.value) {
      return today;
    }
    if (
      LocationsTimeFrameEnum.LAST_7_DAYS === selectedTimeframeOption.value ||
      LocationsTimeFrameEnum.LAST_30_DAYS === selectedTimeframeOption.value
    ) {
      const startOfPeriod = new Date(
        new Date().getTime() - (selectedTimeframeOption.value - 1) * 24 * 60 * 60 * 1000,
      ).toLocaleDateString();
      return `${startOfPeriod} - ${today}`;
    }
    return ``;
  }, [selectedTimeframeOption]);

  const {
    data: statistics,
    isLoading: isLoadingLocationStatistics,
    isFetching,
  } = useGetLocationsStatistics(selectedTimeframeOption, selectedRestaurantOptions, {
    limit: LOCATIONS_PAGINATION_SIZE,
    offset: page,
    q: debouncedSearch,
  });

  // eslint-disable-next-line
  const handleSelectedTimeframeOption = (value: any) => setSelectedTimeframeOption(value);
  // eslint-disable-next-line
  const handleSelectedRestaurantOption = (value: any) => setSelectedRestaurantOption(value);

  if (isLoadingLocationStatistics) {
    return (
      <>
        <MainPageContent>
          <Spinner />
        </MainPageContent>
      </>
    );
  }

  return (
    <>
      <MainPageContent>
        {restaurants.length ? (
          <Box>
            <MainPageHeading>{fnbLocationAccountName || translations('your_locations')}</MainPageHeading>
            <Box>
              <Box bgColor={colors.beige[50]} marginTop="18px" height="50px" width="100%">
                <Flex mx={6} height="100%" alignItems="center">
                  <div style={{ width: '200px', zIndex: '5' }}>
                    <Select
                      value={selectedTimeframeOption}
                      onChange={(value) => handleSelectedTimeframeOption(value)}
                      options={timeframeOptions}
                      styles={selectCustomStyle}
                    />
                  </div>
                  <h2 style={timeframeHeadingStyle}>{getTimeframeHeading}</h2>
                  <Spacer />
                  <div style={{ width: '300px', zIndex: '5' }}>
                    <Select
                      value={selectedRestaurantOptions}
                      isMulti
                      onChange={(value) => handleSelectedRestaurantOption(value)}
                      options={restaurantOptions}
                      styles={selectCustomStyle}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{ ValueContainer }}
                    />
                  </div>
                </Flex>
              </Box>
            </Box>
            <VStack alignItems="start" spacing="auto" width="100%" pl={2}>
              <h2 style={defaultStyle}>{translations('summary_for_selected_locations')}</h2>
              <LocationsStatistics statistics={statistics} timeFrameOption={selectedTimeframeOption.value} />
            </VStack>
            <Box>
              {statistics ? (
                <Box bgColor={colors.beige[50]} marginTop="18px" width="100%">
                  {restaurants.length > LOCATIONS_PAGINATION_SIZE ? (
                    <Flex ml={6} pt={2}>
                      <Searchbox
                        value={searchText}
                        onChange={setSearchText}
                        placeholder={translations('locations_searchbox_placeholder')}
                      />
                    </Flex>
                  ) : null}
                  <FullPageLoader show={isFetching}>
                    <RestaurantsStatistics
                      restaurants={statistics.locationsStatistics.items}
                      timeFrameOption={selectedTimeframeOption.value}
                    />
                  </FullPageLoader>
                  <Center pb={2}>
                    <Pagination
                      page={page}
                      totalPages={statistics.locationsStatistics.totalPages}
                      onPageChanged={setPage}
                    />
                  </Center>
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : (
          <NoLiveLocationsHeader />
        )}
      </MainPageContent>
    </>
  );
};

export default Locations;
