import React, { JSX } from 'react';
import { Box, Link, Text, useTheme } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { ABOUT_US_LINK } from './consts';
import HubspotContactForm from './HubspotContactForm';

const Contact = (): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();

  return (
    <MainPageContent>
      <Box width="100%" textAlign="center">
        <MainPageHeading>{translations('contact_heading')}</MainPageHeading>
        <HubspotContactForm />
        <Text mt={10} color={colors.orange[500]}>
          {translations('contact_visit')}
          <Link href={ABOUT_US_LINK} color="blue.500" isExternal>
            {ABOUT_US_LINK}
          </Link>
          .
        </Text>
      </Box>
    </MainPageContent>
  );
};

export default Contact;
