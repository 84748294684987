import { useContext } from 'react';

import { ScannedRelevosContext, ScannedRelevosContextInterface } from './ScannedRelevosContext';

export const useScannedRelevosContext = (): ScannedRelevosContextInterface => {
  const ctx = useContext(ScannedRelevosContext);
  if (!ctx) {
    throw new Error('No context provided for ScannedRelevos');
  }
  return ctx;
};
