import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Flex,
  Heading,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { RelevoTableContainer } from 'common/components/Table/TableHelper';
import { StatusCodes } from 'http-status-codes';

import { getApplicationUsers, SearchUserType } from '../../../../api/applicationUser';
import { upgradeCard } from '../../../../api/relevos';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import colors from '../../../../styles/colors';
import { UPGRADE_VALUES } from './upgrade-values.const';

const acceptButtonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: colors.orange[500] },
  _hover: { backgroundColor: colors.orange[500] },
  _active: { backgroundColor: colors.orange[500] },
};

const CURRENCY_SYMBOL = '€';

const amountParse = (val: string): number => Math.floor(Number(val) / 5) * 5;

const Upgrade = (): JSX.Element | null => {
  const { applicationUser, restaurantId, setApplicationUser } = useScannedRelevosContext();
  const translations = useTranslations();
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  // eslint-disable-next-line
  const [isModalVisible, setIsModalVisible] = useState(false);
  // eslint-disable-next-line
  const [isError, setIsError] = useState(false);

  const sendCardUpgradeRequest = () => {
    if (restaurantId) {
      upgradeCard(applicationUser!.id, selectedValue!, restaurantId)
        .then(() => {
          setIsModalVisible(false);
          setIsError(false);
          toast.success(
            <h2>
              {translations('client')}
              <b>{applicationUser!.userId}</b>
              {translations('successfully_upgraded')}
            </h2>,
          );
          setSelectedValue(null);
          getApplicationUsers(applicationUser!.userId, SearchUserType.Card).then((applicationUsers) => {
            if (applicationUsers?.length === 1) {
              setApplicationUser(applicationUsers[0]);
            } else {
              setApplicationUser(null);
            }
          });
        })
        .catch((error: AxiosError) => {
          setIsError(true);
          toast.error(
            <b>
              {translations(
                error.response?.status === StatusCodes.NOT_FOUND
                  ? 'card_upgrade_inactive_card_error'
                  : 'something_went_wrong',
              )}
            </b>,
          );
        });
    }
  };

  if (applicationUser) {
    return (
      <>
        <Box pl={2} textAlign="left" ml="10px">
          <h4 style={{ fontSize: '20px' }}>
            {Number(applicationUser.accountInfo?.accountBalance.amount) === 0
              ? translations('relevos_upgrade_activate_header')
              : translations('relevos_upgrade_header')}
            <b>{applicationUser.userId.toString()}</b>
          </h4>

          {applicationUser.accountInfo?.accountBalance ? (
            <RelevoTableContainer>
              <Table width="500px">
                <Tbody>
                  <Tr>
                    <Td>{translations('relevos_upgrade_card_value')}</Td>
                    <Td>
                      <b>
                        {applicationUser.accountInfo?.accountBalance.amount}{' '}
                        {applicationUser.accountInfo?.accountBalance.currency}{' '}
                      </b>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>{translations('relevos_upgrade_borrowed_value')}</Td>
                    <Td>
                      <b>
                        {applicationUser.accountInfo?.notReturnFeeSum.toFixed(2)}{' '}
                        {applicationUser.accountInfo?.accountBalance.currency}{' '}
                      </b>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>{translations('relevos_upgrade_free_value')}</Td>
                    <Td>
                      <b>
                        {(
                          Number(applicationUser.accountInfo?.accountBalance.amount) -
                          Number(applicationUser.accountInfo?.notReturnFeeSum)
                        ).toFixed(2)}{' '}
                        {applicationUser.accountInfo?.accountBalance.currency}{' '}
                      </b>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </RelevoTableContainer>
          ) : null}
          <br />
          <Heading as="h2" fontWeight="normal" fontSize="16px" color={colors.black} textAlign="left">
            {translations('upgrade_explanation_line_1')} <br />
            {translations('upgrade_explanation_line_2')}
          </Heading>
          <Flex textAlign="left" mt={3} wrap="wrap">
            {UPGRADE_VALUES.map((value) => (
              <Button
                width="120px"
                key={value}
                padding={10}
                fontSize="20px"
                mr={2}
                mt={2}
                fontWeight={selectedValue === value ? 'bold' : 'normal'}
                border="1px solid"
                borderColor={selectedValue === value ? colors.orange[500] : colors.grey[50]}
                bgColor={colors.white}
                onClick={() => setSelectedValue(value)}
                _hover={{ bg: colors.white }}
                _focus={{
                  boxShadow: 'none',
                }}
                _active={{
                  borderColor: colors.orange[500],
                }}
              >
                {CURRENCY_SYMBOL + value}
              </Button>
            ))}
            <Box pr={2} mt={2}>
              <InputGroup size="lg" fontSize="20px">
                <InputLeftAddon children={CURRENCY_SYMBOL} style={{ height: '82px' }} />
                <NumberInput
                  step={5}
                  onChange={(valueString) => setSelectedValue(amountParse(valueString))}
                  value={selectedValue || undefined}
                  min={5}
                  max={200}
                  width="150px"
                >
                  <NumberInputField style={{ height: '82px' }} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </InputGroup>
            </Box>
          </Flex>
          <Box width="100%" textAlign="left" mt={2} ml="-18px">
            <Button
              {...acceptButtonDefaultProps}
              disabled={!selectedValue || !restaurantId}
              color={colors.black}
              bgColor={colors.orange[500]}
              fontWeight="normal"
              minWidth={144}
              margin="auto"
              mx={4}
              mt={5}
              onClick={sendCardUpgradeRequest}
            >
              {Number(applicationUser.accountInfo?.accountBalance.amount) === 0
                ? translations('card_upgrade_activate_confirm_button')
                : translations('card_upgrade_confirm_button')}
            </Button>
          </Box>
        </Box>
      </>
    );
  }
  return <div />;
};

export default Upgrade;
