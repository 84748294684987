import React, { JSX } from 'react';
import { Text } from '@chakra-ui/react';

type ItemsSummaryStatusTextProps = {
  summaryText: string | JSX.Element | JSX.Element[];
  successItems: unknown[];
  failureItems: unknown[];
};

export const ItemsSummaryStatusText = ({ summaryText, successItems, failureItems }: ItemsSummaryStatusTextProps) => {
  const getSummaryTextColor = () => {
    if (successItems.length === 0) {
      return 'red.100';
    } else if (failureItems.length === 0) {
      return 'green.100';
    } else {
      return 'orange.100';
    }
  };
  return (
    <Text px={8} py={2} bg={getSummaryTextColor()}>
      {summaryText}
    </Text>
  );
};
