import React, { ComponentProps, JSX } from 'react';
import { UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { Input } from '@chakra-ui/react';

import FormInput from './FormInput';

type FormIndentedInputProps = {
  id: string;
  useFormRegisterReturn: UseFormRegisterReturn;
  setValue: UseFormSetValue<any>;
  label: string;
  isInvalid?: boolean;
  validationError?: string;
} & ComponentProps<typeof Input>;

const FormIndentedInput = ({
  id,
  useFormRegisterReturn,
  isInvalid,
  setValue,
  label,
  validationError,
  ...inputAttributes
}: FormIndentedInputProps): JSX.Element => {
  return (
    <FormInput
      id={id}
      isIndented={true}
      isBold={false}
      label={label}
      useFormRegisterReturn={useFormRegisterReturn}
      isInvalid={isInvalid}
      validationError={validationError}
      setValue={setValue}
      {...inputAttributes}
    />
  );
};

export default FormIndentedInput;
