import React, { JSX, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Button, Heading, Stack, Text, useTheme } from '@chakra-ui/react';
import { ResetPasswordRequestData } from 'api/types';
import { useTranslations } from 'contexts/LocalizationContext';

import Input from './Input';
import { ResetPasswordInputs } from './types';

type Props = {
  resetPassword: (args: ResetPasswordRequestData) => void;
  isLoading: boolean;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
};

const ResetPasswordForm = ({ resetPassword, isLoading, errorMessage, setErrorMessage }: Props): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordInputs>();
  const passwordRef = useRef<string | null>(null);
  passwordRef.current = watch('password', '');
  const params = new URLSearchParams(useLocation().search);

  const onSubmit = async (data: ResetPasswordInputs) => {
    const { password } = data;
    const token = params.get('token') || '';
    resetPassword({ password, token });
  };

  const handleChange = () => setErrorMessage('');

  return (
    <>
      <Heading size="xl" color={colors.orange[500]} mb={8}>
        {translations('reset_password')}
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
        <Stack spacing={8} alignItems="center">
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              minLength: 8,
              maxLength: 100,
              pattern: /^.*[A-Za-z][0-9]+.*$/,
            }}
            render={({ field: { onChange, value } }) => (
              <Input onChange={onChange} value={value} placeholder={translations('reset_password_new_password')} />
            )}
          />
          <Controller
            name="passwordRepeat"
            control={control}
            defaultValue=""
            rules={{
              validate: (value) => value === passwordRef.current || 'Password mismatch',
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                onChange={onChange}
                value={value}
                placeholder={translations('reset_password_confirm_new_password')}
              />
            )}
          />
          <Button
            type="submit"
            title={translations('reset_password_confirm_save')}
            colorScheme="orange"
            isLoading={isLoading}
          >
            {translations('reset_password_confirm_save')}
          </Button>
          {errorMessage ? <Text color={colors.orange[500]}>{translations(errorMessage)}</Text> : null}
          {errors.password ? <Text color={colors.orange[500]}>{translations('reset_password_validation')}</Text> : null}
          {errors.passwordRepeat ? (
            <Text color={colors.orange[500]}>{translations('reset_password_mismatch')}</Text>
          ) : null}
        </Stack>
      </form>
    </>
  );
};

export default ResetPasswordForm;
