export const handleSelectedCategoryIdsChange = (
  isChecked: boolean,
  categoryId: string,
  categoryIds: string[],
  onChange: (ids: string[]) => void,
): void => {
  if (isChecked) {
    const ids = categoryIds;
    ids.push(categoryId);
    onChange(Array.from(ids));
  } else {
    let ids = categoryIds;
    ids = ids.filter((id) => Number(id) !== Number(categoryId));
    onChange(ids);
  }
};
