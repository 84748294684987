import React, { JSX } from 'react';
import { Image } from '@chakra-ui/react';

import { getIconPath } from './utils/menuHelper';

type MenuSubsectionArrowImageProps = {
  isSectionExpanded: boolean;
};

export const MenuSubsectionArrowImage = ({ isSectionExpanded }: MenuSubsectionArrowImageProps): JSX.Element => {
  return (
    <Image
      src={getIconPath('arrow.svg')}
      style={isSectionExpanded ? { transform: 'rotate(360deg)' } : { transform: 'rotate(180deg)' }}
      alt="Menu item icon"
      display="flex"
      justifyContent="space-between"
      ml="auto"
      mr={0}
    />
  );
};
