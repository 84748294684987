import { useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import { useTranslations } from '../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../contexts/ScannedRelevosContext';
import { useLocalStorage } from '../../utils/LocalStorage.hook';

export interface DeviceInfo {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
}

export enum HandleDevicesMode {
  ASSIGN = 'ASSIGN',
  RETURN = 'RETURN',
  DAMAGED_ITEMS = 'DAMAGED_ITEMS',
}

export const useHandleVideoDevices = (mode?: HandleDevicesMode) => {
  const translations = useTranslations();
  const [devices, setDevices] = useState<DeviceInfo[]>([]);
  const [facingMode, setFacingMode] = useLocalStorage<'user' | 'environment' | undefined>('facingMode', 'environment');
  const [deviceId, setDeviceId] = useLocalStorage('deviceId', '');
  const [currentStream, setCurrentStream] = useState<MediaStream>();
  const { applicationUser } = useScannedRelevosContext();

  const devicesSelectOptions = useMemo(
    () =>
      devices?.map((device) => ({
        value: device.deviceId,
        label: device.label,
      })),
    [devices],
  );

  useEffect(() => {
    return () => currentStream?.getTracks().forEach((t) => t.stop());
  }, [currentStream]);

  const handleSelectDevice = (deviceOption: SingleValue<{ value: string; label: string }>) => {
    setDeviceId('');
    if (currentStream) {
      currentStream.getTracks().forEach((track) => track.stop());
    }
    setCurrentStream(undefined);

    navigator.mediaDevices
      ?.getUserMedia({ video: { deviceId: deviceOption?.value } })
      .then((stream) => {
        setCurrentStream(stream);
        setDeviceId(deviceOption?.value || '');
      })
      .catch((reason) => toast.error(translations('camera_devices_error', { '{{error-details}}': reason.toString() })));
  };

  const handleFetchVideoDevices = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(async (mediaDevices) => {
        const videoDevices = mediaDevices.filter((device) => device.kind === 'videoinput' && device.deviceId);
        setDevices(videoDevices);
        if (!deviceId && videoDevices.length) {
          handleSelectDevice({
            value: videoDevices[videoDevices.length - 1].deviceId,
            label: videoDevices[videoDevices.length - 1].label,
          });
        }
      })
      .catch((error) => toast.error(translations('camera_devices_error', { '{{error-details}}': error.toString() })));
  };

  const getMediaDevices = () => {
    navigator.mediaDevices
      ?.getUserMedia({ video: { deviceId } })
      .then((stream) => {
        setCurrentStream(stream);
        handleFetchVideoDevices();
      })
      .catch(() => toast.error(translations('camera_devices_permission_error')));
  };

  // FYI: workaround for Relevos -> Assign page in case of removing selected user
  useEffect(() => {
    if (!applicationUser && mode === HandleDevicesMode.ASSIGN) {
      getMediaDevices();
    }
    // eslint-disable-next-line
  }, [applicationUser]);

  useEffect(() => {
    if (mode !== HandleDevicesMode.ASSIGN) {
      getMediaDevices();
    }
    // eslint-disable-next-line
  }, []);

  const handleSwitchCamera = () => {
    setFacingMode(facingMode === 'user' ? 'environment' : 'user');
  };

  return {
    devices,
    deviceId,
    facingMode,
    devicesSelectOptions,
    handleSwitchCamera,
    handleSelectDevice,
    currentStream,
  };
};
