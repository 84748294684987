import React, { JSX, ReactNode } from 'react';

type StepElementProps = {
  index: number;
  children?: ReactNode;
};

const Step = ({ children }: StepElementProps): JSX.Element => {
  return <>{children}</>;
};

export default Step;
