import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import colors from 'styles/colors';

const helpers = createMultiStyleConfigHelpers(['container', 'button']);

const Accordion = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderColor: `${colors.grey[50]}`,
    },
    button: {
      _hover: {
        background: 'none',
      },
    },
  },
  variants: {
    userProfileRestaurants: {
      panel: {
        fontSize: '14px',
        padding: 0,
      },
      button: {
        padding: 0,
        fontSize: '14px',
      },
      container: {
        border: 'none',
        fontSize: '14px',
      },
    },
    itemsSummary: {
      button: {
        padding: 0,
      },
      container: {
        border: 'none',
        fontSize: '14px',
      },
    },
  },
});

export default Accordion;
