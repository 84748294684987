import { useEffect } from 'react';
import { Box, Center, Heading, Image, Spinner, VStack } from '@chakra-ui/react';

import { useTranslations } from '../../contexts/LocalizationContext';
import { useRegisterCustomerSurveyCompletion } from '../../services/restaurant-users-surveys';

const getImagePath = (imageName: string) => require(`../../assets/images/${imageName}`);

const SurveySubmitted = () => {
  const translations = useTranslations();
  const { mutate: registerCustomerSurveyCompletion, isLoading: isRegisterCustomerSurveyLoading } =
    useRegisterCustomerSurveyCompletion();

  useEffect(() => {
    registerCustomerSurveyCompletion({});
  }, [registerCustomerSurveyCompletion]);

  return (
    <Center>
      {isRegisterCustomerSurveyLoading ? (
        <Spinner />
      ) : (
        <VStack pt={8}>
          <Heading as="h2" fontSize={20}>
            {translations('restaurant_user_survey_confirmation_message')}
          </Heading>
          <Box boxSize="sm">
            <Image src={getImagePath('ruckgabe.png')} alt="ruckgabe.png" />
          </Box>
        </VStack>
      )}
    </Center>
  );
};

export default SurveySubmitted;
