import { useInventoryCountsContext } from '../../context/hooks/use-inventory-counts-context.hook';
import { InventoryCountsFirstStep } from './InventoryCountsFirstStep/InventoryCountsFirstStep';
import { InventoryCountFourthStep } from './InventoryCountsFourthStep/InventoryCountFourthStep';
import { InventoryCountsSecondStep } from './InventoryCountsSecondStep/InventoryCountsSecondStep';
import { InventoryCountsThirdStep } from './InventoryCountsThirdStep/InventoryCountsThirdStep';

export const InventoryCountsStep = () => {
  const { activeStep } = useInventoryCountsContext();

  switch (activeStep) {
    case 0:
      return <InventoryCountsFirstStep />;
    case 1:
      return <InventoryCountsSecondStep />;
    case 2:
      return <InventoryCountsThirdStep />;
    case 3:
      return <InventoryCountFourthStep />;
    default:
      return <></>;
  }
};
