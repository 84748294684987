import React, { JSX } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from '@chakra-ui/react';

type ItemsSummaryAccordionProps = {
  buttonText: string | JSX.Element | JSX.Element[];
  children: React.ReactNode;
  isOpen?: boolean;
};

export const ItemsSummaryAccordion = ({ buttonText, children, isOpen }: ItemsSummaryAccordionProps) => {
  const defaultIndex = isOpen ? 0 : undefined;

  return (
    <Accordion allowToggle variant="itemsSummary" defaultIndex={defaultIndex}>
      <AccordionItem>
        <AccordionButton>
          <Text>{buttonText}</Text>
          <AccordionIcon ml={2} />
        </AccordionButton>
        <AccordionPanel>
          <Box py={2}>{children}</Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
