import { apiPaths } from '../config/apiPaths';
import { NotificationSettingsResponse } from '../modules/Profile/components/NotificationSettings/model/notification-settings-response';
import { UpdateNotificationSettingsRequest } from '../modules/Profile/components/NotificationSettings/model/update-notification-settings-request';
import axiosInstance from './axios';
import axios from './axios';

export const getNotificationSettings = (): Promise<NotificationSettingsResponse> =>
  axiosInstance
    .get<NotificationSettingsResponse>(apiPaths.RESTAURANT_USER_NOTIFICATION_SETTINGS_PATH)
    .then(({ data }) => data);

export const updateNotificationSettings = (
  updateRequest: UpdateNotificationSettingsRequest,
): Promise<NotificationSettingsResponse> =>
  axios.patch(apiPaths.RESTAURANT_USER_NOTIFICATION_SETTINGS_PATH, updateRequest);
