import React from 'react';
import { Box, Button } from '@chakra-ui/react';

import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../../contexts/ScannedRelevosContext';
import { ScannerMode } from '../../../../RelevoAssignment/model/enums/scanner-mode.enum';
import { useDeposit } from '../../hooks/use-deposit.hook';
import { DepositConfirmModal } from '../DepositConfirmModal/DepositConfirmModal';
import { DepositResultModal } from '../DepositResultModal/DepositResultModal';

export const DepositConfirm = () => {
  const translations = useTranslations();
  const { scannedRelevos, restaurantId, mode } = useScannedRelevosContext();
  const depositConfirmButtonText =
    mode === ScannerMode.DEPOSIT_ASSIGN ? translations('scan_assign_items') : translations('scan_return_items');

  const { confirm, confirmDepositModal, depositResultModal, depositState, isLoadingConfirm } = useDeposit();

  const handleConfirm = () => {
    if (restaurantId) {
      confirm();
    }
  };

  return (
    <Box>
      <Button
        variant="orangeSolid"
        disabled={scannedRelevos.length < 1 || !restaurantId}
        title={depositConfirmButtonText}
        onClick={confirmDepositModal.onOpen}
        minWidth={144}
        margin="auto"
      >
        {depositConfirmButtonText}
      </Button>
      <DepositConfirmModal
        isOpen={confirmDepositModal.isOpen}
        onClose={confirmDepositModal.onClose}
        onConfirm={handleConfirm}
        isLoadingConfirm={isLoadingConfirm}
      />
      <DepositResultModal
        isOpen={depositResultModal.isOpen}
        onClose={depositResultModal.onClose}
        deposit={depositState.deposit}
        successItems={depositState.successItems}
        failureItems={depositState.failureItems}
      />
    </Box>
  );
};
