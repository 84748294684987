import React from 'react';
import { Box, Center, Image, Text, useDisclosure, VStack } from '@chakra-ui/react';

import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { DocumentsTileResponse } from './model/DocumentsTileResponse';
import GoogleDriveFolderModal from './GoogleDriveFolderModal';

type DocumentsTileProps = {
  tile: DocumentsTileResponse;
};
const DocumentsTile = ({ tile }: DocumentsTileProps) => {
  const { language } = useLocalizationContext();
  const {
    isOpen: isGoogleDriveFolderModalOpen,
    onOpen: handleOpenGoogleDriveFolderModal,
    onClose: handleCloseGoogleDriveFolderModal,
  } = useDisclosure();

  return (
    <>
      <Box p={4} width={['50%', '50%', '33%', '25%', '25%']}>
        <VStack p={2} h="140px" boxShadow="md" rounded="md" cursor="pointer" onClick={handleOpenGoogleDriveFolderModal}>
          <Center h="60%">
            <Image src={tile.imageUrl || ''} boxSize="80px" alt="Tile icon" />
          </Center>
          <Text>{tile.translations[language]}</Text>
        </VStack>
      </Box>
      <GoogleDriveFolderModal
        isOpen={isGoogleDriveFolderModalOpen}
        onClose={handleCloseGoogleDriveFolderModal}
        tile={tile}
      />
    </>
  );
};

export default DocumentsTile;
