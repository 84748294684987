import React, { JSX } from 'react';
import { SingleValue } from 'react-select';
import { Box } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import DropDownSelect from '../DropDownSelect/DropDownSelect';

type CameraDeviceSelectProps = {
  devicesSelectOptions: { value: string; label: string }[];
  handleSelectDevice: (deviceOption: SingleValue<{ value: string; label: string }>) => void;
  selectedOption?: SingleValue<{ value: string; label: string }>;
};

const SwitchCamera = ({
  devicesSelectOptions,
  selectedOption,
  handleSelectDevice,
}: CameraDeviceSelectProps): JSX.Element | null => {
  const translations = useTranslations();

  if (devicesSelectOptions.length > 1) {
    return (
      <Box width="200px">
        <DropDownSelect
          options={devicesSelectOptions}
          selectedOption={selectedOption}
          handleChange={handleSelectDevice}
          isSearchable={false}
          placeholder={translations('camera_select_camera_placeholder')}
        />
      </Box>
    );
  } else {
    return null;
  }
};

export default SwitchCamera;
