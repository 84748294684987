import { Box, Flex, VStack } from '@chakra-ui/react';

import { MissingItems } from './components/MissingItems/MissingItems';
import { Review } from './components/Review/Review';

export const InventoryCountsThirdStep = () => {
  return (
    <VStack mt={[0, 0, 0, 20]} w={'100%'}>
      <Flex w={'100%'} pt={[0, 0, 0, 6]} wrap={'wrap'}>
        <Box w={['100%', '100%', '50%', '50%']} pr={[0, 0, 4, 4]}>
          <Review />
        </Box>
        <Box w={['100%', '100%', '50%', '50%']} pl={[0, 0, 4, 4]}>
          <MissingItems />
        </Box>
      </Flex>
    </VStack>
  );
};
