import React from 'react';
import { Button, Tooltip } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useQuickContactsFilterContext } from '../../../../context/QuickContactsFilterContext/hooks/useQuickContactsFilterContext';

type ReorderButtonProps = {
  onClick: () => void;
};

export const ReorderButton = ({ onClick }: ReorderButtonProps) => {
  const translations = useTranslations();
  const { fnbGroups, quickContactsState } = useQuickContactsFilterContext();
  const isDisabled = !fnbGroups.length || !!quickContactsState.search;

  return (
    <>
      {quickContactsState.search?.length ? (
        <Tooltip bg="white" color="grey.900" hasArrow label={translations('quick_contacts_reorder_forbidden_message')}>
          <Button isDisabled={isDisabled} variant={'orangeSolid'} width={'100%'} onClick={onClick}>
            {translations('quick_contacts_reorder_contacts_button')}
          </Button>
        </Tooltip>
      ) : (
        <Button isDisabled={isDisabled} variant={'orangeSolid'} width={'100%'} onClick={onClick}>
          {translations('quick_contacts_reorder_contacts_button')}
        </Button>
      )}
    </>
  );
};
