import React, { JSX } from 'react';
import { Box, HStack } from '@chakra-ui/react';

import Datepicker from '../../../common/components/Datepicker/Datepicker';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { getFormattedDate } from '../../../utils/dateHelper';

type DateRangeProps = {
  startDate: string;
  endDate: string;
  onStartDateChange: (value: string) => void;
  onEndDateChange: (value: string) => void;
};

const DateRange = ({ startDate, endDate, onStartDateChange, onEndDateChange }: DateRangeProps): JSX.Element => {
  const translations = useTranslations();

  return (
    <>
      <HStack width="100%">
        <Box width="50%" zIndex="5">
          <Datepicker
            maxDate={new Date()}
            selected={startDate.length > 0 ? new Date(startDate) : undefined}
            placeholderText={translations('reports_filter_start_date')}
            onDateChange={(v) => {
              onStartDateChange(getFormattedDate(v));
            }}
          />
        </Box>
        <Box width="50%" zIndex="5">
          <Datepicker
            maxDate={new Date()}
            selected={endDate.length > 0 ? new Date(endDate) : undefined}
            placeholderText={translations('reports_filter_end_date')}
            onDateChange={(v) => {
              onEndDateChange(getFormattedDate(v));
            }}
          />
        </Box>
      </HStack>
    </>
  );
};

export default DateRange;
