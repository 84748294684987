import React from 'react';

import { RadioCard } from '../../../../common/components/RadioCard';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { ItemAssignmentSelectMode } from '../UserSelect/types';

type SelectAssignmentModeProps = {
  bgColor?: string;
};

export const SelectAssignmentMode = ({ bgColor }: SelectAssignmentModeProps) => {
  const { selectItemMode, setSelectItemMode } = useScannedRelevosContext();

  return (
    <RadioCard
      options={Object.values(ItemAssignmentSelectMode)}
      name="AssignMode"
      defaultValue={selectItemMode}
      onChange={setSelectItemMode}
      bgColor={bgColor}
    />
  );
};
