import React, { JSX } from 'react';
import { Flex, HTMLChakraProps, Table as ChakraTable, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { VisibilityTableState } from '@tanstack/table-core/src/features/ColumnVisibility';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { FullPageLoader } from '../Loader/FullPageLoader';
import { Pagination } from '../Pagination/Pagination';
import { TableType } from './model/enums/table-type.enum';
import { RelevoTableContainer } from './TableHelper';

type BaseProps<T> = {
  data: T[];
  columns: ColumnDef<T>[];
  isLoading?: boolean;
  variant?: string;
  tableState?: VisibilityTableState;
  withBorder?: boolean;
} & HTMLChakraProps<'div'>;

type SimpleTableProps<T> = {
  type: TableType.SIMPLE;
} & BaseProps<T>;

type PaginatedTableProps<T> = {
  type: TableType.PAGINATED;
  page: number;
  onPageChanged: (nextPage: number) => void;
  totalPages: number;
} & BaseProps<T>;

type TableProps<T> = SimpleTableProps<T> | PaginatedTableProps<T>;

export const Table = <T,>(props: TableProps<T>): JSX.Element => {
  const table = useReactTable<T>({
    data: props.data,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
    state: props.tableState,
  });
  const translations = useTranslations();

  return (
    <FullPageLoader show={!!props.isLoading}>
      <RelevoTableContainer
        border={props.withBorder ? '1px solid' : undefined}
        maxH={props.maxH}
        overflowY={props.overflowY}
      >
        <ChakraTable variant={props.variant} overflowX="auto">
          <Thead position="sticky" top={0}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={`headerGroup_${headerGroup.id}`}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.length ? (
              <>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <Tr key={`row_${row.id}`}>
                      {row.getVisibleCells().map((cell) => (
                        <Td key={cell.id} style={{ width: cell.column.getSize() }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </>
            ) : (
              <Tr>
                <Td colSpan={props.columns.length} p={5}>
                  <Text textAlign="center">{translations('no_data')}</Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </ChakraTable>
        {props.type === TableType.PAGINATED && props.totalPages > 1 ? (
          <Flex w="100%" justifyContent="center">
            <Pagination page={props.page} totalPages={props.totalPages} onPageChanged={props.onPageChanged} />
          </Flex>
        ) : null}
      </RelevoTableContainer>
    </FullPageLoader>
  );
};
