import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useNotificationSettingsContext } from '../../context/hooks/use-notification-settings-context.hook';
import { NotificationSettingsEnabled } from '../NotificationSettingsTable/model/enums/notification-settings-enabled.enum';

export const NotificationEnabled = () => {
  const translations = useTranslations();
  const { isEditMode } = useNotificationSettingsContext();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="enabled"
      render={({ field: { value, onChange } }) => (
        <RadioGroup onChange={onChange} value={value} isDisabled={!isEditMode}>
          <Stack direction="row">
            <Radio value={NotificationSettingsEnabled.ON}>{translations('notification_settings_enabled_on')}</Radio>
            <Radio value={NotificationSettingsEnabled.OFF}>{translations('notification_settings_enabled_off')}</Radio>
          </Stack>
        </RadioGroup>
      )}
    />
  );
};
