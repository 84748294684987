import React, { JSX } from 'react';
import { Box, Flex, Heading, HStack, Spacer, VStack } from '@chakra-ui/react';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Decimation,
  Filler,
  Legend,
  LinearScale,
  LineController,
  RadialLinearScale,
  SubTitle,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslations } from 'contexts/LocalizationContext';

import { LocationProperties } from '../model/LocationProperties';
import { ReportsStatisticsResponse } from '../model/ReportsStatisticsResponse';
import { getFormattedTimeRange } from '../utils/reportsHelper';
import AllLocationsView from './AllLocations/AllLocationsView';
import BorrowingsPerCategoryBox from './BorrowingsPerCategory/BorrowingsPerCategoryBox';
import ReturnRateBox from './ReturnRate/ReturnRateBox';
import ReturnTimeBox from './ReturnTime/ReturnTimeBox';
import TotalUsersBox from './TotalUsers/TotalUsersBox';
import { CHART_COLORS_HEX } from './constants';

Chart.register(
  BarElement,
  BarController,
  LineController,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartDataLabels,
);

type GraphViewProps = {
  firstSetData: ReportsStatisticsResponse[];
  secondSetData: ReportsStatisticsResponse[];
  locationsOption: LocationProperties;
};
const GraphView = ({ firstSetData, secondSetData, locationsOption }: GraphViewProps): JSX.Element => {
  const translations = useTranslations();
  const graphData = firstSetData.concat(secondSetData);
  const legendLabels = graphData.map(
    (dataset) => `${dataset.locationName}: ${getFormattedTimeRange(dataset.startDate, dataset.endDate)}`,
  );
  return locationsOption === LocationProperties.ALL_LOCATIONS ? (
    <AllLocationsView data={graphData} />
  ) : graphData.length === 2 ? (
    <Box>
      <VStack mt={4} ml={4} alignItems="flex-start">
        {legendLabels.map((label, index) => (
          <HStack key={label + Math.random().toString()}>
            <Box backgroundColor={CHART_COLORS_HEX[index]} padding={2} />
            <Heading as="h3" fontSize={14}>
              {label}
            </Heading>
          </HStack>
        ))}
      </VStack>
      <h4 style={{ fontSize: '16px' }}>{translations('reports_explanation_info')}</h4>
      <Flex wrap="wrap" px={4}>
        <BorrowingsPerCategoryBox data={graphData} />
        <Spacer />
        <TotalUsersBox data={graphData} />
        <Spacer />
        <ReturnTimeBox data={graphData} />
        <Spacer />
        <ReturnRateBox data={graphData} />
      </Flex>
    </Box>
  ) : (
    <Box textAlign="left" pt={2} px={4}>
      <h4 style={{ fontSize: '16px' }}>{translations('reports_explanation_info')}</h4>
    </Box>
  );
};

export default GraphView;
