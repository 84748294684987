import React, { AriaAttributes, JSX } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { ArrowUpIcon } from '@chakra-ui/icons';
import { Button, Icon, InputGroup } from '@chakra-ui/react';

import colors from '../../../styles/colors';

const buttonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: 'none' },
  _hover: { backgroundColor: 'none' },
  _active: { backgroundColor: 'none' },
  margin: 'auto',
  fontWeight: 'normal',
  color: colors.white,
};

type FileInputProps = {
  uploadButtonLabel: string;
  register: UseFormRegisterReturn;
  accept?: string;
  children?: React.ReactNode;
  backgroundColor?: string;
  multiple?: boolean;
} & AriaAttributes;

export const FileInput = ({
  uploadButtonLabel,
  register,
  accept,
  children,
  backgroundColor,
  multiple = false,
  ...props
}: FileInputProps): JSX.Element => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register as { ref: (instance: HTMLInputElement | null) => void };

  return (
    <InputGroup onClick={() => inputRef.current?.click()}>
      <input
        type="file"
        hidden
        multiple={multiple}
        accept={accept}
        {...rest}
        {...props}
        ref={(e) => {
          ref(e);
          inputRef.current = e;
        }}
      />
      <Button
        {...buttonDefaultProps}
        backgroundColor={backgroundColor ?? colors.grey[50]}
        leftIcon={<Icon as={ArrowUpIcon} />}
      >
        {uploadButtonLabel}
      </Button>
    </InputGroup>
  );
};
