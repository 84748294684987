import React, { ComponentProps } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Textarea } from '@chakra-ui/react';

import colors from '../../../styles/colors';

type FormTextareaProps = {
  id: string;
  useFormRegisterReturn: UseFormRegisterReturn;
  isInvalid?: boolean;
} & ComponentProps<typeof Textarea>;

const inputDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: 'none' },
  _hover: { backgroundColor: 'none' },
  _active: { backgroundColor: 'none' },
  borderRadius: 0,
  border: 'none',
  backgroundColor: colors.beige[50],
};

const FormTextarea = ({ id, useFormRegisterReturn, isInvalid, ...inputAttributes }: FormTextareaProps) => {
  return (
    <Textarea
      {...inputDefaultProps}
      my="auto"
      id={id}
      {...useFormRegisterReturn}
      isInvalid={isInvalid}
      {...inputAttributes}
    />
  );
};

export default FormTextarea;
