import React from 'react';
import { Heading, VStack } from '@chakra-ui/react';

import { useTranslations } from '../../contexts/LocalizationContext';
import { CommunityStatisticsReponse } from './model/CommunityStatisticsReponse';
import AccountStatistics from './AccountStatistics';
import RelevoCommunity from './RelevoCommunity';

type CommunityStatisticsProps = {
  communityStatistics: CommunityStatisticsReponse | undefined;
  restaurantIds: string[];
};

const CommunityStatistics = ({ communityStatistics, restaurantIds }: CommunityStatisticsProps) => {
  const translations = useTranslations();

  return (
    <VStack alignItems="start" spacing="auto" ml={2}>
      <VStack alignItems="start" spacing="auto" width="100%">
        <Heading as="h2" fontWeight="normal" fontSize="16px" marginTop="24px" marginBottom="6px">
          {translations('relevo_community_label')}
        </Heading>
        <RelevoCommunity statistics={communityStatistics} />
      </VStack>
      <VStack alignItems="start" spacing="auto" width="100%">
        <Heading as="h2" fontWeight="normal" fontSize="16px" marginTop="24px" marginBottom="6px">
          {translations('your_statisticts_label')}
        </Heading>
        <AccountStatistics restaurantIds={restaurantIds} statistics={communityStatistics} />
      </VStack>
    </VStack>
  );
};

export default CommunityStatistics;
