import { generatePath } from 'react-router-dom';

import { apiPaths } from '../config/apiPaths';
import { QuickContactsCreateRequest } from '../modules/QuickContacts/model/quick-contact-create-request';
import {
  QuickContactFnbGroupResponse,
  QuickContactFnbGroupsResponse,
} from '../modules/QuickContacts/model/quick-contact-fnb-group-response';
import { QuickContactRequest } from '../modules/QuickContacts/model/quick-contact-request';
import { QuickContactResponse, QuickContactsResponse } from '../modules/QuickContacts/model/quick-contact-response';
import {
  QuickContactRestaurantResponse,
  QuickContactRestaurantsResponse,
} from '../modules/QuickContacts/model/quick-contact-restaurant-response';
import { QuickContactsCreateResponse } from '../modules/QuickContacts/model/quick-contacts-create-response';
import { QuickContactsFeatureEnabledResponse } from '../modules/QuickContacts/model/quick-contacts-feature-enabled-response';
import { QuickContactsReorderRequest } from '../modules/QuickContacts/model/quick-contacts-reorder-request';
import axiosInstance from './axios';

export const getQuickContacts = (fnbGroupId: string): Promise<QuickContactResponse[]> => {
  return axiosInstance
    .get<QuickContactsResponse>(generatePath(apiPaths.QUICK_CONTACTS_FNB_GROUPS_FNB_GROUP_PATH, { fnbGroupId }))
    .then(({ data }) => data.quickContacts);
};

export const getFnbGroups = (): Promise<QuickContactFnbGroupResponse[]> => {
  return axiosInstance
    .get<QuickContactFnbGroupsResponse>(apiPaths.QUICK_CONTACTS_FNB_GROUPS_PATH)
    .then(({ data }) => data.availableGroups);
};

export const getRestaurants = (fnbGroupId: string): Promise<QuickContactRestaurantResponse[]> => {
  return axiosInstance
    .get<QuickContactRestaurantsResponse>(
      generatePath(apiPaths.QUICK_CONTACTS_FNB_GROUPS_FNB_GROUP_RESTAURANTS_PATH, { fnbGroupId }),
    )
    .then(({ data }) => data.availableRestaurants);
};

export const reorderQuickContacts = (
  fnbGroupId: string,
  reorderRequest: QuickContactsReorderRequest,
): Promise<void> => {
  return axiosInstance.patch(
    generatePath(apiPaths.QUICK_CONTACTS_FNB_GROUPS_FNB_GROUP_PATH, { fnbGroupId }),
    reorderRequest,
  );
};

export const createQuickContacts = (
  fnbGroupId: string,
  createRequest: QuickContactsCreateRequest,
): Promise<QuickContactsCreateResponse> => {
  return axiosInstance
    .post(generatePath(apiPaths.QUICK_CONTACTS_FNB_GROUPS_FNB_GROUP_PATH, { fnbGroupId }), createRequest)
    .then(({ data }) => data);
};

export const deleteQuickContact = (fnbGroupId: string, deleteRequest: QuickContactRequest): Promise<void> => {
  return axiosInstance.delete(generatePath(apiPaths.QUICK_CONTACTS_FNB_GROUPS_FNB_GROUP_PATH, { fnbGroupId }), {
    data: deleteRequest,
  });
};

export const getRestaurantQuickContacts = (restaurantId: string): Promise<QuickContactResponse[]> => {
  return axiosInstance
    .get<QuickContactsResponse>(generatePath(apiPaths.QUICK_CONTACTS_RESTAURANTS_PATH, { restaurantId }))
    .then(({ data }) => data.quickContacts);
};

export const getFeatureEnabled = (): Promise<boolean> => {
  return axiosInstance
    .get<QuickContactsFeatureEnabledResponse>(apiPaths.QUICK_CONTACTS_FEATURE_ENABLED_PATH)
    .then(({ data }) => data.featureEnabled);
};
