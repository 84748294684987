import React, { JSX, useEffect, useState } from 'react';
import { Spinner } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import './Home.css';
import 'react-multi-carousel/lib/styles.css';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { Language } from '../../contexts/LocalizationContext/types';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useCommunityStatistics } from '../../services/community-statistics';
import { useNews } from '../../services/news';
import { useRestaurantUserSurvey } from '../../services/restaurant-users-surveys';
import { useRestaurantsData } from '../../services/restaurants';
import CommunityStatistics from './CommunityStatistics';
import CustomerSurvey from './CustomerSurvey';
import News from './News';

export const CK_COMMUNITY_STATISTICS = 'community';

const Home = (): JSX.Element => {
  const translations = useTranslations();
  const { language, isFetchingRestaurantUser } = useLocalizationContext();
  const { restaurantIds } = useRestaurantsData();
  const [isCustomerSurveyOpen, setIsCustomerSurveyOpen] = useState(false);
  const { data: surveyUrlData } = useRestaurantUserSurvey(language === Language.DE && !isFetchingRestaurantUser); // FYI: For now, the survey is shown only to German users
  const { data: communityStatistics, isLoading: isLoadingCommunityStatistics } = useCommunityStatistics(restaurantIds);
  const { data: news, isLoading: isLoadingNews } = useNews();

  const handleCustomerSurveyClose = () => {
    setIsCustomerSurveyOpen(false);
  };

  useEffect(() => {
    if (surveyUrlData?.surveyUrl) {
      setIsCustomerSurveyOpen(true);
    }
  }, [surveyUrlData]);

  return (
    <>
      <MainPageContent>
        <MainPageHeading>{translations('home_screen_title')}</MainPageHeading>
        {isLoadingNews && isLoadingCommunityStatistics ? (
          <Spinner />
        ) : (
          <>
            {isLoadingNews ? <Spinner /> : <News news={news} />}
            {isLoadingCommunityStatistics ? (
              <Spinner />
            ) : (
              <CommunityStatistics communityStatistics={communityStatistics} restaurantIds={restaurantIds} />
            )}
          </>
        )}
        <CustomerSurvey
          isOpen={isCustomerSurveyOpen}
          surveyUrl={surveyUrlData?.surveyUrl ?? undefined}
          onClose={handleCustomerSurveyClose}
        />
      </MainPageContent>
    </>
  );
};

export default Home;
