import React, { JSX } from 'react';
import { Box, Button } from '@chakra-ui/react';

import { useTranslations } from '../../contexts/LocalizationContext';
import colors from '../../styles/colors';

const buttonDefaultProps = {
  borderTop: '2px',
  borderBottom: '2px',
  bg: colors.white,
  fontSize: '14px',
  height: '30px',
  width: '70px',
  fontWeight: '100',
  _focus: { boxShadow: 'none', backgroundColor: colors.white },
  _hover: { backgroundColor: colors.white },
  _active: { backgroundColor: colors.white },
};

export enum ViewOption {
  LIST = 'LIST',
  CARD = 'CARD',
  GRAPH = 'GRAPH',
}

type ResultsViewProps = {
  selectedViewOption: ViewOption;
  onSelectedViewOptionChange: (option: ViewOption) => void;
};

const ResultsViewSelect = ({ selectedViewOption, onSelectedViewOptionChange }: ResultsViewProps): JSX.Element => {
  const translations = useTranslations();

  return (
    <Box>
      <Button
        {...buttonDefaultProps}
        borderLeft="2px"
        borderRight={selectedViewOption === ViewOption.LIST ? '2px' : 'none'}
        borderColor={selectedViewOption === ViewOption.LIST ? colors.orange[500] : colors.beige[50]}
        color={selectedViewOption === ViewOption.LIST ? colors.black : colors.grey[200]}
        borderRadius="10px 0px 0px 10px"
        onClick={() => onSelectedViewOptionChange(ViewOption.LIST)}
      >
        {translations('list_view_label')}
      </Button>
      <Button
        {...buttonDefaultProps}
        borderLeft={selectedViewOption === ViewOption.LIST ? 'none' : '2px'}
        borderRight={selectedViewOption === ViewOption.CARD ? '2px' : 'none'}
        borderColor={selectedViewOption === ViewOption.CARD ? colors.orange[500] : colors.beige[50]}
        borderRadius="0px 0px 0px 0px"
        color={selectedViewOption === ViewOption.CARD ? colors.black : colors.grey[200]}
        onClick={() => onSelectedViewOptionChange(ViewOption.CARD)}
      >
        {translations('card_view_label')}
      </Button>
      <Button
        {...buttonDefaultProps}
        borderLeft={selectedViewOption === ViewOption.CARD ? 'none' : '2px'}
        borderRight="2px"
        borderColor={selectedViewOption === ViewOption.GRAPH ? colors.orange[500] : colors.beige[50]}
        borderRadius="0px 10px 10px 0px"
        color={selectedViewOption === ViewOption.GRAPH ? colors.black : colors.grey[200]}
        onClick={() => onSelectedViewOptionChange(ViewOption.GRAPH)}
      >
        {translations('graph_view_label')}
      </Button>
    </Box>
  );
};

export default ResultsViewSelect;
