import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { VisibilityTableState } from '@tanstack/table-core/src/features/ColumnVisibility';

import { TableType } from '../../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../../common/components/Table/Table';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { LanguagesConfig } from '../../../../../contexts/LocalizationContext/types';
import { useLocalizationContext } from '../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { DepositRelevoTableMode } from '../../../model/enums/deposit-relevo-table-mode.enum';

interface DepositRelevo {
  productName: string | null;
  category: {
    translations: LanguagesConfig;
  } | null;
  deposit?: string;
  error?: string;
  message?: string;
}

type DepositRelevoTableProps = {
  mode: DepositRelevoTableMode;
  items: DepositRelevo[];
};
export const DepositRelevoTable = ({ items, mode }: DepositRelevoTableProps) => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();

  const columns = useMemo<ColumnDef<DepositRelevo>[]>(
    () => [
      {
        id: 'category',
        header: translations('item_category'),
        cell: ({ row }) => <>{row.original.category?.translations[language]}</>,
      },
      { accessorKey: 'productName', header: translations('item_name') },
      { accessorKey: 'message', header: translations('error') },
      { accessorKey: 'deposit', header: translations('deposit') },
    ],
    [language, translations],
  );

  const tableState: VisibilityTableState = useMemo(() => {
    return {
      columnVisibility: {
        category: true,
        productName: true,
        message: mode === DepositRelevoTableMode.ERROR,
        deposit: mode === DepositRelevoTableMode.SUCCESS,
      },
    };
  }, [mode]);

  return (
    <Table type={TableType.SIMPLE} variant="relevoSummary" data={items} columns={columns} tableState={tableState} />
  );
};
