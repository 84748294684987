import React, { useMemo, useState } from 'react';
import { Box, Spinner, Text, VStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import { PageSectionHeading } from '../../../../../../../common/components/PageSectionHeading/PageSectionHeading';
import { TableType } from '../../../../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../../../../common/components/Table/Table';
import { TableItemWithImage } from '../../../../../../../common/components/TableItemWithImage';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useInventoryCountMissingItems } from '../../../../../../../services/inventory-counts';
import { useInventoryCountsContext } from '../../../../../context/hooks/use-inventory-counts-context.hook';
import { InventoryCountMissingItemResponse } from '../../../../../model/inventory-count-missing-item-response';
import { INVENTORY_COUNTS_DEFAULT_TABLE_LIMIT } from '../../../../../utils/inventory-counts.consts';

export const MissingItems = () => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [page, setPage] = useState(1);
  const { currentInventoryCountId } = useInventoryCountsContext();
  const {
    data,
    isLoading: isLoadingMissingItems,
    isFetching: isFetchingMissingItems,
  } = useInventoryCountMissingItems(currentInventoryCountId || '', page - 1, INVENTORY_COUNTS_DEFAULT_TABLE_LIMIT);

  const columns = useMemo<ColumnDef<InventoryCountMissingItemResponse>[]>(
    () => [
      {
        id: 'category',
        header: translations('inventory_counts_review_item_header'),
        cell: ({ row }) => (
          <TableItemWithImage
            text={row.original.category.translations ? row.original.category.translations[language] : ''}
            imageUrl={row.original.category.imageUrl}
          />
        ),
      },
      { accessorKey: 'productName', header: translations('inventory_counts_review_missing_items_name_header') },
    ],
    [language, translations],
  );

  return (
    <VStack alignItems={'start'} p={2}>
      <PageSectionHeading>{translations('inventory_counts_review_missing_items_header')}</PageSectionHeading>
      <Text fontSize={14} textAlign={'left'}>
        {translations('inventory_counts_review_missing_items_summary')}
      </Text>
      <Box w={'100%'}>
        {data ? (
          <Table
            type={TableType.PAGINATED}
            data={data.items}
            columns={columns}
            withBorder
            page={page}
            onPageChanged={setPage}
            isLoading={isLoadingMissingItems || isFetchingMissingItems}
            totalPages={data.totalPages}
          />
        ) : (
          <Spinner />
        )}
      </Box>
    </VStack>
  );
};
