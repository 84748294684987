import React, { JSX, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { SendRestaurantInventoryReorderResponse } from '../../../api/types';
import MessageForm from '../../../common/components/MessageForm';
import { ContactMessageInputs } from '../../../common/components/MessageForm/types';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useSendRestaurantInventoryReorder } from '../../../services/inventory';
import { InventoryMessageConfirmation } from './InventoryMessageConfirmation';

type InventoryMessageModalProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  restaurantName: string | null;
  restaurantId: string | null;
};

export const InventoryMessageModal = ({
  isModalOpen,
  onCloseModal,
  restaurantName,
  restaurantId,
}: InventoryMessageModalProps): JSX.Element => {
  const translations = useTranslations();
  const { mutate: sendInventoryReorder, isLoading } = useSendRestaurantInventoryReorder(restaurantId);
  const [showForm, setShowForm] = useState(true);

  const reorderItemsMessageSubject = `${translations('reorder_items_message_subject')}. ${translations(
    'restaurant',
  )} ${restaurantName}`;
  const reorderItemsMessageBody = `${translations('reorder_items_message_please')}\n\n`;

  const belowSubject = [
    translations('reorder_items_message_greeting'),
    translations('reorder_items_message_we_need_items'),
  ];

  const belowBody = [translations('reorder_items_message_thanks')];
  const nonMessageInformation = [translations('reorder_items_message_mugs_warning')];

  const onSubmitSuccess = (response: SendRestaurantInventoryReorderResponse) => {
    if (!response.messageSent) {
      toast.error(<b>{translations('message_could_not_send')}</b>);
      return;
    }
    setShowForm(false);
  };

  const onSubmitError = () => {
    toast.error(<b>{translations('something_went_wrong')}</b>);
  };

  const onSubmit = (values: ContactMessageInputs) =>
    sendInventoryReorder(
      { ...values },
      {
        onSuccess: onSubmitSuccess,
        onError: onSubmitError,
      },
    );

  const onCloseInventoryMessageModal = () => {
    setShowForm(true);
    onCloseModal();
  };

  return (
    <Modal isOpen={isModalOpen} onClose={onCloseInventoryMessageModal} size="4xl">
      <ModalOverlay />
      <ModalContent my={[0, 0, 12]} py={4}>
        {showForm ? <ModalHeader>{translations('inventory_reorder_relevos')}</ModalHeader> : null}
        <ModalCloseButton />
        <ModalBody>
          {showForm ? (
            <MessageForm
              defaultSubject={reorderItemsMessageSubject}
              defaultBody={reorderItemsMessageBody}
              belowSubject={belowSubject}
              belowBody={belowBody}
              nonMessageInformation={nonMessageInformation}
              onSubmit={onSubmit}
              isLoading={isLoading}
            />
          ) : (
            <InventoryMessageConfirmation onCloseModal={onCloseInventoryMessageModal} />
          )}
        </ModalBody>
      </ModalContent>
      <ModalFooter />
    </Modal>
  );
};
