import React, { JSX } from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import { ReportsStatisticsResponse } from 'modules/Reports/model/ReportsStatisticsResponse';

import GraphBox from '../GraphBox';
import BorrowingsPerCategoryChart from './BorrowingsPerCategoryChart';

const getIconPath = (iconName: string) => require(`../../../../assets/icons/${iconName}`);

const BorrowingsPerCategoryBox = ({ data }: { data: ReportsStatisticsResponse[] }): JSX.Element => {
  const translations = useTranslations();
  const dishSummaries = data.map((dataset) => dataset.dishesSummary);

  return (
    <GraphBox
      heading={translations('reports_graph_view_borrowings')}
      iconSrc={getIconPath('location_stats_borrowings.svg')}
      iconAlt="borrowings per category"
      boxProps={{ p: 4, alignSelf: 'flex-start', flexBasis: ['65%', null, null, null, '40%', '35%'] }}
    >
      <Box marginTop={6}>
        <BorrowingsPerCategoryChart dishSummaries={dishSummaries} />
      </Box>
    </GraphBox>
  );
};

export default BorrowingsPerCategoryBox;
