import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { AssignedRelevosResponse, RelevoInterface } from 'api/types';

import { QuickContactResponse } from '../../modules/QuickContacts/model/quick-contact-response';
import { ItemAssignmentSelectMode } from '../../modules/RelevoAssignment/components/UserSelect/types';
import { ScannerMode } from '../../modules/RelevoAssignment/model/enums/scanner-mode.enum';
import { useRestaurantsData } from '../../services/restaurants';
import { useLocalStorage } from '../../utils/LocalStorage.hook';
import { ASSIGN_RETURN_RESTAURANT_ID_LS_KEY } from './index';

type ScannedRelevos = RelevoInterface[];
interface ApplicationUser {
  id: string;
  userId: string;
  accountInfo?: {
    accountBalance: {
      amount: string;
      currency: string;
    };
    notReturnFeeSum: number;
  };
  paymentInformationValid?: boolean;
}

export interface ScannedRelevosContextInterface {
  applicationUser: ApplicationUser | null;
  quickContact: QuickContactResponse | null;
  restaurantId: string | null;
  scannedRelevos: ScannedRelevos;
  assignedRelevos: AssignedRelevosResponse | null;
  setAssignedRelevos: Dispatch<SetStateAction<AssignedRelevosResponse | null>>;
  setScannedRelevos: Dispatch<SetStateAction<ScannedRelevos>>;
  setApplicationUser: (user: ApplicationUser | null) => void;
  setQuickContact: (quickContact: QuickContactResponse | null) => void;
  setRestaurantId: (restaurantId: string | null) => void;
  resetRestaurantId: () => void;
  mode: ScannerMode;
  selectItemMode: ItemAssignmentSelectMode;
  setSelectItemMode: (mode: ItemAssignmentSelectMode) => void;
}

export const ScannedRelevosContext = createContext<ScannedRelevosContextInterface | null>(null);

type ScannedRelevosContextProps = {
  mode: ScannerMode;
  children: React.ReactNode;
  locationId?: string;
};

export const ScannedRelevosContextProvider = ({ mode, children, locationId }: ScannedRelevosContextProps) => {
  const { restaurantIds } = useRestaurantsData();
  const defaultRestaurantId = useMemo(() => {
    if (locationId) {
      return locationId;
    }
    if (restaurantIds.length === 1) {
      return restaurantIds[0];
    }
    return null;
  }, [locationId, restaurantIds]);
  const [applicationUser, setApplicationUser] = useState<ApplicationUser | null>(null);

  const [lsRestaurantId, setLsRestaurantId] = useLocalStorage<string | null>(
    ASSIGN_RETURN_RESTAURANT_ID_LS_KEY,
    defaultRestaurantId,
  );

  const [scannedRelevos, setScannedRelevos] = useState<ScannedRelevos>([]);
  const [assignedRelevos, setAssignedRelevos] = useState<AssignedRelevosResponse | null>(null);
  const [quickContact, setQuickContact] = useState<QuickContactResponse | null>(null);
  const [selectItemMode, setSelectItemMode] = useLocalStorage<ItemAssignmentSelectMode>(
    'selectItemMode',
    ItemAssignmentSelectMode.SELECT_CATEGORY,
  );

  const resetLsRestaurantId = () => setLsRestaurantId(defaultRestaurantId);

  return (
    <ScannedRelevosContext.Provider
      value={{
        applicationUser,
        quickContact,
        scannedRelevos,
        restaurantId: lsRestaurantId,
        setScannedRelevos,
        setApplicationUser,
        setQuickContact,
        setAssignedRelevos,
        setRestaurantId: setLsRestaurantId,
        resetRestaurantId: resetLsRestaurantId,
        assignedRelevos,
        mode,
        selectItemMode,
        setSelectItemMode,
      }}
    >
      {children}
    </ScannedRelevosContext.Provider>
  );
};
