import React, { JSX } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Text } from '@chakra-ui/react';

const buttonDefaultProps = {
  bg: 'none',
  _focus: { boxShadow: 'none', backgroundColor: 'none' },
  _hover: { backgroundColor: 'none' },
  _active: { backgroundColor: 'none' },
};

type PaginationProps = {
  page: number;
  onPageChanged: (newPage: number) => void;
  totalPages: number;
};

export const Pagination = ({ page, onPageChanged, totalPages }: PaginationProps): JSX.Element => {
  const handleFirstPage = () => onPageChanged(1);
  const handlePreviousPage = () => onPageChanged(page - 1);
  const handleNextPage = () => onPageChanged(page + 1);
  const handleLastPage = () => onPageChanged(totalPages);

  return (
    <Flex justifyContent="flex-end" alignItems="center">
      <Flex>
        <IconButton
          {...buttonDefaultProps}
          aria-label="first-page"
          onClick={handleFirstPage}
          isDisabled={page === 1}
          icon={<ArrowLeftIcon h={3} w={3} />}
          mr={4}
        />
        <IconButton
          {...buttonDefaultProps}
          aria-label="previous-page"
          onClick={handlePreviousPage}
          isDisabled={page === 1}
          icon={<ChevronLeftIcon h={6} w={6} />}
        />
      </Flex>

      <Flex alignItems="center">
        <Text fontWeight="bold" mx={4} as="span">
          {`${page} / ${totalPages}`}
        </Text>
      </Flex>

      <Flex>
        <IconButton
          {...buttonDefaultProps}
          aria-label="next-page"
          onClick={handleNextPage}
          isDisabled={page === totalPages}
          icon={<ChevronRightIcon h={6} w={6} />}
        />
        <IconButton
          {...buttonDefaultProps}
          aria-label="last-page"
          onClick={handleLastPage}
          isDisabled={page === totalPages}
          icon={<ArrowRightIcon h={3} w={3} />}
          ml={4}
        />
      </Flex>
    </Flex>
  );
};
