import React from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import { Box, Heading } from '@chakra-ui/react';

import { WordpressNewsResponse } from '../../api/types';
import { useTranslations } from '../../contexts/LocalizationContext';
import NewsCard from './NewsCard';

const responsive: ResponsiveType = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 770 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
  },
};

type NewsProps = {
  news: WordpressNewsResponse[] | undefined;
};

const News = ({ news }: NewsProps) => {
  const translations = useTranslations();

  return news ? (
    <Box>
      <Box textAlign="left" mx={2}>
        <Heading as="h2" fontWeight="normal" fontSize="16px" marginTop="24px" marginBottom="6px">
          {translations('news_label')}
        </Heading>
      </Box>
      <Box width="100%" overflow="hidden" pb="180px">
        <Box w={['98%', '98%', '98%', '98%', '79%', '83%']} position="absolute" pr={[2, 2, 0, 0, 2]}>
          <Carousel responsive={responsive} autoPlay={false} shouldResetAutoplay={false}>
            {news?.map((details: WordpressNewsResponse) => <NewsCard key={details.id} news={details} />)}
          </Carousel>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default News;
