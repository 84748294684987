import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useNotificationSettingsContext } from '../../context/hooks/use-notification-settings-context.hook';
import { NotificationSettingsFrequency } from '../../model/enums/notification-settings-frequency.enum';

export const NotificationFrequency = () => {
  const translations = useTranslations();
  const { isEditMode } = useNotificationSettingsContext();
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name="frequency"
      render={({ field: { value, onChange } }) => (
        <RadioGroup onChange={onChange} value={value} isDisabled={!isEditMode}>
          <Stack direction="row">
            <Radio value={NotificationSettingsFrequency.WEEKLY}>
              {translations('notification_settings_frequency_weekly')}
            </Radio>
            <Radio value={NotificationSettingsFrequency.MONTHLY}>
              {translations('notification_settings_frequency_monthly')}
            </Radio>
          </Stack>
        </RadioGroup>
      )}
    />
  );
};
