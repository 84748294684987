import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import {
  createRestaurantDraft,
  getRestaurantDraft,
  updateRestaurantDraft,
  updateRestaurantDraftStatus,
} from '../api/draft';
import { HttpException } from '../common/models/httpException';
import { CK_LOCATION_STATISTICS } from '../modules/Locations/Locations';
import { RestaurantDraft, RestaurantDraftStatus } from '../modules/Restaurants/types';

export const CK_RESTAURANT_DRAFT = 'restaurantDraft';

export const useRestaurantDraft = (restaurantId: string): QueryObserverResult<RestaurantDraft, AxiosError> => {
  return useQuery([CK_RESTAURANT_DRAFT, restaurantId], () => getRestaurantDraft(restaurantId), {
    retry: false,
    staleTime: Infinity,
  });
};

export const useCreateRestaurantDraft = ({
  onSuccess,
  onError,
}: {
  onSuccess: (draft: RestaurantDraft) => void;
  onError: (error: AxiosError<HttpException>) => void;
}): UseMutationResult<RestaurantDraft, AxiosError<HttpException>, { restaurantId: string; draft: RestaurantDraft }> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ restaurantId, draft }: { restaurantId: string; draft: RestaurantDraft }) =>
      createRestaurantDraft(restaurantId, draft),
    {
      onSuccess: (draft, { restaurantId }) => {
        queryClient.setQueryData([CK_RESTAURANT_DRAFT, restaurantId], draft);
        onSuccess(draft);
      },
      onError: (error) => {
        onError(error);
      },
    },
  );
};

export const useUpdateRestaurantDraft = ({
  onSuccess,
  onError,
}: {
  onSuccess: (draft: RestaurantDraft) => void;
  onError: (error: AxiosError<HttpException>) => void;
}): UseMutationResult<RestaurantDraft, AxiosError<HttpException>, { restaurantId: string; draft: RestaurantDraft }> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ draft, restaurantId }: { restaurantId: string; draft: RestaurantDraft }) =>
      updateRestaurantDraft(restaurantId, draft),
    {
      onSuccess: (draft, { restaurantId }) => {
        queryClient.setQueryData([CK_RESTAURANT_DRAFT, restaurantId], draft);
        queryClient.invalidateQueries([CK_LOCATION_STATISTICS]);
        onSuccess(draft);
      },
      onError: (error) => {
        onError(error);
      },
    },
  );
};

export const useCloseRestaurantDraft = ({
  onSuccess,
  onError,
}: {
  onSuccess: (draft: RestaurantDraft) => void;
  onError: (error: AxiosError<HttpException>) => void;
}): UseMutationResult<RestaurantDraft, AxiosError<HttpException>, { restaurantId: string; draftId: string }> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ draftId, restaurantId }: { restaurantId: string; draftId: string }) =>
      updateRestaurantDraftStatus({
        restaurantId,
        draftId,
        status: RestaurantDraftStatus.CLOSED,
      }),
    {
      onSuccess: async (draft, { restaurantId }) => {
        await queryClient.invalidateQueries([CK_RESTAURANT_DRAFT, restaurantId]);
        onSuccess(draft);
      },
      onError: (error) => {
        onError(error);
      },
    },
  );
};
