import React, { JSX, useEffect, useState } from 'react';
import { Box, Button, Flex, Grid, Spacer, useDisclosure } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { RelevoInventoryResponse } from '../../api/types';
import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { RestaurantSelect } from '../../common/components/RestaurantSelect/RestaurantSelect';
import { selectStyle } from '../../common/components/Select/select-style';
import { useRestaurantInventory } from '../../services/inventory';
import { useRestaurantsData } from '../../services/restaurants';
import { InventoryMessageModal } from './components/InventoryMessageModal';
import InventoryTable from './components/InventoryTable';

const Inventory = (): JSX.Element => {
  const translations = useTranslations();
  const { restaurants, isTwoFactorReturnEnabled } = useRestaurantsData();
  const { isOpen: isMessageModalOpen, onOpen: onOpenMessageModal, onClose: onCloseMessageModal } = useDisclosure();

  const defaultRestaurantId = restaurants.length === 1 ? restaurants[0].id : null;
  const [restaurantId, setRestaurantId] = useState<string | null>(defaultRestaurantId);
  const [restaurantName, setRestaurantName] = useState<string | null>(null);

  const { data, isLoading, isFetching, isError } = useRestaurantInventory(restaurantId);
  const [restaurantData, setRestaurantData] = useState<RelevoInventoryResponse | null>(null);
  const [fetchMetadata, setFetchMetadata] = useState<{ isLoading: boolean; isError: boolean }>({
    isLoading,
    isError,
  });

  useEffect(() => {
    if (data) {
      setRestaurantData(data);
    }
    setFetchMetadata({ isLoading: isLoading || isFetching, isError });
  }, [data, isError, isLoading, isFetching]);

  useEffect(() => {
    if (restaurants.length > 0 && !restaurantId) {
      setRestaurantId(restaurants[0].id);
      setRestaurantName(restaurants[0].restaurantName);
    }
    if (restaurants.length > 0 && restaurantId) {
      const targetRestaurant = restaurants.find((restaurant) => restaurant.id === restaurantId);
      if (targetRestaurant) {
        setRestaurantName(targetRestaurant.restaurantName);
      }
    }
  }, [restaurantId, restaurants]);

  return (
    <MainPageContent>
      <MainPageHeading>{translations('inventory_heading')}</MainPageHeading>
      <Grid px={2} gap={8} pt={3}>
        <Flex flexDirection="row">
          <Spacer display={restaurants.length === 1 ? 'none' : 'inherit'} />
          <Box w={'300px'}>
            <RestaurantSelect
              userRestaurants={restaurants}
              restaurantId={restaurantId}
              setRestaurantId={setRestaurantId}
              resetRestaurantId={() => setRestaurantId(defaultRestaurantId)}
              styles={selectStyle}
            />
          </Box>
          <Spacer />
          <Button
            colorScheme="orange"
            title={translations('inventory_reorder')}
            fontSize={['x-small', 'small', 'medium']}
            _focus={{ boxShadow: 'none', backgroundColor: 'none' }}
            _hover={{ backgroundColor: 'none' }}
            _active={{ backgroundColor: 'none' }}
            onClick={onOpenMessageModal}
            isDisabled={!restaurantName}
          >
            {translations('inventory_reorder')}
          </Button>
          <Spacer />
        </Flex>
        <InventoryTable
          isTwoFactorReturnEnabled={isTwoFactorReturnEnabled}
          restaurantData={restaurantData}
          fetchMetadata={fetchMetadata}
        />
      </Grid>
      <InventoryMessageModal
        isModalOpen={isMessageModalOpen}
        onCloseModal={onCloseMessageModal}
        restaurantName={restaurantName}
        restaurantId={restaurantId}
      />
    </MainPageContent>
  );
};

export default Inventory;
