import * as yup from 'yup';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { isValidStringUrl } from '../../../utils/urlHelper';

export const useFormValidationSchema = () => {
  const translations = useTranslations();

  return yup.object({
    restaurantName: yup
      .string()
      .required(translations('validation_required'))
      .max(100, translations('validation_too_long', { '{{maxLength}}': '100' })),
    restaurantDetail: yup
      .string()
      .required(translations('validation_required'))
      .max(80, translations('validation_too_long', { '{{maxLength}}': '80' })),
    restaurantAddress: yup
      .string()
      .required(translations('validation_required'))
      .max(200, translations('validation_too_long', { '{{maxLength}}': '200' })),
    websiteLink: yup
      .string()
      .nullable()
      .default(null)
      .max(255, translations('validation_too_long', { '{{maxLength}}': '255' }))
      .test('test url', translations('validation_incorrect_url_format'), (text) => {
        if (text?.trim().length === 0 || !text) {
          return true;
        }
        return text.trim().length > 0 && isValidStringUrl(text.trim());
      }),
    phoneNumber: yup
      .string()
      .nullable()
      .default(null)
      .max(20, translations('validation_too_long', { '{{maxLength}}': '20' })),
    metaCategories: yup
      .array()
      .required()
      .test({
        name: 'minMetaCategories',
        message: translations('validation_too_few_items', {
          '{{minItems}}': '1',
          '{{items}}': translations('validation_meta_category'),
        }),
        test: (value) => value && value.length >= 1,
      }),
    images: yup
      .array()
      .required()
      .test({
        name: 'maxImages',
        message: translations('validation_too_many_items', {
          '{{maxItems}}': '5',
          '{{items}}': translations('validation_images'),
        }),
        test: (value) => value && value.length <= 5,
      })
      .test({
        name: 'minImages',
        message: translations('validation_too_few_items', {
          '{{minItems}}': '1',
          '{{items}}': translations('validation_image'),
        }),
        test: (value) => value && value.length >= 1,
      }),
    overrideOpeningHours: yup.boolean().required(),
    restaurantOpeningHours: yup
      .array()
      .of(
        yup.object({
          day: yup.number().required(),
          periods: yup
            .array()
            .required()
            .of(
              yup.object({
                openTime: yup.string().nullable().default(null),
                closeTime: yup.string().nullable().default(null),
                day: yup.number().required(),
              }),
            ),
        }),
      )
      .nullable()
      .default(null)
      .test({
        name: 'minOpeningHours',
        message: translations('validation_too_few_items', {
          '{{minItems}}': '1',
          '{{items}}': translations('opening_hours'),
        }),
        test: (value, context) => {
          const overrideOpeningHours = context.parent.overrideOpeningHours;
          const atLeastOneDayIsOpened = value && value.some((day) => day.periods.length >= 1);
          return overrideOpeningHours ? !!atLeastOneDayIsOpened : true;
        },
      }),
  });
};
