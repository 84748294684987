import { Box, Flex, Text } from '@chakra-ui/react';

import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { DocumentResponse } from './model/DocumentResponse';
import DocumentsTile from './DocumentsTile';

type DocumentsSectionProps = {
  document: DocumentResponse;
};

const DocumentsSection = ({ document }: DocumentsSectionProps) => {
  const { language } = useLocalizationContext();

  return (
    <Box pb={4}>
      <Box my={2}>
        <Text textAlign="left" fontWeight="bold">
          {document.section.translations[language]}
        </Text>
      </Box>
      <Flex width="100%" wrap="wrap">
        {document.tiles.map((tile) => (
          <DocumentsTile tile={tile} key={tile.id} />
        ))}
      </Flex>
    </Box>
  );
};

export default DocumentsSection;
