import React, { JSX } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex } from '@chakra-ui/react';

import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { QuestionResponse } from './model/QuestionResponse';
import Answer from './Answer';
import Question from './Question';

type QuestionsAnswersProps = {
  questions: QuestionResponse[];
};

const QuestionsAnswers = ({ questions }: QuestionsAnswersProps): JSX.Element => {
  const { language } = useLocalizationContext();

  return (
    <Accordion allowToggle>
      {questions.map((question) => (
        <AccordionItem key={question.id}>
          <AccordionButton>
            <Flex width="100%" justifyContent="space-between">
              <Question question={question.questionTranslations[language]} />
              <AccordionIcon />
            </Flex>
          </AccordionButton>
          <AccordionPanel>
            <Answer answer={question.answerTranslations[language]} />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default QuestionsAnswers;
