import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import { ItemsSummaryAccordion } from '../../../../../../common/components/ItemsSummaryModal/ItemsSummaryAccordion';
import { ItemsSummaryStatusText } from '../../../../../../common/components/ItemsSummaryModal/ItemsSummaryStatusText';
import { TableType } from '../../../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../../../common/components/Table/Table';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { QuickContactElement, QuickContactFailureResponse } from '../../../../model/quick-contacts-create-response';

type AddNewContactsResultModalBodyProps = {
  failureItems: QuickContactFailureResponse[];
  successItems: QuickContactElement[];
};

export const AddNewContactsResultModalBody = ({ successItems, failureItems }: AddNewContactsResultModalBodyProps) => {
  const translations = useTranslations();
  const summaryText = translations('quick_contacts_create_result_modal_summary_text', {
    '{{successItems}}': successItems.length.toString(),
    '{{allItems}}': (successItems.length + failureItems.length).toString(),
  });

  const columns = useMemo<ColumnDef<QuickContactFailureResponse>[]>(
    () => [
      {
        id: 'type',
        header: translations('quick_contacts_type'),
        cell: ({ row }) => <>{row.original.quickContact.type}</>,
      },
      {
        id: 'name',
        header: translations('quick_contacts_client'),
        cell: ({ row }) => <>{row.original.quickContact.nickname || row.original.quickContact.restaurantId}</>,
      },
      {
        id: 'error',
        header: translations('error'),
        cell: ({ row }) => <>{row.original.message || row.original.error}</>,
      },
    ],
    [translations],
  );

  return (
    <>
      <ItemsSummaryStatusText
        summaryText={summaryText}
        successItems={successItems || []}
        failureItems={failureItems || []}
      />
      {failureItems?.length ? (
        <Box px={8} pt={4}>
          <ItemsSummaryAccordion
            buttonText={translations('quick_contacts_create_result_modal_failure_text')}
            isOpen={true}
          >
            <Table type={TableType.SIMPLE} data={failureItems} columns={columns} variant="relevoSummary" />
          </ItemsSummaryAccordion>
        </Box>
      ) : null}
    </>
  );
};
