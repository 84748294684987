import colors from '../../../../styles/colors';

export const selectCustomStyle = {
  // eslint-disable-next-line
  menu: (base: any) => ({
    ...base,
    zIndex: 1000,
  }),
  // eslint-disable-next-line
  control: (base: any) => ({
    ...base,
    border: 0,
    minHeight: '30px',
    height: '30px',
    boxShadow: 'none',
    fontStyle: 'Rubik',
    fontSize: '14px',
    textAlign: 'left',
    background: colors.beige[50],
  }),
  // eslint-disable-next-line
  dropdownIndicator: (styles: any) => ({
    ...styles,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  // eslint-disable-next-line
  clearIndicator: (styles: any) => ({
    ...styles,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  // eslint-disable-next-line
  option: (base: any, state: any) => ({
    ...base,
    minHeight: '30px',
    paddingTop: 6,
    paddingBottom: 6,
    fontStyle: 'Rubik',
    fontSize: '14px',
    textAlign: 'left',
    whiteSpace: 'normal',
    fontWeight: state.isSelected ? '600' : '100',
    background: colors.white,
    color: colors.black,
    '&:hover': {
      cursor: 'pointer',
      background: colors.beige[50],
    },
  }),
};

export const datePickerDefaultStyle = {
  backgroundColor: colors.beige[50],
  border: 'none',
  paddingTop: 0,
  paddingBottom: 0,
  fontStyle: 'Rubik',
  fontSize: '14px',
  width: '100%',
  height: '28px',
  minHeight: '28px',
  borderRadius: 0,
  paddingLeft: '16px',
  paddingRight: '16px',
};
