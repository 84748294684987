import React, { JSX } from 'react';
import { Box, Spinner } from '@chakra-ui/react';

import colors from '../../../styles/colors';

type LoaderProps = {
  show: boolean;
  children: React.ReactNode;
};

export const FullPageLoader = ({ show, children }: LoaderProps): JSX.Element => {
  return (
    <Box w="100%" position="relative">
      {show && (
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          width="100%"
          height="100%"
          zIndex={9}
          opacity={0.3}
          color={colors.grey[50]}
          bg={colors.grey[500]}
        >
          <Spinner role="status" size="xl" m={10} />
        </Box>
      )}
      {children}
    </Box>
  );
};
