import React from 'react';
import { Heading } from '@chakra-ui/react';

import colors from '../../../../styles/colors';

type HeadingProps = {
  heading: string;
  color?: string;
};

const CustomerCreationFormHeading = ({ color, heading }: HeadingProps) => {
  return (
    <Heading fontFamily="Rubik" fontSize="28px" fontWeight="bold" color={color ?? colors.black}>
      {heading}
    </Heading>
  );
};

export default CustomerCreationFormHeading;
