import React from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useRestaurantsData } from '../../services/restaurants';
import { BorrowDepositTile } from './components/BorrowDepositTile/BorrowDepositTile';
import { ReturnDepositTile } from './components/ReturnDepositTile/ReturnDepositTile';

export const Deposit = () => {
  const translations = useTranslations();
  const { restaurants, isDepositEnabled } = useRestaurantsData();

  if (restaurants.length && !isDepositEnabled) {
    return <Navigate to={Routes.HOME_PATH} />;
  }

  return (
    <MainPageContent>
      <MainPageHeading>{translations('deposit_heading')}</MainPageHeading>
      <Box bg="beige.50" mt={2}>
        <Flex width="100%" wrap="wrap" justifyContent="space-evenly" py={6} px={4}>
          <BorrowDepositTile />
          <ReturnDepositTile />
        </Flex>
      </Box>
    </MainPageContent>
  );
};
