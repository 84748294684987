import { useQuery } from 'react-query';

import { META_CATEGORIES_QUERY_KEY } from '../api/apiConsts';
import { getMetaCategories } from '../api/meta-categories';

export const useMetaCategories = () => {
  return useQuery([META_CATEGORIES_QUERY_KEY], () => getMetaCategories(), {
    staleTime: Infinity,
  });
};
