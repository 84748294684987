import { FullPageLoader } from '../../../../common/components/Loader/FullPageLoader';
import { useNotificationSettings } from '../../../../services/notification-settings';
import { NotificationSettingsForm } from './components/NotificationSettingsForm/NotificationSettingsForm';
import { NotificationSettingsContextProvider } from './context/NotificationSettingsContext';

export const NotificationSettings = () => {
  const { data: notificationSettings, isLoading: isLoadingNotificationSettings } = useNotificationSettings();

  return (
    <>
      {notificationSettings ? (
        <FullPageLoader show={isLoadingNotificationSettings}>
          <NotificationSettingsContextProvider notificationSettings={notificationSettings}>
            <NotificationSettingsForm />
          </NotificationSettingsContextProvider>
        </FullPageLoader>
      ) : null}
    </>
  );
};
