import React, { JSX, useRef, useState } from 'react';
import { Button, Center, Stack, Text, useTheme } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import { signOut } from 'supertokens-auth-react/recipe/emailpassword';

import Dialog from '../../../../common/components/ConfirmationDialog/Dialog';
import { ASSIGN_RETURN_RESTAURANT_ID_LS_KEY } from '../../../../contexts/ScannedRelevosContext';

export const SignOut = (): JSX.Element => {
  const translations = useTranslations();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const cancelRef = useRef(null);
  const { colors } = useTheme();

  const handleSignOutButtonClick = () => {
    setErrorMessage(null);
    setIsOpen(true);
  };

  const handleSignOut = async () => {
    localStorage.removeItem(ASSIGN_RETURN_RESTAURANT_ID_LS_KEY);
    await signOut();
    window.location.href = '/';
  };

  return (
    <Center>
      <Button variant="greySolid" onClick={handleSignOutButtonClick}>
        {translations('sign_out_menu_button')}
      </Button>
      <Dialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleClose}
        onConfirm={handleSignOut}
        cancelText={translations('cancel')}
        confirmText={translations('sign_out')}
      >
        {errorMessage ? (
          <Stack spacing={1} fontWeight="bold" color={colors.red[500]}>
            <Text>{translations(errorMessage)}</Text>
            <Text>{translations('please_try_again')}</Text>
          </Stack>
        ) : (
          <Text>{translations('sign_out_are_you_sure')}</Text>
        )}
      </Dialog>
    </Center>
  );
};
