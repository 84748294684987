import React, { JSX } from 'react';
import { Badge, Box, Flex, useTheme } from '@chakra-ui/react';

import { SIDEBAR_WIDTH } from '../AppLayout/AppLayout';
import { Menu } from './Menu/Menu';

export const Sidebar = (): JSX.Element => {
  const { colors } = useTheme();
  return (
    <>
      <Box
        bgColor={colors.beige[50]}
        p={2}
        height="100%"
        width={SIDEBAR_WIDTH}
        position="fixed"
        overflowY="auto"
        zIndex={9}
      >
        <Badge ml="auto" mt={2} fontSize="0.8em" colorScheme="grey" variant="outline" data-testid="environment-badge">
          {process.env.REACT_APP_ENV}
        </Badge>
        <Flex justifyContent="center" pt={8}>
          <Menu />
        </Flex>
      </Box>
    </>
  );
};
