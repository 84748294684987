import React, { JSX } from 'react';
import { Box, Container, Text, VStack } from '@chakra-ui/react';

import { MainPageContent } from '../../../../common/components/MainPageContent';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import CustomerCreationFormHeading from '../../components/CustomerCreationFormHeading/CustomerCreationFormHeading';

const CustomerCreationHomePage = (): JSX.Element => {
  const translations = useTranslations();
  return (
    <MainPageContent>
      <Container maxW={['80%', '60%', '52%']} pt={2} pb={12}>
        <Box pb={12}>
          <CustomerCreationFormHeading heading={translations('new_customer_home_page_header')} />
        </Box>
        <VStack spacing="20px">
          <Text>{translations('new_customer_home_page_information_1')}</Text>
          <Text>{translations('new_customer_home_page_information_2')}</Text>
          <Text>{translations('new_customer_home_page_information_3')}</Text>
        </VStack>
      </Container>
    </MainPageContent>
  );
};

export default CustomerCreationHomePage;
