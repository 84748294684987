export interface SignInFormInputs {
  email: string;
  password: string;
}

export interface SendResetPasswordEmailInputs {
  email: string;
}

export enum SendResetPasswordEmailStep {
  FormStep,
  ConfirmationStep,
  SuccessStep,
}

export interface SendResetPasswordEmailStepProps {
  closeModal: () => void;
  currentStep: SendResetPasswordEmailStep;
}
