import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { reportDamagedItem } from '../api/damaged-items';
import { ReportDamagedItemRequest } from '../modules/DamagedItemsReporting/model/ReportDamagedItemRequest';

const CK_DAMAGED_ITEMS = 'damaged-items';

export const useReportDamagedItem = (): UseMutationResult<void, AxiosError, { values: ReportDamagedItemRequest }> => {
  const queryClient = useQueryClient();

  return useMutation(({ values }) => reportDamagedItem(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_DAMAGED_ITEMS);
    },
  });
};
