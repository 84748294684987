import React, { JSX } from 'react';
import { Input } from '@chakra-ui/react';

import colors from '../../../styles/colors';
import useSearchWithUrl from './useSearchWithUrl.hook';

const inputDefaultProps = {
  _placeholder: { color: colors.grey[50] },
  _focus: { boxShadow: 'none', backgroundColor: 'none' },
  _hover: { backgroundColor: 'none' },
  _active: { backgroundColor: 'none' },
  height: '28px',
  fontSize: '14px',
  borderRadius: 0,
  border: 'none',
  backgroundColor: colors.white,
};

type SearchboxProps = {
  value: string;
  onChange: (text: string) => void;
  placeholder?: string;
};

export const Searchbox = ({ placeholder, value, onChange }: SearchboxProps): JSX.Element => {
  const { updateUrlOnSearch } = useSearchWithUrl(onChange);

  return (
    <Input
      {...inputDefaultProps}
      role="searchbox"
      placeholder={placeholder}
      w={400}
      value={value}
      onChange={(e) => {
        updateUrlOnSearch(e.target.value);
      }}
    />
  );
};
