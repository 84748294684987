export const getRequiredValidationObject = (message: string) => {
  return {
    value: true,
    message,
  };
};

export const getPatternValidationObject = (pattern: string, message: string) => {
  return {
    value: new RegExp(pattern),
    message,
  };
};

export const getEmailValidationObject = (message: string) => {
  return getPatternValidationObject('^\\s*[^\\s@]+@[^\\s@]+\\.[^\\s@]+\\s*$', message);
};

export const getMaxLengthValidationObject = (maxLength: number, message: string) => {
  return {
    value: maxLength,
    message,
  };
};
