import React from 'react';
import { Flex } from '@chakra-ui/react';

import { ClearAllRelevos } from '../ClearAllRelevos/ClearAllRelevos';
import { DepositConfirm } from '../DepositConfirm/DepositConfirm';

export const DepositFooter = () => {
  return (
    <Flex mt={2}>
      <DepositConfirm />
      <ClearAllRelevos />
    </Flex>
  );
};
