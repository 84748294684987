import { useContext } from 'react';

import { SDKContext } from './model/sdk-context';

export function useScanditSDK() {
  const value = useContext(SDKContext);
  if (value.sdk === null) {
    throw new Error('No context provided for ScanditSDK');
  }
  return value;
}
