import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { NEWS_QUERY_KEY } from '../api/apiConsts';
import { getNews } from '../api/news';
import { WordpressNewsResponse } from '../api/types';

export const useNews = (): QueryObserverResult<WordpressNewsResponse[], AxiosError> => {
  return useQuery([NEWS_QUERY_KEY], () => getNews(), {
    refetchOnWindowFocus: false,
  });
};
