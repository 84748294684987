import React, { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import Webcam from 'react-webcam';
import { CheckIcon, RepeatIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, IconButton, Image, Spinner, Tooltip, useTheme, VStack } from '@chakra-ui/react';

import SwitchCamera from '../../common/components/SwitchCamera/SwitchCamera';
import { useHandleVideoDevices } from '../../common/hooks/useHandleVideoDevices';
import { useTranslations } from '../../contexts/LocalizationContext';

const buttonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: 'none' },
  _hover: { backgroundColor: 'none' },
  _active: { backgroundColor: 'none' },
};

type CapturePhotoProps = {
  img: string | null;
  webcamRef: React.MutableRefObject<Webcam | null>;
  handleCapture: () => void;
  handleAccept: () => void;
  handleRetake: () => void;
};

const CapturePhoto = ({
  img,
  webcamRef,
  handleCapture,
  handleAccept,
  handleRetake,
}: CapturePhotoProps): JSX.Element => {
  const { colors } = useTheme();
  const translations = useTranslations();
  const getIconPath = (iconName: string) => require(`../../assets/icons/${iconName}`);
  const [isWebcamLoading, setIsWebcamLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(true);
  const { deviceId, devicesSelectOptions, handleSelectDevice } = useHandleVideoDevices();

  const [deviceOption, setDeviceOption] = useState<SingleValue<{ value: string; label: string }>>();

  const handleUserMedia = () => {
    setIsWebcamLoading(false);
  };

  useEffect(() => {
    setShowVideo(true);
  }, [deviceId]);

  useEffect(() => {
    if (deviceOption) {
      handleSelectDevice(deviceOption);
    }
    // FYI: handleSelectDevice - consider using useEffectEvent once it is released in a stable version of React, and we upgrade it
    // https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
    // eslint-disable-next-line
  }, [deviceOption]);

  const handleDeviceChange = (device: SingleValue<{ value: string; label: string }>) => {
    setShowVideo(false);
    setDeviceOption(device);
  };

  return (
    <>
      {img === null ? (
        <VStack>
          <Box maxW={370}>
            {isWebcamLoading && <Spinner />}
            {showVideo ? (
              <Webcam
                imageSmoothing={true}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ deviceId }}
                onUserMedia={handleUserMedia}
              />
            ) : null}
          </Box>
          <HStack>
            <Button height={6} {...buttonDefaultProps} background="none" onClick={handleCapture}>
              <Image width={6} height={6} src={getIconPath('photo-camera.svg')} />
            </Button>
            <SwitchCamera
              selectedOption={devicesSelectOptions.find((option) => option.value === deviceId)}
              devicesSelectOptions={devicesSelectOptions}
              handleSelectDevice={handleDeviceChange}
            />
          </HStack>
        </VStack>
      ) : (
        <VStack>
          <Box maxW={370}>
            <img src={img || undefined} alt="screenshot" />
          </Box>
          <HStack>
            <Tooltip label={translations('report_damaged_items_accept_button')}>
              <IconButton
                {...buttonDefaultProps}
                height={6}
                background="none"
                aria-label={translations('report_damaged_items_accept_button')}
                onClick={handleAccept}
                icon={<CheckIcon color={colors.green[500]} boxSize="20px" height={6} />}
              />
            </Tooltip>
            <Tooltip label={translations('report_damaged_items_retake_button')}>
              <IconButton
                {...buttonDefaultProps}
                height={6}
                background="none"
                aria-label={translations('report_damaged_items_retake_button')}
                onClick={handleRetake}
                icon={<RepeatIcon color={colors.red[500]} boxSize="20px" height={6} />}
              />
            </Tooltip>
          </HStack>
        </VStack>
      )}
    </>
  );
};

export default CapturePhoto;
