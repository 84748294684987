import React, { JSX } from 'react';
import { Text } from '@chakra-ui/react';

type SectionProps = {
  section: string;
};

const Section = ({ section }: SectionProps): JSX.Element => {
  return (
    <Text fontWeight="bold" textAlign="left" fontSize="18px" mb={4}>
      {section}
    </Text>
  );
};

export default Section;
