import React, { JSX, useMemo } from 'react';
import { Box, BoxProps, Heading, HStack, Text, useTheme } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import { ReportsStatisticsResponse } from 'modules/Reports/model/ReportsStatisticsResponse';

type AllLocationsBoxProps = {
  data: ReportsStatisticsResponse[];
  heading: string;
  countKey: keyof Pick<ReportsStatisticsResponse, 'totalAccountBorrowings' | 'totalAccountUsers'>;
  children: React.ReactNode | React.ReactNode[];
  chartContainerHeight: number;
  boxProps?: BoxProps;
};
const AllLocationsBox = ({
  heading,
  countKey,
  children,
  boxProps,
  data,
  chartContainerHeight,
}: AllLocationsBoxProps): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const totalCount = useMemo(
    () =>
      data
        .map((dataset) => dataset[countKey])
        .reduce((acc: number, curr: number) => {
          return acc + curr;
        }, 0),
    [data, countKey],
  );
  return (
    <Box border="2px solid" borderColor={colors.beige[50]} borderRadius="6px" flexBasis="47.5%" {...boxProps}>
      <HStack p={4} pt={5} borderBottom="2px solid" borderColor={colors.beige[50]} justifyContent="space-between">
        <Heading as="h2" fontSize={16} fontWeight="bolder">
          {heading}
        </Heading>
        <Box>
          <HStack>
            <Text>{`${translations('reports_graph_view_total')}:`}</Text>
            <Text fontWeight="bolder">{totalCount}</Text>
          </HStack>
        </Box>
      </HStack>
      <Box p={4} minHeight={chartContainerHeight}>
        {children}
      </Box>
    </Box>
  );
};

export default AllLocationsBox;
