import React, { forwardRef, useRef, useState } from 'react';
import { Button, Td, Text, Tr } from '@chakra-ui/react';
import { RelevoInterface } from 'api/types';
import { useTranslations } from 'contexts/LocalizationContext';

import Dialog from '../../../../common/components/ConfirmationDialog/Dialog';
import { TableItemWithImage } from '../../../../common/components/TableItemWithImage';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import colors from '../../../../styles/colors';

type Props = {
  relevo: RelevoInterface;
  removeRelevo: (relevo: string) => void;
};

const cancelButtonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: 'none' },
  _hover: { backgroundColor: 'none' },
  _active: { backgroundColor: 'none' },
};

const RelevosListItem = forwardRef<HTMLTableRowElement, Props>(({ removeRelevo, relevo }, ref): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef(null);

  const handleClose = () => setIsOpen(false);
  const handleConfirm = () => {
    handleClose();
    removeRelevo(relevo.uId);
  };

  return (
    <Tr ref={ref}>
      <Td>
        <TableItemWithImage
          text={relevo.category.translations ? relevo.category.translations[language] : ''}
          imageUrl={relevo.category.imageUrl}
        />
      </Td>
      <Td>{relevo.productName}</Td>
      <Td>
        <Button
          {...cancelButtonDefaultProps}
          mr={2}
          bg={colors.white}
          color={colors.orange[500]}
          fontSize="16px"
          fontWeight="normal"
          aria-label={translations('scan_remove_relevo')}
          title={translations('scan_remove_relevo')}
          onClick={() => setIsOpen(true)}
        >
          {translations('scan_clear_relevo')}
        </Button>
        <Dialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleClose}
          onConfirm={handleConfirm}
          cancelText={translations('scan_remove_cancel')}
          confirmText={translations('scan_remove_confirmed')}
        >
          <Text>{translations('scan_remove_confirm', { '{{relevo-name}}': relevo.productName })}</Text>
        </Dialog>
      </Td>
    </Tr>
  );
});

export default RelevosListItem;
