import { useCallback, useState } from 'react';

import { OrderType } from '../../../../../../common/components/TableHeaderWithOrder/orderType.enum';
import { InventoryCountsHistoryState } from '../../../../model/inventory-counts-history-state';
import { InventoryCountsOrderField } from '../../../../model/inventory-counts-order-field.enum';

export const useInventoryCountsHistoryState = () => {
  const [inventoryCountsState, setInventoryCountsState] = useState<InventoryCountsHistoryState>({
    orderField: InventoryCountsOrderField.DATE,
    orderType: OrderType.DESC,
    page: 1,
  });

  const handleOrderInventoryCountsBy = useCallback(
    (orderField: InventoryCountsOrderField) => () => {
      if (inventoryCountsState.orderField === orderField) {
        setInventoryCountsState({
          ...inventoryCountsState,
          orderField,
          orderType: inventoryCountsState.orderType === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
        });
      } else {
        setInventoryCountsState({
          ...inventoryCountsState,
          orderField,
          orderType: OrderType.ASC,
        });
      }
    },
    [inventoryCountsState],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setInventoryCountsState({
        ...inventoryCountsState,
        page,
      });
    },
    [inventoryCountsState],
  );

  return {
    handlePageChange,
    inventoryCountsState,
    handleOrderInventoryCountsBy,
  };
};
