import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { PROFILE_QUERY_KEY } from '../api/apiConsts';
import { UserProfileResponse } from '../api/types';
import { getProfile } from '../api/user';

export const useProfile = (): QueryObserverResult<UserProfileResponse, AxiosError> => {
  return useQuery([PROFILE_QUERY_KEY], () => getProfile(), {
    refetchOnWindowFocus: false,
  });
};
