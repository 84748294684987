import React, { useRef } from 'react';
import { Button } from '@chakra-ui/react';

import Dialog from '../../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../../contexts/ScannedRelevosContext';
import { DepositItemResponse } from '../../../model/deposit-item-response';
import { DepositResponse } from '../../../model/deposit-response';
import { ErrorDepositItemResponse } from '../../../model/error-deposit-item-response';
import { DepositResultModalBody } from './DepositResultModalBody';

type DepositResultModalProps = {
  isOpen: boolean;
  onClose: () => void;
  successItems: DepositItemResponse[];
  failureItems: ErrorDepositItemResponse[];
  deposit: DepositResponse;
};

export const DepositResultModal = ({
  isOpen,
  onClose,
  deposit,
  successItems,
  failureItems,
}: DepositResultModalProps) => {
  const translations = useTranslations();
  const cancelRef = useRef(null);
  const { setScannedRelevos } = useScannedRelevosContext();

  const handleClose = () => {
    onClose();
    setScannedRelevos([]);
  };

  return (
    <Dialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={handleClose}
      size="3xl"
      dialogHeader={translations('deposit_summary_heading')}
      dialogFooter={
        <Button
          minW="100px"
          variant="orangeSolid"
          color="black"
          margin="auto"
          title={translations('ok')}
          onClick={handleClose}
          mr={3}
        >
          {translations('ok')}
        </Button>
      }
    >
      <DepositResultModalBody deposit={deposit} successItems={successItems} failureItems={failureItems} />
    </Dialog>
  );
};
