import React, { useRef } from 'react';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import Dialog from '../../../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useInventoryCountsContext } from '../../../../context/hooks/use-inventory-counts-context.hook';

export const CheckYourCount = () => {
  const translations = useTranslations();
  const { activeStep, setActiveStep } = useInventoryCountsContext();
  const confirmDiscontinueCancelRef = useRef(null);
  const discontinueInventoryCountConfirmModal = useDisclosure();

  const handleCheckYourCount = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    discontinueInventoryCountConfirmModal.onOpen();
  };

  const handleConfirm = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <Flex justifyContent="flex-end" w={'100%'} px={[2, 2, 4, 4]}>
        <Box w={['50%', '50%', '200px', '200px']} p={1}>
          <Button w={'100%'} onClick={handleBack} variant={'white'} leftIcon={<ArrowLeftIcon h={3} w={3} />}>
            {translations('inventory_counts_back_button_label')}
          </Button>
        </Box>
        <Box w={['50%', '50%', '200px', '200px']} p={1}>
          <Button w={'100%'} onClick={handleCheckYourCount} variant={'orangeSolid'}>
            {translations('inventory_counts_check_your_count')}
          </Button>
        </Box>
      </Flex>
      <Dialog
        leastDestructiveRef={confirmDiscontinueCancelRef}
        isOpen={discontinueInventoryCountConfirmModal.isOpen}
        onClose={discontinueInventoryCountConfirmModal.onClose}
        onConfirm={handleConfirm}
        confirmText={translations('inventory_counts_discontinue_confirm_button_label')}
        cancelText={translations('cancel')}
      >
        <Text fontSize={'20px'}>{translations('inventory_counts_discontinue_confirm')}</Text>
      </Dialog>
    </>
  );
};
