import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { queryParamsToString } from '../../../utils/queryParamsHelper';
import usePaginationWithUrl from '../Pagination/usePaginationWithUrl.hook';

const useSearchWithUrl = (onChangeFn: (value: string) => void): { updateUrlOnSearch: (query: string) => void } => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { updateUrlOnPaginationChange } = usePaginationWithUrl();
  const { q, page } = Object.fromEntries(new URLSearchParams(search).entries());

  const updateUrlOnSearch = (searchQuery = '') => {
    onChangeFn(searchQuery);
    navigate({
      pathname: pathname,
      search: queryParamsToString({ paramValue: searchQuery, paramName: 'q', search }),
    });
  };

  useEffect(() => {
    if (page !== '1') {
      updateUrlOnPaginationChange(1);
    }
    if (q) {
      onChangeFn(q);
    }
    // eslint-disable-next-line
  }, [q, onChangeFn]);

  useEffect(() => {
    updateUrlOnSearch(q);
    // eslint-disable-next-line
  }, []);

  return { updateUrlOnSearch };
};

export default useSearchWithUrl;
