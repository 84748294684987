import Accordion from './Accordion';
import Button from './Button';
import Checkbox from './Checkbox';
import Heading from './Heading';
import Input from './Input';
import Radio from './Radio';
import Stepper from './Stepper';
import Table from './Table';
import Textarea from './Textarea';

export default {
  Button,
  Heading,
  Table,
  Accordion,
  Radio,
  Input,
  Checkbox,
  Stepper,
  Textarea,
};
