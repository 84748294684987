import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, useDisclosure } from '@chakra-ui/react';

import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../../contexts/ScannedRelevosContext';
import { useReturnUnknownItems } from '../../../../../services/relevos';
import { UnknownItemsConfirmModal } from '../../../common/components/UnknownItemsConfirmModal/UnknownItemsConfirmModal';
import { ReturnUnknownRelevosResponse } from '../../../model/return-unknown-relevos-response';
import { useQuickContactsUnknownItems } from '../utils/use-quick-contacts-return-items.hook';
import { QuickContactsReturnItemsTable } from './QuickContactsReturnItemsTable/QuickContactsReturnItemsTable';
import { ReturnUnknownItemsConfirmModalBody } from './ReturnUnknownItemsConfirmModalBody/ReturnUnknownItemsConfirmModalBody';
import { ReturnUnknownItemsResultModal } from './ReturnUnknownItemsResultModal/ReturnUnknownItemsResultModal';

type QuickContactReturnItemsListProps = {
  restaurantId: string;
};

export const QuickContactReturnItemsList = ({ restaurantId }: QuickContactReturnItemsListProps) => {
  const translations = useTranslations();
  const { resetRestaurantId } = useScannedRelevosContext();
  const [returnResult, setReturnResult] = useState<ReturnUnknownRelevosResponse | undefined>(undefined);
  const confirmModal = useDisclosure();
  const resultModal = useDisclosure();
  const { itemsToReturn, handleUpdateItemsQuantity, unknownItems, isLoadingUnknownItems } =
    useQuickContactsUnknownItems(restaurantId);

  const { mutate: returnUnknownRelevos } = useReturnUnknownItems();

  const handleReturn = () => {
    confirmModal.onOpen();
  };

  const handleConfirm = () => {
    const request = itemsToReturn.filter((unknownItem) => unknownItem.quantity > 0);
    returnUnknownRelevos(
      { restaurantId, request: { items: request } },
      {
        onSuccess: (data) => {
          if (!data.notAssignedItems.length) {
            toast.success(translations('unknown_items_return_success_message'));
            resetRestaurantId();
          } else {
            setReturnResult(data);
            resultModal.onOpen();
          }
        },
      },
    );
    confirmModal.onClose();
  };

  const handleResultModalClose = () => {
    resultModal.onClose();
    resetRestaurantId();
  };

  const isReturnDisabled = useMemo(() => !itemsToReturn.some((item) => item.quantity > 0), [itemsToReturn]);

  return (
    <>
      <Box pl={[2, 2, 2, 12, 12]} px={2} pb={4}>
        <Box py={4} width={['100%', '100%', '100%', '75%', '75%']}>
          <QuickContactsReturnItemsTable
            items={unknownItems}
            isLoadingItems={isLoadingUnknownItems}
            itemsToReturn={itemsToReturn}
            onUpdateItemsQuantity={handleUpdateItemsQuantity}
          />
        </Box>
        <Box alignItems={'left'} justifyContent={'left'} display={'flex'} pt={2}>
          <Button variant={'orangeSolid'} onClick={handleReturn} isDisabled={isReturnDisabled}>
            {translations('quick_contacts_return_list_return_button')}
          </Button>
        </Box>
      </Box>
      <UnknownItemsConfirmModal
        isOpen={confirmModal.isOpen}
        handleClose={confirmModal.onClose}
        onConfirm={handleConfirm}
        confirmButtonText={translations('quick_contacts_return_list_return_button')}
        confirmText={translations('quick_contacts_return_list_confirm_text')}
      >
        <ReturnUnknownItemsConfirmModalBody itemsToReturn={itemsToReturn} unknownItems={unknownItems} />
      </UnknownItemsConfirmModal>
      {returnResult ? (
        <ReturnUnknownItemsResultModal
          isVisible={resultModal.isOpen}
          onCancel={handleResultModalClose}
          result={returnResult}
          unknownItems={unknownItems}
        />
      ) : null}
    </>
  );
};
