/* eslint-disable import/prefer-default-export */
export const LOCALIZATION_CONFIG = {
  de: {
    locale: 'de-DE',
    timeZone: 'Europe/Berlin',
    dateFormat: 'dd/MM/yyyy',
  },
};

export const DEBOUNCE_SEARCH_TIME = 300;
