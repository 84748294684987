import React, { JSX } from 'react';
import { Box, Spinner, Text, useTheme } from '@chakra-ui/react';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useFrequentlyAskedQuestions } from '../../services/frequently-asked-questions';
import QuestionsAnswers from './QuestionsAnswers';
import Section from './Section';

const FrequentlyAskedQuestions = (): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const { colors } = useTheme();
  const { data: faq, isLoading: faqLoading, isError: faqError } = useFrequentlyAskedQuestions();

  return (
    <MainPageContent>
      <MainPageHeading>{translations('faq_heading')}</MainPageHeading>
      {faqLoading ? <Spinner /> : null}
      {faqError ? (
        <Text color={colors.orange[500]}>{translations('something_went_wrong')}</Text>
      ) : (
        <Box p={4}>
          {faq?.map((response) => (
            <Box p={4} key={response.section.id}>
              <Section section={response.section.translations[language]} />
              <QuestionsAnswers questions={response.questions} />
            </Box>
          ))}
        </Box>
      )}
    </MainPageContent>
  );
};

export default FrequentlyAskedQuestions;
