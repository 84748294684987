import React, { JSX, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Flex, HStack, Spinner, useDisclosure } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { AxiosError } from 'axios';
import { HttpException } from 'common/models/httpException';
import { useTranslations } from 'contexts/LocalizationContext';

import { assignUnknownRelevos } from '../../../../api/relevos';
import { CategoryQuantity, RelevosProductCategoryWithImage } from '../../../../api/types';
import { TableType } from '../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../common/components/Table/Table';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { useRestaurantCategories } from '../../../../services/categories';
import colors from '../../../../styles/colors';
import { UnknownItemsConfirmModal } from '../../common/components/UnknownItemsConfirmModal/UnknownItemsConfirmModal';
import CategoriesList from './components/CategoriesList';

const SelectCategories = (): JSX.Element => {
  const [categoriesToCreate, setCategoriesToCreate] = useState<CategoryQuantity>({});
  const { restaurantId, applicationUser, quickContact, setApplicationUser } = useScannedRelevosContext();
  const translations = useTranslations();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { language } = useLocalizationContext();
  const { data: categories, isLoading: isLoadingCategories } = useRestaurantCategories(restaurantId || '');

  const updateQuantityOfItemsInCategory = (categoryId: string, quantity: number) => {
    const categoriesQuantities = { ...categoriesToCreate, [categoryId]: quantity };
    setCategoriesToCreate(
      Object.keys(categoriesQuantities)
        .filter((category) => categoriesQuantities[category] != 0)
        .reduce(
          (categoriesQuantitiesToUpdate, category) => ({
            ...categoriesQuantitiesToUpdate,
            [category]: categoriesQuantities[category],
          }),
          {},
        ),
    );
  };

  const executeUnknownAssign = () => {
    onClose();
    if (restaurantId && (applicationUser || quickContact)) {
      const userId = applicationUser?.id || quickContact?.id || '';
      setIsLoading(true);
      assignUnknownRelevos(userId, categoriesToCreate, restaurantId)
        .then((result) => {
          const expectedResult = Object.values(categoriesToCreate).reduce((a, b) => a + b);
          if (result === expectedResult) {
            toast.success(
              translations('successfully_assigned', {
                '{{dishes-number}}': result.toString(),
                '{{client}}': userId,
              }),
            );
            setCategoriesToCreate({});
            setApplicationUser(null);
          } else {
            toast.warn(
              translations('partial_successfully_assigned', {
                '{{dishes-number}}': result.toString(),
                '{{expected-dishes-number}}': expectedResult.toString(),
                '{{client}}': userId,
              }),
            );
          }
        })
        .catch((error: AxiosError<HttpException>) => {
          toast.error(<b>{error.response?.data.message || translations('something_went_wrong')}</b>);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const columns = useMemo<ColumnDef<RelevosProductCategoryWithImage | undefined>[]>(
    () => [
      {
        id: 'category',
        header: translations('category_list_category'),
        cell: ({ row }) => <>{row.original?.translations[language]}</>,
      },
      {
        id: 'quantity',
        header: translations('category_list_quantity'),
        cell: ({ row }) => <>{row.original ? categoriesToCreate[row.original.id] : undefined}</>,
      },
    ],
    [categoriesToCreate, language, translations],
  );

  if (!applicationUser && !quickContact) {
    return <Box />;
  }

  return (
    <Box flex={1} pl={[0, 0, 0, 0, 6]} w={'100%'} maxW={'600px'}>
      <Flex direction="column" textAlign="left">
        {isLoadingCategories ? (
          <Spinner />
        ) : (
          <CategoriesList
            categories={categories || []}
            updateQuantityOfItemsInCategory={updateQuantityOfItemsInCategory}
            categoriesToCreate={categoriesToCreate}
          />
        )}
        <HStack textAlign="left" mt={6}>
          <Box pr="4px">
            <Button
              variant={'orangeSolid'}
              color={colors.black}
              fontWeight="normal"
              disabled={
                Object.values(categoriesToCreate).every((x) => x === 0) ||
                !restaurantId ||
                (!applicationUser?.id && !quickContact) ||
                isLoading
              }
              colorScheme="orange"
              title={translations('scan_assign_items')}
              onClick={onOpen}
              minWidth={144}
              margin="auto"
            >
              {!isLoading ? translations('scan_assign_items') : <Spinner />}
            </Button>
          </Box>
        </HStack>
        <UnknownItemsConfirmModal
          isOpen={isOpen}
          handleClose={onClose}
          onConfirm={executeUnknownAssign}
          confirmText={translations('assign_items_confirm', {
            '{{user-id}}': applicationUser?.userId || quickContact?.quickContactName || '',
          })}
          confirmButtonText={translations('assign_items_confirmed')}
        >
          <Table
            variant={'relevoSummary'}
            type={TableType.SIMPLE}
            data={Object.keys(categoriesToCreate)
              .map((categoryId) => categories?.find((category) => categoryId === category.id))
              .filter((category) => !!category)}
            columns={columns}
          />
        </UnknownItemsConfirmModal>
      </Flex>
    </Box>
  );
};

export default SelectCategories;
