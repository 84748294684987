import { RestaurantOpeningHours, SortedImage } from '../types';

interface IdObject {
  id: string;
}

export interface RestaurantEditForm {
  restaurantName: string;
  restaurantAddress: string;
  restaurantDetail: string;
  phoneNumber: string | null;
  websiteLink: string | null;
  metaCategories: IdObject[];
  restaurantOpeningHours: RestaurantOpeningHours[] | null;
  overrideOpeningHours: boolean;
  images: SortedImage[];
}

export enum RestaurantEditFormFields {
  RESTAURANT_NAME = 'restaurantName',
  RESTAURANT_ADDRESS = 'restaurantAddress',
  RESTAURANT_DETAIL = 'restaurantDetail',
  PHONE_NUMBER = 'phoneNumber',
  WEBSITE_LINK = 'websiteLink',
  META_CATEGORIES = 'metaCategories',
  RESTAURANT_OPENING_HOURS = 'restaurantOpeningHours',
  OVERRIDE_OPENING_HOURS = 'overrideOpeningHours',
  IMAGES = 'images',
}

export const restaurantEditFormInitialValues: RestaurantEditForm = {
  [RestaurantEditFormFields.RESTAURANT_NAME]: '',
  [RestaurantEditFormFields.RESTAURANT_ADDRESS]: '',
  [RestaurantEditFormFields.RESTAURANT_DETAIL]: '',
  [RestaurantEditFormFields.PHONE_NUMBER]: '',
  [RestaurantEditFormFields.WEBSITE_LINK]: '',
  [RestaurantEditFormFields.META_CATEGORIES]: [],
  [RestaurantEditFormFields.RESTAURANT_OPENING_HOURS]: [],
  [RestaurantEditFormFields.OVERRIDE_OPENING_HOURS]: false,
  [RestaurantEditFormFields.IMAGES]: [],
};
