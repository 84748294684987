import { toast } from 'react-toastify';
import axios from 'axios';
import config from 'config/config';
import { addAxiosInterceptors } from 'supertokens-auth-react/recipe/session';

const axiosInstance = axios.create({ baseURL: config.apiUrl, withCredentials: true });
addAxiosInterceptors(axiosInstance);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ERR_NETWORK') {
      toast.error('Connection error. Check your internet connection, and try again.');
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
