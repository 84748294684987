import React, { JSX } from 'react';
import { Box, Heading, HStack, useTheme } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import { ReportsStatisticsResponse } from 'modules/Reports/model/ReportsStatisticsResponse';
import { useReportGraphLabels } from 'modules/Reports/useReportGraphLabels';

import { CHART_COLORS_HEX } from '../constants';
import GraphBox from '../GraphBox';
import TotalUsersChart from './TotalUsersChart';

const getIconPath = (iconName: string) => require(`../../../../assets/icons/${iconName}`);

const TotalUsersBox = ({ data }: { data: ReportsStatisticsResponse[] }): JSX.Element => {
  const translations = useTranslations();
  const labels = useReportGraphLabels(data);
  const { colors } = useTheme();
  const totalUsersCount = data.map((dataSet) => dataSet.totalAccountUsers);

  return (
    <GraphBox
      heading={translations('reports_graph_view_users')}
      iconSrc={getIconPath('location_stats_users.svg')}
      iconAlt="users"
      boxProps={{
        p: 4,
        alignSelf: 'flex-start',
        flexBasis: '10%',
      }}
    >
      <Box maxWidth={125} minHeight={250} mt={4} p={2}>
        <TotalUsersChart labels={labels} totalUsersCount={totalUsersCount} />
      </Box>
      <HStack spacing={0} p={2} justifyContent="center">
        {totalUsersCount.map((count, index) => (
          <Box key={data[index].locationUId} backgroundColor={CHART_COLORS_HEX[index]} px={2} py={0.5}>
            <Heading as="h4" color={colors.white} fontSize={16}>
              {count}
            </Heading>
          </Box>
        ))}
      </HStack>
    </GraphBox>
  );
};

export default TotalUsersBox;
