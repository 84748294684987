import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EditIcon } from '@chakra-ui/icons';
import { Button, Flex, IconButton } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useNotificationSettingsContext } from '../../context/hooks/use-notification-settings-context.hook';

export const NotificationSettingsMenu = () => {
  const translations = useTranslations();
  const { isEditMode, handleSwitchEditMode, notificationSettingsTableData } = useNotificationSettingsContext();
  const { reset } = useFormContext();

  const handleCancel = () => {
    reset(notificationSettingsTableData);
    handleSwitchEditMode(!isEditMode);
  };

  const handleEditMode = () => handleSwitchEditMode(!isEditMode);

  return (
    <>
      {isEditMode ? (
        <Flex justifyContent="flex-end">
          <Button type="submit" size="sm" variant="transparent" height="14px" color="orange.500" mr={4}>
            {translations('save')}
          </Button>
          <Button onClick={handleCancel} size="sm" variant="transparent" height="14px" color="grey.200">
            {translations('cancel')}
          </Button>
        </Flex>
      ) : (
        <IconButton
          variant="iconButton"
          onClick={handleEditMode}
          icon={<EditIcon />}
          aria-label="edit-icon"
          data-testid="edit-icon"
        />
      )}
    </>
  );
};
