import React, { useMemo } from 'react';
import ReactDomServer from 'react-dom/server';
import { Box, Text, VStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import { PageSectionHeading } from '../../../../../../../common/components/PageSectionHeading/PageSectionHeading';
import { TableType } from '../../../../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../../../../common/components/Table/Table';
import { TableItemWithImage } from '../../../../../../../common/components/TableItemWithImage';
import { useHtmlTranslations, useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { InventoryCountsItemOverview } from '../../../../../model/inventory-counts-item-overview';
import { useReview } from './hooks/use-review.hook';

export const Review = () => {
  const htmlTranslations = useHtmlTranslations();
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const { inventoryCount, inventoryTotalItems, inventoryMissingItems, inventoryScannedItems, isLoading } = useReview();

  const summaryArgs = {
    '{{scannedItems}}': ReactDomServer.renderToString(<Text as="b">{inventoryScannedItems?.toString()}</Text>),
    '{{allItems}}': ReactDomServer.renderToString(<Text as="b">{inventoryTotalItems?.toString()}</Text>),
    '{{missingItems}}': ReactDomServer.renderToString(<Text as="b">{inventoryMissingItems?.toString()}</Text>),
  };
  const summary = htmlTranslations('inventory_counts_review_summary', summaryArgs);

  const columns = useMemo<ColumnDef<InventoryCountsItemOverview>[]>(
    () => [
      {
        id: 'category',
        header: translations('inventory_counts_item_overview_item_header'),
        cell: ({ row }) => (
          <TableItemWithImage
            text={row.original.category.translations ? row.original.category.translations[language] : ''}
            imageUrl={row.original.category.imageUrl}
          />
        ),
      },
      {
        id: 'currentInventory',
        header: translations('inventory_counts_item_overview_stock_header'),
        cell: ({ row }) => <>{row.original.currentInventory.toString()}</>,
      },
      {
        id: 'scannedItems',
        header: translations('inventory_counts_item_overview_scanned_header'),
        cell: ({ row }) => <>{row.original.scannedItems.toString()}</>,
      },
      {
        id: 'missing',
        header: translations('inventory_counts_review_missing_header'),
        cell: ({ row }) => <>{row.original.currentInventory - row.original.scannedItems}</>,
      },
    ],
    [language, translations],
  );

  return (
    <VStack alignItems={'start'} p={2}>
      <PageSectionHeading>{translations('inventory_counts_review_heading')}</PageSectionHeading>
      <Text fontSize={14} textAlign={'left'}>
        {summary}
      </Text>
      <Box w={'100%'}>
        <Table
          variant={'inventoryItemOverview'}
          type={TableType.SIMPLE}
          isLoading={isLoading}
          data={inventoryCount?.currentInventoryScannedItems || []}
          columns={columns}
          withBorder
          maxH={'30.5em'}
          overflowY={'auto'}
        />
      </Box>
    </VStack>
  );
};
