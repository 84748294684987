import { generatePath } from 'react-router-dom';

import { apiPaths } from '../config/apiPaths';
import { BorrowDepositResponse } from '../modules/Deposit/model/borrow-deposit-response';
import { DepositRequest } from '../modules/Deposit/model/deposit-request';
import { ReturnDepositResponse } from '../modules/Deposit/model/return-deposit-response';
import axios from './axios';

export const borrowDepositItems = async (
  restaurantId: string,
  data: DepositRequest,
): Promise<BorrowDepositResponse> => {
  return axios
    .post<BorrowDepositResponse>(generatePath(apiPaths.DEPOSIT_BORROW_PATH, { restaurantId }), data)
    .then((response) => response.data);
};

export const returnDepositItems = async (
  restaurantId: string,
  data: DepositRequest,
): Promise<ReturnDepositResponse> => {
  return axios
    .post<ReturnDepositResponse>(generatePath(apiPaths.DEPOSIT_RETURN_PATH, { restaurantId }), data)
    .then((response) => response.data);
};
