import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  border: '1px', // change the appearance of the border
  borderColor: 'beige.50',
  borderRadius: 4,
  background: 'white',
  padding: 2,
  _focus: {
    background: 'white',
    borderColor: 'beige.50',
    boxShadow: 'none',
  },
  _hover: {
    background: 'white',
    borderColor: 'beige.50',
  },
  _active: {
    background: 'white',
    borderColor: 'beige.50',
    boxShadow: 'none',
  },
  fontSize: '14px',
  fontFamily: 'Rubik',
});

const Textarea = defineStyleConfig({
  variants: { outline },
});

export default Textarea;
