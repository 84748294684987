/* eslint-disable no-console */
import parse from 'html-react-parser';

import { TranslationKeys } from './types';
import { useLocalizationContext } from './useLocalizationContext.hook';

type Args = {
  [key: string]: string;
};

const getTranslation = (translations: TranslationKeys, key: string, args: Args | undefined): string => {
  let translation = translations[key] || key;

  if (typeof args !== 'undefined') {
    Object.keys(args).forEach((k) => {
      const reg = new RegExp(k, 'g');
      translation = translation.replace(reg, args[k]);
    });
  }

  if (translation === key && process.env.NODE_ENV === 'development') {
    console.log(`There is no translation for key: ${key}`);
  }
  return translation;
};

// It can be used if you want to use translations with HTML tags.
export const useHtmlTranslations = (): { (key: string, args?: Args): string | JSX.Element | JSX.Element[] } => {
  const { translations } = useLocalizationContext();

  return (key, args) => {
    const translation = getTranslation(translations, key, args);
    return parse(translation);
  };
};

const useTranslations = (): { (key: string, args?: Args): string } => {
  const { translations } = useLocalizationContext();

  return (key, args) => {
    return getTranslation(translations, key, args);
  };
};

export default useTranslations;
