import React, { Dispatch, JSX, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Text, useTheme } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import {
  SendResetPasswordEmailInputs,
  SendResetPasswordEmailStep,
  SendResetPasswordEmailStepProps,
} from '../../../types';

interface Props extends SendResetPasswordEmailStepProps {
  setEmail: (email: string) => void;
  setCurrentStep: Dispatch<SetStateAction<SendResetPasswordEmailStep>>;
}

const EmailForm = ({ setCurrentStep, currentStep, setEmail, closeModal }: Props): JSX.Element | null => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const { control, handleSubmit } = useForm<SendResetPasswordEmailInputs>();

  const onSubmit = async (data: SendResetPasswordEmailInputs) => {
    const { email } = data;
    setEmail(email);
    setCurrentStep(SendResetPasswordEmailStep.ConfirmationStep);
  };

  if (currentStep !== SendResetPasswordEmailStep.FormStep) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>{translations('reset_password')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <Text mb={4}>{translations('reset_password_will_send_you_email')}</Text>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input
              autoComplete="on"
              required
              value={value}
              onChange={onChange}
              type="email"
              borderColor={colors.orange[500]}
              focusBorderColor={colors.orange[500]}
              placeholder={translations('reset_password_email_address')}
            />
          )}
        />
      </ModalBody>
      <ModalFooter justifyContent="center">
        <Button
          fontWeight="bold"
          type="submit"
          title={translations('reset_password_send_email')}
          colorScheme="orange"
          mr={4}
        >
          {translations('reset_password_send_email')}
        </Button>
        <Button onClick={closeModal} title={translations('cancel')}>
          {translations('cancel')}
        </Button>
      </ModalFooter>
    </form>
  );
};

export default EmailForm;
