import { createContext, ReactNode, useState } from 'react';
import { SingleValue } from 'react-select';

import { SelectOption } from '../../../../common/components/Select/model/selectOption';
import { QuickContactTableRow } from '../../components/QuickContactsTable/model/quick-contact-table-row';
import { QuickContactFnbGroupResponse } from '../../model/quick-contact-fnb-group-response';
import { QuickContactsFilterContextInterface } from './model/QuickContactsFilterContext';
import { QuickContactsState } from './model/QuickContactsState';

type QuickContactsFilterContextProps = {
  children: ReactNode;
  fnbGroups: QuickContactFnbGroupResponse[];
};

export const QuickContactsContext = createContext<QuickContactsFilterContextInterface | null>(null);

export const QuickContactsFilterContextProvider = ({ children, fnbGroups }: QuickContactsFilterContextProps) => {
  const [quickContactsState, setQuickContactsState] = useState<QuickContactsState>({
    search: '',
    selectedFnbGroupId: fnbGroups.length ? fnbGroups[0].id : undefined,
    isReorderMode: false,
    newOrder: undefined,
  });

  const handleSetReorderMode = (isReorderMode: boolean) => {
    if (!isReorderMode) {
      setQuickContactsState({
        ...quickContactsState,
        isReorderMode,
        newOrder: undefined,
      });
    } else {
      setQuickContactsState({
        ...quickContactsState,
        isReorderMode,
      });
    }
  };

  const handleSelectFnbGroup = (selectedFnbGroup: SingleValue<SelectOption>) => {
    if (selectedFnbGroup) {
      setQuickContactsState({
        ...quickContactsState,
        selectedFnbGroupId: selectedFnbGroup.value,
      });
    }
  };

  const handleSetSearchText = (searchText: string) => {
    setQuickContactsState({
      ...quickContactsState,
      search: searchText,
    });
  };

  const handleSetNewOrder = (newOrder: QuickContactTableRow[] | undefined) => {
    setQuickContactsState({
      ...quickContactsState,
      newOrder: newOrder,
    });
  };

  return (
    <QuickContactsContext.Provider
      value={{
        fnbGroups,
        quickContactsState,
        handleSelectFnbGroup,
        handleSetSearchText,
        handleSetReorderMode,
        handleSetNewOrder,
      }}
    >
      {children}
    </QuickContactsContext.Provider>
  );
};
