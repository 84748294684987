import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  field: {
    border: '0px solid',
    width: '100%',
    minWidth: 0,
    outline: 0,
    color: 'grey.900',
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
});

const variantFilled = definePartsStyle(() => {
  return {
    field: {
      bg: 'white',
      border: '0px solid',
      _hover: {
        outline: 0,
        bg: 'white',
        border: '0px solid',
      },
      _focus: {
        outline: 0,
        bg: 'white',
        border: '0px solid',
      },
      _active: {
        outline: 0,
        bg: 'white',
        border: '0px solid',
      },
      _placeholder: {
        color: 'grey.200',
      },
    },
  };
});

const variantUnderline = definePartsStyle(() => {
  return {
    field: {
      bg: 'white',
      borderBottom: '1px solid',
      borderColor: 'grey.200',
      borderRadius: '0px',
      _hover: {
        outline: 0,
        bg: 'white',
      },
      _focus: {
        outline: 0,
        bg: 'white',
      },
      _active: {
        outline: 0,
        bg: 'white',
      },
      _placeholder: {
        color: 'grey.200',
      },
    },
  };
});

const variantQuantity = definePartsStyle(() => {
  return {
    field: {
      bg: 'none',
      fontWeight: 'bold',
      borderBottom: '1px solid',
      borderColor: 'grey.300',
      borderRadius: '0px',
      _hover: {
        outline: 0,
      },
      _focus: {
        outline: 0,
      },
      _active: {
        outline: 0,
      },
    },
  };
});

const variants = {
  whiteFilled: variantFilled,
  transparentUnderline: variantUnderline,
  quantity: variantQuantity,
};

const Input = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    size: 'md',
  },
});

export default Input;
