import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { HISTORY_QUERY_KEY } from '../api/apiConsts';
import { getRelevosHistory } from '../api/relevos';
import { Paginated } from '../common/models/paginated';
import { HistoryQueryRequest } from '../modules/History/model/HistoryQueryRequest';
import { HistoryResponse } from '../modules/History/model/HistoryResponse';

const getRelevosHistoryQueryKey = (offset: number, limit: number, historyQueryParams: HistoryQueryRequest) => [
  HISTORY_QUERY_KEY,
  { offset, limit, ...historyQueryParams },
];

export const useRelevosHistory = (
  historyQueryParams: HistoryQueryRequest,
  offset: number,
  limit: number,
): QueryObserverResult<Paginated<HistoryResponse>, AxiosError> => {
  return useQuery(
    getRelevosHistoryQueryKey(offset, limit, historyQueryParams),
    () => getRelevosHistory(historyQueryParams, offset, limit),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
      enabled: !!historyQueryParams.restaurantIds.length && !!historyQueryParams.endDate,
    },
  );
};
