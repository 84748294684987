import config from 'config/config';
import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/lib/build/recipe/emailpassword/recipe';
import Session from 'supertokens-auth-react/recipe/session';

const initSuperTokens = (): void => {
  SuperTokens.init({
    appInfo: {
      appName: config.appName,
      apiDomain: config.apiUrl,
      websiteDomain: config.websiteUrl,
      apiBasePath: '/api/web/auth',
    },
    recipeList: [
      EmailPassword.init({
        signInAndUpFeature: {
          disableDefaultUI: true,
        },
        resetPasswordUsingTokenFeature: {
          disableDefaultUI: true,
        },
      }),
      Session.init(),
    ],
  });
};

export default initSuperTokens;
