import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { APPLICATION_USERS_RESTAURANTS_UNKNOWN_ITEMS_QUERY_KEY } from '../api/apiConsts';
import { getUnknownRelevosToReturn, returnUnknownRelevos } from '../api/relevos';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { ReturnUnknownRelevosRequest } from '../modules/RelevoAssignment/model/return-unknown-relevos-request';
import { ReturnUnknownRelevosResponse } from '../modules/RelevoAssignment/model/return-unknown-relevos-response';
import { UnknownRelevosListResponse } from '../modules/RelevoAssignment/model/unknown-relevos-list-response';

const getUnknownItemsToReturnQueryKey = (restaurantId: string) => [
  APPLICATION_USERS_RESTAURANTS_UNKNOWN_ITEMS_QUERY_KEY,
  restaurantId,
];

export const useUnknownItemsToReturn = (
  restaurantId: string,
): QueryObserverResult<UnknownRelevosListResponse[], AxiosError> => {
  return useQuery(getUnknownItemsToReturnQueryKey(restaurantId), () => getUnknownRelevosToReturn(restaurantId), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
};

export const useReturnUnknownItems = (): UseMutationResult<
  ReturnUnknownRelevosResponse,
  AxiosError<HttpException>,
  { restaurantId: string; request: ReturnUnknownRelevosRequest }
> => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  return useMutation(
    ({ restaurantId, request }: { restaurantId: string; request: ReturnUnknownRelevosRequest }) =>
      returnUnknownRelevos(restaurantId, request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(APPLICATION_USERS_RESTAURANTS_UNKNOWN_ITEMS_QUERY_KEY);
      },
      onError: (error) => {
        toast.error(error.response?.data.message || error.response?.data.error || translations('something_went_wrong')); // todo: remove error.response?.data.error once translations are added on the backend side
      },
    },
  );
};
