import React, { ComponentProps, JSX, MutableRefObject } from 'react';
import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
} from '@chakra-ui/react';

import colors from '../../../styles/colors';

type AlertDialogProps = ComponentProps<typeof ChakraAlertDialog> & {
  leastDestructiveRef: MutableRefObject<null>;
  onConfirm?: () => void;
  cancelText?: string;
  confirmText?: string;
  dialogHeader?: React.ReactNode;
  dialogFooter?: React.ReactNode;
  isLoading?: boolean;
};

const Dialog = ({
  isOpen,
  cancelText,
  confirmText,
  onClose,
  onConfirm,
  leastDestructiveRef,
  dialogHeader,
  dialogFooter,
  children,
  isLoading,
  ...props
}: AlertDialogProps): JSX.Element => (
  <ChakraAlertDialog isOpen={isOpen} leastDestructiveRef={leastDestructiveRef} onClose={onClose} {...props}>
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogCloseButton />
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {dialogHeader ? dialogHeader : <></>}
        </AlertDialogHeader>
        <AlertDialogBody>{children}</AlertDialogBody>
        <AlertDialogFooter>
          {dialogFooter ? (
            dialogFooter
          ) : (
            <Flex pb={2}>
              <Button
                aria-label={'confirm-return'}
                minW="120px"
                variant="orangeSolid"
                color="black"
                margin="auto"
                title={confirmText}
                onClick={onConfirm}
                isLoading={isLoading}
                mr={3}
              >
                {confirmText}
              </Button>
              <Button
                minW="120px"
                variant="transparent"
                color={colors.orange[500]}
                margin="auto"
                ref={leastDestructiveRef}
                onClick={onClose}
                title={cancelText}
              >
                {cancelText}
              </Button>
            </Flex>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </ChakraAlertDialog>
);

export default Dialog;
