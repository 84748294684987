import React, { JSX } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { useTranslations } from '../../contexts/LocalizationContext';
import { CommunityStatisticsReponse } from './model/CommunityStatisticsReponse';
import RelevoCommunityCard from './RelevoCommunityCard';

const getIconPath = (iconName: string) => require(`../../assets/icons/${iconName}`);

const RelevoCommunity = (props: { statistics: CommunityStatisticsReponse | undefined }): JSX.Element => {
  const translations = useTranslations();

  return (
    <Flex width="100%" wrap="wrap">
      <Box width={['100%', '100%', '50%', '33%', '33%']} pr={[2, 2, 2, 2, 2]}>
        <RelevoCommunityCard
          imgSrc={getIconPath('all_locations.svg')}
          imgAlt="all locations"
          statsNumber={props.statistics?.totalLocations}
          statsDescription={translations('community_all_locations')}
          minWidth="300px"
        />
      </Box>
      <Box width={['100%', '100%', '50%', '33%', '33%']} pr={2} pt={[2, 2, 0, 0, 0]}>
        <RelevoCommunityCard
          imgSrc={getIconPath('total_saves.svg')}
          imgAlt="total saves"
          statsNumber={props.statistics?.totalSaves}
          statsDescription={translations('community_total_saves')}
          minWidth="300px"
        />
      </Box>
      <Box width={['100%', '100%', '50%', '33%', '33%']} pr={[2, 2, 2, 2, 2]} pt={[2, 2, 2, 0, 0]}>
        <RelevoCommunityCard
          imgSrc={getIconPath('total_users.svg')}
          imgAlt="total users"
          statsNumber={props.statistics?.totalUsers}
          statsDescription={translations('community_total_users')}
          minWidth="300px"
        />
      </Box>
    </Flex>
  );
};

export default RelevoCommunity;
