import React, { JSX, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Routes } from 'config/routes';
import { Role, useSessionContext } from 'contexts/SessionContext';

import SignInForm from './components/SignInForm';

const SignIn = (): JSX.Element => {
  const { doesSessionExist, message, role } = useSessionContext();
  const location = useLocation();
  const [from] = useState<string>(location.state?.nextPathname ?? Routes.HOME_PATH);

  if (!doesSessionExist) {
    return <SignInForm message={message} />;
  }
  return <Navigate to={{ pathname: Role.RETURN_STATION === role ? Routes.RETURN_STATION_PATH : from }} />;
};

export default SignIn;
