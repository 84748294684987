import React, { JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { AttachmentIcon } from '@chakra-ui/icons';
import { Box, Container, Flex, Text, useTheme } from '@chakra-ui/react';

import { FileInput } from '../../../../common/components/FileInput';
import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import { MainPageContent } from '../../../../common/components/MainPageContent';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import CustomerCreationFormHeading from '../../components/CustomerCreationFormHeading/CustomerCreationFormHeading';
import { CustomerCreationFormData } from '../../model/CustomerCreationFormData';
import { getRequiredValidationObject } from '../../validation/formValidation';

const ContractUpload = (): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const requiredValidation = getRequiredValidationObject(translations('new_customer_form_validation_required'));
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<CustomerCreationFormData>();

  const isFileUploaded = watch('contractFile')?.length > 0;

  return (
    <MainPageContent>
      <Container maxW={['80%', '60%', '52%']} pt={2} pb={12}>
        <Box pb={12}>
          <CustomerCreationFormHeading heading={translations('new_customer_form_upload_contract_header')} />
        </Box>
        <Box pt={12}>
          <FormGroup
            reverseLayout={true}
            isVertical={true}
            isCentred={true}
            label={translations('new_customer_form_upload_contract_information')}
            inputId="contractFile"
            validationError={errors.contractFile?.message}
          >
            <FileInput
              uploadButtonLabel={translations('new_customer_form_upload_button')}
              accept="application/pdf,image/*"
              register={register('contractFile', { required: requiredValidation })}
              aria-label="contractFile"
              backgroundColor={colors.orange[500]}
            />
          </FormGroup>

          <Flex alignItems="center" justifyContent="center" pt={6} width="100%">
            {isFileUploaded && <AttachmentIcon color={colors.green[500]} w={10} h={6} />}
            <Text height={4} color={colors.grey[200]} mb={2}>
              {isFileUploaded ? watch('contractFile')[0].name : ''}
            </Text>
          </Flex>
        </Box>
      </Container>
    </MainPageContent>
  );
};

export default ContractUpload;
