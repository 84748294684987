import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { FREQUENTLY_ASKED_QUESTIONS_QUERY_KEY } from '../api/apiConsts';
import { getFrequentlyAskedQuestions } from '../api/frequently-asked-questions';
import { FrequentlyAskedQuestionResponseDto } from '../modules/FrequentlyAskedQuestions/model/FrequentlyAskedQuestionResponse';

export const useFrequentlyAskedQuestions = (): QueryObserverResult<
  FrequentlyAskedQuestionResponseDto[],
  AxiosError
> => {
  return useQuery(FREQUENTLY_ASKED_QUESTIONS_QUERY_KEY, () => getFrequentlyAskedQuestions(), {
    refetchOnWindowFocus: false,
  });
};
