import { Box } from '@chakra-ui/react';

import { InventoryOverview } from './components/InventoryOverview/InventoryOverview';
import { YourBenefits } from './components/YourBenefits/YourBenefits';

export const InventoryCountsFirstStep = () => {
  return (
    <>
      <Box mt={[0, 0, 0, 24]} w={'100%'}>
        <YourBenefits />
      </Box>
      <InventoryOverview />
    </>
  );
};
