import { useMemo } from 'react';
import { QueryObserverResult, useMutation, UseMutationResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { addRestaurantImage, getRestaurantDetails, getRestaurantImages, getRestaurants } from '../api/restaurant';
import { HttpException } from '../common/models/httpException';
import { RestaurantInfo } from '../contexts/SessionContext/SessionContext';
import { RestaurantDetails, RestaurantImagesResponse } from '../modules/Restaurants/types';

export const CK_RESTAURANTS = 'restaurants';
export const CK_RESTAURANT_IMAGES = 'restaurantImages';

interface RestaurantsDataLists {
  restaurants: RestaurantInfo[];
  restaurantIds: string[];
  isTwoFactorReturnEnabled: boolean;
  isDepositEnabled: boolean;
  isInventoryCountInProgress: boolean;
  isQuickContactsEnabled?: boolean;
}

export const useRestaurantDetails = (restaurantId: string): QueryObserverResult<RestaurantDetails, AxiosError> => {
  return useQuery([CK_RESTAURANTS, restaurantId], () => getRestaurantDetails(restaurantId), {
    staleTime: Infinity,
  });
};

export const useRestaurantImages = (
  restaurantId: string,
): QueryObserverResult<RestaurantImagesResponse, AxiosError> => {
  return useQuery([CK_RESTAURANT_IMAGES, restaurantId], () => getRestaurantImages(restaurantId), {
    staleTime: Infinity,
  });
};

export const useRestaurants = (enabled = true): QueryObserverResult<RestaurantInfo[], AxiosError> => {
  return useQuery([CK_RESTAURANTS], () => getRestaurants(), {
    enabled,
    refetchOnWindowFocus: false,
  });
};

export const useRestaurantsData = (): RestaurantsDataLists => {
  const { data } = useRestaurants();
  return useMemo(() => {
    const restaurants = data ?? [];
    const isTwoFactorReturnEnabled = restaurants.some((restaurant) => restaurant.isTwoFactorReturnEnabled);
    const isDepositEnabled = restaurants.some((restaurant) => restaurant.isDepositEnabled);
    const restaurantIds = restaurants.map((restaurant) => restaurant.id);
    const isQuickContactsEnabled = restaurants.some((restaurant) => restaurant.isQuickContactFeatureEnabled);
    const isInventoryCountInProgress = restaurants.some((restaurant) => !!restaurant.inventoryCountInProgress);
    return {
      restaurants,
      restaurantIds,
      isTwoFactorReturnEnabled,
      isDepositEnabled,
      isQuickContactsEnabled,
      isInventoryCountInProgress,
    };
  }, [data]);
};

export interface PublicFile {
  id: string;
  url: string;
}

export const useAddRestaurantImage = (): UseMutationResult<
  PublicFile,
  AxiosError<HttpException>,
  { restaurantId: string; image: File }
> => {
  return useMutation(({ restaurantId, image }: { restaurantId: string; image: File }) =>
    addRestaurantImage(restaurantId, image),
  );
};
