import React, { JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Container } from '@chakra-ui/react';

import FormInput from '../../../../common/components/Input/FormInput';
import { MainPageContent } from '../../../../common/components/MainPageContent';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import CustomerCreationFormHeading from '../../components/CustomerCreationFormHeading/CustomerCreationFormHeading';
import { CustomerCreationFormData } from '../../model/CustomerCreationFormData';
import {
  getEmailValidationObject,
  getMaxLengthValidationObject,
  getRequiredValidationObject,
} from '../../validation/formValidation';

const ApplicantData = (): JSX.Element => {
  const translations = useTranslations();
  const requiredValidation = getRequiredValidationObject(translations('new_customer_form_validation_required'));
  const emailValidation = getEmailValidationObject(translations('new_customer_form_validation_email'));
  const maxLengthValidation = (maxLengthValue: number) =>
    getMaxLengthValidationObject(
      maxLengthValue,
      translations('new_customer_form_validation_too_long', {
        '{{maxLength}}': maxLengthValue.toString(),
      }),
    );

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<CustomerCreationFormData>();

  return (
    <MainPageContent>
      <Container maxW={['90%', '80%', '70%', '62%']} pt={2} pb={12}>
        <Box pb={12}>
          <CustomerCreationFormHeading heading={translations('new_customer_form_sales_person_header')} />
        </Box>
        <FormInput
          id="salesOrganisation"
          label={translations('new_customer_form_sales_organisation')}
          useFormRegisterReturn={register('salesOrganisation', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.salesOrganisation}
          autoComplete="on"
          validationError={errors?.salesOrganisation?.message}
          setValue={setValue}
        />
        <FormInput
          id="salesPersonName"
          label={translations('new_customer_form_sales_person_name')}
          useFormRegisterReturn={register('salesPersonName', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.salesPersonName}
          autoComplete="on"
          validationError={errors?.salesPersonName?.message}
          setValue={setValue}
        />
        <FormInput
          id="salesPersonEmail"
          label={translations('new_customer_form_sales_person_email')}
          useFormRegisterReturn={register('salesPersonEmail', {
            required: requiredValidation,
            pattern: emailValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.salesPersonEmail}
          autoComplete="on"
          validationError={errors?.salesPersonEmail?.message}
          setValue={setValue}
        />
        <FormInput
          id="salesPersonPhone"
          label={translations('new_customer_form_sales_person_phone')}
          useFormRegisterReturn={register('salesPersonPhone', {
            required: requiredValidation,
            maxLength: maxLengthValidation(20),
          })}
          isInvalid={!!errors.salesPersonPhone}
          autoComplete="on"
          validationError={errors?.salesPersonPhone?.message}
          setValue={setValue}
        />
      </Container>
    </MainPageContent>
  );
};

export default ApplicantData;
