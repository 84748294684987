import { useCallback, useMemo } from 'react';

import { Routes } from '../../../../../config/routes';
import { Role, useSessionContext } from '../../../../../contexts/SessionContext';
import { useQuickContactsManagementEnabled } from '../../../../../services/quick-contacts';
import { useRestaurantsData } from '../../../../../services/restaurants';
import { MenuItem } from '../model/MenuItem.model';
import MENU_ITEMS from './menuItemsConsts';

export const useMenuItems = () => {
  const { role } = useSessionContext();
  const { isDepositEnabled } = useRestaurantsData();
  const { data: isQuickContactsManagementEnabled, isLoading } = useQuickContactsManagementEnabled(
    role === Role.MANAGER,
  );

  const filterItems = useCallback(
    (items: MenuItem[]) => {
      return items
        .filter((item) => (item.roles ? item.roles.includes(role!) : true))
        .filter((item) => {
          if (item.route === Routes.DEPOSIT_PATH) {
            return isDepositEnabled;
          } else if (item.route === Routes.QUICK_CONTACTS_PATH) {
            return isLoading ? true : isQuickContactsManagementEnabled;
          } else {
            return true;
          }
        })
        .map((item) => {
          if (item.subSections) {
            item.subSections = filterItems(item.subSections);
            return item;
          }
          return item;
        });
    },
    [isDepositEnabled, isLoading, isQuickContactsManagementEnabled, role],
  );

  const menuItems = useMemo(() => filterItems(MENU_ITEMS), [filterItems]);

  return { menuItems };
};
