import React, { JSX } from 'react';
import { Center, HStack, VStack } from '@chakra-ui/react';

import TooltipHelp from '../../../common/components/TooltipHelp';
import colors from '../../../styles/colors';

const boldStyle = {
  fontSize: '14px',
  fontWeight: 600,
};

const defaultStyle = {
  fontSize: '12px',
  marginTop: '0px',
};

type CardItemStatisticsProps = {
  imgSrc: string;
  imgAlt: string;
  statsNumber: number | string | undefined;
  statsDescription: string;
  tooltip?: string;
};

const CardItemStatisticsCard = ({
  imgSrc,
  imgAlt,
  statsNumber,
  statsDescription,
  tooltip,
}: CardItemStatisticsProps): JSX.Element => {
  return (
    <Center p={2} width="25%" height="50px">
      <VStack width="100%">
        <Center height="30px">
          <img
            style={{
              width: 'auto',
              maxHeight: '100%',
              maxWidth: 'none',
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
            src={imgSrc}
            alt={imgAlt}
          />
        </Center>
        <HStack>
          <h1 style={boldStyle}>{statsNumber}</h1>
          {tooltip && <TooltipHelp label={tooltip} bgColor={colors.beige[50]} />}
        </HStack>
        <h2 style={defaultStyle}>{statsDescription}</h2>
      </VStack>
    </Center>
  );
};

export default CardItemStatisticsCard;
