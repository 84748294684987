import React, { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import { FullPageLoader } from '../../../../../../common/components/Loader/FullPageLoader';
import { TableType } from '../../../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../../../common/components/Table/Table';
import { TableItemWithImage } from '../../../../../../common/components/TableItemWithImage';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { ReturnUnknownRelevosInformationRequest } from '../../../../model/return-unknown-relevos-request';
import { UnknownRelevosListResponse } from '../../../../model/unknown-relevos-list-response';
import { CategoryQuantityUnknownItem } from '../CategoryQuantityUnknownItem/CategoryQuantityUnknownItem';

type QuickContactsReturnItemsTableProps = {
  itemsToReturn: ReturnUnknownRelevosInformationRequest[];
  onUpdateItemsQuantity: (categoryId: string, quantity: number, userId: string) => void;
  isLoadingItems: boolean;
  items?: UnknownRelevosListResponse[];
};

export const QuickContactsReturnItemsTable = ({
  items,
  isLoadingItems,
  itemsToReturn,
  onUpdateItemsQuantity,
}: QuickContactsReturnItemsTableProps) => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();

  const columns = useMemo<ColumnDef<UnknownRelevosListResponse>[]>(
    () => [
      {
        id: 'category',
        header: translations('quick_contacts_return_list_category_header'),
        cell: ({ row }) => (
          <TableItemWithImage
            text={row.original.category.translations[language]}
            imageUrl={row.original.category.imageUrl}
          />
        ),
      },
      {
        id: 'user',
        header: translations('quick_contacts_return_list_user_header'),
        cell: ({ row }) => <>{row.original.user.firstName}</>,
      },
      {
        id: 'quantity',
        header: translations('quick_contacts_return_list_quantity_header'),
        cell: ({ row }) => (
          <Box w={'150px'}>
            <CategoryQuantityUnknownItem
              category={row.original.category}
              onUpdateItemsQuantity={onUpdateItemsQuantity}
              categoryQuantity={
                itemsToReturn.find(
                  (itemToReturn) =>
                    itemToReturn.userId === row.original.user.id &&
                    itemToReturn.categoryId === row.original.category.id,
                )?.quantity || 0
              }
              maxValue={row.original.borrowingsCount || 0}
              userId={row.original.user.id}
            />
          </Box>
        ),
      },
      { accessorKey: 'borrowingsCount', header: translations('quick_contacts_return_list_borrowed_header') },
    ],
    [itemsToReturn, language, onUpdateItemsQuantity, translations],
  );

  return (
    <>
      <Text py={4} textAlign={'left'} fontWeight={'bold'}>
        {translations('quick_contacts_return_list_table_header')}
      </Text>
      <FullPageLoader show={isLoadingItems}>
        <Table
          type={TableType.SIMPLE}
          data={items || []}
          columns={columns}
          variant={'quickContactsReturnItems'}
          withBorder
        />
      </FullPageLoader>
    </>
  );
};
