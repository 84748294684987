import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Text, VStack } from '@chakra-ui/react';

import Dialog from '../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../contexts/ScannedRelevosContext';
import { useReportDamagedItem } from '../../services/damaged-items';
import colors from '../../styles/colors';
import RelevosList from '../RelevoAssignment/components/SumUp/RelevosList';

const acceptButtonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: colors.orange[500] },
  _hover: { backgroundColor: colors.orange[500] },
  _active: { backgroundColor: colors.orange[500] },
};

type DamagedItemsSumUpProps = {
  acceptedImg: string | null;
  resetState: () => void;
};

const DamagedItemsSumUp = ({ acceptedImg, resetState }: DamagedItemsSumUpProps): JSX.Element => {
  const translations = useTranslations();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cancelRef = useRef(null);
  const { scannedRelevos, setScannedRelevos } = useScannedRelevosContext();
  const { mutate: reportDamagedItem, isLoading: isLoadingReportDamagedItems } = useReportDamagedItem();

  const getImgBlob = async (imageSrc: string) => (await fetch(imageSrc)).blob();

  const handleCloseModal = () => setIsModalOpen(false);

  const removeRelevo = (relevo: string) => {
    setScannedRelevos((prevState) => prevState.filter((el) => el.uId !== relevo));
    resetState();
  };

  const handleReportDamagedItems = async () => {
    if (acceptedImg) {
      const blob = await getImgBlob(acceptedImg);
      reportDamagedItem(
        { values: { file: new File([blob], 'damaged-item.jpg'), uId: scannedRelevos[0].uId } },
        {
          onSuccess: () => {
            setIsModalOpen(true);
            resetState();
            setScannedRelevos([]);
          },
          onError: () => {
            toast.error(<b>{translations('something_went_wrong')}</b>);
          },
        },
      );
    }
  };

  return (
    <>
      <Box width="100%" pl={2} textAlign="left">
        {scannedRelevos.length ? (
          <>
            <Text fontSize="20px">{translations('report_damaged_items_sum_up_header')}</Text>
            <RelevosList onRemoveItem={removeRelevo} />
            <Box pt={4}>
              <Button
                isLoading={isLoadingReportDamagedItems}
                {...acceptButtonDefaultProps}
                fontWeight="normal"
                colorScheme="orange"
                minWidth={144}
                margin="auto"
                color={colors.black}
                onClick={handleReportDamagedItems}
              >
                {translations('report_damaged_items_report_claims_button')}
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
      <Dialog
        leastDestructiveRef={cancelRef}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        dialogFooter={
          <Box textAlign="right">
            <Button
              {...acceptButtonDefaultProps}
              color={colors.black}
              fontWeight="normal"
              colorScheme="orange"
              margin="auto"
              title={translations('report_damaged_items_ok_button')}
              ml={3}
              onClick={handleCloseModal}
            >
              {translations('report_damaged_items_ok_button')}
            </Button>
          </Box>
        }
      >
        <VStack>
          <Text>{translations('report_damaged_items_success_message')}</Text>
          <Text>
            <b>{translations('report_damaged_items_success_message_2')}</b>
          </Text>
          <Text>
            <b>{translations('report_damaged_items_success_message_3')}</b>
          </Text>
        </VStack>
      </Dialog>
    </>
  );
};

export default DamagedItemsSumUp;
