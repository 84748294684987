import { MenuItem } from '../model/MenuItem.model';

const SVG_EXTENSION = '.svg';
const ACTIVE_ICON_SVG_FILENAME_SUFFIX = '_active.svg';

export const getIconPath = (iconName: string) => require(`../../../../../assets/icons/${iconName}`);

export const isMenuButtonActive = (item: MenuItem, pathname: string) =>
  item.subSections?.some(({ route }) => route === pathname);

export const getSvgImgSrc = (item: MenuItem, pathname: string) =>
  isMenuButtonActive(item, pathname)
    ? getIconPath(item.iconName.replace(SVG_EXTENSION, ACTIVE_ICON_SVG_FILENAME_SUFFIX))
    : getIconPath(item.iconName);

export const getLinkButtonSvgImgSrc = (item: MenuItem, pathname: string) =>
  pathname === item.route
    ? getIconPath(item.iconName.replace(SVG_EXTENSION, ACTIVE_ICON_SVG_FILENAME_SUFFIX))
    : getIconPath(item.iconName);
