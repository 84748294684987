import { createContext, ReactNode, useMemo, useState } from 'react';

import { buildNotificationSettingsTableData } from '../components/NotificationSettingsForm/utils/notification-settings-form.helper';
import { NotificationSettingsTableData } from '../components/NotificationSettingsTable/model/notification-settings-table-data';
import { NotificationSettingsResponse } from '../model/notification-settings-response';
import { NotificationSettingsContextInterface } from './model/NotificationSettingsContext';

type EditModeContextProps = {
  notificationSettings: NotificationSettingsResponse;
  children: ReactNode;
};

export const NotificationSettingsContext = createContext<NotificationSettingsContextInterface | null>(null);

export const NotificationSettingsContextProvider = ({ notificationSettings, children }: EditModeContextProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const notificationSettingsTableData: NotificationSettingsTableData = useMemo(() => {
    return buildNotificationSettingsTableData(notificationSettings);
  }, [notificationSettings]);

  return (
    <NotificationSettingsContext.Provider
      value={{ isEditMode, handleSwitchEditMode: setIsEditMode, notificationSettingsTableData }}
    >
      {children}
    </NotificationSettingsContext.Provider>
  );
};
