import MainPageHeading from '../../../../common/components/MainPageHeading/MainPageHeading';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useInventoryCountsContext } from '../../context/hooks/use-inventory-counts-context.hook';

export const InventoryCountsHeader = () => {
  const translations = useTranslations();
  const { activeStep, restaurantName } = useInventoryCountsContext();

  switch (activeStep) {
    case 0:
      return <MainPageHeading>{translations('inventory_counts_heading')}</MainPageHeading>;
    case 1:
      return (
        <MainPageHeading>
          {translations('inventory_counts_scan_items_heading', {
            '{{restaurantName}}': restaurantName || '',
          })}
        </MainPageHeading>
      );
    case 2:
      return (
        <MainPageHeading>
          {translations('inventory_counts_check_count_heading', {
            '{{restaurantName}}': restaurantName || '',
          })}
        </MainPageHeading>
      );
    case 3:
      return (
        <MainPageHeading>
          {translations('inventory_counts_submit_count_heading', {
            '{{restaurantName}}': restaurantName || '',
          })}
        </MainPageHeading>
      );
    default:
      return <></>;
  }
};
