import React, { JSX } from 'react';
import { Box, Center, HStack, VStack } from '@chakra-ui/react';

import TooltipHelp from '../../common/components/TooltipHelp';
import colors from '../../styles/colors';

const boldStyle = {
  fontSize: '32px',
  fontWeight: 600,
};

const defaultStyle = {
  fontSize: '14px',
  color: colors.grey[200],
};

const RelevoCommunityCard = (props: {
  imgSrc: string;
  imgAlt: string;
  statsNumber: number | string | undefined;
  statsDescription: string;
  minWidth: string;
  tooltip?: string;
}): JSX.Element => {
  return (
    <Center
      p={4}
      borderWidth="2px"
      borderRadius="10px"
      borderColor={colors.beige[50]}
      width="stretch"
      minWidth={props.minWidth}
      height="125px"
    >
      <HStack width="100%" height="100%">
        <Box width="100%" height="100%">
          <img
            style={{
              width: 'auto',
              maxHeight: '100%',
              maxWidth: 'none',
            }}
            src={props.imgSrc}
            alt={props.imgAlt}
          />
        </Box>
        <VStack width="100%" align="end" textAlign="right" alignItems="flex-end">
          {props.tooltip && <TooltipHelp label={props.tooltip} bgColor={colors.beige[50]} />}
          <h1 style={boldStyle}>{props.statsNumber}</h1>
          <h2 style={defaultStyle}>{props.statsDescription}</h2>
        </VStack>
      </HStack>
    </Center>
  );
};

export default RelevoCommunityCard;
