import React, { JSX } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';

import { LocationsTimeFrameEnum } from '../../api/community-statistics';
import { LocationStatisticsResponse } from './model/LocationStatisticsResponse';
import RestaurantCard from './RestaurantCard';

type RestaurantsStatisticsProps = {
  restaurants: LocationStatisticsResponse[];
  timeFrameOption: LocationsTimeFrameEnum;
};

const RestaurantsStatistics = ({ restaurants, timeFrameOption }: RestaurantsStatisticsProps): JSX.Element => {
  const secondColumnStart = Math.floor(restaurants.length / 2);

  return (
    <Center>
      <Flex px={4} height="100%" width="100%" wrap="wrap">
        <Box width={['100%', '100%', '100%', '100%', '50%']}>
          {restaurants.slice(secondColumnStart).map((value) => (
            <RestaurantCard key={value.id} locationStatistics={value} timeframeOption={timeFrameOption} />
          ))}
        </Box>
        <Box width={['100%', '100%', '100%', '100%', '50%']}>
          {restaurants.slice(0, secondColumnStart).map((value) => (
            <RestaurantCard key={value.id} locationStatistics={value} timeframeOption={timeFrameOption} />
          ))}
        </Box>
      </Flex>
    </Center>
  );
};

export default RestaurantsStatistics;
