import React, { JSX } from 'react';
import ReactDatepicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { Box, Container, Flex, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import de from 'date-fns/locale/de';

import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import FormIndentedInput from '../../../../common/components/Input/FormIndentedInput';
import FormInput from '../../../../common/components/Input/FormInput';
import { MainPageContent } from '../../../../common/components/MainPageContent';
import FormTextarea from '../../../../common/components/Textarea/Textarea';
import config from '../../../../config/config';
import { LOCALIZATION_CONFIG } from '../../../../config/configConsts';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import colors from '../../../../styles/colors';
import CustomerCreationFormHeading from '../../components/CustomerCreationFormHeading/CustomerCreationFormHeading';
import { CustomerCreationFormData, SepaBilling, SocialMediaPress } from '../../model/CustomerCreationFormData';
import { starterSetSizeSelectOptions } from '../../model/CustomerCreationRequest';
import {
  getEmailValidationObject,
  getMaxLengthValidationObject,
  getRequiredValidationObject,
} from '../../validation/formValidation';
import { datePickerDefaultStyle, selectCustomStyle } from './styles';

const CustomerData = (): JSX.Element => {
  const translations = useTranslations();
  const requiredValidation = getRequiredValidationObject(translations('new_customer_form_validation_required'));
  const emailValidation = getEmailValidationObject(translations('new_customer_form_validation_email'));
  const maxLengthValidation = (maxLengthValue: number) =>
    getMaxLengthValidationObject(
      maxLengthValue,
      translations('new_customer_form_validation_too_long', {
        '{{maxLength}}': maxLengthValue.toString(),
      }),
    );

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CustomerCreationFormData>();
  return (
    <MainPageContent>
      <Container maxW={['90%', '80%', '70%', '62%']} pt={2} pb={2}>
        <Box pb={4}>
          <CustomerCreationFormHeading heading={translations('new_customer_form_customer_header')} />
        </Box>
        <FormInput
          id="customerName"
          label={translations('new_customer_form_customer_name')}
          isInvalid={!!errors.customerName}
          useFormRegisterReturn={register('customerName', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          autoComplete="off"
          validationError={errors?.customerName?.message}
          setValue={setValue}
        />
        <FormInput
          id="wholesaleCustomerId"
          label={translations('new_customer_form_customer_id')}
          useFormRegisterReturn={register('wholesaleCustomerId', { maxLength: maxLengthValidation(50) })}
          isInvalid={!!errors.wholesaleCustomerId}
          autoComplete="off"
          setValue={setValue}
        />
        <Flex pb={2}>
          <Text fontWeight="bold">{translations('new_customer_form_customer_contact')}</Text>
        </Flex>
        <FormIndentedInput
          id="contactPersonFirstName"
          label={translations('new_customer_form_customer_first_name')}
          useFormRegisterReturn={register('contactPersonFirstName', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.contactPersonFirstName}
          autoComplete="off"
          validationError={errors?.contactPersonFirstName?.message}
          setValue={setValue}
        />
        <FormIndentedInput
          id="contactPersonLastName"
          label={translations('new_customer_form_customer_last_name')}
          useFormRegisterReturn={register('contactPersonLastName', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.contactPersonLastName}
          autoComplete="off"
          validationError={errors?.contactPersonLastName?.message}
          setValue={setValue}
        />
        <FormIndentedInput
          id="contactPersonEmail"
          label={translations('new_customer_form_customer_email')}
          useFormRegisterReturn={register('contactPersonEmail', {
            required: requiredValidation,
            pattern: emailValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.contactPersonEmail}
          autoComplete="off"
          validationError={errors?.contactPersonEmail?.message}
          setValue={setValue}
        />
        <FormIndentedInput
          id="contactPersonPhone"
          label={translations('new_customer_form_customer_phone')}
          useFormRegisterReturn={register('contactPersonPhone', {
            required: requiredValidation,
            maxLength: maxLengthValidation(20),
          })}
          isInvalid={!!errors.contactPersonPhone}
          autoComplete="off"
          validationError={errors?.contactPersonPhone?.message}
          setValue={setValue}
        />
        <Flex pb={2}>
          <Text fontWeight="bold">{translations('new_customer_form_customer_billing_address')}</Text>
        </Flex>
        <FormIndentedInput
          id="billingAddressStreetNumber"
          label={translations('new_customer_form_customer_street_number')}
          useFormRegisterReturn={register('billingAddressStreetNumber', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.billingAddressStreetNumber}
          autoComplete="off"
          validationError={errors?.billingAddressStreetNumber?.message}
          setValue={setValue}
        />
        <FormIndentedInput
          id="billingAddressAddition"
          label={translations('new_customer_form_customer_additional')}
          useFormRegisterReturn={register('billingAddressAddition', { maxLength: maxLengthValidation(100) })}
          isInvalid={!!errors.billingAddressAddition}
          autoComplete="off"
          setValue={setValue}
        />
        <FormIndentedInput
          id="billingAddressZipCode"
          label={translations('new_customer_form_customer_zip_code')}
          useFormRegisterReturn={register('billingAddressZipCode', {
            required: requiredValidation,
            maxLength: maxLengthValidation(50),
          })}
          isInvalid={!!errors.billingAddressZipCode}
          autoComplete="off"
          validationError={errors?.billingAddressZipCode?.message}
          setValue={setValue}
        />
        <FormIndentedInput
          id="billingAddressCity"
          label={translations('new_customer_form_customer_city')}
          useFormRegisterReturn={register('billingAddressCity', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.billingAddressCity}
          autoComplete="off"
          validationError={errors?.billingAddressCity?.message}
          setValue={setValue}
        />
        <FormIndentedInput
          id="billingAddressCountry"
          label={translations('new_customer_form_customer_country')}
          useFormRegisterReturn={register('billingAddressCountry', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.billingAddressCountry}
          autoComplete="off"
          validationError={errors?.billingAddressCountry?.message}
          setValue={setValue}
        />
        <FormIndentedInput
          id="billingPersonTitle"
          label={translations('new_customer_form_customer_billing_person_title')}
          useFormRegisterReturn={register('billingPersonTitle', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.billingPersonTitle}
          autoComplete="off"
          validationError={errors?.billingPersonTitle?.message}
          setValue={setValue}
        />
        <FormIndentedInput
          id="billingName"
          label={translations('new_customer_form_customer_billing_name')}
          useFormRegisterReturn={register('billingName', {
            required: requiredValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.billingName}
          autoComplete="off"
          validationError={errors?.billingName?.message}
          setValue={setValue}
        />
        <FormIndentedInput
          id="billingEmail"
          label={translations('new_customer_form_customer_billing_email')}
          useFormRegisterReturn={register('billingEmail', {
            required: requiredValidation,
            pattern: emailValidation,
            maxLength: maxLengthValidation(100),
          })}
          isInvalid={!!errors.billingEmail}
          autoComplete="off"
          validationError={errors?.billingEmail?.message}
          setValue={setValue}
        />
        <FormGroup
          label={translations('new_customer_form_customer_sepa')}
          inputId="sepa"
          isIndented={true}
          validationError={errors?.sepa?.message}
        >
          <Controller
            control={control}
            defaultValue={SepaBilling.SEPA_DESIRED}
            name="sepa"
            render={({ field: { value, onChange } }) => (
              <RadioGroup onChange={onChange} value={value}>
                <Stack direction="row">
                  <Radio colorScheme="orange" borderColor={colors.grey[50]} value={SepaBilling.SEPA_DESIRED}>
                    {translations('yes')}
                  </Radio>
                  <Radio colorScheme="orange" borderColor={colors.grey[50]} value={SepaBilling.SEPA_NOT_DESIRED}>
                    {translations('no')}
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormGroup>
        <FormGroup
          label={translations('new_customer_form_customer_desired_start_date')}
          inputId="desiredStartDate"
          isBold={true}
          validationError={errors?.desiredStartDate?.message}
        >
          <Controller
            name={'desiredStartDate'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <ReactDatepicker
                locale={de}
                selected={value}
                customInput={
                  <Input
                    style={datePickerDefaultStyle}
                    color="black"
                    _focus={{ boxShadow: 'none' }}
                    {...register('desiredStartDate', { required: requiredValidation })}
                  />
                }
                dateFormat={LOCALIZATION_CONFIG[config.language].dateFormat}
                onChange={onChange}
                isClearable
              />
            )}
          />
        </FormGroup>
        <FormGroup
          label={translations('new_customer_form_social_media')}
          inputId="socialMediaPress"
          isBold={true}
          validationError={errors?.socialMediaPress?.message}
        >
          <Controller
            control={control}
            defaultValue={SocialMediaPress.SOCIAL_MEDIA_PRESS_PERMITTED}
            name="socialMediaPress"
            render={({ field: { value, onChange } }) => (
              <RadioGroup onChange={onChange} value={value}>
                <Stack direction="row">
                  <Radio
                    colorScheme="orange"
                    borderColor={colors.grey[50]}
                    value={SocialMediaPress.SOCIAL_MEDIA_PRESS_PERMITTED}
                  >
                    {translations('new_customer_form_yes_please')}
                  </Radio>
                  <Radio
                    colorScheme="orange"
                    borderColor={colors.grey[50]}
                    value={SocialMediaPress.SOCIAL_MEDIA_PRESS_NOT_PERMITTED}
                  >
                    {translations('new_customer_form_no_thank_you')}
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormGroup>
        {/* FYI: RELEVO-758 multiple starter sets has not been done yet*/}
        {/* <Flex py={2}>*/}
        {/*  <Text fontWeight="bold">{translations('new_customer_form_startersets')}</Text>*/}
        {/* </Flex>*/}
        {/* <FormGroup*/}
        {/*  label={translations('new_customer_form_starterset_s')}*/}
        {/*  inputId="sStartersetNumber"*/}
        {/*  isVertical={false}*/}
        {/*  isIndented={true}*/}
        {/*  validationError={errors?.sStartersetNumber?.message}*/}
        {/* >*/}
        {/*  <NumberInput*/}
        {/*    name="sStartersetNumber"*/}
        {/*    register={register('sStartersetNumber', {*/}
        {/*      required: requiredValidation,*/}
        {/*    })}*/}
        {/*    isInvalid={!!errors.sStartersetNumber}*/}
        {/*    min={0}*/}
        {/*  />*/}
        {/* </FormGroup>*/}
        {/* <FormGroup*/}
        {/*  label={translations('new_customer_form_starterset_m')}*/}
        {/*  inputId="mStartersetNumber"*/}
        {/*  isVertical={false}*/}
        {/*  isIndented={true}*/}
        {/*  validationError={errors?.mStartersetNumber?.message}*/}
        {/* >*/}
        {/*  <NumberInput*/}
        {/*    name="mStartersetNumber"*/}
        {/*    register={register('mStartersetNumber', {*/}
        {/*      required: requiredValidation,*/}
        {/*    })}*/}
        {/*    isInvalid={!!errors.mStartersetNumber}*/}
        {/*    min={0}*/}
        {/*  />*/}
        {/* </FormGroup>*/}
        {/* <FormGroup*/}
        {/*  label={translations('new_customer_form_starterset_l')}*/}
        {/*  inputId="lStartersetNumber"*/}
        {/*  isVertical={false}*/}
        {/*  isIndented={true}*/}
        {/*  validationError={errors?.lStartersetNumber?.message}*/}
        {/* >*/}
        {/*  <NumberInput*/}
        {/*    name="lStartersetNumber"*/}
        {/*    register={register('lStartersetNumber', {*/}
        {/*      required: requiredValidation,*/}
        {/*    })}*/}
        {/*    isInvalid={!!errors.lStartersetNumber}*/}
        {/*    min={0}*/}
        {/*  />*/}
        {/* </FormGroup>*/}
        {/* <FormGroup*/}
        {/*  label={translations('new_customer_form_starterset_xl')}*/}
        {/*  inputId="xlStartersetNumber"*/}
        {/*  isVertical={false}*/}
        {/*  isIndented={true}*/}
        {/*  validationError={errors?.xlStartersetNumber?.message}*/}
        {/* >*/}
        {/*  <NumberInput*/}
        {/*    name="xlStartersetNumber"*/}
        {/*    register={register('xlStartersetNumber', {*/}
        {/*      required: requiredValidation,*/}
        {/*    })}*/}
        {/*    isInvalid={!!errors.xlStartersetNumber}*/}
        {/*    min={0}*/}
        {/*  />*/}
        {/* </FormGroup>*/}
        {/* <FormGroup*/}
        {/*  label={translations('new_customer_form_starterset_xxl')}*/}
        {/*  inputId="xxlStartersetNumber"*/}
        {/*  isVertical={false}*/}
        {/*  isIndented={true}*/}
        {/*  validationError={errors?.xxlStartersetNumber?.message}*/}
        {/* >*/}
        {/*  <NumberInput*/}
        {/*    name="xxlStartersetNumber"*/}
        {/*    register={register('xxlStartersetNumber', {*/}
        {/*      required: requiredValidation,*/}
        {/*    })}*/}
        {/*    isInvalid={!!errors.xxlStartersetNumber}*/}
        {/*    min={0}*/}
        {/*  />*/}
        {/* </FormGroup>*/}
        <FormGroup label={translations('new_customer_form_starterset')} inputId={'starterSet'} isBold={true}>
          <Controller
            control={control}
            name="starterSet"
            render={({ field: { value, onChange } }) => (
              <Select
                styles={selectCustomStyle}
                value={value}
                defaultValue={starterSetSizeSelectOptions[0]}
                onChange={onChange}
                options={starterSetSizeSelectOptions}
              />
            )}
          />
        </FormGroup>
        <FormGroup
          label={translations('new_customer_form_customer_other_requests')}
          inputId="specialRequests"
          isBold={true}
        >
          <FormTextarea
            id="specialRequests"
            useFormRegisterReturn={register('specialRequests', { maxLength: maxLengthValidation(500) })}
            isInvalid={!!errors.specialRequests}
            autoComplete="off"
          />
        </FormGroup>
      </Container>
    </MainPageContent>
  );
};

export default CustomerData;
