import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import { Center, Flex } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useTranslations } from 'contexts/LocalizationContext';
import { useScannedRelevosContext } from 'contexts/ScannedRelevosContext';
import { StatusCodes } from 'http-status-codes';

import { getApplicationUsers } from '../../../../api/applicationUser';
import QrCodeScanner from '../../../../common/components/QrCodeScanner/QrCodeScanner';
import { RestaurantSelect } from '../../../../common/components/RestaurantSelect/RestaurantSelect';
import { SelectOption } from '../../../../common/components/Select/model/selectOption';
import { useRestaurantsData } from '../../../../services/restaurants';
import { QuickContactType } from '../../../QuickContacts/model/enums/quick-contact-type.enum';
import { QuickContactResponse } from '../../../QuickContacts/model/quick-contact-response';
import RelevoScanner from '../../common/components/RelevoScanner';
import { getAssignSelectStyle } from '../../styles/relevo-assign-select-style';
import { QuickContactSelect } from '../QuickContactSelect/QuickContactSelect';
import SelectCategories from '../SelectCategories';
import Autocomplete from './Autocomplete';
import { ItemAssignmentSelectMode, UserSelectMode } from './types';

const UserSelect = ({ mode }: { mode: UserSelectMode }) => {
  const translations = useTranslations();
  const {
    selectItemMode: scanMode,
    setSelectItemMode,
    applicationUser,
    setApplicationUser,
    quickContact,
    setQuickContact,
    restaurantId,
    setRestaurantId,
    resetRestaurantId,
  } = useScannedRelevosContext();
  const { uId } = useParams<{ uId?: string }>();
  const [code, setCode] = useState('');
  const { restaurants, isQuickContactsEnabled } = useRestaurantsData();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const isClientSelected = useMemo(() => applicationUser || quickContact, [applicationUser, quickContact]);

  const showError = useCallback((error: AxiosError) => {
    if (error.response) {
      toast.error(
        <b>
          {translations(error.response.status === StatusCodes.NOT_FOUND ? 'client_not_found' : 'something_went_wrong')}
        </b>,
      );
    }
    // FYI: translations in deps cause endless re-rendering
    // eslint-disable-next-line
  }, []);

  const handleScan = useCallback(
    async (uniqueId: string | null) => {
      let isUserScanned = false;
      if (uniqueId && !isUserScanned && !isClientSelected) {
        getApplicationUsers(uniqueId)
          .then((applicationUsers) => {
            isUserScanned = true;
            setCode('');
            if (applicationUsers.length === 1) {
              setApplicationUser(applicationUsers[0]);
            }
          })
          .catch((error: AxiosError) => {
            showError(error);
          });
      }
    },
    [isClientSelected, setApplicationUser, showError],
  );

  useEffect(() => {
    if (uId) {
      handleScan(uId);
    }
  }, [handleScan, uId]);

  useEffect(() => {
    if (code) {
      handleScan(code);
    }
  }, [handleScan, code]);

  const handleQuickContactChange = (option: SingleValue<QuickContactResponse>) => {
    if (option) {
      if (option.type === QuickContactType.RESTAURANT) {
        setSelectItemMode(ItemAssignmentSelectMode.SCAN_ITEM);
      }
      setQuickContact(option);
    }
  };

  return (
    <Flex w={'100%'}>
      <Flex py={0} wrap="wrap">
        {!isClientSelected ? (
          <Center
            m={'auto'}
            p={2}
            display={!isClientSelected || scanMode === ItemAssignmentSelectMode.SCAN_ITEM ? undefined : 'none'}
          >
            <QrCodeScanner onScan={setCode} />
          </Center>
        ) : null}

        {!isClientSelected || !restaurantId ? (
          <Center maxWidth={'305px'} minWidth={'270px'} m={'auto'} p={2}>
            <form onSubmit={handleSubmit}>
              <RestaurantSelect
                userRestaurants={restaurants}
                restaurantId={restaurantId}
                setRestaurantId={setRestaurantId}
                resetRestaurantId={resetRestaurantId}
                styles={getAssignSelectStyle<SelectOption>()}
              />
              {isQuickContactsEnabled ? (
                <QuickContactSelect
                  quickContact={quickContact}
                  restaurantId={restaurantId || undefined}
                  onQuickContactChange={handleQuickContactChange}
                />
              ) : null}
              <Autocomplete placeholder={translations('client_id_input_label')} mode={mode} />
            </form>
          </Center>
        ) : null}
      </Flex>

      {applicationUser && restaurantId && UserSelectMode.ASSIGNMENT === mode ? (
        <Flex w={'100%'}>
          {scanMode === ItemAssignmentSelectMode.SCAN_ITEM ? <RelevoScanner /> : <SelectCategories />}
        </Flex>
      ) : null}

      {quickContact && restaurantId && UserSelectMode.ASSIGNMENT === mode ? (
        <Flex w={'100%'}>
          {scanMode === ItemAssignmentSelectMode.SCAN_ITEM || quickContact.type === QuickContactType.RESTAURANT ? (
            <RelevoScanner />
          ) : (
            <SelectCategories />
          )}
        </Flex>
      ) : null}
    </Flex>
  );
};

export default UserSelect;
