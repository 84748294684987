import React from 'react';
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { flexRender, HeaderGroup, RowModel } from '@tanstack/react-table';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { IdRow } from './DndTable';
import { DraggableRow } from './DraggableRow';
import { RelevoTableContainer } from './TableHelper';

type DraggableTableProps<T> = {
  headerGroups: HeaderGroup<T>[];
  rowModel: RowModel<T>;
  data: T[];
  dataIds: UniqueIdentifier[];
  variant?: string;
};

export const DraggableTable = <T extends IdRow>({
  headerGroups,
  rowModel,
  data,
  dataIds,
  variant,
}: DraggableTableProps<T>) => {
  const translations = useTranslations();
  return (
    <Box p={2}>
      <RelevoTableContainer maxHeight={['67vh', '67vh', '67vh', '73vh', '71vh']} overflowY="auto" border={'1px solid'}>
        <Table variant={variant} overflowX="auto">
          <Thead position="sticky" top={0} zIndex={2}>
            <>
              {headerGroups.map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  <>
                    {headerGroup.headers.map((header) => (
                      <Th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Th>
                    ))}
                  </>
                </Tr>
              ))}
            </>
          </Thead>
          <Tbody>
            {data.length ? (
              <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
                {rowModel.rows.map((row) => (
                  <DraggableRow key={row.id} row={row} />
                ))}
              </SortableContext>
            ) : (
              <Tr>
                <Td colSpan={5} p={5}>
                  <Text textAlign="center">{translations('no_data')}</Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </RelevoTableContainer>
    </Box>
  );
};
