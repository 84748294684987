import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';

export enum Language {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
}

export interface TranslationKeys {
  [key: string]: string;
}

export type LanguagesConfig = {
  [key in Language]: string;
};

export interface LocalizationContextInterface {
  language: Language;
  translations: TranslationKeys;
  setLanguage: { (language: Language): void };
  isFetchingRestaurantUser: boolean;
}

export interface SetLanguage {
  (translations: TranslationKeys): void;
}

export const localeMapper: Record<Language, Locale> = {
  [Language.EN]: enUS,
  [Language.DE]: de,
  [Language.FR]: fr,
};
