import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Routes } from '../../../../config/routes';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { Role, useSessionContext } from '../../../../contexts/SessionContext';
import { useRestaurantsData } from '../../../../services/restaurants';

export const useInventoryCountsBanner = () => {
  const translations = useTranslations();
  const { role } = useSessionContext();
  const { isInventoryCountInProgress } = useRestaurantsData();
  const location = useLocation();

  const isValidRoute = useMemo(() => {
    switch (location.pathname) {
      case Routes.INVENTORY_COUNTS_PATH:
        return false;
      case Routes.SIGNIN_PATH:
        return false;
      default:
        return true;
    }
  }, [location.pathname]);

  const bannerText = useMemo(() => translations('inventory_counts_in_progress_banner_text'), [translations]);

  return { showBanner: isValidRoute && isInventoryCountInProgress && bannerText && role === Role.MANAGER, bannerText };
};
