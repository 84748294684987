import { useState } from 'react';

interface Props {
  itemsIds: string[];
  onDragComplete: (newOrder: string[] | null) => void;
}

export const useDragAndDrop = ({ itemsIds, onDragComplete }: Props) => {
  const [activeElement, setActiveElement] = useState<string | null>(null);
  const [predictedOrder, setPredictedOrder] = useState<string[] | null>(null);
  const onDragStart = (itemId: string) => {
    setActiveElement(itemId);
  };
  const onDrop = () => {
    onDragComplete(predictedOrder);
  };
  const onDragEnd = () => {
    setActiveElement(null);
    setPredictedOrder(null);
  };

  const onDragOver = (itemId: string, event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (activeElement) {
      const activeElementIndex = itemsIds.findIndex((id) => id === activeElement);
      const itemIdIndex = itemsIds.findIndex((id) => id === itemId);
      const newPredictedOrder = [...itemsIds];
      newPredictedOrder.splice(activeElementIndex, 1);
      newPredictedOrder.splice(itemIdIndex, 0, itemsIds[activeElementIndex]);

      if (JSON.stringify(predictedOrder) === JSON.stringify(newPredictedOrder)) {
        return;
      }
      setPredictedOrder(newPredictedOrder);
    }
  };

  return {
    activeElement,
    predictedOrder,
    onDragStart,
    onDrop,
    onDragEnd,
    onDragOver,
    isActive: !!activeElement,
  };
};
