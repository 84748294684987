import React, { useRef } from 'react';

import { ConfirmDialogBody } from '../../../../../common/components/ConfirmationDialog/ConfirmDialogBody';
import Dialog from '../../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../../contexts/ScannedRelevosContext';
import { ScannerMode } from '../../../../RelevoAssignment/model/enums/scanner-mode.enum';
import { DepositRelevoTableMode } from '../../../model/enums/deposit-relevo-table-mode.enum';
import { DepositRelevoTable } from '../DepositRelevoTable/DepositRelevoTable';

type BorrowDepositConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoadingConfirm: boolean;
};

export const DepositConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoadingConfirm,
}: BorrowDepositConfirmModalProps) => {
  const translations = useTranslations();
  const { scannedRelevos, mode } = useScannedRelevosContext();
  const confirmAssignCancelRef = useRef(null);

  const confirmButtonText =
    mode === ScannerMode.DEPOSIT_ASSIGN
      ? translations('borrow_deposit_confirm_button')
      : translations('return_deposit_confirm_button');

  const confirmText =
    mode === ScannerMode.DEPOSIT_ASSIGN
      ? translations('borrow_deposit_confirm')
      : translations('return_deposit_confirm');

  return (
    <Dialog
      leastDestructiveRef={confirmAssignCancelRef}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmText={confirmButtonText}
      cancelText={translations('cancel')}
      size="3xl"
      isLoading={isLoadingConfirm}
    >
      <ConfirmDialogBody confirmText={confirmText}>
        <DepositRelevoTable items={scannedRelevos} mode={DepositRelevoTableMode.CONFIRM} />
      </ConfirmDialogBody>
    </Dialog>
  );
};
