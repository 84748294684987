import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUnknownItemsToReturn } from '../../../../../services/relevos';
import { ReturnUnknownRelevosInformationRequest } from '../../../model/return-unknown-relevos-request';

export const useQuickContactsUnknownItems = (restaurantId: string) => {
  const { data: items, isLoading: isLoadingItems, isFetching: isFetchingItems } = useUnknownItemsToReturn(restaurantId);
  const initialValue: ReturnUnknownRelevosInformationRequest[] = useMemo(() => {
    return (
      items?.map((item) => {
        return { userId: item.user.id, categoryId: item.category.id, quantity: item.borrowingsCount };
      }) || []
    );
  }, [items]);

  const [itemsToReturn, setItemsToReturn] = useState<ReturnUnknownRelevosInformationRequest[]>(initialValue);

  const handleUpdateItemsQuantity = (categoryId: string, quantity: number, userId: string) => {
    const unknownItems = [...itemsToReturn].map((item) => {
      if (item.userId === userId && item.categoryId === categoryId) {
        item.quantity = quantity;
      }
      return item;
    });
    setItemsToReturn(unknownItems);
  };

  const resetItemsToReturn = useCallback(() => setItemsToReturn(initialValue), [initialValue]);

  useEffect(() => {
    resetItemsToReturn();
  }, [resetItemsToReturn]);

  return {
    itemsToReturn,
    setItemsToReturn,
    handleUpdateItemsQuantity,
    unknownItems: items,
    isLoadingUnknownItems: isLoadingItems || isFetchingItems,
  };
};
