import { useContext } from 'react';

import { NotificationSettingsContextInterface } from '../model/NotificationSettingsContext';
import { NotificationSettingsContext } from '../NotificationSettingsContext';

export const useNotificationSettingsContext = (): NotificationSettingsContextInterface => {
  const ctx = useContext(NotificationSettingsContext);
  if (!ctx) {
    throw new Error('No context provided for NotificationSettings');
  }
  return ctx;
};
