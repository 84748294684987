import { getFormattedDate } from '../../../utils/dateHelper';

export const showReturnRateExplanation = (start?: string, end?: string): boolean => {
  let showTooltip = false;
  if (start && end) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const differenceInDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
    showTooltip = differenceInDays < 14;
  }
  return showTooltip;
};

export const getFormattedTimeRange = (startDate: string, endDate: string): string => {
  let output = '';
  if (startDate) output += new Date(getFormattedDate(startDate)).toLocaleDateString();
  if (startDate || endDate) output += ' - ';
  if (endDate) output += new Date(getFormattedDate(endDate)).toLocaleDateString();

  return output;
};
