import React, { JSX } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';

import { CHART_COLORS_RGBA } from '../constants';

const chartOptions: ChartOptions = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        font: {
          weight: 'bold',
        },
      },
      grid: {
        z: 1,
        tickWidth: 0,
      },
    },
    x: {
      stacked: true,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
};

const TotalUsersChart = ({ totalUsersCount, labels }: { totalUsersCount: number[]; labels: string[] }): JSX.Element => {
  const data = {
    labels,
    datasets: totalUsersCount.map((value, index) => ({
      categoryPercentage: 1.0,
      barPercentage: 1.0,
      data: [{ y: value, x: labels[index] }],
      backgroundColor: CHART_COLORS_RGBA[index],
    })),
  };
  const config = {
    options: { ...chartOptions },
    data,
  };

  return <Chart type="bar" {...config} />;
};

export default TotalUsersChart;
