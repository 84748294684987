import React from 'react';

const safeLocalStorageGetItem = (key: string): string | null => {
  const value = localStorage.getItem(key);
  return value === 'null' ? null : value;
};

export const useLocalStorage = <T>(
  storageKey: string,
  fallbackState: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const storageValue = safeLocalStorageGetItem(storageKey);
  const [value, setValue] = React.useState<T>(storageValue ? JSON.parse(storageValue) : fallbackState);

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};
