import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Flex, Text, Textarea, VStack } from '@chakra-ui/react';

import { PageSectionHeading } from '../../../../../../../common/components/PageSectionHeading/PageSectionHeading';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { InventoryCountsFormValues } from '../../../../../model/inventory-counts-form-values';

export const AdditionalInformation = () => {
  const translations = useTranslations();
  const { control, setValue } = useFormContext<InventoryCountsFormValues>();

  const handleTrimValue = (event: React.FocusEvent<HTMLTextAreaElement>) =>
    setValue('additionalInformation', event.target.value.trim());

  return (
    <VStack alignItems={'start'} p={2} w={'100%'}>
      <PageSectionHeading>{translations('inventory_counts_additional_information_heading')}</PageSectionHeading>
      <Text fontSize={14} textAlign={'left'}>
        {translations('inventory_counts_additional_information_summary')}
      </Text>
      <Flex w={'100%'} pr={[0, 0, 0, 2]}>
        <Controller
          control={control}
          name="additionalInformation"
          render={({ field: { value, onChange } }) => (
            <Textarea
              variant={'outline'}
              minH={['10em', '10em', '16em', '16em']}
              w={'100%'}
              m={0}
              pr={0}
              value={value}
              onChange={onChange}
              onBlur={handleTrimValue}
            />
          )}
        />
      </Flex>
    </VStack>
  );
};
