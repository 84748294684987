import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import components from './components';

const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '1080px',
  xl: '1380px',
  '2xl': '1536px',
};

const theme = extendTheme({
  breakpoints,
  components,
  colors: {
    ...colors,
  },
  fonts: {
    body: 'Rubik',
  },
  shadows: {
    outline: 'none',
  },
});

export default theme;
