import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import {
  useCloseRestaurantDraft,
  useCreateRestaurantDraft,
  useRestaurantDraft,
  useUpdateRestaurantDraft,
} from '../../services/draft';
import { useMetaCategories } from '../../services/meta-categories';
import { useRestaurantDetails, useRestaurantImages } from '../../services/restaurants';

export const useDraft = (id: string) => {
  const navigate = useNavigate();
  const translations = useTranslations();

  const { data: metaCategories, isLoading: isLoadingMetaCategories } = useMetaCategories();

  const { data: restaurantImages, isLoading: isLoadingRestaurantImages } = useRestaurantImages(id);

  const { isLoading: isLoadingActiveDraft, data: activeDraft, isFetched: isDraftFetched } = useRestaurantDraft(id);

  const { isLoading: isLoadingDetails, data: restaurant } = useRestaurantDetails(id);

  const { mutateAsync: createDraft, isLoading: isCreatingDraft } = useCreateRestaurantDraft({
    onSuccess: () => {
      toast.success(translations('restaurant_draft_create_success_message'));
      navigate(Routes.LOCATIONS_PATH);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const { mutateAsync: updateDraft, isLoading: isUpdatingDraft } = useUpdateRestaurantDraft({
    onSuccess: () => {
      navigate(Routes.LOCATIONS_PATH);
      toast.success(translations('restaurant_draft_update_success_message'));
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const { mutateAsync: closeDraft, isLoading: isClosingDraft } = useCloseRestaurantDraft({
    onSuccess: () => {
      navigate(Routes.LOCATIONS_PATH);
      toast.success(translations('restaurant_draft_close_success_message'));
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  return {
    createDraft,
    updateDraft,
    closeDraft,
    isLoading:
      isCreatingDraft ||
      isUpdatingDraft ||
      isClosingDraft ||
      isLoadingActiveDraft ||
      isLoadingDetails ||
      isLoadingMetaCategories ||
      isLoadingRestaurantImages,
    metaCategories,
    activeDraft,
    restaurant,
    restaurantImages,
    isDraftFetched,
  };
};
