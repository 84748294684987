import { Language } from '../../../contexts/LocalizationContext/types';

export type HubspotContactFormsIds = {
  [key in Language]: string;
};

export const HUBSPOT_CONTACT_FORMS_IDS: HubspotContactFormsIds = {
  [Language.DE]: 'f2f45df6-2aa2-4e81-8bcb-d08095a822fe',
  [Language.EN]: 'f2f45df6-2aa2-4e81-8bcb-d08095a822fe',
  [Language.FR]: 'f2f45df6-2aa2-4e81-8bcb-d08095a822fe',
};
