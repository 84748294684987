import React, { JSX } from 'react';
import { Box, Checkbox, Divider, Flex, HStack, Image, useTheme, VStack } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { ProductCategoryResponse } from '../model/ProductCategoryResponse';

const getIconPath = (iconName: string) => require(`../../../assets/icons/${iconName}`);

type ProductCategoriesProps = {
  categories: ProductCategoryResponse[];
  onChange: (e: boolean, categoryId: string) => void;
  categoryIds: string[];
  onSelectAllChange: (e: boolean, categoryGroup: string) => void;
};

const headingDefaultStyle = {
  fontSize: '14px',
};

const ProductCategories = ({
  categories,
  onChange,
  categoryIds,
  onSelectAllChange,
}: ProductCategoriesProps): JSX.Element => {
  const { colors } = useTheme();
  const translations = useTranslations();
  const { language } = useLocalizationContext();

  const bowlsCategories = categories.filter((value) => value.categoryGroup === 'BOWLS');
  const cupsCategories = categories.filter((value) => value.categoryGroup === 'CUPS');

  const allBowlsCategoriesChecked = bowlsCategories.map((value) => value.id).every((id) => categoryIds.includes(id));
  const isSelectAllBowlsCategoriesIndeterminate =
    !allBowlsCategoriesChecked && bowlsCategories.map((value) => value.id).some((id) => categoryIds.includes(id));

  const allCupsCategoriesChecked = cupsCategories.map((value) => value.id).every((id) => categoryIds.includes(id));
  const isSelectAllCupsCategoriesIndeterminate =
    !allCupsCategoriesChecked && cupsCategories.map((value) => value.id).some((id) => categoryIds.includes(id));

  return (
    <Box bg={colors.white} p={2}>
      <HStack>
        <VStack width="20%">
          <h2 style={headingDefaultStyle}>Bowls:</h2>
          <Image src={getIconPath('bowl.svg')} maxHeight={7} maxWidth={7} alt="Relevo" display="inline-block" />
          <Checkbox
            size="sm"
            colorScheme="orange"
            defaultChecked
            isIndeterminate={isSelectAllBowlsCategoriesIndeterminate}
            isChecked={allBowlsCategoriesChecked}
            onChange={(e) => onSelectAllChange(e.target.checked, 'BOWLS')}
          >
            {translations('reports_select_all_categories')}
          </Checkbox>
        </VStack>
        <Flex wrap="wrap" width="80%" textAlign="left">
          {bowlsCategories.map((value) => (
            <Checkbox
              name={value.id}
              key={value.id}
              width={['50%', '50%', '50%', '50%', '33%', '25%']}
              size="sm"
              colorScheme="orange"
              defaultChecked
              isChecked={categoryIds.some((id) => id === value.id)}
              onChange={(e) => onChange(e.target.checked, value.id)}
            >
              {value.translations[language]}
            </Checkbox>
          ))}
        </Flex>
      </HStack>

      <Divider borderColor={colors.beige[50]} border={1} my={2} />

      <HStack>
        <VStack width="20%">
          <h2 style={headingDefaultStyle}>Cups:</h2>
          <Image src={getIconPath('cup.svg')} maxHeight={7} maxWidth={7} alt="Relevo" display="inline-block" />
          <Checkbox
            size="sm"
            colorScheme="orange"
            defaultChecked
            isIndeterminate={isSelectAllCupsCategoriesIndeterminate}
            isChecked={allCupsCategoriesChecked}
            onChange={(e) => onSelectAllChange(e.target.checked, 'CUPS')}
          >
            {translations('reports_select_all_categories')}
          </Checkbox>
        </VStack>
        <Flex wrap="wrap" width="80%" textAlign="left">
          {cupsCategories.map((value) => (
            <Checkbox
              key={value.id}
              width={['50%', '50%', '50%', '50%', '33%', '25%']}
              size="sm"
              colorScheme="orange"
              defaultChecked
              isChecked={categoryIds.some((id) => id === value.id)}
              onChange={(e) => onChange(e.target.checked, value.id)}
            >
              {value.translations[language]}
            </Checkbox>
          ))}
        </Flex>
      </HStack>
    </Box>
  );
};

export default ProductCategories;
