import React, { useRef } from 'react';
import { Text } from '@chakra-ui/react';

import Dialog from '../../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../../contexts/ScannedRelevosContext';

type ClearAllRelevosConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ClearAllRelevosConfirmModal = ({ isOpen, onClose }: ClearAllRelevosConfirmModalProps) => {
  const translations = useTranslations();
  const { setScannedRelevos } = useScannedRelevosContext();
  const confirmRemoveCancelRef = useRef(null);

  const removeRelevos = () => {
    onClose();
    setScannedRelevos(() => []);
  };
  const handleConfirm = () => removeRelevos();

  return (
    <Dialog
      isOpen={isOpen}
      leastDestructiveRef={confirmRemoveCancelRef}
      onClose={onClose}
      onConfirm={handleConfirm}
      cancelText={translations('scan_remove_cancel')}
      confirmText={translations('scan_remove_confirmed')}
    >
      <Text>{translations('scan_clear_all_assignments_confirm')}</Text>
    </Dialog>
  );
};
