import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { createCustomer } from '../api/customers';
import { CustomerCreationRequest } from '../modules/CustomerCreation/model/CustomerCreationRequest';
import { CustomerCreationResponse } from '../modules/CustomerCreation/model/CustomerCreationResponse';
import { CustomerCreationWithCalculatorRequest } from '../modules/CustomerCreation/model/CustomerCreationWithCalculatorRequest';

export const CK_CUSTOMERS = 'customers';

export const useCreateCustomer = (): UseMutationResult<
  CustomerCreationResponse,
  AxiosError,
  CustomerCreationRequest | CustomerCreationWithCalculatorRequest
> => {
  const queryClient = useQueryClient();
  return useMutation(createCustomer, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CUSTOMERS);
    },
  });
};
