import { NotificationSettingsFrequency } from '../../../model/enums/notification-settings-frequency.enum';
import { NotificationSettingsResponse } from '../../../model/notification-settings-response';
import { UpdateNotificationSettingsRequest } from '../../../model/update-notification-settings-request';
import { NotificationSettingsEnabled } from '../../NotificationSettingsTable/model/enums/notification-settings-enabled.enum';
import { NotificationSettingsLocations } from '../../NotificationSettingsTable/model/enums/notification-settings-locations.enum';
import { NotificationSettingsTableData } from '../../NotificationSettingsTable/model/notification-settings-table-data';

export const buildNotificationSettingsTableData = (
  notificationSettings: NotificationSettingsResponse,
): NotificationSettingsTableData => {
  return {
    enabled: notificationSettings.isNotificationEnabled
      ? NotificationSettingsEnabled.ON
      : NotificationSettingsEnabled.OFF,
    frequency:
      notificationSettings.notificationFrequency === NotificationSettingsFrequency.WEEKLY
        ? NotificationSettingsFrequency.WEEKLY
        : NotificationSettingsFrequency.MONTHLY,
    locationsSetting: notificationSettings.selectedLocations?.length
      ? NotificationSettingsLocations.SELECTED
      : NotificationSettingsLocations.ALL,
    selectedLocations: notificationSettings.selectedLocations || [],
    isEditMode: false,
  };
};

export const buildNotificationSettingsUpdateRequest = (
  data: NotificationSettingsTableData,
): UpdateNotificationSettingsRequest => {
  return {
    isNotificationEnabled: data.enabled === NotificationSettingsEnabled.ON,
    notificationFrequency: data.frequency,
    selectedLocations:
      data.locationsSetting === NotificationSettingsLocations.ALL || !data.selectedLocations.length
        ? null
        : data.selectedLocations,
  };
};
