import React from 'react';
import { Button, Image } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';

const getIconPath = (iconName: string) => require(`../../../assets/icons/${iconName}`);

type RowDragHandleCellProps = {
  rowId: string;
  isDisabled: boolean;
};

export const RowDragHandleCell = ({ rowId, isDisabled }: RowDragHandleCellProps) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    <Button {...attributes} {...listeners} isDisabled={isDisabled} variant={'transparent'} aria-label={'order'}>
      <Image src={getIconPath('dnd.svg')} my="auto" mr={2} alt="dnd" />
    </Button>
  );
};
