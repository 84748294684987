import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Routes } from '../../../../config/routes';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { DepositTile } from '../../common/components/DepositTile/DepositTile';

export const ReturnDepositTile = () => {
  const translations = useTranslations();
  const navigate = useNavigate();

  const handleNavigateToReturn = () => navigate(Routes.DEPOSIT_RETURN_PATH);

  return (
    <DepositTile
      tileName={translations('deposit_return')}
      explanatoryText={translations('deposit_return_info')}
      tileColor="blue.500"
      onClick={handleNavigateToReturn}
    />
  );
};
