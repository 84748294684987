const baseStyle = {
  indicator: {
    borderColor: 'grey.20',
    background: 'grey.20',
    color: 'grey.200',
  },
  separator: {
    background: 'grey.20',
  },
  title: {
    '&[data-status=complete]': {
      opacity: '55%',
    },
    '&[data-status=incomplete]': {
      opacity: '55%',
    },
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'grey.900',
  },
  description: {
    '&[data-status=complete]': {
      opacity: '55%',
    },
    '&[data-status=incomplete]': {
      opacity: '55%',
    },
    textAlign: 'left',
    fontSize: '14px',
    color: 'grey.200',
  },
};

const sizes = {
  lg: {
    title: {
      // change the font size to lg
    },
  },
};

const Stepper = {
  baseStyle,
  sizes,
};

export default Stepper;
