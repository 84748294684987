import React, { JSX } from 'react';
import { Text, useTheme } from '@chakra-ui/react';

type QuestionProps = {
  question: string;
};

const Question = ({ question }: QuestionProps): JSX.Element => {
  const { colors } = useTheme();

  return (
    <Text color={colors.orange[500]} fontWeight="bold">
      {question}
    </Text>
  );
};

export default Question;
