import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react';

import './DateRangeSelect.css';

import { SelectOption } from '../../../../common/components/Select/model/selectOption';
import config from '../../../../config/config';
import { LOCALIZATION_CONFIG } from '../../../../config/configConsts';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { localeMapper } from '../../../../contexts/LocalizationContext/types';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { addDays, getFirstDayOfMonth, getLastDayOfMonth } from '../../../../utils/dateHelper';
import { DateRangeSelectOption } from '../../model/enums/dateRangeSelectOption.enum';

type DateRangeSelectProps = {
  onDatesRangeChange: (startDate: Date, endDate?: Date, dateRange?: DateRangeSelectOption) => void;
  startDate: Date;
  endDate?: Date;
  dateRange?: DateRangeSelectOption;
};

const DateRangeSelect = ({ startDate, endDate, dateRange, onDatesRangeChange }: DateRangeSelectProps): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const { language } = useLocalizationContext();

  const onChange = (dates: [Date, Date | null]) => {
    const [start, end] = dates;
    onDatesRangeChange(start, end ? end : undefined, undefined);
  };

  const dateRangeSelectOptions: SelectOption[] = [
    { label: translations('history_date_range_select_today'), value: DateRangeSelectOption.TODAY },
    { label: translations('history_date_range_select_this_week'), value: DateRangeSelectOption.THIS_WEEK },
    { label: translations('history_date_range_select_this_month'), value: DateRangeSelectOption.THIS_MONTH },
  ];

  const onClose = () => {
    // TODO: should we keep it?
    if (!!startDate && !endDate) {
      onDatesRangeChange(startDate, startDate, undefined);
    }
  };

  const handleDateRangeChange = (range: string) => () => {
    if (Object.values(DateRangeSelectOption).includes(range as DateRangeSelectOption)) {
      const today = new Date();
      switch (range as DateRangeSelectOption) {
        case DateRangeSelectOption.TODAY:
          onDatesRangeChange(today, today, DateRangeSelectOption.TODAY);
          break;
        case DateRangeSelectOption.THIS_WEEK:
          onDatesRangeChange(addDays(today, -7), today, DateRangeSelectOption.THIS_WEEK);
          break;
        case DateRangeSelectOption.THIS_MONTH:
          onDatesRangeChange(
            getFirstDayOfMonth(today.getFullYear(), today.getMonth()),
            getLastDayOfMonth(today.getFullYear(), today.getMonth()),
            DateRangeSelectOption.THIS_MONTH,
          );
          break;
      }
    }
  };

  return (
    <Menu onClose={onClose} isLazy>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Box}
            h="40px"
            w="100%"
            borderColor={colors.beige[50]}
            borderWidth="1px"
            borderRadius={3}
            position="relative"
            maxW="20em"
            minW="16em"
          >
            <HStack justifyContent="space-between" p={2}>
              <Text fontSize="14px" cursor="default">
                {dateRangeSelectOptions.find((option) => option.value === dateRange)?.label ||
                  `${startDate.toLocaleDateString()} - ${endDate?.toLocaleDateString() || ''}`}
              </Text>
              <Flex>
                <svg width="8" height="25">
                  <line x1="0" y1="0" x2="0" y2="150" stroke={colors.grey[50]} strokeWidth="2" />
                </svg>
                <ChevronDownIcon h={6} w={6} color={isOpen ? colors.grey[200] : colors.grey[50]} />
              </Flex>
            </HStack>
          </MenuButton>
          <MenuList
            boxShadow="0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)"
            border="0"
            bgColor={colors.white}
            w="100%"
          >
            <VStack>
              <Box px={2}>
                <ReactDatePicker
                  locale={localeMapper[language]}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                  dateFormat={LOCALIZATION_CONFIG[config.language].dateFormat}
                />
              </Box>
              <List width="100%">
                {dateRangeSelectOptions.map((option) => (
                  <ListItem
                    pl={2}
                    py={2}
                    cursor="pointer"
                    onClick={handleDateRangeChange(option.value)}
                    key={option.value}
                    _hover={{ bg: colors.beige[50] }}
                  >
                    <Text fontSize="14px" textAlign="left" fontWeight={dateRange === option.value ? 'bold' : 'normal'}>
                      {option.label}
                    </Text>
                  </ListItem>
                ))}
              </List>
            </VStack>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default DateRangeSelect;
