import { createContext, ReactNode, useReducer } from 'react';
import { MultiValue, SingleValue } from 'react-select';

import { SelectOption } from '../../../../common/components/Select/model/selectOption';
import { OrderType } from '../../../../common/components/TableHeaderWithOrder/orderType.enum';
import { RestaurantInfo } from '../../../../contexts/SessionContext/SessionContext';
import { addDays } from '../../../../utils/dateHelper';
import { DateRangeSelectOption } from '../../model/enums/dateRangeSelectOption.enum';
import { HistoryActionType } from '../../model/enums/historyActionType.enum';
import { HistoryOrderField } from '../../model/enums/historyOrderField.enum';
import { HistoryFilterContextActionType } from './model/enums/HistoryFilterContextActionType.enum';
import { HistoryFilterContextInterface } from './model/HistoryFilterContext';
import { HistoryFilterContextAction } from './model/HistoryFilterContextAction';
import { HistoryState } from './model/HistoryState';

type HistoryFilterContextProps = {
  userRestaurants: RestaurantInfo[];
  children: ReactNode;
};

export const HistoryFilterContext = createContext<HistoryFilterContextInterface | null>(null);

const reducer = (state: HistoryState, action: HistoryFilterContextAction): HistoryState => {
  switch (action.type) {
    case HistoryFilterContextActionType.ORDER: {
      if (action.payload.field === state.orderField) {
        return {
          ...state,
          orderField: action.payload.field,
          orderType: state.orderType === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
        };
      } else {
        return {
          ...state,
          orderField: action.payload.field,
          orderType: OrderType.ASC,
        };
      }
    }
    case HistoryFilterContextActionType.RESTAURANTS: {
      return {
        ...state,
        restaurantIds: action.payload.ids,
        page: 1,
      };
    }
    case HistoryFilterContextActionType.ACTION: {
      if (
        action.payload.action &&
        Object.values(HistoryActionType).includes(action.payload.action as HistoryActionType)
      ) {
        return {
          ...state,
          action: action.payload.action as HistoryActionType,
          page: 1,
        };
      } else {
        return {
          ...state,
          action: undefined,
          page: 1,
        };
      }
    }
    case HistoryFilterContextActionType.DATES: {
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        dateRange: action.payload.dateRange,
        page: 1,
      };
    }
    case HistoryFilterContextActionType.PAGE: {
      return {
        ...state,
        page: action.payload.page,
      };
    }
    default:
      throw new TypeError('Not supported action type');
  }
};

export const HistoryFilterContextProvider = ({ userRestaurants, children }: HistoryFilterContextProps) => {
  const [historyState, dispatch] = useReducer(reducer, {
    restaurantIds: userRestaurants.map((restaurant) => restaurant.id),
    orderField: HistoryOrderField.DATE_TIME,
    orderType: OrderType.DESC,
    startDate: addDays(new Date(), -7),
    dateRange: DateRangeSelectOption.THIS_WEEK,
    endDate: new Date(),
    action: undefined,
    page: 1,
  });

  const handlePage = (page: number) => {
    dispatch({ type: HistoryFilterContextActionType.PAGE, payload: { page } });
  };

  const handleOrderBy = (field: HistoryOrderField) => {
    dispatch({ type: HistoryFilterContextActionType.ORDER, payload: { field } });
  };

  const handleSelectRestaurants = (selectedRestaurants: MultiValue<SelectOption>) => {
    const ids = selectedRestaurants.map((selectedRestaurant) => selectedRestaurant.value);
    dispatch({ type: HistoryFilterContextActionType.RESTAURANTS, payload: { ids } });
  };

  const handleSelectHistoryAction = (selectedHistoryAction: SingleValue<SelectOption>) => {
    const action = selectedHistoryAction?.value;
    dispatch({ type: HistoryFilterContextActionType.ACTION, payload: { action } });
  };

  const handleSelectDatesRange = (startDate: Date, endDate?: Date, dateRange?: DateRangeSelectOption) => {
    dispatch({
      type: HistoryFilterContextActionType.DATES,
      payload: {
        startDate,
        endDate,
        dateRange,
      },
    });
  };

  return (
    <HistoryFilterContext.Provider
      value={{
        historyState,
        handleOrderBy,
        handleSelectRestaurants,
        handleSelectHistoryAction,
        handleSelectDatesRange,
        handlePage,
      }}
    >
      {children}
    </HistoryFilterContext.Provider>
  );
};
