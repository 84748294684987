import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CheckmarkIcon = createIcon({
  displayName: 'CheckmarkIcon',
  viewBox: '0 0 14.508 14.464',
  path: (
    <>
      <path
        fill="currentColor"
        d="M7.265 14.464H6.89l-.376-.044-.375-.022-.353-.088-.331-.066-.354-.11-.663-.241-.64-.286-.596-.352-.575-.462-.507-.44-.464-.527-.42-.572-.353-.57-.331-.638-.22-.682-.111-.307-.089-.353-.043-.373-.067-.352L0 7.606v-.748l.022-.351.067-.374.043-.374.089-.308.11-.351.221-.682.331-.637.353-.571.42-.572.464-.506.507-.461.575-.418.596-.396.64-.286.663-.242.354-.087.33-.088.354-.066.75-.088h.729l.398.044.353.044.353.066.354.088.33.087.686.242.618.286.596.396.552.418.552.461.442.506.442.572.353.57.287.638.243.682.132.351.044.308.088.374.045.374.022.351v.748l-.022.373-.045.352-.088.373-.044.353-.132.307-.243.682-.287.637-.353.571-.442.572-.442.528-.552.44-.552.461-.596.352-.618.286-.685.241-.331.11-.354.066-.353.088-.353.022-.398.044zm0-13.629l-.663.022-.64.089-.618.175-.597.22-.573.263-.508.33-.486.352-.486.418-.42.461-.353.506-.331.505-.265.55-.199.594-.176.637-.089.615-.044.682.044.638.089.615.176.637.2.594.264.55.33.527.354.484.42.461.486.418.486.351.508.33.573.264.597.242.618.153.64.089.663.022.663-.022.618-.089.618-.153.597-.242.551-.263.552-.33.464-.352.486-.418.398-.461.375-.484.309-.528.265-.55.243-.593.154-.637.089-.615.022-.638-.022-.682-.089-.615-.154-.637-.243-.594-.265-.55-.309-.505-.375-.506-.398-.461-.486-.418-.464-.351-.552-.33-.551-.264-.597-.22-.618-.175-.618-.089z"
        fillRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M10.913 4.762L6.248 9.415 3.75 6.9l-.487.485 2.764 2.756.11.089.11.044.155-.044.111-.089 4.908-4.872z"
        fillRule="evenodd"
      />
    </>
  ),
});

export const CloseIcon = createIcon({
  displayName: 'CloseIcon',
  viewBox: '0 0 14.508 14.508',
  path: (
    <>
      <path
        fill="currentColor"
        d="M7.243 14.508H6.87l-.704-.044-.397-.066-.33-.089-.353-.11-.638-.243-.66-.309-.594-.353-.551-.42-.507-.464-.484-.53-.396-.574-.374-.596-.309-.618-.241-.685-.088-.331-.09-.354-.13-.706L0 7.618V6.89l.022-.353.066-.398.066-.353.088-.331L.33 5.1l.242-.64.309-.663.374-.596.396-.552.484-.508.507-.486.55-.397.595-.376.66-.309.638-.243.353-.088.33-.089.397-.065.352-.067L6.869 0h.748l.375.022.704.132.352.09.33.087.683.243.617.31.638.375.528.397.529.486.462.508.418.552.352.596.309.663.264.64.088.354.088.33.066.354.022.398.022.353.044.375-.044.353-.022.398-.022.353-.066.353-.088.354-.088.33-.264.686-.309.618-.352.596-.418.575-.462.53-.529.463-.528.42-.638.353-.617.309-.682.243-.33.11-.353.089-.352.066-.352.022-.375.022zm0-13.67l-.638.023-.66.089-.617.176-.595.22-.528.266-.528.309-.507.398-.44.42-.418.44-.375.508-.33.509-.264.551-.22.597-.177.618-.088.663-.021.64.021.663.088.618.177.618.22.597.264.573.33.53.375.509.418.441.44.42.507.353.528.331.528.265.595.243.616.132.66.089.64.044.66-.044.616-.089.616-.132.595-.243.572-.265.528-.33.507-.354.44-.42.418-.441.353-.509.33-.53.264-.573.242-.597.132-.618.088-.618.066-.663-.066-.64-.088-.663-.132-.618-.242-.597-.264-.551-.33-.509-.353-.507-.418-.442-.44-.42-.507-.397-.528-.309-.572-.265-.595-.22L8.52.95 7.904.86z"
        fillRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M9.657 5.377l-.531-.526-1.883 1.887-1.905-1.887-.531.526 1.905 1.888L4.807 9.13l.531.526 1.905-1.843 1.883 1.843.531-.526-1.86-1.866z"
        fillRule="evenodd"
      />
    </>
  ),
});

export const InfoIcon = createIcon({
  displayName: 'InfoIcon',
  viewBox: '0 0 14.508 14.508',
  path: (
    <>
      <path
        fill="currentColor"
        d="M7.265 14.508l-.375-.044-.75-.044-.354-.066-.354-.088-.33-.088-.686-.264-.618-.309-.596-.352-.574-.418-.486-.462-.486-.529-.42-.528-.375-.638-.309-.617-.22-.682-.133-.33-.045-.353-.132-.704L0 7.617V6.87l.022-.352.067-.352.065-.397L.2 5.46l.132-.375.221-.638.31-.66.375-.595.42-.55.485-.507.486-.462.574-.418.596-.352.618-.33.685-.242.331-.088.354-.09.353-.065L6.89 0h.728l.398.044.353.044.353.066.354.089.33.088.686.241.618.33.596.353.552.418.53.462.464.507.397.55.398.595.287.66.243.638.11.375.067.308.065.397.09.704v.748l-.045.375-.045.352-.132.704-.11.33-.243.683-.287.617-.398.638-.397.528-.464.529-.53.462-.552.418-.596.352-.618.309-.685.264-.331.088-.354.088-.353.066-.353.022-.398.022zm0-13.671L6.58.858l-.618.088-.618.177-.597.22-.573.264-.53.33-.464.375-.486.418-.42.44-.353.507-.331.528-.265.528-.199.595-.176.616-.089.66-.022.66.022.64.089.616.176.616.2.595.264.572.331.528.353.507.42.44.486.418.464.353.53.33.573.264.597.242.618.132.618.088.685.066.663-.066.618-.088.618-.132.597-.242.551-.264.552-.33.464-.353.486-.418.398-.44.353-.507.331-.528.265-.572.243-.595.154-.616.089-.616.022-.64-.022-.66-.089-.66-.154-.616-.243-.595-.265-.528-.331-.528-.353-.507-.398-.44-.486-.418-.464-.375-.552-.33-.551-.264-.597-.22-.618-.177-.618-.088z"
        fillRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M7.353 4.807l.108-.023.087-.045.13-.045.086-.067.065-.09.044-.112.043-.135.022-.112-.022-.135-.043-.09-.044-.112-.065-.09-.086-.067-.13-.045-.087-.045-.108-.022-.152.022-.086.045-.108.045-.087.068-.065.09-.043.112-.043.09-.022.134.022.112.043.135.043.112.065.09.087.067.108.045.086.045zM7.708 9.529v-3.71l-.022-.154-.089-.11-.133-.087-.11-.044H5.8v.746h1.176V9.53H5.6v.746h3.484v-.746z"
        fillRule="evenodd"
      />
    </>
  ),
});

export const KeyIcon = createIcon({
  displayName: 'KeyIcon',
  viewBox: '0 0 7.188 7.232',
  path: (
    <path
      fill="currentColor"
      d="M7.056 0H5.671l-.088.022-3.011 3.043-.242-.066h-.44l-.198.022-.22.066-.175.066-.198.088-.176.11-.154.11-.176.155-.11.154-.153.177-.088.176-.088.198-.088.177-.044.22L0 4.918v.397l.022.198.044.22.088.2.176.352.154.176.11.155.175.154.154.11.176.11.198.089.176.066.22.066.197.022h.418l.198-.022.22-.066.197-.066.176-.088.176-.11.154-.111.176-.154.176-.22.154-.2.11-.242.087-.265.067-.264.022-.265-.045-.529.638-.683.044-.067v-.815h.879l.022-.044.044-.022v-.882h.857l.022-.045.506-.506V.088L7.144.022h-.022zM2.11 5.777l-.132.088-.11.066-.132.022-.131.044-.154-.044-.11-.022-.132-.066-.11-.088-.088-.11-.066-.133-.022-.11L.88 5.27l.044-.133.022-.132.066-.11.088-.11.11-.089.132-.066.11-.044.154-.022.131.022.133.044.11.066.131.088.088.111.044.11.044.132.022.133-.022.154-.044.11-.044.132z"
      fillRule="evenodd"
    />
  ),
});

export const PersonIcon = createIcon({
  displayName: 'PersonIcon',
  viewBox: '0 0 6.967 7.497',
  path: (
    <path
      fill="currentColor"
      d="M3.495 4.674l.177-.044h.178l.177-.044.177-.065.333-.176.266-.241.244-.264.178-.329.066-.131.022-.22.045-.175.022-.176v-.944l-.022-.175-.045-.176-.022-.175-.066-.176-.178-.329-.244-.263-.266-.242-.333-.175-.177-.066-.355-.044L3.495 0l-.2.044-.355.044-.177.066-.31.175-.29.242-.221.263-.177.33-.045.175-.066.175-.022.176v1.295l.022.175.066.22.045.131.177.33.222.263.288.241.31.176.178.065.178.044h.177zM4.778 4.674l-.266.177-.332.154-.331.088-.354.022-.354-.022-.354-.088-.287-.154-.31-.177-.2.066-.22.067-.2.088-.177.088-.22.132-.465.463-.266.375-.22.42-.134.44-.066.199-.022.22v.11l.044.066.089.066.088.023h6.547l.089-.023.066-.066.044-.066v-.11l-.022-.22-.022-.199-.155-.44-.199-.42-.265-.375-.2-.154-.132-.176-.354-.265-.221-.088-.2-.088-.22-.067z"
      fillRule="evenodd"
    />
  ),
});

export const MenuIcon = createIcon({
  displayName: 'MenuIcon',
  viewBox: '0 0 14.507 14.509',
  path: (
    <>
      <path
        fill="currentColor"
        d="M7.243 14.509h-.375l-.351-.023-.352-.044-.397-.088-.33-.044-.353-.132-.638-.244-.66-.286-.595-.354-.55-.442-.507-.441-.484-.552-.418-.552-.353-.597-.308-.618-.242-.685-.088-.331-.088-.353-.132-.707L0 7.618V6.89l.022-.375.066-.376.066-.353.088-.331.088-.353.242-.686.308-.618.353-.596.418-.574.484-.508.507-.464.55-.42.595-.375.66-.309.638-.243.353-.088.33-.089.397-.043.352-.067L6.868 0h.727l.397.022.352.067.352.043.352.089.33.088.683.243.617.31.616.375.55.42.529.463.462.508.419.574.352.596.308.618.264.686.088.353.089.331.065.353.044.751.044.375-.044.353-.021.398-.023.353-.065.354-.09.353-.087.331-.264.685-.308.618-.352.597-.419.552-.462.552-.529.441-.55.442-.616.354-.617.286-.682.244-.33.132-.353.044-.352.088-.352.044-.397.023zm0-13.692L6.605.86l-.66.089-.617.176-.595.2-.528.264-.528.331-.507.353-.44.42-.418.464-.397.508-.308.508-.286.574-.198.596-.177.619-.088.64-.021.662.021.663.088.618.177.619.198.596.286.552.308.552.397.464.417.485.441.398.507.376.528.309.528.265.595.242.616.155.66.089.64.022.66-.022.616-.09.616-.154.595-.242.573-.265.527-.31.507-.375.44-.398.418-.485.353-.464.33-.552.264-.552.22-.596.155-.619.088-.618.065-.663-.065-.662-.088-.64-.154-.619-.22-.596-.265-.574-.33-.508-.353-.508-.417-.464-.441-.42-.507-.353-.527-.331-.573-.265-.595-.199L8.52.95 7.904.86z"
        fillRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M4.134 4.013l.157.022.156.045.112.09.09.09.111.089.068.156.022.112.045.157-.045.134-.022.157-.068.134-.201.202-.112.067-.156.044h-.314l-.134-.044-.134-.067-.112-.09-.09-.112-.067-.134-.022-.157-.023-.134.023-.157.022-.112.067-.156.09-.09.112-.09.134-.089.134-.045zM10.65 5.027H5.777l-.066-.046-.022-.023-.045-.023v-.368l.067-.069h5.005l.044.023.044.092v.253l-.022.07-.022.022-.044.023zM4.134 6.483l.313.043.112.065.09.109.111.087.068.13.022.152.045.13-.045.152-.022.152-.068.11-.111.108-.09.108-.112.044-.156.043-.157.022-.157-.022-.268-.087-.112-.108-.09-.109-.067-.109-.045-.304.023-.13.022-.152.067-.13.202-.196.134-.065.134-.022zM10.65 7.453H5.71l-.066-.07v-.37l.045-.022.022-.023h5.005l.044.023.022.023.022.07v.253l-.044.093-.044.023zM4.134 8.952l.157.044.156.021.112.065.201.196.068.109.022.152.045.152-.045.152-.022.13-.068.13-.111.087-.09.109-.112.065-.313.043-.157-.021-.134-.022-.134-.065-.202-.196-.067-.13-.022-.13-.023-.152.045-.304.067-.11.09-.108.112-.087.134-.065.134-.021zM10.65 9.922H5.71l-.021-.044-.045-.022v-.353l.067-.066h5.005l.044.022.044.088v.243l-.022.066-.066.066z"
        fillRule="evenodd"
      />
    </>
  ),
});

export const SignOutIcon = createIcon({
  displayName: 'SignOutIcon',
  viewBox: '0 0 14.508 14.508',
  path: (
    <>
      <path
        fill="currentColor"
        d="M7.254 14.508l-.397-.022h-.353l-.705-.132-.706-.176-.661-.221-.64-.33-.595-.353-.573-.42-.508-.485-.462-.507-.42-.551-.352-.595-.287-.64-.286-.661-.177-.705-.044-.354-.044-.375-.022-.352L0 7.254l.022-.397.022-.353.088-.705.177-.706.286-.661.287-.64.353-.595.419-.573.462-.508.508-.462.573-.42.595-.352.64-.287.661-.265.353-.11.353-.088.705-.088.353-.022L7.254 0l.375.022.352.022.375.044.354.044.352.088.353.11.64.265.66.287.596.353.551.419.507.462.485.508.42.573.352.595.287.64.265.661.176.706.066.353.022.352.044.353.022.397-.022.375-.044.352-.022.375-.066.354-.176.705-.265.661-.287.64-.352.595-.42.551-.485.507-.507.485-.551.42-.595.352-.662.33-.64.222-.704.176-.354.066-.375.066H7.63zm0-13.692L6.592.86l-.639.11-.595.154-.595.2-.574.264-.53.33-.506.397-.44.397-.42.44-.375.508-.33.551-.265.552-.199.595-.154.595-.11.64-.044.661.044.64.11.661.154.595.2.595.264.552.33.529.375.507.42.44.44.42.507.397.53.309.573.286.595.199.595.154.64.11.661.022.64-.022.661-.11.595-.154.595-.2.552-.285.529-.31.507-.396.44-.42.42-.44.397-.507.309-.53.286-.55.199-.596.154-.595.11-.662.023-.639-.023-.662-.11-.639-.154-.595-.2-.595-.285-.552-.31-.551-.396-.507-.42-.44-.44-.398-.507-.397-.53-.33-.55-.265-.596-.199L8.555.97 7.893.86z"
        fillRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M8.952 4.586l-.11-.044h-.11l-.089.066-.132.177.044.11.022.088.088.088.287.22.309.243.242.265.176.287.177.33.11.353.066.353.022.353v.308l-.066.265-.044.265-.088.264-.132.243-.133.22-.154.22-.176.199-.221.176-.198.155-.243.154-.22.11-.287.088-.265.089-.264.044-.287.022-.309-.022-.264-.044-.53-.177-.242-.11-.44-.309-.2-.176-.176-.198-.308-.441-.11-.243-.089-.264-.066-.265-.044-.265v-.308l.022-.353.066-.353.11-.353.177-.33.176-.287.243-.265.286-.242.31-.22.087-.089.022-.088.022-.11-.044-.088-.066-.089-.088-.066h-.11l-.133.044-.353.265-.33.265-.287.352-.242.353-.199.397-.132.397-.088.419-.022.44v.353l.066.331.088.33.11.332.133.264.176.287.198.264.199.243.242.22.53.353.308.133.287.132.33.088.331.022.353.022.353-.022.33-.022.331-.088.574-.265.264-.176.287-.177.22-.22.22-.243.2-.264.176-.287.132-.264.11-.331.088-.33.066-.332v-.352l-.044-.441-.088-.42-.11-.396-.22-.397-.221-.353-.309-.352-.308-.265z"
        fillRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M7.288 6.218l.114-.022.091-.066.069-.089.023-.088V3.97l-.023-.133-.069-.088-.091-.044-.114-.044-.138.044-.091.044-.046.088-.046.133v1.984l.092.177.091.066z"
        fillRule="evenodd"
      />
    </>
  ),
});
