import React from 'react';
import { Flex, Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';

type CustomerSurveyProps = {
  isOpen: boolean;
  onClose: () => void;
  surveyUrl?: string;
};

const CustomerSurvey = ({ isOpen, surveyUrl, onClose }: CustomerSurveyProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent h="70%">
        <ModalCloseButton />
        <Flex h="100%" display="column" p={2}>
          <iframe src={surveyUrl} width="100%" height="100%" frameBorder="0" marginHeight={0} marginWidth={0} />
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default CustomerSurvey;
