import React, { JSX } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, useTheme } from '@chakra-ui/react';

import { LinkButton } from '../../LinkButton';

type SubSectionButtonProps = {
  leftIcon?: React.ReactElement;
  color?: string;
  bg?: string;
  children: React.ReactNode;
} & ({ type: 'link'; route: string; activeTextColor: string } | { type: 'button'; onClick: () => void });

export const SubSectionButton = ({ children, ...props }: SubSectionButtonProps): JSX.Element => {
  const { pathname } = useLocation();
  const { colors } = useTheme();

  const defaultProps = {
    width: 'full',
    variant: 'ghost',
    justifyContent: 'flex-start',
    fontSize: 16,
    height: '39px',
    mt: 4,
    font: 'Rubik',
    fontWeight: 'normal',
    color: 'route' in props && pathname === props.route ? props.activeTextColor : colors.grey[200],
    bg: 'route' in props && pathname === props.route ? colors.white : colors.beige[50],
    _focus: {
      boxShadow: 'none',
    },
    _hover: {
      backgroundColor: 'none',
    },
    _active: {
      backgroundColor: 'none',
    },
    borderRadius: '8px',
    paddingLeft: '3em',
  };

  return (
    <>
      {props.type === 'link' && (
        <LinkButton {...defaultProps} color={defaultProps.color} leftIcon={props.leftIcon} to={props.route}>
          {children}
        </LinkButton>
      )}
      {props.type === 'button' && (
        <Button {...defaultProps} color={defaultProps.color} leftIcon={props.leftIcon} onClick={props.onClick}>
          {children}
        </Button>
      )}
    </>
  );
};
