import React, { JSX } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import Toast from '../../common/components/Toast/Toast';
import { Role, useSessionContext } from '../../contexts/SessionContext';
import { NotificationSettings } from './components/NotificationSettings/NotificationSettings';
import { ProfileInformationTable } from './components/ProfileInformation/ProfileInformationTable';
import { SignOut } from './components/SignOut/SignOut';

const Profile = (): JSX.Element => {
  const translations = useTranslations();
  const { role } = useSessionContext();

  return (
    <>
      <MainPageContent>
        <MainPageHeading>{translations('profile_heading')}</MainPageHeading>
        <Box width="100%" pl={2}>
          <Box mr={2} mt={4}>
            <ProfileInformationTable />
          </Box>
          {role === Role.MANAGER ? (
            <Box mr={2} mt={4}>
              <NotificationSettings />
            </Box>
          ) : null}
          <Box p={4}>
            <SignOut />
          </Box>
          <Text color="grey.100" fontSize={14}>
            {translations('profile_password_info')}
          </Text>
        </Box>
      </MainPageContent>
      <Toast />
    </>
  );
};

export default Profile;
