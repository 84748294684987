import React, { JSX } from 'react';
import { Box, Center, HStack, VStack } from '@chakra-ui/react';

import TooltipHelp from '../../common/components/TooltipHelp';
import colors from '../../styles/colors';

const boldStyle = {
  fontSize: '14px',
  fontWeight: 600,
};

const defaultStyle = {
  fontSize: '12px',
  marginTop: '0px',
};

const RestaurantStatisticsCard = (props: {
  imgSrc: string;
  imgAlt: string;
  statsNumber: number | string | undefined;
  statsDescription: string;
  tooltip?: string;
}): JSX.Element => {
  return (
    <Center p={2} width="stretch" minWidth="40px" maxW="100%" height="50px">
      <HStack width="stretch" height="stretch">
        <Box width="30%" height="auto">
          <img
            style={{
              width: 'auto',
              maxHeight: '100%',
              maxWidth: 'none',
            }}
            src={props.imgSrc}
            alt={props.imgAlt}
          />
        </Box>
        <VStack width="100%" align="start" textAlign="left" alignItems="start">
          <HStack>
            <h1 style={boldStyle}>{props.statsNumber}</h1>
            {props.tooltip && <TooltipHelp label={props.tooltip} bgColor={colors.beige[50]} />}
          </HStack>
          <h2 style={defaultStyle}>{props.statsDescription}</h2>
        </VStack>
      </HStack>
    </Center>
  );
};

export default RestaurantStatisticsCard;
