import React, { JSX } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { useTheme } from '@chakra-ui/react';

import './Toast.css';
import 'react-toastify/dist/ReactToastify.css';

const Toast = (): JSX.Element | null => {
  const { colors } = useTheme();
  return (
    <ToastContainer
      toastStyle={{
        backgroundColor: colors.grey[200],
        color: colors.white,
        fontFamily: 'Rubik',
        fontSize: '18px',
        alignItems: 'left',
        textAlign: 'left',
      }}
      transition={Slide}
      position="top-right"
      autoClose={5000}
      hideProgressBar
      closeOnClick
      pauseOnHover
      draggable
      closeButton={false}
      icon={false}
    />
  );
};
export default Toast;
