import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { components as ReactSelectComponents, MultiValueRemoveProps } from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import invariant from 'tiny-invariant';

import { MainPageContent } from '../../common/components/MainPageContent';
import { MultiSelect } from '../../common/components/MultiSelect/MultiSelect';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useAddRestaurantImage } from '../../services/restaurants';
import colors from '../../styles/colors';
import { ImagesInput } from './form/ImagesInput/ImagesInput';
import { createDummyOpeningHours } from './form/restaurantFormHelper';
import { RestaurantOpeningHoursDay } from './form/RestaurantOpeningHoursDay';
import { RestaurantEditForm, RestaurantEditFormFields, restaurantEditFormInitialValues } from './form/types';
import { useFormValidationSchema } from './form/useformValidationSchema';
import { FILE_SIZE_LIMIT } from './consts';
import { MetaCategory, RestaurantDraft, RestaurantOpeningHours } from './types';
import { useDraft } from './useDraft';

const EditRestaurant = (): JSX.Element => {
  const { language } = useLocalizationContext();
  const translations = useTranslations();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');

  const {
    metaCategories,
    closeDraft,
    createDraft,
    updateDraft,
    activeDraft,
    restaurant,
    restaurantImages,
    isLoading,
    isDraftFetched,
  } = useDraft(id);
  const { mutateAsync: addImage, isLoading: isUploading } = useAddRestaurantImage();

  const validationSchema = useFormValidationSchema();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: restaurantEditFormInitialValues,
  });

  const isOverrideOpeningHours = watch(RestaurantEditFormFields.OVERRIDE_OPENING_HOURS);

  useEffect(() => {
    if (isDraftFetched && restaurant && restaurantImages)
      if (activeDraft) {
        reset({
          [RestaurantEditFormFields.RESTAURANT_NAME]: activeDraft.restaurantName,
          [RestaurantEditFormFields.RESTAURANT_DETAIL]: activeDraft.restaurantDetail,
          [RestaurantEditFormFields.RESTAURANT_ADDRESS]: activeDraft.restaurantAddress,
          [RestaurantEditFormFields.PHONE_NUMBER]: activeDraft.phoneNumber,
          [RestaurantEditFormFields.WEBSITE_LINK]: activeDraft.websiteLink,
          [RestaurantEditFormFields.META_CATEGORIES]: activeDraft.metaCategoriesIds.map((metaCategory) => ({
            id: metaCategory,
          })),
          [RestaurantEditFormFields.OVERRIDE_OPENING_HOURS]: activeDraft.overrideOpeningHours,
          [RestaurantEditFormFields.RESTAURANT_OPENING_HOURS]: activeDraft.restaurantOpeningHours?.length
            ? activeDraft.restaurantOpeningHours
            : createDummyOpeningHours(),
          [RestaurantEditFormFields.IMAGES]: activeDraft.sortedImages,
        });
      } else {
        reset({
          [RestaurantEditFormFields.RESTAURANT_NAME]: restaurant.restaurantName,
          [RestaurantEditFormFields.RESTAURANT_DETAIL]: restaurant.restaurantDetail,
          [RestaurantEditFormFields.RESTAURANT_ADDRESS]: restaurant.restaurantAddress,
          [RestaurantEditFormFields.PHONE_NUMBER]: restaurant.phoneNumber,
          [RestaurantEditFormFields.WEBSITE_LINK]: restaurant.websiteLink,
          [RestaurantEditFormFields.META_CATEGORIES]: restaurant.metaCategories.map((metaCategory) => ({
            id: metaCategory.id,
          })),
          [RestaurantEditFormFields.OVERRIDE_OPENING_HOURS]: restaurant.overrideOpeningHours,
          [RestaurantEditFormFields.RESTAURANT_OPENING_HOURS]: restaurant.restaurantOpeningHours?.length
            ? restaurant.restaurantOpeningHours
            : createDummyOpeningHours(),
          [RestaurantEditFormFields.IMAGES]: restaurantImages.restaurantImages,
        });
      }
  }, [restaurant, restaurantImages, activeDraft, isDraftFetched, reset]);

  const onSubmit = async (data: RestaurantEditForm) => {
    const payload: RestaurantDraft = {
      ...data,
      sortedImages: data.images,
      metaCategoriesIds: data.metaCategories.map((metaCategory) => metaCategory.id),
    };
    if (activeDraft) {
      await updateDraft({ restaurantId: id as string, draft: { ...payload, id: activeDraft.id } });
    } else {
      await createDraft({ restaurantId: id as string, draft: payload });
    }
  };

  const handleAddFiles = async (files: FileList) => {
    const someFileExceedsSize = Array.from(files).some((file) => file.size > FILE_SIZE_LIMIT);
    if (someFileExceedsSize) {
      toast.error(translations('restaurant_draft_error_image_size', { '{{size}}': '1' }));
      return;
    }
    await Promise.all(
      Array.from(files).map(async (file) => {
        return addImage({ image: file, restaurantId: id as string });
      }),
    ).then((uploadedFiles) => {
      const currentImages = watch(RestaurantEditFormFields.IMAGES);
      setValue(RestaurantEditFormFields.IMAGES, [
        ...currentImages,
        ...uploadedFiles.map((uploadedFile, index) => ({
          file: { id: uploadedFile.id, url: uploadedFile.url },
          order: currentImages.length + index,
        })),
      ]);
    });
  };

  const handleBackButton = () => navigate(Routes.LOCATIONS_PATH);
  const handleCloseDraftButton = () => {
    closeDraft({ draftId: activeDraft?.id as string, restaurantId: restaurant?.id as string });
  };

  if (isLoading) {
    return (
      <MainPageContent>
        <Spinner />
      </MainPageContent>
    );
  }

  const MultiValueRemove = (props: MultiValueRemoveProps<MetaCategory>) => {
    const isNew = restaurant?.metaCategories?.find((mc) => mc.id === props.data.id);
    if (isNew) {
      return null;
    }
    return <ReactSelectComponents.MultiValueRemove {...props} />;
  };

  return (
    <MainPageContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack
          p="2"
          alignItems="flex-start"
          width="calc(100% - var(--chakra-space-4))"
          maxHeight={[
            'calc(100vh - var(--chakra-space-5))',
            'calc(100vh - var(--chakra-space-5))',
            'calc(100vh - var(--chakra-space-5))',
            'calc(100vh - var(--chakra-space-20))',
          ]}
          overflow="hidden"
        >
          <Box>
            <Heading variant="pageHeading" as="h1" textAlign="left" marginLeft={2} paddingTop={3}>
              {restaurant?.restaurantName}
              {activeDraft && (
                <Badge ml="2" variant="outline">
                  {translations('restaurant_draft_awaiting_approval')}
                </Badge>
              )}
            </Heading>
            <Heading variant="pageSubheading" as="h2" textAlign="left" marginLeft={2} paddingTop={3}>
              {translations('restaurant_draft_subheader')}
            </Heading>
          </Box>
          <Flex width="100%" pt="6" pl="2" pr="4" flexGrow="1" overflow="scroll" direction="column" gap={4}>
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl isInvalid={!!errors.images}>
                    <ImagesInput
                      isInvalid={!!errors.images}
                      onFilesAdd={handleAddFiles}
                      isUploading={isUploading}
                      fixedFiles={restaurantImages?.keyAccountGroupImages || []}
                      files={value}
                      onChange={onChange}
                    />
                    <FormHelperText textAlign="left">
                      {translations('restaurant_draft_helper_text_images')}
                    </FormHelperText>
                    <FormErrorMessage>{errors.images?.message}</FormErrorMessage>
                  </FormControl>
                );
              }}
              name={RestaurantEditFormFields.IMAGES}
            />
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl isInvalid={!!errors.restaurantName} isRequired>
                    <FormLabel htmlFor={RestaurantEditFormFields.RESTAURANT_NAME}>
                      {translations('restaurant_draft_label_name')}
                    </FormLabel>
                    <Input
                      data-testid="restaurant_draft_input_name"
                      value={value}
                      onChange={onChange}
                      id={RestaurantEditFormFields.RESTAURANT_NAME}
                      isInvalid={!!errors.restaurantName}
                      autoComplete="off"
                    ></Input>
                    <FormErrorMessage>{errors.restaurantName?.message}</FormErrorMessage>
                  </FormControl>
                );
              }}
              name={RestaurantEditFormFields.RESTAURANT_NAME}
            />
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl isInvalid={!!errors.restaurantDetail} isRequired>
                    <FormLabel htmlFor={RestaurantEditFormFields.RESTAURANT_DETAIL}>
                      {translations('restaurant_draft_label_description')}
                    </FormLabel>
                    <Input
                      value={value}
                      onChange={onChange}
                      id={RestaurantEditFormFields.RESTAURANT_DETAIL}
                      isInvalid={!!errors.restaurantDetail}
                      autoComplete="off"
                    ></Input>
                    <FormErrorMessage>{errors.restaurantDetail?.message}</FormErrorMessage>
                  </FormControl>
                );
              }}
              name={RestaurantEditFormFields.RESTAURANT_DETAIL}
            />
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl isInvalid={!!errors.restaurantAddress} isRequired>
                    <FormLabel htmlFor={RestaurantEditFormFields.RESTAURANT_ADDRESS}>
                      {translations('restaurant_draft_label_address')}
                    </FormLabel>
                    <Input
                      value={value}
                      onChange={onChange}
                      id={RestaurantEditFormFields.RESTAURANT_ADDRESS}
                      isInvalid={!!errors.restaurantAddress}
                      autoComplete="off"
                    ></Input>
                    <FormErrorMessage>{errors.restaurantAddress?.message}</FormErrorMessage>
                  </FormControl>
                );
              }}
              name={RestaurantEditFormFields.RESTAURANT_ADDRESS}
            />
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl isInvalid={!!errors.phoneNumber}>
                    <FormLabel htmlFor={RestaurantEditFormFields.PHONE_NUMBER}>
                      {translations('restaurant_draft_label_phone_number')}
                    </FormLabel>
                    <Input
                      value={value ?? ''}
                      onChange={onChange}
                      id={RestaurantEditFormFields.PHONE_NUMBER}
                      isInvalid={!!errors.phoneNumber}
                      autoComplete="off"
                    ></Input>
                    <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
                  </FormControl>
                );
              }}
              name={RestaurantEditFormFields.PHONE_NUMBER}
            />
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl isInvalid={!!errors.websiteLink}>
                    <FormLabel htmlFor={RestaurantEditFormFields.WEBSITE_LINK}>
                      {translations('restaurant_draft_label_website')}
                    </FormLabel>
                    <Input
                      value={value ?? ''}
                      onChange={onChange}
                      id={RestaurantEditFormFields.WEBSITE_LINK}
                      isInvalid={!!errors.websiteLink}
                      autoComplete="off"
                    ></Input>
                    <FormErrorMessage>{errors.websiteLink?.message}</FormErrorMessage>
                  </FormControl>
                );
              }}
              name={RestaurantEditFormFields.WEBSITE_LINK}
            />
            <Controller
              name={RestaurantEditFormFields.META_CATEGORIES}
              control={control}
              render={({ field: { value, onChange, name } }) => (
                <FormControl isInvalid={!!errors.metaCategories} isRequired>
                  <FormLabel htmlFor={RestaurantEditFormFields.META_CATEGORIES}>
                    {translations('restaurant_draft_label_meta_categories')}
                  </FormLabel>
                  <MultiSelect
                    id={RestaurantEditFormFields.META_CATEGORIES}
                    name={name}
                    isMulti
                    isClearable={false}
                    getOptionLabel={(option: Partial<MetaCategory>) =>
                      metaCategories?.find((metaCategory) => metaCategory.id === (option as MetaCategory).id)
                        ?.translations[language] ||
                      ((<span style={{ color: 'red' }}>{(option as MetaCategory).translations[language]}</span>) as any)
                    }
                    getOptionValue={(option: Partial<MetaCategory>) => (option as MetaCategory).id}
                    onChange={onChange}
                    value={value}
                    options={metaCategories}
                    components={{ MultiValueRemove }}
                  />
                  <FormErrorMessage>{errors.metaCategories?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControl display="flex" alignItems="center">
                  <Text opacity={value ? 0.2 : 1} mr="4">
                    {translations('restaurant_draft_label_override_opening_hours_off')}
                  </Text>
                  <Switch
                    id={RestaurantEditFormFields.OVERRIDE_OPENING_HOURS}
                    colorScheme="orange"
                    isChecked={value}
                    onChange={onChange}
                    sx={{ 'span.chakra-switch__track:not([data-checked])': { backgroundColor: 'orange.500' } }}
                  />
                  <Text opacity={value ? 1 : 0.2} ml="4">
                    {translations('restaurant_draft_label_override_opening_hours_on')}
                  </Text>
                </FormControl>
              )}
              name={RestaurantEditFormFields.OVERRIDE_OPENING_HOURS}
            />

            <Controller
              control={control}
              render={({ field: { value: openingHours, onChange } }) => (
                <FormControl>
                  {openingHours
                    ? (openingHours as RestaurantOpeningHours[]).map((day) => (
                        <RestaurantOpeningHoursDay
                          day={day}
                          openingHours={openingHours}
                          setOpeningHours={onChange}
                          isOverrideEnabled={!!isOverrideOpeningHours}
                          isLoadingGoogle={false}
                          key={day.day}
                        />
                      ))
                    : null}
                </FormControl>
              )}
              name={'restaurantOpeningHours'}
            />
          </Flex>

          <Box w="100%" pb={4} pt={4}>
            <Flex w="100%" justifyContent="flex-end">
              <Button onClick={handleBackButton} type="button" mr={2}>
                {translations('restaurant_draft_button_back')}
              </Button>
              {activeDraft && (
                <Button onClick={handleCloseDraftButton} type="button" mr={2}>
                  {translations('restaurant_draft_button_close')}
                </Button>
              )}
              <Button isDisabled={!isValid} variant="orangeSolid" type="submit">
                {activeDraft
                  ? translations('restaurant_draft_button_update')
                  : translations('restaurant_draft_button_create')}
              </Button>
            </Flex>
            <Text fontSize={14} mt={2} textAlign="right" color={colors.grey[100]}>
              {translations('restaurant_draft_info_text')}
            </Text>
          </Box>
        </VStack>
      </form>
    </MainPageContent>
  );
};

export default EditRestaurant;
