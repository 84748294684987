import React, { JSX } from 'react';

import colors from '../../../styles/colors';

type StepElementProps = {
  index: number;
  isActive: boolean;
};

const StepElement = ({ index, isActive }: StepElementProps): JSX.Element => {
  return (
    <>
      {index === 0 ? (
        <svg width="25" height="25">
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke={colors.orange[500]}
            strokeWidth="4"
            fill={isActive ? colors.grey[900] : colors.orange[500]}
          />
        </svg>
      ) : (
        <>
          <svg width="25" height="150" style={{ marginTop: '-1px' }}>
            <line
              x1="12"
              y1="0"
              x2="12"
              y2="150"
              stroke={!isActive ? colors.white : colors.orange[500]}
              strokeWidth="3"
            />
          </svg>
          <svg width="25" height="25" style={{ marginTop: '-1px' }}>
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke={isActive ? colors.orange[500] : colors.white}
              strokeWidth="4"
              fill={isActive ? colors.grey[900] : colors.beige[50]}
            />
          </svg>
        </>
      )}
    </>
  );
};

export default StepElement;
