import React from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { useInventoryCountsContext } from '../../context/hooks/use-inventory-counts-context.hook';
import { CheckYourCount } from './components/CheckYourCount/CheckYourCount';
import { ReviewYourCount } from './components/ReviewYourCount/ReviewYourCount';
import { StartInventory } from './components/StartInventory/StartInventory';
import { SubmitResults } from './components/SubmitResults/SubmitResults';
import { SubmitResultsSuccessModal } from './components/SubmitReusltsSuccessModal/SubmitResultsSuccessModal';

export const InventoryCountsFooter = () => {
  const { activeStep, setActiveStep } = useInventoryCountsContext();
  const submitInventoryCountSummaryModal = useDisclosure();
  const handleSubmitSuccess = () => {
    setActiveStep(0);
    submitInventoryCountSummaryModal.onOpen();
  };

  switch (activeStep) {
    case 0:
      return (
        <>
          <StartInventory />
          <SubmitResultsSuccessModal
            isOpen={submitInventoryCountSummaryModal.isOpen}
            onClose={submitInventoryCountSummaryModal.onClose}
          />
        </>
      );
    case 1:
      return <CheckYourCount />;
    case 2:
      return <ReviewYourCount />;
    case 3:
      return <SubmitResults handleSubmitSuccess={handleSubmitSuccess} />;
    default:
      return <></>;
  }
};
