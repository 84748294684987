import React, { JSX } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { ReportsStatisticsResponse } from '../model/ReportsStatisticsResponse';
import CardItem from './CardItem';

type ResultCardProps = {
  firstSetData: ReportsStatisticsResponse[];
  secondSetData: ReportsStatisticsResponse[];
};

const CardView = ({ firstSetData, secondSetData }: ResultCardProps): JSX.Element => {
  return (
    <Box>
      <Flex py={2} height="100%" width="100%" wrap="wrap">
        <Box width="50%">
          {firstSetData.map((statistics) => (
            <CardItem key={statistics.locationUId} data={statistics} />
          ))}
        </Box>
        <Box width="50%">
          {secondSetData.map((statistics) => (
            <CardItem key={statistics.locationUId} data={statistics} />
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default CardView;
