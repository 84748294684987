import React, { JSX } from 'react';
import { Box, Image, VStack } from '@chakra-ui/react';

import ScanditScanner from '../Scanner/ScanditScanner';

export type QrCodeScannerProps = {
  onScan: (data: string) => void;
  showScanConfirmation?: boolean;
  maxWidth?: string;
};

const getImagePath = (imageName: string) => require(`../../../assets/images/${imageName}`);

const QrCodeScanner = ({ onScan, showScanConfirmation, maxWidth }: QrCodeScannerProps): JSX.Element => {
  return (
    <VStack my="auto" maxWidth={maxWidth ?? '330px'} w={'100%'}>
      <Box position="relative" w={'100%'}>
        {showScanConfirmation ? (
          <Box position="absolute" left="50%" top="50%" zIndex={2} ml={-35} mt={-35}>
            <Image h={70} w={70} src={getImagePath('green-check-mark.png')} alt="green-check-mark.png" />
          </Box>
        ) : null}
        <ScanditScanner onScan={onScan} />
      </Box>
    </VStack>
  );
};

export default QrCodeScanner;
