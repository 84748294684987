export const createFormData = (data: object): FormData => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value != null) {
      if (Array.isArray(value)) {
        const arrayKey = value.every((element) => element instanceof File) ? key : `${key}[]`;
        value.forEach((v) => {
          formData.append(arrayKey, v);
        });
      } else if (typeof value === 'string') {
        formData.append(key, value.toString().trim());
      } else {
        formData.append(key, value);
      }
    }
  });
  return formData;
};
