import React, { JSX, useMemo, useRef } from 'react';
import { AlertDialogFooter, Box, Button } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import { RelevoInterface } from '../../../api/types';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import Dialog from '../ConfirmationDialog/Dialog';
import { TableType } from '../Table/model/enums/table-type.enum';
import { Table } from '../Table/Table';
import { ItemsSummaryAccordion } from './ItemsSummaryAccordion';
import { ItemsSummaryStatusText } from './ItemsSummaryStatusText';

interface FailureItem {
  uId: string;
  product?: RelevoInterface;
  message?: string;
}

type ItemsSummaryModalProps = {
  isVisible: boolean;
  dialogHeader: string | JSX.Element | JSX.Element[];
  summaryText: string | JSX.Element | JSX.Element[];
  failureText: string | JSX.Element | JSX.Element[];
  onCancelClicked: () => void;
  failureItems?: FailureItem[];
  successItems?: unknown[];
};
const ItemsSummaryModal = ({
  isVisible,
  dialogHeader,
  summaryText,
  failureText,
  onCancelClicked,
  failureItems,
  successItems,
}: ItemsSummaryModalProps): JSX.Element | null => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const cancelRef = useRef(null);

  const handleCancel = () => {
    onCancelClicked();
  };

  const columns = useMemo<ColumnDef<FailureItem>[]>(
    () => [
      {
        header: translations('item_category'),
        cell: ({ row }) => row.original.product?.category.translations[language],
      },
      {
        id: 'name',
        header: translations('item_name'),
        cell: ({ row }) => row.original.product?.productName,
      },
      { accessorKey: 'message', header: translations('error') },
    ],
    [language, translations],
  );

  return (
    <Dialog
      dialogHeader={dialogHeader}
      size="3xl"
      isOpen={isVisible}
      onClose={handleCancel}
      leastDestructiveRef={cancelRef}
      dialogFooter={
        <AlertDialogFooter justifyContent="flex-start">
          <Button colorScheme="orange" onClick={handleCancel}>
            {translations('ok')}
          </Button>
        </AlertDialogFooter>
      }
    >
      <ItemsSummaryStatusText
        summaryText={summaryText}
        successItems={successItems || []}
        failureItems={failureItems || []}
      />
      {failureItems?.length ? (
        <Box px={8} pt={4}>
          <ItemsSummaryAccordion buttonText={failureText} isOpen={true}>
            <Table type={TableType.SIMPLE} variant="relevoSummary" data={failureItems} columns={columns} />
          </ItemsSummaryAccordion>
        </Box>
      ) : null}
    </Dialog>
  );
};

export default ItemsSummaryModal;
