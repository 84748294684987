import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Center, Spinner } from '@chakra-ui/react';

import { Routes } from '../../../config/routes';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useInventoryCountsBanner } from './utils/use-inventory-counts-banner.hook';

type MainPageContentProp = {
  children: React.ReactNode | React.ReactNode[];
};

export const MainPageContent = ({ children }: MainPageContentProp): JSX.Element => {
  const { isFetchingRestaurantUser } = useLocalizationContext();
  const { showBanner, bannerText } = useInventoryCountsBanner();
  const navigate = useNavigate();
  const handleInventoryCounts = () => navigate(Routes.INVENTORY_COUNTS_PATH);

  return (
    <>
      {isFetchingRestaurantUser ? (
        <Spinner />
      ) : (
        <Box textAlign="center" mt={[0, 0, 0, 16]} ml="-6px">
          {showBanner ? (
            <Center
              position={'sticky'}
              top={[0, 0, 0, 16]}
              zIndex={6}
              ml={-1}
              bg={'red.500'}
              color={'white'}
              px={12}
              cursor="pointer"
              onClick={handleInventoryCounts}
            >
              {bannerText}
            </Center>
          ) : null}
          <>{children}</>
        </Box>
      )}
    </>
  );
};
