import React, { JSX } from 'react';
import { Heading } from '@chakra-ui/react';

type MainPageHeadingProps = {
  children: React.ReactNode;
};

const MainPageHeading = ({ children }: MainPageHeadingProps): JSX.Element => {
  return (
    <Heading variant="pageHeading" textAlign="left" ml={2} pt={3}>
      {children}
    </Heading>
  );
};

export default MainPageHeading;
