import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { RESTAURANT_USER_SURVEYS_QUERY_KEY } from '../api/apiConsts';
import { getSurveyUrl, registerCustomerSurveyCompletion } from '../api/restaurant-users-surveys';
import { RestaurantUserSurveyResponse } from '../modules/Home/model/RestaurantUserSurveyResponse';

export const useRestaurantUserSurvey = (
  isEnabled: boolean,
): QueryObserverResult<RestaurantUserSurveyResponse, AxiosError> => {
  return useQuery([RESTAURANT_USER_SURVEYS_QUERY_KEY], () => getSurveyUrl(), {
    enabled: isEnabled,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
};

export const useRegisterCustomerSurveyCompletion = (): UseMutationResult<void, AxiosError> => {
  const queryClient = useQueryClient();
  return useMutation(() => registerCustomerSurveyCompletion(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(RESTAURANT_USER_SURVEYS_QUERY_KEY);
    },
  });
};
