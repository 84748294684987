import React, { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Text, useTheme, VStack } from '@chakra-ui/react';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import Step from '../../common/components/Stepper/Step';
import Stepper from '../../common/components/Stepper/Stepper';
import { useTranslations } from '../../contexts/LocalizationContext';
import { ScannedRelevosContextProvider } from '../../contexts/ScannedRelevosContext';
import { ScannerMode } from '../RelevoAssignment/model/enums/scanner-mode.enum';
import CapturePhoto from './CapturePhoto';
import DamagedItemsSumUp from './DamagedItemsSumUp';
import ScanRelevo from './ScanRelevo';

const ReportDamagedItems = (): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const [img, setImg] = useState<string | null>(null);
  const [acceptedImg, setAcceptedImg] = useState<string | null>(null);
  const webcamRef = useRef<Webcam>(null);
  const handleCapture = useCallback(async () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setImg(imageSrc);
    }
  }, [webcamRef]);
  const [activeStep, setActiveStep] = useState(0);
  const [pageVisibility, setPageVisibility] = useState(document.visibilityState);

  const getPageVisibilityListener = () => setPageVisibility(document.visibilityState);

  useEffect(() => {
    document.addEventListener('visibilitychange', getPageVisibilityListener);
    return () => {
      document.removeEventListener('visibilitychange', getPageVisibilityListener);
    };
  }, []);

  const handleApplyImage = () => {
    setAcceptedImg(img);
    setActiveStep(1);
  };

  const handleRetake = () => setImg(null);

  const resetState = () => {
    setActiveStep(0);
    setImg(null);
    setAcceptedImg(null);
  };

  return (
    <>
      <MainPageContent>
        <Box mb={2}>
          <MainPageHeading>{translations('report_damaged_items_header')}</MainPageHeading>
          <ScannedRelevosContextProvider mode={ScannerMode.REPORT_DAMAGED_ITEM}>
            <VStack spacing="20px">
              <Flex bgColor={colors.beige[50]} marginTop="18px" width="100%" wrap="wrap" px={6} py={4}>
                <Box height="100%" py={4} width={['100%', '100%', '100%', '100%', '50%']}>
                  <HStack alignItems="stretch">
                    <Stepper activeIndex={activeStep}>
                      <Step index={0}>
                        <VStack alignItems="start" spacing="0px" textAlign="left">
                          <Text color={activeStep === 0 ? colors.grey[900] : colors.grey[100]} fontWeight="normal">
                            {translations('relevos_step_1')}
                          </Text>
                          <Text color={activeStep === 0 ? colors.grey[900] : colors.grey[100]} fontWeight="bold">
                            {translations('report_damaged_items_step_1_title')}
                          </Text>
                          <Text color={activeStep === 0 ? colors.grey[900] : colors.grey[100]} fontWeight="normal">
                            {translations('report_damaged_items_step_1_description')}
                          </Text>
                          {acceptedImg && (
                            <Flex>
                              <CheckIcon boxSize="20px" color={colors.green[500]} mr={2} my="auto" />
                              <Text fontSize="20px">{translations('report_damaged_items_photo_captured')}</Text>
                            </Flex>
                          )}
                        </VStack>
                      </Step>
                      <Step index={1}>
                        <VStack alignItems="start" spacing="0px" textAlign="left">
                          <Text color={activeStep === 1 ? colors.grey[900] : colors.grey[100]} fontWeight="normal">
                            {translations('relevos_step_2')}
                          </Text>
                          <Text color={activeStep === 1 ? colors.grey[900] : colors.grey[100]} fontWeight="bold">
                            {translations('report_damaged_items_step_2_title')}
                          </Text>
                          <Text color={activeStep === 1 ? colors.grey[900] : colors.grey[100]} fontWeight="normal">
                            {translations('report_damaged_items_step_2_description')}
                          </Text>
                        </VStack>
                      </Step>
                    </Stepper>
                  </HStack>
                </Box>
                {activeStep === 0 && pageVisibility !== 'hidden' ? (
                  <CapturePhoto
                    img={img}
                    webcamRef={webcamRef}
                    handleCapture={handleCapture}
                    handleAccept={handleApplyImage}
                    handleRetake={handleRetake}
                  />
                ) : (
                  <ScanRelevo />
                )}
              </Flex>

              <DamagedItemsSumUp acceptedImg={acceptedImg} resetState={resetState} />
            </VStack>
          </ScannedRelevosContextProvider>
        </Box>
      </MainPageContent>
    </>
  );
};

export default ReportDamagedItems;
