import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { Input, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import colors from '../../../../../../styles/colors';

export const QuickContactUserNickname = () => {
  const translations = useTranslations();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const trimValue = (event: React.FocusEvent<HTMLInputElement>) => setValue('nickname', event.target.value.trim());

  return (
    <Controller
      control={control}
      name="nickname"
      render={({ field: { value, onChange } }) => (
        <>
          <Input
            aria-label={'nickname'}
            p={0}
            value={value}
            onChange={onChange}
            variant="transparentUnderline"
            my="auto"
            id={'nickname'}
            onBlur={trimValue}
            placeholder={translations('quick_contacts_add_new_contact_nickname')}
          />
          <Text color={colors.red[500]} fontSize="12px" textAlign={'left'} height={4}>
            {(errors.nickname as FieldError)?.message}
          </Text>
        </>
      )}
    />
  );
};
