import React, { JSX } from 'react';
import Select, { SingleValue } from 'react-select';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useRestaurantQuickContacts } from '../../../../services/quick-contacts';
import { QuickContactResponse } from '../../../QuickContacts/model/quick-contact-response';
import { getAssignSelectStyle } from '../../styles/relevo-assign-select-style';

type RestaurantSelectProps = {
  quickContact: QuickContactResponse | null;
  onQuickContactChange: (option: SingleValue<QuickContactResponse>) => void;
  restaurantId?: string;
};

export const QuickContactSelect = ({
  quickContact,
  restaurantId,
  onQuickContactChange,
}: RestaurantSelectProps): JSX.Element | null => {
  const translations = useTranslations();
  const { data: quickContacts } = useRestaurantQuickContacts(restaurantId);

  return (
    <Select<QuickContactResponse>
      value={quickContact}
      getOptionLabel={(option) => option.quickContactName}
      placeholder={translations('quick_contacts_select_placeholder')}
      styles={getAssignSelectStyle<QuickContactResponse>()}
      options={quickContacts}
      onChange={onQuickContactChange}
      isDisabled={!restaurantId}
    />
  );
};
