import React from 'react';
import { Box, Text, useTheme } from '@chakra-ui/react';

import { FullPageLoader } from '../../common/components/Loader/FullPageLoader';
import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useGetDocuments } from '../../services/documents';
import DocumentsSection from './DocumentsSection';

const InfoPoint = () => {
  const translations = useTranslations();
  const { colors } = useTheme();

  const {
    data: documents,
    isLoading: isLoadingDocuments,
    isFetching: isFetchingDocuments,
    isError: isDocumentsError,
  } = useGetDocuments();

  return (
    <MainPageContent>
      <MainPageHeading>{translations('infopoint_heading')}</MainPageHeading>
      {documents ? (
        <FullPageLoader show={isLoadingDocuments || isFetchingDocuments}>
          <Box p={2}>
            {documents?.map((document) => <DocumentsSection document={document} key={document.section.id} />)}
          </Box>
        </FullPageLoader>
      ) : null}
      {isDocumentsError ? <Text color={colors.orange[500]}>{translations('something_went_wrong')}</Text> : null}
    </MainPageContent>
  );
};

export default InfoPoint;
