import { useQuery } from 'react-query';

import { RESTAURANT_CATEGORIES_QUERY_KEY } from '../api/apiConsts';
import { getRestaurantCategories } from '../api/restaurant';

export const useRestaurantCategories = (restaurantId: string) => {
  return useQuery([RESTAURANT_CATEGORIES_QUERY_KEY], () => getRestaurantCategories(restaurantId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!restaurantId.length,
  });
};
