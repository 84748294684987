import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { Text } from '@chakra-ui/react';

import RestaurantMultiSelect from '../../../../../../common/components/RestaurantSelect/RestaurantMultiSelect';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useQuickContactsRestaurants } from '../../../../../../services/quick-contacts';
import colors from '../../../../../../styles/colors';
import { useQuickContactsFilterContext } from '../../../../context/QuickContactsFilterContext/hooks/useQuickContactsFilterContext';

export const QuickContactRestaurants = () => {
  const translations = useTranslations();
  const { quickContactsState } = useQuickContactsFilterContext();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { data: restaurants } = useQuickContactsRestaurants(quickContactsState.selectedFnbGroupId);

  const selectOptions =
    restaurants?.map((res) => {
      return {
        id: res.id,
        restaurantName: res.name,
      };
    }) || [];

  return (
    <Controller
      control={control}
      name="selectedRestaurants"
      render={({ field: { value, onChange } }) => (
        <>
          <RestaurantMultiSelect
            selectedRestaurantIds={value}
            userRestaurants={selectOptions}
            onSelectRestaurants={(e) => onChange(e.map((v) => v.value))}
            placeholder={translations('quick_contacts_add_new_contact_restaurant')}
          />
          <Text color={colors.red[500]} fontSize="12px" textAlign={'left'} height={4}>
            {(errors.selectedRestaurants as FieldError)?.message}
          </Text>
        </>
      )}
    />
  );
};
