import React, { useRef } from 'react';
import { AlertDialogFooter, Button, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import Dialog from '../../../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';

type SubmitResultsSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SubmitResultsSuccessModal = ({ isOpen, onClose }: SubmitResultsSuccessModalProps) => {
  const translations = useTranslations();
  const submitCancelRef = useRef(null);
  return (
    <Dialog
      size={'xl'}
      dialogHeader={translations('inventory_counts_submit_success_modal_header')}
      leastDestructiveRef={submitCancelRef}
      isOpen={isOpen}
      onClose={onClose}
      dialogFooter={
        <AlertDialogFooter>
          <Button variant="white" color={'orange.500'} onClick={onClose}>
            {translations('inventory_counts_submit_success_modal_close_button_label')}
          </Button>
        </AlertDialogFooter>
      }
    >
      <Text>{translations('inventory_counts_submit_success_modal_text')}</Text>
      <UnorderedList pt={4}>
        <ListItem>{translations('inventory_counts_submit_success_modal_next_step_1')}</ListItem>
        <ListItem>{translations('inventory_counts_submit_success_modal_next_step_2')}</ListItem>
        <ListItem>{translations('inventory_counts_submit_success_modal_next_step_3')}</ListItem>
        <ListItem>{translations('inventory_counts_submit_success_modal_next_step_4')}</ListItem>
      </UnorderedList>
    </Dialog>
  );
};
