import { BaseQueryParams } from '../common/models/base-query-params';
import { apiPaths } from '../config/apiPaths';
import { CommunityStatisticsReponse } from '../modules/Home/model/CommunityStatisticsReponse';
import { LocationsStatisticsResponse } from '../modules/Locations/model/LocationsStatisticsResponse';
import axiosInstance from './axios';

export enum LocationsTimeFrameEnum {
  TODAY = 1,
  LAST_7_DAYS = 7,
  LAST_30_DAYS = 30,
  ALL = 0,
}

export const getCommunityStatistics = (restaurantIds: string[]): Promise<CommunityStatisticsReponse> =>
  axiosInstance
    .get<CommunityStatisticsReponse>(apiPaths.COMMUNITY_STATISTICS_PATH, { params: { restaurantIds } })
    .then(({ data }) => data);

export const getLocationsStatistics = (
  restaurantIds: string[],
  timeframe: LocationsTimeFrameEnum,
  { offset, limit, q }: BaseQueryParams,
): Promise<LocationsStatisticsResponse> => {
  const urlParams = new URLSearchParams({ ...(q && { q }), offset: (offset - 1).toString(), limit: limit.toString() });
  restaurantIds.forEach((restaurantId) => urlParams.append('restaurantIds[]', restaurantId));
  urlParams.append('timeframe', timeframe.toString());
  return axiosInstance
    .get<LocationsStatisticsResponse>(apiPaths.LOCATION_STATISTICS_PATH, { params: urlParams })
    .then(({ data }) => data);
};
