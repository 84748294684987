import React from 'react';
import { Box, Center, Heading, useTheme } from '@chakra-ui/react';

import { MainPageContent } from '../../../../common/components/MainPageContent';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { ScannedRelevosContextProvider } from '../../../../contexts/ScannedRelevosContext';
import { ScannerMode } from '../../model/enums/scanner-mode.enum';
import ReturnStationScanner from './ReturnStationScanner';

const ReturnStation = (): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();

  return (
    <MainPageContent>
      <Heading variant="returnStationHeading" paddingTop="12px">
        {translations('return_station_heading')}
      </Heading>
      <ScannedRelevosContextProvider mode={ScannerMode.RETURN}>
        <Box>
          <Center bgColor={colors.beige[50]} marginTop="18px" width="100%" height="400px" py={8}>
            <ReturnStationScanner />
          </Center>
        </Box>
      </ScannedRelevosContextProvider>
    </MainPageContent>
  );
};

export default ReturnStation;
