import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDisclosure } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useUpdateNotificationSettings } from '../../../../../../services/notification-settings';
import { useNotificationSettingsContext } from '../../context/hooks/use-notification-settings-context.hook';
import { UpdateNotificationSettingsRequest } from '../../model/update-notification-settings-request';
import { AllLocationsConfirmationModal } from '../AllLocationsConfirmationModal/AllLocationsConfirmationModal';
import { NotificationSettingsEnabled } from '../NotificationSettingsTable/model/enums/notification-settings-enabled.enum';
import { NotificationSettingsLocations } from '../NotificationSettingsTable/model/enums/notification-settings-locations.enum';
import { NotificationSettingsTableData } from '../NotificationSettingsTable/model/notification-settings-table-data';
import { NotificationSettingsTable } from '../NotificationSettingsTable/NotificationSettingsTable';
import { buildNotificationSettingsUpdateRequest } from './utils/notification-settings-form.helper';

export const NotificationSettingsForm = () => {
  const translations = useTranslations();
  const { isEditMode, handleSwitchEditMode, notificationSettingsTableData } = useNotificationSettingsContext();
  const { isOpen: isModalOpen, onOpen: handleOpenModal, onClose: handleCloseModal } = useDisclosure();
  const { mutate: updateNotificationSettings } = useUpdateNotificationSettings();
  const methods = useForm<NotificationSettingsTableData>({
    defaultValues: notificationSettingsTableData,
  });

  const handleUpdateNotificationSettings = useCallback(
    (form: NotificationSettingsTableData) => {
      const updateRequest: UpdateNotificationSettingsRequest = buildNotificationSettingsUpdateRequest(form);
      updateNotificationSettings(
        { request: updateRequest },
        {
          onSuccess: async () => toast.success(translations('notification_settings_successfully_saved')),
          onError: async () => toast.error(<b>{translations('something_went_wrong')}</b>),
        },
      );
      handleSwitchEditMode(!isEditMode);
    },
    [handleSwitchEditMode, isEditMode, translations, updateNotificationSettings],
  );

  const handleFormSubmit = methods.handleSubmit((form) => {
    const { locationsSetting, selectedLocations, enabled } = form;
    if (locationsSetting === NotificationSettingsLocations.SELECTED && !selectedLocations.length) {
      toast.warn(translations('notification_settings_selected_locations_warning'));
    } else {
      if (locationsSetting === NotificationSettingsLocations.ALL && enabled === NotificationSettingsEnabled.ON) {
        handleOpenModal();
      } else {
        handleUpdateNotificationSettings(form);
      }
    }
  });

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleFormSubmit}>
          <NotificationSettingsTable />
        </form>
        <AllLocationsConfirmationModal
          isOpen={isModalOpen}
          onConfirm={handleUpdateNotificationSettings}
          onCancel={handleCloseModal}
        />
      </FormProvider>
    </>
  );
};
