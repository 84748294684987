import React, { ChangeEvent, JSX, useState } from 'react';
import { Button, Input, InputGroup, InputRightElement, useTheme } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

type Props = {
  placeholder: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const ResetPassword = ({ placeholder, value, onChange }: Props): JSX.Element => {
  const translations = useTranslations();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const { colors } = useTheme();

  return (
    <InputGroup size="md">
      <Input
        required
        type={show ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        variant="flushed"
        color={colors.orange[500]}
        borderColor={colors.orange[500]}
        focusBorderColor={colors.orange[500]}
        autoComplete="off"
      />
      <InputRightElement width="4.5rem">
        <Button fontWeight="normal" size="sm" variant="ghost" onClick={handleClick}>
          {show ? translations('reset_password_hide') : translations('reset_password_show')}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default ResetPassword;
