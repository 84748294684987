import React from 'react';
import { Box } from '@chakra-ui/react';

import { RestaurantSelect } from '../../../../common/components/RestaurantSelect/RestaurantSelect';
import { SelectOption } from '../../../../common/components/Select/model/selectOption';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { RestaurantInfo } from '../../../../contexts/SessionContext/SessionContext';
import { getAssignSelectStyle } from '../../styles/relevo-assign-select-style';
import { SelectAssignmentMode } from '../SelectAssignmentMode/SelectAssignmentMode';
import { QuickContactReturnItemsList } from './components/QuickContactReturnItemsList';

type QuickContactsReturnItemsListProps = {
  restaurants: RestaurantInfo[];
};

export const QuickContactsReturnItems = ({ restaurants }: QuickContactsReturnItemsListProps) => {
  const { restaurantId, setRestaurantId, resetRestaurantId } = useScannedRelevosContext();

  return (
    <Box>
      <Box
        bg={['beige.50', 'beige.50', 'beige.50', 'none', 'none']}
        width={'100%'}
        px={[4, 4, 2, 2, 2]}
        py={[6, 6, 6, 0, 0]}
      >
        <Box display={['block', 'block', 'block', 'none', 'none']} width={['100%', '100%', '50%', '50%']}>
          <SelectAssignmentMode />
        </Box>
        <Box
          width="100%"
          alignItems={['center', 'center', 'none', 'none']}
          justifyContent={['center', 'center', 'left', 'left']}
          display={'flex'}
          pt={[4, 4, 4, 0]}
        >
          <Box width={'270px'}>
            <RestaurantSelect
              userRestaurants={restaurants}
              restaurantId={restaurantId}
              setRestaurantId={setRestaurantId}
              resetRestaurantId={resetRestaurantId}
              styles={getAssignSelectStyle<SelectOption>()}
            />
          </Box>
        </Box>
      </Box>
      {restaurantId ? <QuickContactReturnItemsList restaurantId={restaurantId} /> : null}
    </Box>
  );
};
