import React, { JSX, useRef } from 'react';
import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useTheme,
} from '@chakra-ui/react';

import { Language } from '../../../contexts/LocalizationContext/types';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useSessionContext } from '../../../contexts/SessionContext';
import { useUpdateRestaurantUser } from '../../../services/restaurant-user';
import Dialog from '../ConfirmationDialog/Dialog';

export type LanguageSelectOption = {
  label: string;
  value: Language;
  icon: string;
};

const getLanguageIconPath = (iconName: string) => require(`../../../assets/icons/languages/${iconName}`);

// FYI: flags images comes from https://github.com/HatScripts/circle-flags/tree/gh-pages/flags
export const languageSelectOptions: LanguageSelectOption[] = [
  { value: Language.DE, label: 'Deutsch', icon: 'de.svg' },
  { value: Language.EN, label: 'English', icon: 'en.svg' },
  { value: Language.FR, label: 'Français', icon: 'fr.svg' },
];

export enum SelectLanguageMode {
  MOBILE = 'MOBILE',
  DEFAULT = 'DEFAULT',
}

type SelectedLanguageProps = {
  mode: SelectLanguageMode;
  textColor?: string;
  onOpen?: () => void;
  onClose?: () => void;
  onChange?: () => void;
};

export const LanguageSelect = ({ textColor, mode, onOpen, onClose, onChange }: SelectedLanguageProps): JSX.Element => {
  const { language, setLanguage } = useLocalizationContext();
  const { doesSessionExist } = useSessionContext();
  const { colors } = useTheme();
  const { mutate: updateProfileLanguage } = useUpdateRestaurantUser();

  const handleLanguageChange = (item: LanguageSelectOption) => () => {
    setLanguage(item.value);
    if (doesSessionExist) {
      updateProfileLanguage({ request: { language: item.value } });
    }
    if (onChange) {
      onChange();
    }
  };

  const ref = useRef(null);
  const getMobileMenuList = (isOpen: boolean) => (
    <Dialog size="xs" isOpen={isOpen} dialogFooter={<></>} leastDestructiveRef={ref} onClose={() => {}}>
      {languageSelectOptions.map((option) => (
        <Box key={option.value} cursor="pointer" m={1} p={1} onClick={handleLanguageChange(option)}>
          <HStack w="100%">
            <Box w="30%">
              <Image
                h="25px"
                w="25px"
                cursor="pointer"
                src={getLanguageIconPath(`${option.value}.svg`)}
                alt={option.value}
              />
            </Box>
            <Box w="60%">
              <Text color={colors.grey[500]} textAlign="left">
                {option.label}
              </Text>
            </Box>
            <Box w="10%">
              <svg width="25" height="25">
                <circle
                  cx="12"
                  cy="12"
                  r="4"
                  stroke={language === option.value ? colors.orange[500] : colors.grey[100]}
                  strokeWidth="4"
                  fill={language === option.value ? colors.orange[500] : colors.grey[100]}
                />
              </svg>
            </Box>
          </HStack>
        </Box>
      ))}
    </Dialog>
  );

  const getDesktopMenuList = () => (
    <MenuList mt="8px" borderRadius={0} padding={0} mr={-3} width="200px">
      {languageSelectOptions.map((option) => (
        <MenuItem key={option.value} onClick={handleLanguageChange(option)} bg={colors.beige[50]} p={1}>
          <HStack w="100%" borderRadius={6} bg={language === option.value ? colors.white : colors.beige[50]} p={1}>
            <Box w="147px">
              <Text color={colors.grey[500]} textAlign="right">
                {option.label}
              </Text>
            </Box>
            <Box w="53px">
              <Image
                h="25px"
                w="25px"
                cursor="pointer"
                src={getLanguageIconPath(`${option.value}.svg`)}
                alt={option.value}
              />
            </Box>
          </HStack>
        </MenuItem>
      ))}
    </MenuList>
  );

  return (
    <Center h={mode === SelectLanguageMode.MOBILE ? '45px' : '71px'} cursor="pointer" pt={0}>
      <Menu isLazy onOpen={onOpen} onClose={onClose}>
        {({ isOpen }) => (
          <>
            <MenuButton
              mr={3}
              as={Button}
              rightIcon={<Image height="25px" src={getLanguageIconPath(`${language}.svg`)} alt={language} />}
              color={textColor ? textColor : [colors.grey[200], colors.grey[200], colors.grey[200], colors.white]}
              backgroundColor="transparent"
              _hover={{
                background: 'transparent',
              }}
              _active={{
                background: 'transparent',
              }}
              _focus={{
                background: 'transparent',
              }}
            >
              {language.toUpperCase()}
            </MenuButton>
            {mode === SelectLanguageMode.MOBILE ? getMobileMenuList(isOpen) : getDesktopMenuList()}
          </>
        )}
      </Menu>
    </Center>
  );
};
