import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import { RelevosProductCategoryWithImage } from '../../../../../../api/types';
import { ItemsSummaryAccordion } from '../../../../../../common/components/ItemsSummaryModal/ItemsSummaryAccordion';
import { ItemsSummaryStatusText } from '../../../../../../common/components/ItemsSummaryModal/ItemsSummaryStatusText';
import { TableType } from '../../../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../../../common/components/Table/Table';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import {
  ReturnUnknownRelevosInformationFailureResponse,
  ReturnUnknownRelevosInformationSuccessResponse,
} from '../../../../model/return-unknown-relevos-response';
import { ApplicationUserResponse } from '../../../../model/unknown-relevos-list-response';

type AddNewContactsResultModalBodyProps = {
  failureItems: ReturnUnknownRelevosInformationFailureResponse[];
  successItems: ReturnUnknownRelevosInformationSuccessResponse[];
  categories?: RelevosProductCategoryWithImage[];
  users?: ApplicationUserResponse[];
};

export const ReturnUnknownItemsResultModalBody = ({
  successItems,
  failureItems,
  categories,
  users,
}: AddNewContactsResultModalBodyProps) => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const successItemsNumber = useMemo(
    () =>
      successItems.map((item) => item.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    [successItems],
  );
  const failureItemsNumber = useMemo(
    () =>
      failureItems.map((item) => item.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    [failureItems],
  );
  const summaryText = translations('unknown_items_return_result_modal_summary_text', {
    '{{successItems}}': successItemsNumber.toString(),
    '{{allItems}}': (successItemsNumber + failureItemsNumber).toString(),
  });

  const columns = useMemo<ColumnDef<ReturnUnknownRelevosInformationFailureResponse>[]>(
    () => [
      {
        id: 'category',
        header: translations('unknown_items_return_result_modal_category'),
        cell: ({ row }) => (
          <>{categories?.find((category) => category.id === row.original.categoryId)?.translations[language]}</>
        ),
      },
      {
        id: 'user',
        header: translations('unknown_items_return_result_modal_user'),
        cell: ({ row }) => <>{users?.find((user) => user.id === row.original.userId)?.firstName}</>,
      },
      { accessorKey: 'quantity', header: translations('unknown_items_return_result_modal_quantity') },
      {
        id: 'error',
        header: translations('error'),
        cell: ({ row }) => <>{row.original.message || row.original.error}</>,
      },
    ],
    [categories, language, translations, users],
  );

  return (
    <>
      <ItemsSummaryStatusText
        summaryText={summaryText}
        successItems={successItems || []}
        failureItems={failureItems || []}
      />
      {failureItems?.length ? (
        <Box px={8} pt={4}>
          <ItemsSummaryAccordion
            buttonText={translations('unknown_items_return_result_modal_failure_text')}
            isOpen={true}
          >
            <Table type={TableType.SIMPLE} data={failureItems} columns={columns} variant={'relevoSummary'} />
          </ItemsSummaryAccordion>
        </Box>
      ) : null}
    </>
  );
};
