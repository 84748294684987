import React from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Flex, Heading } from '@chakra-ui/react';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { Role, useSessionContext } from '../../contexts/SessionContext';
import { useQuickContactsFnbGroups, useQuickContactsManagementEnabled } from '../../services/quick-contacts';
import { QuickContactsFilters } from './components/QuickContactsFilters/QuickContactsFilters';
import { QuickContactsManagement } from './components/QuickContactsManagement/QuickContactsManagement';
import { QuickContactsTable } from './components/QuickContactsTable/QuickContactsTable';
import { QuickContactsFilterContextProvider } from './context/QuickContactsFilterContext/QuickContactsContext';

export const QuickContacts = () => {
  const translations = useTranslations();
  const { data: fnbGroups } = useQuickContactsFnbGroups();
  const { role } = useSessionContext();
  const { data: isQuickContactsManagementEnabled, isLoading: isLoadingManagementEnabled } =
    useQuickContactsManagementEnabled(role === Role.MANAGER);

  if (!isQuickContactsManagementEnabled && !isLoadingManagementEnabled) {
    return <Navigate to={Routes.HOME_PATH} />;
  }

  return (
    <MainPageContent>
      <MainPageHeading>{translations('quick_contacts_heading')}</MainPageHeading>
      {fnbGroups ? (
        <QuickContactsFilterContextProvider fnbGroups={fnbGroups}>
          <Flex width="100%" direction="column">
            <Flex width="100%" direction="column" bg="beige.50" p={2}>
              <Flex display={['block', 'block', 'none', 'none', 'none']} width={'100%'}>
                <QuickContactsManagement />
              </Flex>
              <Flex width="100%" justifyContent="space-between">
                <Flex width={['100%', '100%', '50%', '50%']}>
                  <QuickContactsFilters />
                </Flex>
                <Flex
                  display={['none', 'none', 'block', 'block', 'block']}
                  justifyContent="flex-end"
                  alignItems={'end'}
                >
                  <QuickContactsManagement />
                </Flex>
              </Flex>
            </Flex>
            <Flex width="100%">
              <Box width={['100%', '100%', '100%', '50%']}>
                {fnbGroups.length > 0 ? (
                  <QuickContactsTable />
                ) : (
                  <Heading variant={'warningHeading'} ml={2} pt={3}>
                    {translations('quick_contacts_no_fnb_groups')}
                  </Heading>
                )}
              </Box>
            </Flex>
          </Flex>
        </QuickContactsFilterContextProvider>
      ) : null}
    </MainPageContent>
  );
};
