import { createContext } from 'react';
import { BarcodeCaptureFeedback, BarcodeCaptureListener } from 'scandit-web-datacapture-barcode';

export interface SDK {
  initialize: () => Promise<void>;
  cleanup: () => Promise<void>;
  connectToElement: (element: HTMLElement) => void;
  detachFromElement: () => void;
  enableCamera: (enabled: boolean) => Promise<void>;
  enableScanning: (enabled: boolean) => Promise<void>;
  enableFeedback: (enabled: boolean) => Promise<void>;
  getFeedback: () => BarcodeCaptureFeedback | undefined;
  addBarcodeCaptureListener: (callback: BarcodeCaptureListener) => void;
  removeBarcodeCaptureListener: (callback: BarcodeCaptureListener) => void;
}

export interface SDKWithLoadingStatus {
  loading: boolean;
  loaded: boolean;
  sdk: SDK;
}
export const SDKContext = createContext({
  loaded: false,
  loading: false,
  sdk: null,
} as unknown as SDKWithLoadingStatus);
