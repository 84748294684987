export enum InventoryCountStatus {
  UNDER_REVIEW = 'UNDER_REVIEW',
  INVOICE_OPEN = 'INVOICE_OPEN',
  INVOICE_PAID = 'INVOICE_PAID',
  INVOICE_REJECTED = 'INVOICE_REJECTED',
  INVENTORY_IN_PROGRESS = 'INVENTORY_IN_PROGRESS',
  INVENTORY_FINISHED = 'INVENTORY_FINISHED',
  INVENTORY_REJECTED = 'INVENTORY_REJECTED',
  INVOICE_AVAILABLE = 'INVOICE_AVAILABLE',
  INVENTORY_DELETED = 'INVENTORY_DELETED',
}
