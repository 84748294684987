/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiPaths } from 'config/apiPaths';
import { SignInFormInputs } from 'modules/SignIn/types';

import axios from './axios';
import {
  ResetPasswordRequestData,
  RestaurantUserRequest,
  RestaurantUserResponse,
  SupertokensApiGeneralResponseSuccess,
  SupertokensApiResponseFailure,
  SupertokensSignInApiResponseSuccess,
  UserProfileResponse,
} from './types';

export const headers = { rid: 'emailpassword', 'x-app-name': 'PARTNER' };

export const signInUser = async (
  formData: SignInFormInputs,
): Promise<SupertokensSignInApiResponseSuccess | SupertokensApiResponseFailure> => {
  const { email, password } = formData;

  const requestData = {
    formFields: [
      {
        id: 'email',
        value: email,
      },
      {
        id: 'password',
        value: password,
      },
    ],
  };

  return axios
    .post<SupertokensSignInApiResponseSuccess | SupertokensApiResponseFailure>(apiPaths.SIGN_IN_PATH, requestData, {
      headers,
    })
    .then((response) => response.data);
};

export const sendResetPasswordEmail = async (
  userEmail: string,
): Promise<SupertokensApiGeneralResponseSuccess | SupertokensApiResponseFailure> => {
  const requestData = {
    formFields: [
      {
        id: 'email',
        value: userEmail,
      },
    ],
  };

  return axios
    .post<SupertokensApiGeneralResponseSuccess | SupertokensApiResponseFailure>(
      apiPaths.SEND_RESET_PASSWORD_EMAIL_PATH,
      requestData,
      { headers },
    )
    .then((response) => response.data);
};

export const resetPassword = async ({
  password,
  token,
}: ResetPasswordRequestData): Promise<SupertokensApiGeneralResponseSuccess | SupertokensApiResponseFailure> => {
  const requestData = {
    formFields: [
      {
        id: 'password',
        value: password,
      },
    ],
    token,
  };

  return axios
    .post<SupertokensApiGeneralResponseSuccess | SupertokensApiResponseFailure>(
      apiPaths.RESET_PASSWORD_PATH,
      requestData,
      {
        headers,
      },
    )
    .then((response) => response.data);
};

export const getProfile = async (): Promise<UserProfileResponse> => {
  return axios.get(`${apiPaths.RESTAURANT_USER_PATH}/profile`).then((response) => response.data);
};

export const getRestaurantUser = async (): Promise<RestaurantUserResponse> => {
  return axios.get(apiPaths.RESTAURANT_USER_PATH).then((response) => response.data);
};

export const updateRestaurantUser = (updateProfileRequest: RestaurantUserRequest): Promise<RestaurantUserResponse> => {
  return axios.patch(apiPaths.RESTAURANT_USER_PATH, updateProfileRequest);
};
