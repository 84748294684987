import React, { JSX } from 'react';
import { Box, CircularProgress, CircularProgressLabel, HStack } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import { ReportsStatisticsResponse } from 'modules/Reports/model/ReportsStatisticsResponse';
import { handleReturnRate } from 'utils/statisticsHelper';

import { CHART_COLORS_HEX, DEFAULT_CHART_COLOR_HEX } from '../constants';
import GraphBox from '../GraphBox';

const getIconPath = (iconName: string) => require(`../../../../assets/icons/${iconName}`);

const DEFAULT_CIRCULAR_PROGRESS_PROPS = {
  size: '120px',
  thickness: 16,
  mt: 4,
  capIsRound: true,
};

const ReturnRateBox = ({ data }: { data: ReportsStatisticsResponse[] }): JSX.Element => {
  const translations = useTranslations();
  const rates = data.map((dataset) => dataset.returnRate);

  return (
    <GraphBox
      heading={translations('reports_graph_view_return_rate')}
      iconSrc={getIconPath('location_stats_return_rate.svg')}
      iconAlt="return rate"
      boxProps={{ p: 6, alignSelf: 'flex-start', flexBasis: '20%', sx: { '@media print': { marginTop: 40 } } }}
    >
      <HStack justifyContent="space-between" mt={4}>
        {rates.map((value, index) => (
          <Box key={data[index].locationUId}>
            <CircularProgress
              {...DEFAULT_CIRCULAR_PROGRESS_PROPS}
              value={value * 100}
              color={CHART_COLORS_HEX[index] ? CHART_COLORS_HEX[index] : DEFAULT_CHART_COLOR_HEX}
            >
              <CircularProgressLabel fontWeight={800} fontSize={24}>
                {handleReturnRate(value)}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
        ))}
      </HStack>
    </GraphBox>
  );
};

export default ReturnRateBox;
