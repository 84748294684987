import React, { JSX, useMemo } from 'react';
import { Box, Heading, HStack, useTheme } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import { ReportsStatisticsResponse } from 'modules/Reports/model/ReportsStatisticsResponse';
import { useReportGraphLabels } from 'modules/Reports/useReportGraphLabels';
import { handleReturnTime } from 'utils/statisticsHelper';

import { CHART_COLORS_HEX } from '../constants';
import GraphBox from '../GraphBox';
import ReturnTimeChart from './ReturnTimeChart';

const getIconPath = (iconName: string) => require(`../../../../assets/icons/${iconName}`);

const ReturnTimeBox = ({ data }: { data: ReportsStatisticsResponse[] }): JSX.Element => {
  const translations = useTranslations();
  const graphLabels = useReportGraphLabels(data);
  const { colors } = useTheme();
  const legendLabels = useMemo(
    () =>
      data.map((dataset) => {
        if (dataset.totalAccountBorrowings && dataset.totalAccountBorrowings > 0) {
          const { returnTimeDays, returnTimeHours } = handleReturnTime(dataset.returnTime);
          return `${returnTimeDays}${translations('days_shortcut')} ${returnTimeHours}${translations(
            'hours_shortcut',
          )}`;
        }
        return `0${translations('days_shortcut')} 0${translations('hours_shortcut')}`;
      }),
    [data, translations],
  );
  const returnTimesData = data.map((dataSet) => dataSet.returnTime);

  return (
    <GraphBox
      heading={translations('reports_graph_view_return_time')}
      iconSrc={getIconPath('location_stats_return_time.svg')}
      iconAlt="return rate"
      boxProps={{ p: 6, alignSelf: 'flex-start', flexBasis: '20%', sx: { '@media print': { marginTop: 40 } } }}
    >
      <Box marginTop={6} maxHeight={90}>
        <ReturnTimeChart labels={graphLabels} returnTimes={returnTimesData} />
      </Box>
      <HStack mt={6} spacing={6}>
        {legendLabels.map((label, index) => (
          <Box key={data[index].locationUId} backgroundColor={CHART_COLORS_HEX[index]} px={2} py={0.5}>
            <Heading as="h4" color={colors.white} fontSize={16}>
              {label}
            </Heading>
          </Box>
        ))}
      </HStack>
    </GraphBox>
  );
};

export default ReturnTimeBox;
