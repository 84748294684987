import React, { useMemo } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

import { ItemsSummaryAccordion } from '../../../../../../../common/components/ItemsSummaryModal/ItemsSummaryAccordion';
import { CategoryGroup } from '../../../../../../../common/models/enums/category-group.enum';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../../../../contexts/ScannedRelevosContext';
import { ScannerMode } from '../../../../../../RelevoAssignment/model/enums/scanner-mode.enum';
import { DepositItemResponse } from '../../../../../model/deposit-item-response';
import { DepositResponse } from '../../../../../model/deposit-response';
import { DepositRelevoTableMode } from '../../../../../model/enums/deposit-relevo-table-mode.enum';
import { DepositRelevoTable } from '../../../DepositRelevoTable/DepositRelevoTable';

type DepositSuccessResultProps = {
  successItems: DepositItemResponse[];
  deposit: DepositResponse;
};
export const DepositSuccessResult = ({ deposit, successItems }: DepositSuccessResultProps) => {
  const translations = useTranslations();
  const { mode } = useScannedRelevosContext();
  const bowlsNumber = useMemo(
    () => successItems.filter((item) => item.category?.categoryGroup === CategoryGroup.BOWLS).length,
    [successItems],
  );
  const cupsNumber = useMemo(
    () => successItems.filter((item) => item.category?.categoryGroup === CategoryGroup.CUPS).length,
    [successItems],
  );
  const successText =
    mode === ScannerMode.DEPOSIT_ASSIGN
      ? translations('borrow_deposit_success_items')
      : translations('return_deposit_success_items');

  return (
    <Box px={8} pt={4}>
      <HStack w="100%" spacing="auto" pt={2}>
        <Box w="50%">{translations('deposit_amount')}</Box>
        <Box w="50%">
          <Text>
            {deposit?.amount} {deposit?.currency}
          </Text>
        </Box>
      </HStack>
      {bowlsNumber > 0 ? (
        <HStack w="100%" spacing="auto" pt={2}>
          <Box w="50%">{translations('bowls')}</Box>
          <Text>{bowlsNumber}</Text>
        </HStack>
      ) : null}
      {cupsNumber > 0 ? (
        <HStack w="100%" spacing="auto" pt={2}>
          <Box w="50%">{translations('cups')}</Box>
          <Box w="50%">
            <Text>{cupsNumber}</Text>
          </Box>
        </HStack>
      ) : null}
      <Box pt={2}>
        <ItemsSummaryAccordion buttonText={successText}>
          <DepositRelevoTable
            mode={DepositRelevoTableMode.SUCCESS}
            items={successItems.map((item) => {
              return { ...item, deposit: `${item.amount} ${deposit.currency}` };
            })}
          />
        </ItemsSummaryAccordion>
      </Box>
    </Box>
  );
};
