import { Box, Flex, VStack } from '@chakra-ui/react';

import { AdditionalInformation } from './components/AdditionalInformation/AdditionalInformation';
import { ReviewYourCountSummary } from './components/ReviewYourCountSummary/ReviewYourCountSummary';

export const InventoryCountFourthStep = () => {
  return (
    <VStack mt={[0, 0, 0, 20]} w={'100%'}>
      <Flex w={'100%'} pt={[0, 0, 0, 6]} wrap={'wrap'}>
        <Box w={['100%', '100%', '50%', '50%']} pr={[0, 0, 4, 4]}>
          <ReviewYourCountSummary />
        </Box>
        <Box w={['100%', '100%', '50%', '50%']} pl={[0, 0, 4, 4]}>
          <AdditionalInformation />
        </Box>
      </Flex>
    </VStack>
  );
};
