import React, { JSX, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Center, VStack } from '@chakra-ui/react';

import { useTranslations } from '../../contexts/LocalizationContext';
import { useCreateCustomer } from '../../services/customers';
import colors from '../../styles/colors';
import { CustomerCreationFormData } from './model/CustomerCreationFormData';
import { CustomerCreationResponse } from './model/CustomerCreationResponse';
import ApplicantData from './steps/ApplicantData/ApplicantData';
import ContractUpload from './steps/ContractUpload/ContractUpload';
import CreationModeSelection from './steps/CreationModeSelection/CreationModeSelection';
import CustomerCreationHomePage from './steps/CustomerCreationHomePage/CustomerCreationHomePage';
import CustomerCreationSummary from './steps/CustomerCreationSummary/CustomerCreationSummary';
import CustomerData from './steps/CustomerData/CustomerData';
import ExcelCalculatorUpload from './steps/ExcelCalculatorUpload/ExcelCalculatorUpload';
import { CreationMode, getCustomerCreationRequest, handleNextPage } from './utils/customerHelper';

const buttonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: 'none' },
  _hover: { backgroundColor: 'none' },
  _active: { backgroundColor: 'none' },
  margin: 'auto',
  fontWeight: 'normal',
  color: colors.white,
};

const CustomerCreationFormStepper = (): JSX.Element => {
  const translations = useTranslations();
  const [page, setPage] = useState(0);
  const [creationModeSelected, setCreationModeSelected] = useState(CreationMode.INPUT_INFORMATION_MANUALLY);
  const [isFileSaved, setIsFileSaved] = useState(false);
  const { mutate: createCustomer, isLoading: isLoadingCreateCustomer } = useCreateCustomer();

  const methods = useForm<CustomerCreationFormData>({ mode: 'onChange' });

  const handlePreviousStep = async () => {
    setPage(page - 1);
  };

  const handleNextStep = async () => {
    await handleNextPage(page, methods, creationModeSelected, setPage);
  };

  const onCreateCustomerSuccess = async (response: CustomerCreationResponse) => {
    setIsFileSaved(response.isFileSaved);
    await handleNextStep();
  };

  const onCreateCustomerError = () => {
    toast.error(<b>{translations('something_went_wrong')}</b>);
  };

  const setCreationModeAndHandleNextStep = async (creationMode: CreationMode) => {
    setCreationModeSelected(creationMode);
    await handleNextStep();
  };

  const handleCreateAnotherCustomer = async () => {
    setPage(0);
    methods.reset();
  };

  const handleFormSubmit = methods.handleSubmit(async (data: CustomerCreationFormData) => {
    const customerCreationRequest = getCustomerCreationRequest(creationModeSelected, data);
    if (customerCreationRequest) {
      createCustomer(customerCreationRequest, {
        onSuccess: onCreateCustomerSuccess,
        onError: onCreateCustomerError,
      });
    }
  });

  const getPreviousPageButton = () => {
    return (
      <Button
        leftIcon={<ArrowBackIcon />}
        {...buttonDefaultProps}
        backgroundColor={colors.white}
        color={colors.black}
        onClick={handlePreviousStep}
      >
        {translations('new_customer_previous_page_button')}
      </Button>
    );
  };

  const handleCalculatorUploadMode = () => setCreationModeAndHandleNextStep(CreationMode.UPLOAD_EXCEL_CALCULATOR);
  const handleManualInputMode = () => setCreationModeAndHandleNextStep(CreationMode.INPUT_INFORMATION_MANUALLY);

  const getNextPageButton = () => {
    switch (page) {
      case 0:
        return (
          <Button {...buttonDefaultProps} backgroundColor={colors.orange[500]} minWidth={144} onClick={handleNextStep}>
            {translations('new_customer_home_page_next_button')}
          </Button>
        );
      case 1:
        return (
          <Button {...buttonDefaultProps} backgroundColor={colors.grey[100]} onClick={handleNextStep}>
            {translations('new_customer_form_default_next_button')}
          </Button>
        );
      case 2:
        return (
          <VStack>
            <Button
              {...buttonDefaultProps}
              width="290px"
              backgroundColor={colors.grey[100]}
              onClick={handleCalculatorUploadMode}
            >
              {translations('new_customer_form_upload_excel_button')}
            </Button>
            <Button
              {...buttonDefaultProps}
              width="290px"
              backgroundColor={colors.grey[100]}
              onClick={handleManualInputMode}
            >
              {translations('new_customer_form_input_information_manually_button')}
            </Button>
          </VStack>
        );
      case 3:
        return (
          <Button {...buttonDefaultProps} backgroundColor={colors.grey[100]} onClick={handleNextStep}>
            {translations('new_customer_form_default_next_button')}
          </Button>
        );
      case 5:
        return (
          <Button
            {...buttonDefaultProps}
            backgroundColor={colors.orange[500]}
            minWidth={270}
            onClick={handleCreateAnotherCustomer}
          >
            {translations('new_customer_summary_create_another_customer_button')}
          </Button>
        );
      default:
        return null;
    }
  };

  // FYI: <Box display={page === 1 ? undefined : 'none'}> is for the autocomplete purpose
  // inputs must exist in the DOM until the form is submitted
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleFormSubmit}>
          {page === 0 && <CustomerCreationHomePage />}
          <Box display={page === 1 ? undefined : 'none'}>
            <ApplicantData />
          </Box>
          {page === 2 && (
            <>
              <Box position="absolute">{getPreviousPageButton()}</Box>
              <CreationModeSelection />
            </>
          )}
          {page === 3 && (
            <>
              <Box position="absolute">{getPreviousPageButton()}</Box>
              {creationModeSelected === CreationMode.INPUT_INFORMATION_MANUALLY ? (
                <CustomerData />
              ) : (
                <ExcelCalculatorUpload />
              )}
            </>
          )}
          {page === 4 && (
            <>
              <Box position="absolute">{getPreviousPageButton()}</Box>
              <ContractUpload />
              <Center>
                <Button
                  type="submit"
                  {...buttonDefaultProps}
                  backgroundColor={colors.grey[100]}
                  isLoading={isLoadingCreateCustomer}
                >
                  {translations('new_customer_form_create_customer_button')}
                </Button>
              </Center>
            </>
          )}
          {page === 5 && <CustomerCreationSummary isFileSaved={isFileSaved} />}
          <Center>{getNextPageButton()}</Center>
        </form>
      </FormProvider>
    </>
  );
};

export default CustomerCreationFormStepper;
