import React, { JSX } from 'react';
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Spinner,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { SendResetPasswordEmailStep, SendResetPasswordEmailStepProps } from '../../../types';

interface Props extends SendResetPasswordEmailStepProps {
  email: string;
  isLoading: boolean;
  isError: boolean;
  sendEmail: (email: string) => void;
}

const Confirmation = ({ currentStep, closeModal, email, isLoading, isError, sendEmail }: Props): JSX.Element | null => {
  const translations = useTranslations();
  const { colors } = useTheme();

  if (currentStep !== SendResetPasswordEmailStep.ConfirmationStep) return null;

  return (
    <>
      <ModalHeader>{translations('reset_password_are_you_sure')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <Text mb={4}>{translations('reset_password_we_will_send_email', { '{{email}}': email })}</Text>
        {isError ? (
          <Text textAlign="center" my={4} color={colors.red[500]}>
            {translations('something_went_wrong')}
          </Text>
        ) : null}
      </ModalBody>
      <ModalFooter textAlign="center">
        {isLoading ? (
          <Spinner color={colors.orange[500]} />
        ) : (
          <>
            <Button
              colorScheme="orange"
              title={translations('reset_password_send_email')}
              mr={4}
              onClick={() => sendEmail(email)}
            >
              {translations('reset_password_send_email')}
            </Button>
            <Button onClick={closeModal} title={translations('cancel')}>
              {translations('cancel')}
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default Confirmation;
