import React, { JSX } from 'react';
import { Box, Divider, Flex, HStack, Stack, useTheme, VStack } from '@chakra-ui/react';

import { getFormattedDate } from '../../../utils/dateHelper';
import { ReportsStatisticsResponse } from '../model/ReportsStatisticsResponse';
import { useReportStatisticsItemState } from '../useReportStatisticsItemState';
import { showReturnRateExplanation } from '../utils/reportsHelper';
import CardItemStatisticsCard from './CardItemStatisticsCard';
import DishStatisticsCard from './DishStatisticsCard';

const getIconPath = (iconName: string) => require(`../../../assets/icons/${iconName}`);

type CardItemProps = {
  data: ReportsStatisticsResponse;
};

const headingDefaultBoldStyle = {
  fontSize: '14px',
  fontWeight: '600',
};

const headingDefaultStyle = {
  fontSize: '14px',
};

const getDate = (dateString?: string): string => {
  return new Date(getFormattedDate(dateString)).toLocaleDateString();
};

const CardItem = ({ data }: CardItemProps): JSX.Element => {
  const { translations, returnRate, returnTime } = useReportStatisticsItemState(data);
  const { colors } = useTheme();

  return (
    <Box pt={4} px={4}>
      <Stack border="2px solid" borderColor={colors.beige[50]} borderRadius="6px">
        <VStack>
          <HStack width="100%" pt={2} px={2} spacing="auto">
            <h2 style={headingDefaultBoldStyle}>{data.locationName}</h2>
            {(data.startDate || data.endDate) && (
              <h2 style={headingDefaultStyle}>
                {data.startDate ? getDate(data.startDate) : ''} - {data.endDate ? getDate(data.endDate) : ''}
              </h2>
            )}
          </HStack>
          <Divider color={colors.grey[200]} />
          <HStack width="100%" spacing="auto" py={4}>
            <CardItemStatisticsCard
              imgSrc={getIconPath('location_stats_borrowings.svg')}
              imgAlt="borrowings"
              statsNumber={data.totalAccountBorrowings}
              statsDescription={translations('community_location_borrowings')}
            />
            <CardItemStatisticsCard
              imgSrc={getIconPath('location_stats_users.svg')}
              imgAlt="users"
              statsNumber={data.totalAccountUsers}
              statsDescription={translations('community_location_users')}
            />
            <CardItemStatisticsCard
              imgSrc={getIconPath('location_stats_return_rate.svg')}
              imgAlt="return rate"
              statsNumber={returnRate}
              statsDescription={translations('community_location_return_rate')}
              tooltip={
                showReturnRateExplanation(data.startDate, data.endDate ? data.endDate : new Date().toISOString())
                  ? translations('return_rate_explanation')
                  : undefined
              }
            />
            <CardItemStatisticsCard
              imgSrc={getIconPath('location_stats_return_time.svg')}
              imgAlt="return time"
              statsNumber={returnTime}
              statsDescription={translations('community_location_return_time')}
            />
          </HStack>
          <Flex height="100%" width="100%" wrap="wrap" bgColor={colors.beige[50]}>
            {data.dishesSummary.map((dishesStatistics) => (
              <DishStatisticsCard key={dishesStatistics.id} dishSummary={dishesStatistics} />
            ))}
          </Flex>
        </VStack>
      </Stack>
    </Box>
  );
};

export default CardItem;
