import { UseFormReturn } from 'react-hook-form';

import { getDateISOString } from '../../../utils/dateHelper';
import { CustomerCreationFormData, SepaBilling, SocialMediaPress } from '../model/CustomerCreationFormData';
import { CustomerCreationRequest, StarterSetSize } from '../model/CustomerCreationRequest';
import { CustomerCreationWithCalculatorRequest } from '../model/CustomerCreationWithCalculatorRequest';

export enum CreationMode {
  UPLOAD_EXCEL_CALCULATOR = 'UPLOAD_EXCEL_CALCULATOR',
  INPUT_INFORMATION_MANUALLY = 'INPUT_INFORMATION_MANUALLY',
}

export const getParsedData = (values: CustomerCreationFormData): CustomerCreationRequest => {
  const starterSetSize = values.starterSet?.value ?? StarterSetSize.S;
  const starterSet = {
    S: Number(starterSetSize === StarterSetSize.S),
    M: Number(starterSetSize === StarterSetSize.M),
    L: Number(starterSetSize === StarterSetSize.L),
    XL: Number(starterSetSize === StarterSetSize.XL),
    XXL: Number(starterSetSize === StarterSetSize.XXL),
  };

  // FYI: RELEVO-758 multiple starter sets has not been done yet
  // const starterSet = {
  //   S: Number(values.sStartersetNumber),
  //   M: Number(values.mStartersetNumber),
  //   L: Number(values.lStartersetNumber),
  //   XL: Number(values.xlStartersetNumber),
  //   XXL: Number(values.xxlStartersetNumber),
  // };

  const jsonFlatStarterSetValue: { [key: string]: string } = {};
  jsonFlatStarterSetValue.starterSet = JSON.stringify(starterSet);

  const { calculatorFiles, sepa, socialMediaPress, ...requestData } = values;

  const request = {
    ...requestData,
    isSepaDesired: values.sepa === SepaBilling.SEPA_DESIRED,
    isSocialMediaPressPermitted: values.socialMediaPress === SocialMediaPress.SOCIAL_MEDIA_PRESS_PERMITTED,
    starterSet,
    contractFile: values.contractFile[0],
    desiredStartDate: getDateISOString(values.desiredStartDate),
  };

  return {
    ...request,
    ...jsonFlatStarterSetValue,
  };
};

export const getParsedDataWithCalculatorFiles = (
  values: CustomerCreationFormData,
): CustomerCreationWithCalculatorRequest => {
  return {
    salesOrganisation: values.salesOrganisation,
    salesPersonName: values.salesPersonName,
    salesPersonEmail: values.salesPersonEmail,
    salesPersonPhone: values.salesPersonPhone,
    customerName: values.customerName,
    contactPersonEmail: values.contactPersonEmail,
    calculatorFiles: Array.from(values.calculatorFiles),
    contractFile: values.contractFile[0],
  };
};

export const handleNextPage = async (
  page: number,
  methods: UseFormReturn<CustomerCreationFormData>,
  creationModeSelected: CreationMode | CreationMode.UPLOAD_EXCEL_CALCULATOR,
  setPage: (value: ((prevState: number) => number) | number) => void,
) => {
  let isValid;
  switch (page) {
    case 1:
      isValid = await methods.trigger(['salesOrganisation', 'salesPersonName', 'salesPersonEmail', 'salesPersonPhone']);
      break;
    case 2:
      isValid = await methods.trigger(['customerName', 'contactPersonEmail']);
      break;
    case 3:
      if (creationModeSelected === CreationMode.INPUT_INFORMATION_MANUALLY) {
        isValid = await methods.trigger([
          'customerName',
          'contactPersonFirstName',
          'contactPersonLastName',
          'contactPersonEmail',
          'contactPersonPhone',
          'billingAddressStreetNumber',
          'billingAddressZipCode',
          'billingAddressCity',
          'billingAddressCountry',
          'billingPersonTitle',
          'billingName',
          'billingEmail',
          'desiredStartDate',
          // FYI: RELEVO-758 multiple starter sets has not been done yet
          // 'sStartersetNumber',
          // 'mStartersetNumber',
          // 'lStartersetNumber',
          // 'xlStartersetNumber',
          // 'xxlStartersetNumber',
        ]);
      } else if (creationModeSelected === CreationMode.UPLOAD_EXCEL_CALCULATOR) {
        isValid = await methods.trigger(['calculatorFiles']);
      }
      break;
    case 4:
      isValid = await methods.trigger(['contractFile']);
      break;
    default:
      isValid = true;
  }
  if (isValid) {
    setPage(page + 1);
  }
};

export const getCustomerCreationRequest = (creationModeSelected: CreationMode, data: CustomerCreationFormData) => {
  let customerCreationRequest;
  switch (creationModeSelected) {
    case CreationMode.INPUT_INFORMATION_MANUALLY:
      customerCreationRequest = getParsedData(data);
      break;
    case CreationMode.UPLOAD_EXCEL_CALCULATOR:
      customerCreationRequest = getParsedDataWithCalculatorFiles(data);
      break;
    default: {
      const exhaustiveCheck: never = creationModeSelected;
      throw new Error(exhaustiveCheck);
    }
  }
  return customerCreationRequest;
};
