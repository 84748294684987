import { LOCALIZATION_CONFIG } from './configConsts';

type Config = {
  apiUrl: string;
  websiteUrl: string;
  appName: string;
  paginationSize: 10;
  language: keyof typeof LOCALIZATION_CONFIG;
  apiDateFormat: string;
};

const config: Config = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  websiteUrl: process.env.REACT_APP_WEBSITE_URL || '',
  appName: 'RelevoRestaurants',
  paginationSize: 10,
  language: 'de',
  apiDateFormat: 'YYYY-MM-dd',
};

export default config;
