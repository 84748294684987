import React, { JSX, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import xor from 'lodash.xor';
import { ReportsDishResponse } from 'modules/Reports/model/ReportsDishResponse';

import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { CHART_COLORS_RGBA } from '../constants';

const chartOptions: ChartOptions = {
  animation: false,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
        font: {
          weight: 'bold',
        },
      },
    },
    x: {
      ticks: {
        font: {
          weight: 'bold',
        },
      },
    },
  },
};

const BorrowingsPerCategoryChart = ({ dishSummaries }: { dishSummaries: ReportsDishResponse[][] }): JSX.Element => {
  const { language } = useLocalizationContext();
  const labels = useMemo(
    () =>
      dishSummaries.reduce((acc: string[], curr: ReportsDishResponse[]) => {
        const summaryCategories = curr.map((item) => item.translations[language]);
        return xor(acc, summaryCategories);
      }, []),
    [dishSummaries, language],
  );
  const data = useMemo(() => {
    return {
      labels,
      datasets: dishSummaries.map((summary, summaryIndex) => ({
        categoryPercentage: 1.0,
        barPercentage: 1.0,
        data: summary.map((item) => ({
          y: item.totalBorrowings,
          x: item.translations[language],
        })),
        backgroundColor: CHART_COLORS_RGBA[summaryIndex],
      })),
    };
  }, [dishSummaries, labels, language]);

  return <Chart type="bar" options={chartOptions} data={data} />;
};

export default BorrowingsPerCategoryChart;
