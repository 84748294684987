import { apiPaths } from '../config/apiPaths';
import { CustomerCreationRequest } from '../modules/CustomerCreation/model/CustomerCreationRequest';
import { CustomerCreationResponse } from '../modules/CustomerCreation/model/CustomerCreationResponse';
import { CustomerCreationWithCalculatorRequest } from '../modules/CustomerCreation/model/CustomerCreationWithCalculatorRequest';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export const createCustomer = (
  createCustomerRequest: CustomerCreationRequest | CustomerCreationWithCalculatorRequest,
): Promise<CustomerCreationResponse> => {
  const formData = createFormData(createCustomerRequest);
  return axiosInstance
    .post(`${apiPaths.CUSTOMERS}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data);
};
