import { useState } from 'react';

import { ProductCategoryResponse } from '../model/ProductCategoryResponse';
import { handleSelectedCategoryIdsChange } from '../utils/filterHelper';

export const useFilterSetState = (
  productCategories: ProductCategoryResponse[],
  restaurantOptions: { value: string; label: string }[],
) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [categoryIds, setCategoryIds] = useState(productCategories.map((category) => category.id));
  const [restaurantOption, setRestaurantOption] = useState(restaurantOptions[0]);
  // eslint-disable-next-line
  const handleRestaurantOptionChange = (value: any) => setRestaurantOption(value);
  const handleCategoryIdsChange = (isChecked: boolean, categoryId: string) => {
    handleSelectedCategoryIdsChange(isChecked, categoryId, categoryIds, setCategoryIds);
  };
  const handleSelectAllCategoriesChange = (isChecked: boolean, categoryGroup: string) => {
    const categoryGroupIds = productCategories
      .filter((category) => category.categoryGroup === categoryGroup)
      .map((category) => category.id);
    if (isChecked) {
      categoryGroupIds.forEach((id) => {
        if (!categoryIds.includes(id)) {
          handleCategoryIdsChange(isChecked, id);
        }
      });
    } else {
      const ids = categoryIds.filter((id) => !categoryGroupIds.includes(id));
      setCategoryIds(ids);
    }
  };

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    categoryIds,
    setCategoryIds,
    restaurantOption,
    handleRestaurantOptionChange,
    handleCategoryIdsChange,
    handleSelectAllCategoriesChange,
  };
};
