export const WEEK_IN_MILISECONDS = 7 * 24 * 60 * 60 * 1000;

const getTimeDifference = (initialDate: number): number => {
  const today = Date.now();
  return today - initialDate;
};

export const volumePerWeek = (volume: number, startingDate: string): number => {
  const initialDate = new Date(startingDate).getTime();
  const difference = getTimeDifference(initialDate) / WEEK_IN_MILISECONDS;
  const numberOfWeeks = difference < 1 ? 1 : difference;

  const result = volume / numberOfWeeks;
  return Math.round(result * 10) / 10;
};
