import { default as Select, GroupBase, Props } from 'react-select';

import colors from '../../../styles/colors';

export const MultiSelect = <T,>(props: Props<T, true, GroupBase<T>>) => {
  return (
    <Select
      {...props}
      styles={{
        control: (base) => ({
          ...base,
          borderColor: colors.blueGrey[200],
          '&:hover': {
            borderColor: colors.blueGrey[200],
          },
        }),
        placeholder: (base) => ({ ...base, color: colors.blueGrey[400] }),
        menu: (base) => ({ ...base, zIndex: 1000 }),
        option: (base, state) => ({
          ...base,
          background: state.isSelected ? colors.orange[300] : colors.white,
          color: colors.black,
          '&:hover': {
            background: colors.orange[100],
          },
        }),
      }}
    />
  );
};
