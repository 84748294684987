import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';

import {
  PROFILE_ACCORDION_HEADER_MAX_CHARACTERS,
  PROFILE_ACCORDION_HEADER_SUFFIX,
  PROFILE_ACCORDION_LOCATIONS_MIN_VALUE,
  RESTAURANT_NAMES_SEPARATOR,
} from '../../utils/profile.consts';

type ProfileLocationsProps = {
  restaurantNames: string[];
};

export const ProfileLocations = ({ restaurantNames }: ProfileLocationsProps) => {
  const locationsNumber = useMemo(() => restaurantNames.length, [restaurantNames]);
  const joinRestaurantNames = useMemo(() => restaurantNames.join(RESTAURANT_NAMES_SEPARATOR), [restaurantNames]);

  return (
    <>
      {locationsNumber >= PROFILE_ACCORDION_LOCATIONS_MIN_VALUE ? (
        <Accordion allowToggle variant="userProfileRestaurants">
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Flex w="100%" justifyContent="space-between">
                    {isExpanded ? (
                      <Box>
                        <UnorderedList>
                          <ListItem>{restaurantNames[0]}</ListItem>
                        </UnorderedList>
                      </Box>
                    ) : (
                      <Box textAlign="left">
                        {joinRestaurantNames.slice(0, PROFILE_ACCORDION_HEADER_MAX_CHARACTERS)}
                        {PROFILE_ACCORDION_HEADER_SUFFIX}
                      </Box>
                    )}
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <AccordionPanel>
                  <UnorderedList>
                    {restaurantNames.slice(1).map((name, index) => (
                      <ListItem key={`${name}${index}`}>{name}</ListItem>
                    ))}
                  </UnorderedList>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      ) : (
        <Box>{joinRestaurantNames}</Box>
      )}
    </>
  );
};
